import { Table, Spin, Button } from 'antd';
import './index.scss';
interface tablePropsType {
    height: number;
    status: string;
    // onRow: () => {};
    // columns: ;
    // data: Array;
}
const TableBox = (props: any) => {
    return (
        <>
            {props.status === 'loading' ? (
                <div className="data_loading flex-center" style={{ height: props.height ? props.height : 'auto' }}>
                    <Spin />
                </div>
            ) : (
                <></>
            )}
            {props.status === 'empty' ? <div className="not_data flex-center">No data</div> : <></>}
            {props.status === 'showData' ? (
                <Table
                    onRow={record => {
                        return {
                            onClick: event => {
                                if (!props.onRow) return;
                                props.onRow(record);
                            },
                        };
                    }}
                    className={
                        props.height
                            ? `table_box default_height ${props.className || ''}`
                            : `table_box flex_height ${props.className || ''}`
                    }
                    columns={props.columns}
                    dataSource={props.data}
                    rowKey={props.rowKey}
                    pagination={false}
                    expandable={
                        props.isTree
                            ? {
                                  // defaultExpandAllRows: true,
                                  // expandRowByClick: true,
                                  childrenColumnName: props.childrenColumnName,
                                  expandIcon: ({ expanded, onExpand, record }) => {
                                      if (record.repliesNumber > 0) {
                                          return (
                                              <span
                                                  className={record.repliesNumber ? 'view_more' : 'columns_num'}
                                                  onClick={e => onExpand(record, e)}
                                              >
                                                  {record.repliesNumber}
                                              </span>
                                          );
                                      } else {
                                          return '';
                                      }
                                  },
                                  expandedRowRender: () => '',
                              }
                            : {}
                    }
                />
            ) : (
                <></>
            )}
        </>
    );
};
export default TableBox;
