import pink from '@/assets/images/mark.png';
import pinked from '@/assets/images/mark-hover.png';
import pinkTreasure from '@/assets/images/icon-black.png';
import pinkedTreasure from '@/assets/images/icon-blue.png';
import hoverMark from '@/assets/images/radius-mark.png';
import React, { PureComponent, useEffect, useRef, useState } from 'react';
import { defaultInfo } from '@/typings/allType';
interface MarkerInfo {
    location: string;
    radiusMetres: string;
    lon: string | number;
    lat: string | number;
    position: any;
    deleted?: boolean;
    locationId?: string;
}
const useGoogleMap = () => {
    const [isClick, setIsClick] = useState(Math.random());
    const [nowIndex, setNowIndex] = useState(-1);
    const [typeShow, setTypeShow] = useState('');
    const markerArr = useRef([] as any);
    const [markerInfoArr, setMarkerInfoArr] = useState<any[]>([]);
    const markerArrNew = useRef([] as any);
    const [markerInfoArrNew, setMarkerInfoArrNew] = useState<any[]>([]);
    const location = useRef([]);
    const thisMap = useRef({}) as any;
    useEffect(() => {
        markerArr.current.forEach(v => {
            if (!v) return;
            v?.setIcon(typeShow === 'treasure' ? pinkTreasure : pink);
        });
        if (nowIndex !== -1) {
            markerArr.current[nowIndex]?.setIcon(typeShow === 'treasure' ? pinkedTreasure : pinked);
        }
    }, [nowIndex]);
    const addMarker = (location, map, i) => {
        const marker = new window.google.maps.Marker({
            position: location,
            map: map,
            icon: typeShow === 'treasure' ? pinkTreasure : pink,
            optimized: false,
        });
        setMapCenter(location);
        return marker;
    };
    const setMapCenter = (val: any) => {
        thisMap.current.setCenter(val);
        // const latlng = new window.google.maps.LatLng(val.lat, val.lng);
        // marker.setPosition(latlng);
    };
    const getNowIndex = (arr, current) => {
        // console.log(arr);
        const index = arr.findIndex(v => +v.lat === current.latLng.lat() && +v.lon === current.latLng.lng());
        setNowIndex(index);
        markerArr.current[index]?.setIcon(pinked);
    };
    // this now data
    useEffect(() => {
        markerArr.current.forEach(v => {
            if (!v) return;
            v?.setMap(null);
        });
        let marker;
        const markerArrNow: any[] = [];
        markerInfoArr.forEach((item, i) => {
            if (item.deleted) {
                markerArrNow.push('');
                return;
            }
            marker = addMarker(item.position, thisMap.current, i);
            // map.addListener('drag', e => {
            //     const result = [map.getCenter().lat(), map.getCenter().lng()];
            //     transition(result);
            // });
            marker.addListener('click', e => {
                // console.log(e);
                setIsClick(Math.random());
            });
            marker.addListener('mouseover', e => {
                getNowIndex(markerInfoArr, e);
            });
            marker.addListener('mouseout', e => {
                markerArr.current.forEach(item => {
                    if (!item) return;
                    item?.setIcon(pink);
                });
                setNowIndex(-1);
            });
            markerArrNow.push(marker);
        });
        // new MarkerClusterer({ markers, map });
        markerArr.current = [...markerArrNow];
    }, [markerInfoArr]);
    // again data
    useEffect(() => {
        markerArrNew.current.forEach(v => {
            if (!v) return;
            v?.setMap(null);
        });
        const markerArrNow: any[] = [];
        markerInfoArrNew.forEach((item, i) => {
            const marker = new window.google.maps.Marker({
                position: new window.google.maps.LatLng(item.lat, item.lon),
                map: thisMap.current,
                icon: hoverMark,
                optimized: false,
            });
            const contentString = `<p class='store_name'>${item.name}</p>`;
            const content = document.createElement('div');
            content.innerHTML = contentString;
            const popup = createPopup(new window.google.maps.LatLng(item.lat, item.lon), content);
            popup.setMap(thisMap.current);

            // marker.popup = popup;
            marker.addListener('mouseover', e => {
                // console.log('移入');
                popup.containerDiv.style.display = 'block';
            });
            marker.addListener('mouseout', e => {
                // console.log('移出');
                popup.containerDiv.style.display = 'none';
            });
            markerArrNow.push(marker);
        });
        markerArrNew.current = [...markerArrNow];
    }, [markerInfoArrNew]);
    const styleNew = [
        {
            featureType: 'administrative.locality',
            elementType: 'labels.text',
            stylers: [
                {
                    visibility: 'simplified',
                },
            ],
        },
        {
            featureType: 'administrative.neighborhood',
            elementType: 'labels.text',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'administrative.land_parcel',
            elementType: 'labels.text',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'administrative.land_parcel',
            elementType: 'labels.icon',
            stylers: [
                {
                    visibility: 'simplified',
                },
            ],
        },
        {
            featureType: 'landscape.man_made',
            elementType: 'geometry.fill',
            stylers: [
                {
                    visibility: 'on',
                },
            ],
        },
        {
            featureType: 'landscape.man_made',
            elementType: 'labels.icon',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'landscape.natural.landcover',
            elementType: 'labels.icon',
            stylers: [
                {
                    visibility: 'on',
                },
            ],
        },
        {
            featureType: 'poi.attraction',
            elementType: 'geometry',
            stylers: [
                {
                    visibility: 'on',
                },
            ],
        },
        {
            featureType: 'poi.attraction',
            elementType: 'labels.icon',
            stylers: [
                {
                    visibility: 'off',
                },
                {
                    hue: '#ff0000',
                },
            ],
        },
        {
            featureType: 'poi.business',
            elementType: 'labels.icon',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'poi.government',
            elementType: 'labels.icon',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'poi.medical',
            elementType: 'labels.icon',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'poi.park',
            elementType: 'labels.icon',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'poi.place_of_worship',
            elementType: 'labels.icon',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'poi.school',
            elementType: 'labels.icon',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'poi.sports_complex',
            elementType: 'labels.icon',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'road.highway',
            elementType: 'labels.text',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'road.highway',
            elementType: 'labels.icon',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'road.local',
            elementType: 'labels.text',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'transit.line',
            elementType: 'labels.text',
            stylers: [
                {
                    visibility: 'simplified',
                },
            ],
        },
        {
            featureType: 'transit.station.airport',
            elementType: 'labels.icon',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'transit.station.bus',
            elementType: 'labels.icon',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'transit.station.rail',
            elementType: 'labels.icon',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'water',
            elementType: 'geometry.fill',
            stylers: [
                {
                    visibility: 'on',
                },
                {
                    color: '#c6def2',
                },
            ],
        },
    ];
    const style1 = [
        { featureType: 'administrative', elementType: 'labels.text.fill', stylers: [{ color: '#6195a0' }] },
        { featureType: 'landscape', elementType: 'all', stylers: [{ color: '#f2f2f2' }] },
        { featureType: 'landscape', elementType: 'geometry.fill', stylers: [{ color: '#ffffff' }] },
        { featureType: 'poi', elementType: 'all', stylers: [{ visibility: 'off' }] },
        {
            featureType: 'poi.park',
            elementType: 'geometry.fill',
            stylers: [{ color: '#e6f3d6' }, { visibility: 'on' }],
        },
        {
            featureType: 'road',
            elementType: 'all',
            stylers: [{ saturation: -100 }, { lightness: 45 }, { visibility: 'simplified' }],
        },
        { featureType: 'road.highway', elementType: 'all', stylers: [{ visibility: 'simplified' }] },
        {
            featureType: 'road.highway',
            elementType: 'geometry.fill',
            stylers: [{ color: '#f4d2c5' }, { visibility: 'simplified' }],
        },
        { featureType: 'road.highway', elementType: 'labels.text', stylers: [{ color: '#4e4e4e' }] },
        { featureType: 'road.arterial', elementType: 'geometry.fill', stylers: [{ color: '#f4f4f4' }] },
        { featureType: 'road.arterial', elementType: 'labels.text.fill', stylers: [{ color: '#787878' }] },
        { featureType: 'road.arterial', elementType: 'labels.icon', stylers: [{ visibility: 'off' }] },
        { featureType: 'transit', elementType: 'all', stylers: [{ visibility: 'off' }] },
        { featureType: 'water', elementType: 'all', stylers: [{ color: '#eaf6f8' }, { visibility: 'on' }] },
        { featureType: 'water', elementType: 'geometry.fill', stylers: [{ color: '#eaf6f8' }] },
    ];
    const initMap = (type?: any): void => {
        setTypeShow(type ? type : '');
        const uluru = { lat: -33.91722, lng: 151.23064 };
        // The map, centered at Uluru
        const map = new window.google.maps.Map(document.getElementById('googleMap') as HTMLElement, {
            zoom: 14,
            center: uluru,
            disableDefaultUI: true,
            gestureHandling: 'none', // none  ,  cooperative
            styles: styleNew,
        });
        thisMap.current = map;
    };
    // Add custom pop-ups
    const createPopup = (position: any, content: HTMLElement) => {
        class Popup extends window.google.maps.OverlayView {
            position;
            containerDiv;
            constructor(position, content) {
                super();
                this.position = position;
                content.classList.add('popup-bubble');

                // This zero-height div is positioned at the bottom of the bubble.
                const bubbleAnchor = document.createElement('div');

                bubbleAnchor.classList.add('popup-bubble-anchor');
                bubbleAnchor.appendChild(content);
                // This zero-height div is positioned at the bottom of the tip.
                this.containerDiv = document.createElement('div');
                this.containerDiv.classList.add('popup-container');
                this.containerDiv.appendChild(bubbleAnchor);
                // Optionally stop clicks, etc., from bubbling up to the map.
                Popup.preventMapHitsAndGesturesFrom(this.containerDiv);
                this.containerDiv.style.top = this.containerDiv.style.top - 30;
                this.containerDiv.style.left = this.containerDiv.style.left + 50;
                // this.containerDiv.style.display = 'none';
            }
            /** Called when the popup is added to the map. */
            onAdd() {
                this.getPanes().floatPane.appendChild(this.containerDiv);
            }
            /** Called when the popup is removed from the map. */
            onRemove() {
                if (this.containerDiv.parentElement) {
                    this.containerDiv.parentElement.removeChild(this.containerDiv);
                }
            }
            /** Called each frame when the popup needs to draw itself. */
            draw() {
                const divPosition = this.getProjection().fromLatLngToDivPixel(this.position);
                // Hide the popup when it is far out of view.
                const display = Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000 ? 'block' : 'none';

                if (display === 'block') {
                    this.containerDiv.style.left = divPosition.x + 50 + 'px';
                    this.containerDiv.style.top = divPosition.y - 30 + 'px';
                }
                this.containerDiv.style.display = 'none';
                // if (this.containerDiv.style.display !== display) {
                //     this.containerDiv.style.display = display;
                // }
            }
        }
        return new Popup(position, content);
    };
    return {
        location,
        initMap,
        nowIndex,
        setNowIndex,
        markerInfoArr,
        setMarkerInfoArr,
        markerInfoArrNew,
        setMarkerInfoArrNew,
        setMapCenter,
        isClick,
    };
};
export default useGoogleMap;
