import { Input, Space, Select, message } from 'antd';
import comeBackImg from '@/assets/images/back.png';
import searchIcon from '@/assets/images/search.png';
import selectIcon from '@/assets/images/down.png';
import type { ColumnsType } from 'antd/es/table';
import { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Pagination from '@/components/pagination';
import TableBox from '@/components/tableBox';
import debounce from 'lodash.debounce';
import homeApi from '@/api/dashboard';

interface DataType {
    Ranking: string;
    name: string;
    key: number;
    address: string;
    tags: string[];
}
interface getListParams {
    pageNum: number;
    pageSize: number;
    keyWord: string;
    country: number | string;
    state: string | null;
}
const columns: ColumnsType<DataType> = [
    {
        title: 'RANKING',
        dataIndex: 'ranking',
        key: 'ranking',
    },
    {
        title: 'WORKOUT LOCATIONS NAME',
        dataIndex: 'workoutName',
        key: 'workoutName',
    },
    {
        title: 'NUMBER OF COMPLETION',
        dataIndex: 'completedTimes',
        key: 'completedTimes',
    },
];

const WorkoutLocation = (props: any) => {
    useEffect(() => {
        getCountry();
    }, []);
    const navigate = useNavigate();
    const [status, setStatus] = useState('showData');
    const [listParams, setListParams] = useState<getListParams>({
        pageNum: 1,
        pageSize: 10,
        keyWord: '',
        country: 0,
        state: null,
    });
    const [totalRecord, setTotalRecord] = useState(0);
    const [country, setCountry] = useState<{ value: string | number; label: string }[]>();
    const getCountry = () => {
        const countryList: { value: string; label: string }[] = [];
        homeApi.getCountry(res => {
            if (res.code === 200) {
                res.data.forEach(i => {
                    countryList.push({ value: i, label: i });
                });
                setCountry([{ value: 0, label: 'Global' }, ...countryList]);
            } else {
                message.error(res.message);
            }
        });
    };
    const [stateOptions, setStateOptions] = useState<{ value: string; label: string }[]>([]);
    const getState = country => {
        if (!country) return;
        const stateList: { value: string; label: string }[] = [];
        homeApi.getState({ country }, res => {
            if (res.code === 200) {
                res.data.forEach(i => {
                    stateList.push({ value: i, label: i });
                });
                setStateOptions(stateList);
            } else {
                message.error(res.message);
            }
        });
    };
    const debouncedSave = useCallback(
        debounce(nextValue => setListParams(nextValue), 1000),
        []
    );
    const inputChange = e => {
        debouncedSave({ ...listParams, keyword: e.target.value, pageNum: 1 });
    };
    useEffect(() => {
        getRanking(listParams);
    }, [listParams]);
    const [rankingList, setRankingList] = useState<DataType[]>([]);
    const getRanking = listParams => {
        if (listParams.country && !listParams.state) return;
        setStatus('loading');
        const params = {
            pageNum: listParams.pageNum,
            pageSize: 10,
            keyword: listParams.keyword,
            country: listParams.country ? listParams.country : null,
            state: listParams.country ? listParams.state : null,
        };
        homeApi.getWorkoutRangking(params, res => {
            if (res.code === 200) {
                if (res.data.records.length > 0) {
                    setRankingList(res.data.records);
                    setTotalRecord(res.data.total);
                    setStatus('showData');
                } else {
                    setStatus('empty');
                }
            } else {
                message.error(res.message);
                setStatus('empty');
            }
        });
    };
    const handlePageChange = prop => {
        setListParams(state => ({ ...state, pageNum: prop }));
    };
    return (
        <div className="page-main fitLoot table_page">
            <div className="flex-between page_head t72_b50">
                <div
                    className="come_back"
                    onClick={() => {
                        navigate('/dashboard');
                    }}
                >
                    <img src={comeBackImg} alt="" />
                    Back
                </div>
            </div>
            <div className="search_box">
                <Space wrap>
                    <Input
                        allowClear
                        className="search_input"
                        size="large"
                        placeholder="Search"
                        style={{ width: 210 }}
                        maxLength={50}
                        prefix={<img src={searchIcon} alt="" />}
                        onChange={inputChange}
                    />
                    <Select
                        suffixIcon={<img src={selectIcon} alt="" />}
                        defaultValue={0}
                        style={{ width: 138 }}
                        onChange={(value: number) => {
                            setListParams(state => ({
                                ...state,
                                country: value,
                                state: value ? listParams.state : null,
                                pageNum: value ? listParams.pageNum : 1,
                            }));
                            getState(value);
                        }}
                        options={country}
                    />
                    <Select
                        suffixIcon={<img src={selectIcon} alt="" />}
                        value={listParams.state}
                        placeholder="State"
                        disabled={!listParams.country}
                        style={{ width: 138 }}
                        onChange={(value: string) => {
                            setListParams(state => ({
                                ...state,
                                state: value,
                                pageNum: 1,
                            }));
                        }}
                        options={listParams.country ? stateOptions : []}
                    />
                </Space>
            </div>
            <TableBox status={status} columns={columns} data={rankingList} rowKey="workoutId" />
            {status === 'showData' ? (
                <Pagination total={totalRecord} pageChange={handlePageChange} pageNo={listParams.pageNum} />
            ) : (
                <></>
            )}
        </div>
    );
};
export default WorkoutLocation;
