import axios from '../utils/index';
import { Pat } from '@/typings/request';
const settingsApi = {
    versionAdd(data: Pat, callback: (T: any) => void) {
        axios
            .post(`/admin/version`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getVersionList(params: Pat, callback: (T: any) => void) {
        axios
            .get(`/admin/version`, {
                params,
            })
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    workoutTypeAdd(data: Pat, callback: (T: any) => void) {
        axios
            .post(`/admin/workout/workout-type`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getWorkoutTypeList(callback: (T: any) => void) {
        axios
            .get(`/admin/common/workout-type`)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    deleteWorkoutType(typeId: string, callback: (T: any) => void) {
        axios
            .delete(`/admin/workout/workout-type/${typeId}`)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
};
export default settingsApi;
