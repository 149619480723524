import NavLogo from '@/assets/images/nav-logo.png';
import './index.scss';
const DeleteInstructions = () => {
    return (
        <div className="delete_instructions">
            <div className="logo">
                <img src={NavLogo} alt="" />
            </div>
            <div className="delete_instructions_content">
                <h1>Facebook Data Deletion Instructions URL</h1>
                <div className="step">Geonasium does not save your Facebook personal data on its server. </div>
                <div className="step">
                    However, according to Facebook policy, we have to provide User Data Deletion Callback URL or Data
                    Deletion Instructions URL.
                </div>
                <div className="step">
                    If you want to delete your data from Geonasium App, you can remove your information by following
                    these steps:
                </div>
                <div className="step">1. Go to Geonasium's Profile page</div>
                <div className="step">2. Swipe to the bottom of the page and click Delete Account.</div>
                <div className="step">
                    3. When the user deletes the account, Geonasium will clear all account information related to the
                    user, including Facebook information
                </div>
            </div>
            <div className="delete_instructions_footer">
                <div className="footer_logo">
                    <img
                        src="https://geonasium-email.s3.ap-southeast-2.amazonaws.com/email/geonasium_logo.png"
                        alt=""
                    />
                </div>
                <div>
                    You can view our <a>Terms of Service</a> &
                    <a
                        target="_blank"
                        href="https://www.geonasium.com.au/privacy-policy"
                        rel="noreferrer"
                        style={{ marginLeft: 3 }}
                    >
                        Privacy Policy
                    </a>
                </div>
            </div>
        </div>
    );
};
export default DeleteInstructions;
