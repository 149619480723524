import React from 'react';
// ---------------user---------------------------
import User from '@/views/pages/User';
import UserDetail from '@/views/pages/User/UserDetail';
import GeonasiumPoints from '@/views/pages/User/GeonasiumPoints';
import FitlootList from '@/views/pages/User/FitlootList';
import RelationList from '@/views/pages/User/RelationList';
import PostList from '@/views/pages/User/PostList';
import GroupList from '@/views/pages/User/GroupList';
import GroupDetail from '@/views/pages/User/GroupDetail';
import UserWorkoutRecord from '@/views/pages/User/UserWorkoutRecord';
import UserWorkoutDetail from '@/views/pages/User/UserWorkoutDetail';
import UserRewardsRedemption from '@/views/pages/User/UserRewardsRedemption';
// ---------------Dashboard---------------------------
import Points from '@/views/pages/Dashboard/Points';
import workoutRanking from '@/views/pages/Dashboard/workoutRanking';
// ---------------Socials---------------------------
import Socials from '@/views/pages/Socials';
import ReportApplication from '@/views/pages/Socials/ReportApplication';
import CommentList from '@/views/pages/Socials/CommentList';
import PostDetail from '@/views/pages/Socials/PostDetail';
// ---------------Workouts---------------------------
import Workouts from '@/views/pages/Workouts';
import CreateWorkout from '@/views/pages/Workouts/create-workout';
import WorkoutDetail from '@/views/pages/Workouts/workout-detail';
import WorkoutStatisticsList from '@/views/pages/Workouts/workout-statistics-list';
import WorkoutFeedback from '@/views/pages/Workouts/workout-feedback';
import WorkoutActionLibrary from '@/views/pages/Workouts/workout-action-library';
import ActionLibraryDetail from '@/views/pages/Workouts/action-library-detail';
import CreateActionLibrary from '@/views/pages/Workouts/create-action-library';
// ---------------Rewards---------------------------
import Rewards from '@/views/pages/Rewards';
import RewardDetail from '@/views/pages/Rewards/reward-detail';
import WorkoutReward from '@/views/pages/Rewards/workout-reward';
import FitlootReward from '@/views/pages/Rewards/fitloot-reward';
import FitlootRewardDetail from '@/views/pages/Rewards/fitloot-reward-detail';
import RewardPool from '@/views/pages/Rewards/rewards-pool';
import RewardsTreasure from '@/views/pages/Rewards/rewards-treasure';
import AddRewardPool from '@/views/pages/Rewards/add-reward-pool';
import AddRewardsTreasure from '@/views/pages/Rewards/add-rewards-treasure';
// ---------------Brand---------------------------
import Brand from '@/views/pages/Brand';
import BrandDetail from '@/views/pages/Brand/brand-detail';
import CreateBrand from '@/views/pages/Brand/create-brand';
import CreateLocation from '@/views/pages/Brand/create-location';
import CreateOffer from '@/views/pages/Brand/create-offer';
import offerDetail from '@/views/pages/Brand/offer-detail';
import Subscription from '@/views/pages/Subscription';
// ---------------setting---------------------------
import Setting from '../views/pages/setting/index';
import Versions from '@/views/pages/versions';
import UploadVersion from '@/views/pages/versions/UploadVersion';
import WorkoutType from '@/views/pages/setting/WorkoutType';
import Index from '@/views/pages/index';
import Dashboard from '@/views/pages/Dashboard';
import DownLoadApp from '@/views/pages/SharePage/DownLoadApp';
import Share from '@/views/pages/SharePage/Share';
import DeleteInstructions from '@/views/deleteInstructions';
import DeleteUserAccount from '@/views/deleteAppAccount';
const Login = React.lazy(() => import('@/views/login'));
const RestPassword = React.lazy(() => import('@/views/resetPassword'));
export interface RouteConfigDeclaration {
    path: string;
    name?: string;
    exact?: boolean;
    auth?: boolean;
    isRedirect?: boolean;
    isDynamic?: boolean;
    loadingFallback?: string;
    component: any;
    childrens?: RouteConfigDeclaration[];
    isShowOnNav?: boolean;
    KeepAlive?: boolean;
}
const routes: RouteConfigDeclaration[] = [
    {
        path: '/share',
        component: Share,
        exact: true,
        auth: false,
    },
    {
        path: '/deleteInstructions',
        component: DeleteInstructions,
        exact: true,
        auth: false,
    },
    {
        path: '/deleteUserAccount',
        component: DeleteUserAccount,
        exact: true,
        auth: false,
    },
    {
        path: '/downLoadApp',
        component: DownLoadApp,
        exact: true,
        auth: false,
    },
    {
        path: '/login',
        component: Login,
        exact: true,
        auth: false,
    },
    {
        path: '/restPassword',
        component: RestPassword,
        exact: true,
        auth: false,
    },
    {
        path: '/*',
        component: Index,
        exact: false,
        childrens: [
            {
                name: 'Dashboard',
                path: '/dashboard',
                component: Dashboard,
                exact: true,
                isShowOnNav: true,
                KeepAlive: true,
            },
            {
                name: 'Points',
                path: '/dashboard/points',
                component: Points,
                exact: true,
            },
            {
                name: 'workoutRanking',
                path: '/dashboard/workoutLocations',
                component: workoutRanking,
                exact: true,
            },
            {
                name: 'Users',
                path: '/user',
                component: User,
                exact: true,
                isShowOnNav: true,
                KeepAlive: true,
            },
            {
                name: 'UserDetail',
                path: '/user/detail',
                component: UserDetail,
                exact: true,
                isShowOnNav: false,
                KeepAlive: true,
            },
            {
                name: 'points',
                path: '/user/points',
                component: GeonasiumPoints,
                exact: true,
                isShowOnNav: false,
            },
            {
                name: 'FitlootList',
                path: '/user/fitloot',
                component: FitlootList,
                exact: true,
                isShowOnNav: false,
            },
            {
                name: 'RelationList',
                path: '/user/relation',
                component: RelationList,
                exact: true,
                isShowOnNav: false,
                KeepAlive: true,
            },
            {
                name: 'PostList',
                path: '/user/post',
                component: PostList,
                exact: true,
                isShowOnNav: false,
            },
            {
                name: 'GroupList',
                path: '/user/group',
                component: GroupList,
                exact: true,
                isShowOnNav: false,
                KeepAlive: true,
            },
            {
                name: 'GroupDetail',
                path: '/user/groupDetail',
                component: GroupDetail,
                exact: true,
                isShowOnNav: false,
            },
            {
                name: 'UserWorkoutRecord',
                path: '/user/workoutRecord',
                component: UserWorkoutRecord,
                exact: true,
                isShowOnNav: false,
                KeepAlive: true,
            },
            {
                name: 'UserWorkoutDetail',
                path: '/user/workoutDetail',
                component: UserWorkoutDetail,
                exact: true,
                isShowOnNav: false,
                KeepAlive: true,
            },
            {
                name: 'UserRewardsRedemption',
                path: '/user/userRewardsRedemption',
                component: UserRewardsRedemption,
                exact: true,
                isShowOnNav: false,
            },
            {
                name: 'Socials',
                path: '/socials',
                component: Socials,
                exact: true,
                isShowOnNav: true,
                KeepAlive: true,
            },
            {
                name: 'ReportApplication',
                path: '/socials/reportReportApplication',
                component: ReportApplication,
                exact: true,
                KeepAlive: true,
            },
            {
                name: 'Comments',
                path: '/socials/comments',
                component: CommentList,
                exact: true,
            },
            {
                name: 'PostDetail',
                path: '/socials/post',
                component: PostDetail,
                exact: true,
                KeepAlive: true,
            },
            {
                name: 'Workouts',
                path: '/workouts',
                component: Workouts,
                exact: true,
                isShowOnNav: true,
                KeepAlive: true,
            },
            {
                name: 'CreateWorkout',
                path: '/workouts/create-workout',
                component: CreateWorkout,
                exact: true,
                KeepAlive: false,
            },
            {
                name: 'WorkoutDetail',
                path: '/workouts/detail',
                component: WorkoutDetail,
                exact: true,
                KeepAlive: false,
            },
            {
                name: 'WorkoutFeedback',
                path: '/workouts/feedback',
                component: WorkoutFeedback,
                exact: true,
            },
            {
                name: 'WorkoutStatisticsList',
                path: '/workouts/statistics-list',
                component: WorkoutStatisticsList,
                exact: true,
                KeepAlive: true,
            },
            {
                name: 'WorkoutActionLibrary',
                path: '/workouts/actionLibrary',
                component: WorkoutActionLibrary,
                exact: true,
                KeepAlive: true,
            },
            {
                name: 'ActionLibraryDetail',
                path: '/workouts/actionLibrary-detail',
                component: ActionLibraryDetail,
                exact: true,
            },
            {
                name: 'CreateActionLibrary',
                path: '/workouts/create-actionLibrary',
                component: CreateActionLibrary,
                exact: true,
            },
            {
                name: 'Rewards',
                path: '/rewards',
                component: Rewards,
                exact: true,
                isShowOnNav: true,
                KeepAlive: true,
            },
            {
                name: 'RewardDetail',
                path: '/rewards/detail',
                component: RewardDetail,
                exact: true,
                KeepAlive: true,
            },
            {
                name: 'WorkoutReward',
                path: '/rewards/workout-reward',
                component: WorkoutReward,
                exact: true,
            },
            {
                name: 'FitlootReward',
                path: '/rewards/fitloot-reward',
                component: FitlootReward,
                exact: true,
            },
            {
                name: 'FitlootRewardDetail',
                path: '/rewards/fitloot-detail',
                component: FitlootRewardDetail,
                exact: true,
            },
            {
                name: 'RewardPool',
                path: '/rewards/pool',
                component: RewardPool,
                exact: true,
                KeepAlive: true,
            },
            {
                name: 'RewardsTreasure',
                path: '/rewards/treasure',
                component: RewardsTreasure,
                exact: true,
                KeepAlive: true,
            },
            {
                name: 'AddRewardPool',
                path: '/rewards/add-reward-pool',
                component: AddRewardPool,
                exact: true,
            },
            {
                name: 'AddRewardsTreasure',
                path: '/rewards/add-reward-treasure',
                component: AddRewardsTreasure,
                exact: true,
            },
            {
                name: 'Brand',
                path: '/brand',
                component: Brand,
                exact: true,
                isShowOnNav: true,
                KeepAlive: true,
            },
            {
                name: 'BrandDetail',
                path: '/brand/detail',
                component: BrandDetail,
                exact: true,
                KeepAlive: true,
            },
            {
                name: 'CreateBrand',
                path: '/brand/create-brand',
                component: CreateBrand,
                exact: true,
            },
            {
                name: 'CreateLocation',
                path: '/brand/create-location',
                component: CreateLocation,
                exact: true,
            },
            {
                name: 'CreateOffer',
                path: '/brand/create-offer',
                component: CreateOffer,
                exact: true,
            },
            {
                name: 'offerDetail',
                path: '/brand/offer-detail',
                component: offerDetail,
                exact: true,
            },
            {
                name: 'Subscription',
                path: '/subscription',
                component: Subscription,
                exact: true,
                isShowOnNav: true,
            },
            {
                name: 'Setting',
                path: '/setting',
                component: Setting,
                exact: true,
                isShowOnNav: true,
            },
            {
                name: 'Versions',
                path: '/setting/versions',
                component: Versions,
                exact: true,
                isShowOnNav: false,
            },
            {
                name: 'UploadVersion',
                path: '/setting/uploadVersion',
                component: UploadVersion,
                exact: true,
                isShowOnNav: false,
            },
            {
                name: 'WorkoutType',
                path: '/setting/workoutType',
                component: WorkoutType,
                exact: true,
                isShowOnNav: false,
            },
        ],
    },
];

export default routes;
