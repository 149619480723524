import { Button, Modal, Form, Input, Space, message } from 'antd';
import comeBackImg from '@/assets/images/back.png';
import closeIcon from '@/assets/images/Icons_Cross.png';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import type { ColumnsType } from 'antd/es/table';
import TableBox from '@/components/tableBox';
import settingsApi from '@/api/settings';
interface DataType {
    workoutTypeName: string;
    workoutTypeId: string;
}
const WorkoutType = (props: any) => {
    useEffect(() => {
        getWorkoutTypeList();
    }, []);
    const [status, setStatus] = useState('showData');
    const [currentWorkoutType, setCurrentWorkoutType] = useState<DataType>({
        workoutTypeName: '',
        workoutTypeId: '',
    });
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
    const [workoutTypeForm] = Form.useForm();
    const workoutTypeName = Form.useWatch('workoutTypeName', workoutTypeForm);
    const onFinish = (values: { workoutTypeName: string }) => {
        settingsApi.workoutTypeAdd(values, res => {
            if (res.code === 200) {
                getWorkoutTypeList();
                workoutTypeHandleCancel();
                message.success(res.message);
            } else {
                message.error(res.message);
            }
        });
    };

    const onFinishFailed = (errorInfo: any) => {
        message.error(errorInfo.errorFields[0].errors[0]);
    };
    const [workoutList, setWorkoutList] = useState<DataType[]>([]);
    const getWorkoutTypeList = () => {
        setStatus('loading');
        settingsApi.getWorkoutTypeList(res => {
            if (res.code === 200) {
                if (res.data.length > 0) {
                    setWorkoutList(res.data);
                    setStatus('showData');
                } else {
                    setStatus('empty');
                }
            } else {
                setStatus('empty');
                message.error(res.message);
            }
        });
    };
    const workoutTypeHandleCancel = () => {
        workoutTypeForm.resetFields();
        setIsModalOpen(false);
    };
    const columns: ColumnsType<DataType> = [
        {
            title: 'Workout Type',
            dataIndex: 'workoutTypeName',
            key: 'workoutTypeName',
        },
        {
            width: 170,
            title: '',
            render: (status, record) => (
                <Button
                    className="table_button"
                    onClick={e => {
                        e.stopPropagation();
                        setCurrentWorkoutType(record);
                        setIsRemoveModalOpen(true);
                    }}
                >
                    Remove
                </Button>
            ),
        },
    ];
    const deleteWorkoutType = () => {
        settingsApi.deleteWorkoutType(currentWorkoutType.workoutTypeId, res => {
            if (res.code === 200) {
                getWorkoutTypeList();
                setIsRemoveModalOpen(false);
                message.success(res.message);
            } else {
                message.error(res.message);
            }
        });
    };
    return (
        <div className="page-main versions">
            <div className="flex-between" style={{ margin: '57px 0 40px' }}>
                <div
                    className="come_back"
                    onClick={() => {
                        navigate('/setting');
                    }}
                >
                    <img src={comeBackImg} alt="" />
                    Back
                </div>
                <Button
                    style={{
                        width: 220,
                        height: 48,
                        borderRadius: '4px',
                        marginLeft: 15,
                        color: '#0085FF',
                        borderColor: '#0085FF',
                    }}
                    onClick={() => {
                        setIsModalOpen(true);
                    }}
                >
                    Add New Workout Type
                </Button>
            </div>
            <TableBox status={status} columns={columns} data={workoutList} rowKey="workoutTypeId" />
            <Modal
                className="workout_type_modal modal_no_padding modal_form_w400"
                maskClosable={false}
                centered={true}
                open={isModalOpen}
                closeIcon={<img src={closeIcon} alt="" className="close-icon" />}
                onCancel={workoutTypeHandleCancel}
                footer={[<div className="flex-center"></div>]}
                title={[<div className="modal-top-title">Add new workout type</div>]}
            >
                <Form
                    name="update_password_form"
                    className="update_password_form"
                    form={workoutTypeForm}
                    layout="vertical"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    validateTrigger="onBlur"
                    autoComplete="off"
                >
                    <Form.Item
                        label="Workout type"
                        name="workoutTypeName"
                        rules={[{ required: true, message: 'Please type Workout type!' }]}
                    >
                        <Input className="default_input" maxLength={100} />
                    </Form.Item>
                    <Form.Item>
                        <Space size={12} style={{ marginTop: 40 }}>
                            <Button className="submit-btn" onClick={workoutTypeHandleCancel}>
                                Cancel
                            </Button>
                            <Button className="submit-btn" type="primary" htmlType="submit" disabled={!workoutTypeName}>
                                Add
                            </Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                className="modal-suspend"
                closable={false}
                maskClosable={false}
                centered={true}
                open={isRemoveModalOpen}
                onCancel={() => {
                    setIsRemoveModalOpen(false);
                }}
                footer={[]}
            >
                <div className="modal-tip">Are you sure to delete this workout type?</div>
                <Space size={12} style={{ marginTop: 43 }}>
                    <Button
                        onClick={() => {
                            setIsRemoveModalOpen(false);
                        }}
                        style={{ width: 194, height: 54 }}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="primary"
                        htmlType="submit"
                        onClick={deleteWorkoutType}
                        style={{ width: 194, height: 54 }}
                    >
                        Confirm
                    </Button>
                </Space>
            </Modal>
        </div>
    );
};
export default WorkoutType;
