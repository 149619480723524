import { useNavigate, useLocation } from 'react-router-dom';
import { Space, Button, Modal, message, Spin } from 'antd';
import { useState, useEffect } from 'react';
import comeBackImg from '@/assets/images/back.png';
import { useSelector } from 'react-redux';
import userApi from '@/api/user';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { useAppDispatch } from '@/store/hooks';
import { setIsGetList } from '@/store/stateSlice';
import { useAliveController } from 'react-activation';
interface workoutType {
    createTime: number;
    adventureMode: boolean;
    totalTime: string;
    totalPoints: string;
    fitLoots: string;
    caloriesBurned: string;
    heartRate: string;
    postPublished: string;
    workoutList: {
        workoutName: string;
        workoutType: string;
        startTime: number;
        workoutLocation: string;
        difficultyLevel: string;
        satisfyLevel: string;
    }[];
}
const RecordDetail = (props: any) => {
    const { currentUserId } = useSelector((state: any) => state.state);
    const query = new URLSearchParams(useLocation().search);
    useEffect(() => {
        if (query.get('groupId')) {
            getWorkoutDetail();
        }
    }, []);
    const [workoutDetail, setWorkoutDetail] = useState<workoutType>({
        createTime: 0,
        adventureMode: false,
        totalTime: '',
        totalPoints: '',
        fitLoots: '',
        caloriesBurned: '',
        heartRate: '',
        postPublished: '',
        workoutList: [],
    });
    const getWorkoutDetail = () => {
        userApi.getUserWorkoutDetail({ userId: currentUserId, groupId: query.get('groupId') }, res => {
            const { data, code } = res;
            if (code === 200) {
                setWorkoutDetail(data);
            } else {
                message.error(res.message);
            }
            setPageLoading(false);
        });
    };
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const cardData = [
        [
            {
                label: 'COMPLETED DATE AND TIME :',
                value: 'createTime',
            },
            {
                label: 'ADVENTURE WORKOUT :',
                value: 'adventureMode',
            },
            {
                label: 'TOTAL WORKOUT TIME :',
                value: 'totalTime',
                unit: 'mins',
            },
            {
                label: 'NUMBER OF ACTIVE CALORIES BURNT :',
                value: 'caloriesBurned',
                unit: 'calories',
            },
            {
                label: 'AVERAGE HEART RATE :',
                value: 'heartRate',
                unit: 'BPM',
            },
            {
                label: 'GEONASIUM POINTS EARNED :',
                value: 'totalPoints',
            },
            {
                label: 'FITLOOT EARNED :',
                value: 'fitLoots',
            },
            {
                label: 'POST PUBLISHED :',
                value: 'postPublished',
            },
        ],
    ];
    const workoutLabel = [
        { title: 'workoutName' },
        {
            label: 'WORKOUT TYPE : ',
            value: 'workoutTypeName',
        },
        {
            label: 'WORKOUT START DATE AND TIME :',
            value: 'startTime',
        },
        {
            label: 'Workout duration (approx) :',
            value: 'workoutDuration',
            unit: 'mins',
        },
        {
            label: 'Location of activity :',
            value: 'workoutLocation',
        },
        {
            label: 'Intensity rating :',
            value: 'difficultyLevel',
        },
        {
            label: 'satisfaction rating :',
            value: 'satisfyLevel',
        },
    ];
    const dispatch = useAppDispatch();
    const deleteWorkout = async () => {
        await userApi.deleteUserWorkout(currentUserId, query.get('groupId') || '', res => {
            if (res.code === 200) {
                setIsModalOpen(false);
                drop('UserWorkoutDetail');
                navigate('/user/workoutRecord');
                dispatch(setIsGetList(true));
                message.success(res.message);
            } else {
                message.error(res.message);
            }
        });
    };
    dayjs.extend(duration);
    const { drop } = useAliveController();
    const [PageLoading, setPageLoading] = useState(true);
    return (
        <>
            {PageLoading ? (
                <div className="data_loading flex-center" style={{ height: 500 }}>
                    <Spin />
                </div>
            ) : (
                <div className="page-main user_workout_detail">
                    <div className="page_head flex-between  t57_b25">
                        <div
                            className="come_back"
                            onClick={() => {
                                drop('UserWorkoutDetail');
                                navigate('/user/workoutRecord');
                            }}
                        >
                            <img src={comeBackImg} alt="" />
                            Back
                        </div>
                        <Button
                            style={{ width: 146, height: 48 }}
                            onClick={() => {
                                setIsModalOpen(true);
                            }}
                        >
                            Remove
                        </Button>
                    </div>
                    {cardData.map(group => {
                        return (
                            <div className="workout_detail_introduction">
                                {group.map(item => {
                                    return workoutDetail[item.value] !== undefined ? (
                                        <div className="workout_detail_introduction_item">
                                            <span className="workout_detail_introduction_label">
                                                {item.label?.toUpperCase()}
                                            </span>
                                            <span className="workout_detail_introduction_value">
                                                {item.value === 'createTime' || item.value === 'postPublished'
                                                    ? dayjs(+workoutDetail[item.value]).format('DD/MM/YYYY/ HH:mm')
                                                    : item.value === 'adventureMode'
                                                    ? `${workoutDetail[item.value] ? 'YES' : 'NO'}`
                                                    : item.value === 'totalTime'
                                                    ? `${
                                                          +workoutDetail['totalTime'] > 3599
                                                              ? dayjs
                                                                    .duration(+workoutDetail['totalTime'] * 1000)
                                                                    .format('HH') + 'h '
                                                              : ''
                                                      }` +
                                                      `${
                                                          +workoutDetail['totalTime'] > 59
                                                              ? dayjs
                                                                    .duration(+workoutDetail['totalTime'] * 1000)
                                                                    .format('mm') + 'm '
                                                              : ''
                                                      }` +
                                                      dayjs.duration(+workoutDetail['totalTime'] * 1000).format('ss') +
                                                      's '
                                                    : `${Number(workoutDetail[item.value]).toFixed()} ${
                                                          item.unit ? item.unit : ''
                                                      }`}
                                            </span>
                                        </div>
                                    ) : (
                                        ''
                                    );
                                })}
                            </div>
                        );
                    })}
                    {workoutDetail.workoutList.map(workout => {
                        return (
                            <div className="workout_detail_introduction">
                                {workoutLabel.map(i => {
                                    return i.title ? (
                                        <div
                                            className="workout_detail_introduction_title"
                                            onClick={() => {
                                                sessionStorage.setItem('workoutId', workout['workoutId']);
                                                navigate(
                                                    `/workouts/detail?from=/user/workoutDetail?groupId=${query.get(
                                                        'groupId'
                                                    )}`
                                                );
                                            }}
                                        >
                                            {workout[i.title]}
                                        </div>
                                    ) : (
                                        <div className="workout_detail_introduction_item">
                                            <span className="workout_detail_introduction_label">
                                                {i.label?.toUpperCase()}
                                            </span>
                                            <span className="workout_detail_introduction_value">
                                                {i.value === 'startTime'
                                                    ? dayjs(workout[i.value] * 1).format('DD/MM/YYYY/ HH:mm')
                                                    : `${workout[i.value || 0]} ${i.unit ? i.unit : ''}`}
                                            </span>
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                    <Modal
                        className="modal-suspend"
                        closable={false}
                        maskClosable={false}
                        centered={true}
                        open={isModalOpen}
                        onCancel={() => {
                            setIsModalOpen(false);
                        }}
                        footer={[]}
                    >
                        <div className="modal-tip">
                            After deleting this activity, the Geonasium and FitLoot points earned from completing the
                            workout, as well as related posts, will be deleted. Are you sure you want to delete this
                            activity?
                        </div>
                        <Space size={12} style={{ marginTop: 43 }}>
                            <Button
                                onClick={() => {
                                    setIsModalOpen(false);
                                }}
                                style={{ width: 194, height: 54 }}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="primary"
                                htmlType="submit"
                                onClick={deleteWorkout}
                                style={{ width: 194, height: 54 }}
                            >
                                Confirm
                            </Button>
                        </Space>
                    </Modal>
                </div>
            )}
        </>
    );
};
export default RecordDetail;
