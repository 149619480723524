import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Modal, Spin } from 'antd';
import './style/reward-detail.scss';
import rewardsApi from '@/api/rewards';
import { defaultInfo } from '@/typings/allType';
const FitlootRewardDetail = (props: any) => {
    const navigate = useNavigate();
    const [state, setState] = useState({
        businessName: '',
        businessLogo: '',
        businessWebsite: '',
        workoutRewardsId: '',
        rewardsName: '',
        rewardsImage: '',
        redemptionCriteria: '',
        expiryDateShow: '',
        quantity: '',
        desc: '',
        redemptionTypes: 1,
        fitLootAmount: '0',
        collectionRecordVOS: [] as defaultInfo[],
    });
    const [workoutRewardsId, setWorkoutRewardsId] = useState('');
    const [status, setStatus] = useState('loading');
    useEffect(() => {
        const workoutRewardsId = window.sessionStorage.getItem('workoutRewardsId') as string;
        if (workoutRewardsId) {
            setState({ ...state, workoutRewardsId });
            setWorkoutRewardsId(workoutRewardsId);
            const params = {
                redeemRewardsId: workoutRewardsId,
            };
            getInfo(params);
        }
    }, []);
    const getInfo = (params: any) => {
        rewardsApi.getRedeemRewardInfo(params, res => {
            const { data, code, message } = res;
            setStatus('showData');
            if (code === 200) {
                if (!data.expiryStatus && data.expiryDate) {
                    data.expiryDateShow = window.format.foramtTimeDMYHM(+data.expiryDate);
                } else {
                    data.expiryDateShow = 'Forever';
                }
                if (data.collectionRecordVOS && data.collectionRecordVOS.length) {
                    data.collectionRecordVOS.forEach((item: defaultInfo) => {
                        item.collectionTime = window.format.foramtTimeDMYHM(+item.collectionTime);
                    });
                }
                setState({
                    ...state,
                    ...data,
                    desc: data.description,
                    businessWebsite: window.format.getWebsiteShow(data.businessWebsite),
                });
            } else {
                window.format.alert('error', message);
            }
        });
    };
    const back = () => {
        navigate(-1);
    };
    // const linkToPage = (type: number) => {
    //     if (type === 1) {
    //         navigate('/workouts/detail');
    //         return;
    //     }
    //     navigate('/workouts/statistics-list');
    // };
    const [showMore, setShowMore] = useState(false);
    const opeation = (key: number) => {
        if (key) {
            // del
            setModalVisibile(true);
            return;
        }
        const obj = JSON.parse(JSON.stringify(state));
        delete obj.collectionRecordVOS;
        window.sessionStorage.setItem('fitlootRewardInfo', JSON.stringify(obj));
        navigate('/rewards/fitloot-reward');
    };
    const confirmDel = () => {
        const params = {
            id: workoutRewardsId,
        };
        setDelLoading(true);
        rewardsApi.delRedeemReward(params, res => {
            const { data, code, message } = res;
            setDelLoading(false);
            if (code === 200) {
                setModalVisibile(false);
                back();
            } else if (code === 1000) {
                setModalVisibile(false);
                setAlertModalVisibile(true);
            } else {
                window.format.alert('error', message);
            }
        });
    };
    const [modalVisibile, setModalVisibile] = useState(false);
    const [alertModalVisibile, setAlertModalVisibile] = useState(false);
    const [delLoading, setDelLoading] = useState(false);
    return (
        <>
            <div className="RewardDetail-detail_main">
                <div className="top_back r-left">
                    <div className="back_box r-left" onClick={back}>
                        <i className="back_icon"></i>
                        Back
                    </div>
                    <Button className="r-defaultBlick_btn marLeft" style={{ width: 146 }} onClick={() => opeation(0)}>
                        Edit
                    </Button>
                    <div className="space15"></div>
                    <Button className="r-defaultBlick_btn" style={{ width: 146 }} onClick={() => opeation(1)}>
                        Remove
                    </Button>
                </div>
                {status === 'loading' ? (
                    <div className="data_loading flex-center" style={{ height: 'auto' }}>
                        <Spin />
                    </div>
                ) : (
                    <section className="detail_box">
                        <div className="r-top" style={{ marginTop: 35 }}>
                            <div className="workouts_action" style={{ flex: 2 }}>
                                <div className="info_row">
                                    <div className="info_tip">REWARDS NAME : </div>
                                    <div className="reward_name r-left">
                                        {state.rewardsImage && state.rewardsImage !== null ? (
                                            <img src={state.rewardsImage} className="reward_img" alt="" />
                                        ) : (
                                            <span className="user_img_text">
                                                {window.format.getFirstLetter(state.rewardsName)}
                                            </span>
                                        )}
                                        {state.rewardsName}
                                    </div>
                                </div>
                                <div className="info_row r-top">
                                    <span className="info_tip">DESCRIPTION : </span>
                                    <span className="info_msg">{state.desc}</span>
                                </div>
                                <div className="info_row r-top">
                                    <span className="info_tip">REDEMPTION CRITERIA :</span>
                                    <span className="info_msg">
                                        {+state.redemptionCriteria === 0 ? 'Time based' : 'Quantity based'}
                                    </span>
                                </div>
                                {state.redemptionCriteria ? (
                                    <div className="info_row r-top">
                                        <span className="info_tip">TOTAL AMOUNT :</span>
                                        <span className="info_msg">{state.quantity ?? 0}</span>
                                    </div>
                                ) : (
                                    <></>
                                )}
                                <div className="info_row r-top">
                                    <span className="info_tip">EXPIRY DATE :</span>
                                    <span className="info_msg">{state.expiryDateShow}</span>
                                </div>
                                <div className="info_row r-top">
                                    <span className="info_tip">REDEMPTION MODE :</span>
                                    <span className="info_msg">{state.redemptionTypes ? 'Repeat' : 'Once off'}</span>
                                </div>
                                <div className="info_row r-top">
                                    <span className="info_tip">TOTAL FITLOOT AMOUNT FOR REDEMPTION :</span>
                                    <span className="info_msg">{state.fitLootAmount}</span>
                                </div>
                            </div>
                            <div className="space15"></div>
                            <div className="workouts_action" style={{ flex: 1 }}>
                                <div className="info_row">
                                    <div className="info_tip">BUSINESS NAME : </div>
                                    <div className="reward_name r-left">
                                        <img src={state.businessLogo} className="reward_img" alt="" />
                                        {state.businessName}
                                    </div>
                                </div>
                                <div className="info_row r-top">
                                    <span className="info_tip">BUSINESS WEBSITE : </span>
                                    <span className="info_msg">{state.businessWebsite}</span>
                                </div>
                            </div>
                        </div>
                        <div
                            style={{ display: state.collectionRecordVOS.length ? 'block' : 'none', marginTop: 20 }}
                            className="workout-location-statistics"
                        >
                            <div className="r-left location_statisticsTip">
                                REDEMPTION
                                <div className="r-left marLeft">
                                    <span
                                        className="view_box"
                                        onClick={() => {
                                            setShowMore(!showMore);
                                        }}
                                    >
                                        view
                                    </span>
                                    <i className={`downIcon_btm ${showMore ? 'roteIcon' : ''}`}></i>
                                </div>
                            </div>
                            {showMore ? (
                                <ul className={`workout_list user_listBox ${showMore ? 'showList' : ''}`}>
                                    <li className="workout_item_header r-left">
                                        <span className="itemHeader_cell">USER’S NAME</span>
                                        <span className="itemHeader_cell">REDEEMED TIME</span>
                                    </li>
                                    {state.collectionRecordVOS.map((item: defaultInfo, i: number) => {
                                        return (
                                            <li className={`workout_item r-left`} key={i}>
                                                <span className="item_cell r-left">
                                                    <img src={item.avatarUrl} className="user_img" alt="" />
                                                    {item.fullName}
                                                </span>
                                                <span className="item_cell item_cellMini">{item.collectionTime}</span>
                                            </li>
                                        );
                                    })}
                                </ul>
                            ) : (
                                ''
                            )}
                        </div>
                    </section>
                )}
            </div>

            <Modal
                className="r-modal modal_no_padding"
                maskClosable={false}
                centered={true}
                open={modalVisibile}
                onCancel={() => setModalVisibile(false)}
                footer={''}
                title={''}
                key="rewardModal"
                closable={false}
            >
                <div className="modal_form">
                    <p className="del_tip">Are you sure to delete this reward?</p>
                    <div className="r-center" style={{ marginTop: 50 }}>
                        <Button
                            className="r-defaultBlick_btn"
                            style={{ flex: 1 }}
                            onClick={() => setModalVisibile(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="r-primary_btn"
                            type="primary"
                            style={{ flex: 1, marginLeft: 15 }}
                            loading={delLoading}
                            onClick={() => confirmDel()}
                        >
                            Confirm
                        </Button>
                    </div>
                </div>
            </Modal>

            <Modal
                className="r-modal modal_no_padding"
                maskClosable={false}
                centered={true}
                open={alertModalVisibile}
                onCancel={() => setAlertModalVisibile(false)}
                key="rewardModalCon"
                footer={''}
                title={''}
                closable={false}
            >
                <div className="modal_form">
                    <p className="del_tip">The reward is being used in the reward pool, please remove it first.</p>
                    <div className="r-center" style={{ marginTop: 50 }}>
                        <Button
                            className="r-primary_btn"
                            type="primary"
                            style={{ width: 194 }}
                            onClick={() => {
                                setAlertModalVisibile(false);
                                setModalVisibile(false);
                            }}
                        >
                            Okay
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};
export default FitlootRewardDetail;
