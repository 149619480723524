import axios from '../utils/index';
import { Pat, HttpResponse } from '@/typings/request';
const workoutApi = {
    getWorkoutTypeList(params: Pat, callback: (T: any) => void) {
        axios
            .get(`/admin/common/workout-type`, { params })
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getWorkoutList(params: Pat, callback: (T: any) => void) {
        axios
            .get(`/admin/workout/list`, { params })
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getWorkoutLocationList(params: Pat, callback: (T: any) => void) {
        axios
            .get(`/admin/workout/location/list`, { params })
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    addWorkout(params: Pat, callback: (T: any) => void) {
        axios
            .post(`/admin/workout/add`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    editWorkout(params: Pat, callback: (T: any) => void) {
        axios
            .put(`/admin/workout/update/${params.workoutId}`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getWorkoutInfo(params: Pat, callback: (T: any) => void) {
        axios
            .get(`/admin/workout/detail/${params.workoutId}`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    deleteWorkout(params: Pat, callback: (T: any) => void) {
        axios
            .delete(`/admin/workout/delete/${params.workoutId}`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getUserStatisticList(params: Pat, callback: (T: any) => void) {
        axios
            .get(`/admin/workout/user-statistic-list/${params.workoutId}`, { params })
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getWorkoutLocationRequestList(params: Pat, callback: (T: any) => void) {
        axios
            .get(`/admin/workout/location-request/list`, { params })
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    serWorkoutLocationStatus(params: Pat, callback: (T: any) => void) {
        axios
            .put(`/admin/workout/location-request/actioned/${params.requestId}`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getWorkoutFlaggedreportList(params: Pat, callback: (T: any) => void) {
        axios
            .get(`/admin/workout/flagged-report/list`, { params })
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    serWorkoutFlaggedreportStatus(params: Pat, callback: (T: any) => void) {
        axios
            .put(`/admin/workout/flagged-report/actioned/${params.requestId}`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getExerciseList(params: Pat, callback: (T: any) => void) {
        axios
            .get(`/admin/exercise/list`, { params })
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getExerciseInfo(params: Pat, callback: (T: any) => void) {
        axios
            .get(`/admin/exercise/detail/${params.exerciseId}`)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    addExercise(params: Pat, callback: (T: any) => void) {
        axios
            .post(`/admin/exercise/add`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    editExercise(params: Pat, callback: (T: any) => void) {
        axios
            .put(`/admin/exercise/update`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    deleteExercise(params: Pat, callback: (T: any) => void) {
        axios
            .delete(`/admin/exercise/delete/${params.exerciseId}`, { params })
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    // 普通奖励
    getWorkoutRewardsEntityList(params: Pat, callback: (T: any) => void) {
        axios
            .get(`/admin/workout-rewards-entity/list`, { params })
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    // 奖池
    getWorkoutRewardRoolList(params: Pat, callback: (T: any) => void) {
        axios
            .get(`/admin/reward-pool/list`, { params })
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
};
export default workoutApi;
