import { useState, useEffect } from 'react';
import { Button, Modal } from 'antd';
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';

const Tailor = (props: any) => {
    useEffect(() => {
        if (!props.dialogVisible && cropper) {
            cropper.reset();
            cropper.destroy();
        } else if (props.dialogVisible) {
            setTimeout(() => init(), 200);
        }
    }, [props.dialogVisible]);

    const [cropper, setcropper] = useState<any>(null);

    const dataURLtoFile = (dataurl: string, filename: string) => {
        const arr = dataurl.split(',') as any;
        const mime = arr[0].match(/:(.*?);/)[1];
        if (!filename) {
            filename = `${new Date().getTime()}.${mime.substr(mime.indexOf('/') + 1)}`;
        }
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    };
    const init = () => {
        const image = document.getElementById('imageNew');
        const cropper = new Cropper(image as any, {
            dragMode: 'move',
            aspectRatio: props.aspectRatioWidth / props.aspectRatioHeight,
            autoCropArea: 0.9,
            highlight: false,
            viewMode: 2,
            minCropBoxWidth: props.aspectRatioWidth,
            minCropBoxHeight: props.aspectRatioHeight,
            cropBoxResizable: false,
            toggleDragModeOnDblclick: false,
            zoomOnWheel: true,

            ready() {
                setcropper(cropper as any);
            },
        });
    };

    // useEffect(() => {
    //     console.log(cropper as any);
    // }, [cropper as any]);

    const uploadCropImg = () => {
        const herf = (cropper as any).getCroppedCanvas({ maxWidth: 686, maxHeight: 800 }).toDataURL('image/jpeg');
        if (props.isFile) {
            //file
            const blob = dataURLtoFile(herf, 'name');
            props.getResult(blob);
        } else {
            // base 64
            props.getResult(herf);
        }
    };

    const closeUpload = () => {
        props.closeWindow();
    };

    const styleOption = {
        tailor_main: {
            padding: 30,
        },
        imageNew: {
            width: '100%',
            maxHeight: 400,
        },
        buttomBtn: {
            marginTop: 30,
        },
    };
    return (
        <Modal
            className="r-modal modal_no_padding"
            maskClosable={false}
            centered={true}
            open={props.dialogVisible}
            onCancel={() => closeUpload()}
            footer={''}
            title={''}
            closable={false}
        >
            <div style={styleOption.tailor_main}>
                <div className="cropper_box">
                    <img id="imageNew" style={styleOption.imageNew} src={props.imgFile} />
                </div>
                <div className="r-center" style={styleOption.buttomBtn}>
                    <Button className="r-defaultBlick_btn" style={{ flex: 1 }} onClick={() => closeUpload()}>
                        Cancel
                    </Button>
                    <Button
                        className="r-primary_btn"
                        type="primary"
                        style={{ flex: 1, marginLeft: 15 }}
                        onClick={() => uploadCropImg()}
                    >
                        Save
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default Tailor;
