import NavLogo from '@/assets/images/nav-logo.png';
import './index.scss';
import { Button, Modal, Spin } from 'antd';
import { useEffect, useState } from 'react';
import axios from 'axios';
const DeleteAppAccount = () => {
    const [step, setStep] = useState(1);
    const [userInfo, setUserInfo] = useState({
        id: '',
        type: '',
    });
    const [modalVisibile, setModalVisibile] = useState(false);
    const [loading, setLoading] = useState(false);
    const confirmDel = () => {
        setLoading(true);
        const pat = {
            thirdPartyId: userInfo.id,
            thirdPartyType: userInfo.type,
        };
        axios
            .delete(`${process.env.REACT_APP_URL}/admin/user/delete/third-party`, {
                data: pat,
                headers: {
                    'Accept-Language': 'en-US',
                },
            })
            .then((res: any) => {
                const { data, code, message } = res.data;
                setLoading(false);
                if (code === 200) {
                    window.format.alert('success', 'Account deleted successfully');
                    setStep(1);
                    setUserInfo({ id: '', type: '' });
                } else {
                    window.format.alert('error', message);
                }
            });
        setModalVisibile(false);
    };
    let client: any;
    const nextStep = (key: number) => {
        setStep(key);
        if (key === 2) {
            setTimeout(() => {
                try {
                    initGoogleAuth();
                } catch (error) {
                    initGoogleAuth();
                }
            }, 20);
        }
    };
    function initGoogleAuth() {
        window.google.accounts.id.cancel();
        window.google.accounts.id.initialize({
            client_id: '654889986277-0o2p25ekvlbo4i8l1itc62s92qqe597d.apps.googleusercontent.com',
            callback: handleCredentialResponse,
        });
        window.google.accounts.id.renderButton(document.getElementById('buttonDiv'), {
            theme: 'outline',
            size: 'large',
            locale: 'en',
            width: 343,
            text: 'continue_with',
        });
        client = window.google.accounts.oauth2.initCodeClient({
            client_id: '654889986277-0o2p25ekvlbo4i8l1itc62s92qqe597d.apps.googleusercontent.com',
            scope: 'https://www.googleapis.com/auth/userinfo.profile',
            ux_mode: 'popup',
        });
    }
    function decodeJwtResponse(token) {
        const strings = token.split('.');
        return JSON.parse(
            decodeURIComponent(encodeURIComponent(window.atob(strings[1].replace(/-/g, '+').replace(/_/g, '/'))))
        );
    }
    const handleCredentialResponse = response => {
        setLoading(true);
        const responsePayload = decodeJwtResponse(response.credential);
        authLogin('Google', responsePayload.sub);
    };
    const authLogin = (type: string, id: string) => {
        setUserInfo({ id, type });
        const pat = {
            thirdPartyId: id,
            thirdPartyType: type,
        };
        axios
            .post(`${process.env.REACT_APP_URL}/admin/user/login/third-party`, pat, {
                headers: {
                    'Accept-Language': 'en-US',
                },
            })
            .then((res: any) => {
                console.log(res);
                const { data, code, message } = res.data;
                setLoading(false);
                if (code === 200) {
                    setStep(3);
                } else {
                    window.format.alert('error', message);
                }
            });
    };
    const facebookLogin = () => {
        (window as any).FB.getLoginStatus(function (response) {
            // Called after the JS SDK has been initialized.
            if (response.status === 'connected') {
                const data = response.authResponse;
                setLoading(true);
                authLogin('Facebook', data.userID);
                return;
            }
            (window as any).FB.login(
                function (response) {
                    console.log(response);
                    if (response.status === 'connected') {
                        console.log(response.authResponse);
                        const data = response.authResponse;
                        setLoading(true);
                        authLogin('Facebook', data.userID);
                    }
                },
                {
                    scope: 'public_profile',
                    redirect_uri: 'https://admin.geonasium.com.au/',
                }
            );
        });
    };

    useEffect(() => {
        (window as any).FB.init({
            appId: '1916223028738813',
            cookie: true,
            xfbml: true,
            version: 'v16.0',
        });
    }, []);
    return (
        <div className="delete_app_account">
            <div className="logo">
                <img src={NavLogo} alt="" />
            </div>
            <Spin spinning={loading} wrapperClassName="loadingNew_box">
                <div className="delete_main r-center">
                    {step === 1 ? (
                        <div className="step-box">
                            <p className="title_del">Delete Geonasium's account</p>
                            <p className="tip_del marStepBox">You can delete your Geonasium APP account from the web</p>
                            <div className="r-center">
                                <Button
                                    className="r-primary_btn r-del_pageBtn"
                                    style={{ width: 343 }}
                                    icon={<></>}
                                    onClick={() => nextStep(2)}
                                >
                                    Delete account
                                </Button>
                            </div>
                        </div>
                    ) : step === 2 ? (
                        <div className="step-box">
                            <p className="title_del">Login to Geonasium</p>
                            <div className="r-center btnBox" style={{ marginTop: 80 }}>
                                <div
                                    className="r-otherLogin_btn"
                                    onClick={() => {
                                        client.requestCode();
                                    }}
                                >
                                    <i className="bewIcon googleIcon"></i>
                                    Continue with Google
                                </div>
                                <div id="buttonDiv"></div>
                            </div>
                            <div className="r-center" style={{ marginTop: 15 }} onClick={facebookLogin}>
                                <div className="r-otherLogin_btn">
                                    <i className="bewIcon facebookIcon"></i>
                                    Continue with Facebook
                                </div>
                            </div>
                        </div>
                    ) : step === 3 ? (
                        <div className="step-box">
                            <p className="title_del">Delete Geonasium's account</p>
                            <p className="tip_del marStepBox" style={{ marginBottom: 90 }}>
                                Thank you for your continued support and trust in our services.
                                <br></br>
                                <br></br>
                                <br></br>
                                Please note that once your account is deleted,
                                <br></br>
                                you will lose all data and information on the platform, including but not limited to
                                your personal information, favorites, comments, etc.
                            </p>
                            <div className="r-center">
                                <Button
                                    className="r-primary_btn r-del_pageBtn"
                                    style={{ width: 343 }}
                                    icon={<></>}
                                    onClick={() => setModalVisibile(true)}
                                >
                                    Delete account
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </Spin>

            <Modal
                className="r-modal modal_no_padding"
                maskClosable={false}
                centered={true}
                open={modalVisibile}
                onCancel={() => setModalVisibile(false)}
                footer={''}
                destroyOnClose
                title={''}
                closable={false}
            >
                <div className="modal_form">
                    <p className="del_tip">
                        Deleting an account will remove all data on this account and cannot be undone. Are you sure you
                        want to continue?
                    </p>
                    <div className="r-center" style={{ marginTop: 50 }}>
                        <Button
                            className="r-defaultBlick_btn"
                            style={{ flex: 1 }}
                            onClick={() => setModalVisibile(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="r-primary_btn"
                            type="primary"
                            style={{ flex: 1, marginLeft: 15 }}
                            loading={loading}
                            onClick={() => confirmDel()}
                        >
                            Delete Account
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};
export default DeleteAppAccount;
