import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input, Select, Button, Upload, Progress } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import type { UploadFile } from 'antd/es/upload/interface';
import './style/index.scss';
import uploadApi from '@/api/upload';
import workoutApi from '@/api/workout';
import { defaultInfo } from '@/typings/allType';
const CreateActionLibrary = (props: any) => {
    const navigate = useNavigate();
    useEffect(() => {
        const exerciseInfo = JSON.parse(window.sessionStorage.getItem('exerciseInfo') ?? '{}');
        if (exerciseInfo.exerciseId) {
            const videoList = JSON.parse(window.sessionStorage.getItem('videoList') ?? '[]');
            const introsVideo = JSON.parse(window.sessionStorage.getItem('introsVideo') ?? '[]');
            setState({
                type: exerciseInfo.type.toString(),
                exerciseId: exerciseInfo.exerciseId,
                actionName: exerciseInfo.exerciseName,
                actionType:
                    exerciseInfo.exerciseType === 'Workouts'
                        ? '3'
                        : exerciseInfo.exerciseType === 'Cooldowns'
                        ? '2'
                        : '1',
                actionTime: exerciseInfo.estimatedAverageTimePerRep,
                videoList: [
                    {
                        label: 'Beginner',
                        name: exerciseInfo.beginnerUrl ? window.format.extractFilename(exerciseInfo.beginnerUrl) : '',
                        size: videoList[0].size,
                        url: exerciseInfo.beginnerUrl ? exerciseInfo.beginnerUrl : '',
                        key: exerciseInfo.beginnerUrl ? window.format.getFilePathName(exerciseInfo.beginnerUrl) : '',
                        loading: false,
                        percent: 0,
                        inputName: exerciseInfo.beginnerName ?? '',
                    },
                    {
                        label: 'Intermediate',
                        name: exerciseInfo.intermediateUrl
                            ? window.format.extractFilename(exerciseInfo.intermediateUrl)
                            : '',
                        size: videoList[1].size,
                        url: exerciseInfo.intermediateUrl ? exerciseInfo.intermediateUrl : '',
                        key: exerciseInfo.intermediateUrl
                            ? window.format.getFilePathName(exerciseInfo.intermediateUrl)
                            : '',
                        loading: false,
                        percent: 0,
                        inputName: exerciseInfo.intermediateName ?? '',
                    },
                    {
                        label: 'Advanced',
                        name: exerciseInfo.advancedUrl ? window.format.extractFilename(exerciseInfo.advancedUrl) : '',
                        size: videoList[2].size,
                        url: exerciseInfo.advancedUrl ? exerciseInfo.advancedUrl : '',
                        key: exerciseInfo.advancedUrl ? window.format.getFilePathName(exerciseInfo.advancedUrl) : '',
                        loading: false,
                        percent: 0,
                        inputName: exerciseInfo.advancedName ?? '',
                    },
                ],
                workoutLocationAssociation: exerciseInfo.workoutLocationAssociation,
                introsVideo: {
                    label: 'Video',
                    name: exerciseInfo.introsUrl ? window.format.extractFilename(exerciseInfo.introsUrl) : '',
                    size: introsVideo.size,
                    url: exerciseInfo.introsUrl ? exerciseInfo.introsUrl : '',
                    key: exerciseInfo.introsUrl ? window.format.getFilePathName(exerciseInfo.introsUrl) : '',
                    loading: false,
                    percent: 0,
                    inputName: '',
                },
            });
        }
    }, []);
    const [state, setState] = useState({
        type: '1',
        exerciseId: '',
        actionName: '',
        actionType: '3',
        actionTime: '',
        workoutLocationAssociation: [],
        videoList: [
            {
                label: 'Beginner',
                name: '',
                inputName: '',
                size: '',
                url: '',
                key: '',
                loading: false,
                percent: 0,
            },
            {
                label: 'Intermediate',
                name: '',
                inputName: '',
                size: '',
                url: '',
                key: '',
                loading: false,
                percent: 0,
            },
            {
                label: 'Advanced',
                name: '',
                inputName: '',
                size: '',
                url: '',
                key: '',
                loading: false,
                percent: 0,
            },
        ],
        introsVideo: {
            label: 'Video',
            name: '',
            size: '',
            url: '',
            key: '',
            loading: false,
            percent: 0,
            inputName: '',
        },
    });
    const handleChange = (value: string, type: number) => {
        if (state.workoutLocationAssociation.length && type === 1) {
            return;
        }
        setState({
            ...state,
            type: type === 0 ? value : state.type,
            actionType: type === 1 ? value : '3',
            actionTime: type === 1 && value !== '3' ? '' : state.actionTime,
            actionName: '',
            videoList: [
                {
                    label: 'Beginner',
                    name: '',
                    size: '',
                    url: '',
                    key: '',
                    loading: false,
                    percent: 0,
                    inputName: '',
                },
                {
                    label: 'Intermediate',
                    name: '',
                    size: '',
                    url: '',
                    key: '',
                    loading: false,
                    percent: 0,
                    inputName: '',
                },
                {
                    label: 'Advanced',
                    name: '',
                    size: '',
                    url: '',
                    key: '',
                    loading: false,
                    percent: 0,
                    inputName: '',
                },
            ],
            introsVideo: {
                label: 'Video',
                name: '',
                size: '',
                url: '',
                key: '',
                loading: false,
                percent: 0,
                inputName: '',
            },
        });
    };
    const back = () => {
        navigate(-1);
    };
    const beforeUpload = (file: any) => {
        if (+state.actionType === 3) {
            if (file.type !== 'image/gif' && file.type !== 'video/mp4') {
                window.format.alert('error', 'File format error');
                return false;
            }
        } else {
            if (file.type !== 'video/mp4') {
                window.format.alert('error', 'File format error');
                return false;
            }
        }
        if (file.size > 1024 * 1024 * 200) {
            window.format.alert('error', 'The file is too large to upload...');
            return false;
        }
    };
    const uploadFile = (val: any, i: number) => {
        // 0-2 Exercise video type, 4 intros video type
        if (val.file.status === 'done' && val.file.response.code === 200) {
            if (i === 4) {
                const obj = Object.assign(state.introsVideo, {
                    name: val.file.name,
                    size: window.format.formatFileSize(val.file.size),
                    url: val.file.response.data,
                    key: window.format.getFilePathName(val.file.response.data),
                    // url: val.file.response.data.url,
                    // key: val.file.response.data.key,
                    loading: false,
                    percent: 0,
                });
                setState({ ...state, introsVideo: obj });
                return;
            }
            const arr = [...state.videoList];
            arr[i] = Object.assign(arr[i], {
                name: val.file.name,
                size: window.format.formatFileSize(val.file.size),
                url: val.file.response.data,
                key: window.format.getFilePathName(val.file.response.data),
                // url: val.file.response.data.url,
                // key: val.file.response.data.key,
                loading: false,
                percent: 0,
            });
            setState({ ...state, videoList: arr });
            // window.format.alert('success', 'success');
        } else if (val.file.status === 'error') {
            if (i === 4) {
                // intros
                const obj = Object.assign(state.introsVideo, {
                    name: '',
                    size: '',
                    url: '',
                    key: '',
                    loading: false,
                    percent: 0,
                    inputName: '',
                });
                setState({ ...state, introsVideo: obj });
                window.format.alert('error', 'upload fail');
                return;
            }
            const arr = [...state.videoList];
            arr[i] = Object.assign(arr[i], {
                name: '',
                size: '',
                url: '',
                key: '',
                loading: false,
                percent: 0,
            });
            setState({ ...state, videoList: arr });
            window.format.alert('error', 'upload fail');
        } else {
            if (!val.file.status) return;
            if (i === 4) {
                // intros
                const obj = Object.assign(state.introsVideo, {
                    name: val.file.name,
                    size: window.format.formatFileSize(val.file.size),
                    url: '',
                    key: '',
                    loading: true,
                    percent: val.file.percent === 100 ? 99 : val.file.percent.toFixed(0),
                });
                setState({ ...state, introsVideo: obj });
                return;
            }
            const arr = [...state.videoList];
            arr[i] = Object.assign(arr[i], {
                name: val.file.name,
                size: window.format.formatFileSize(val.file.size),
                url: '',
                key: '',
                loading: true,
                percent: val.file.percent === 100 ? 99 : val.file.percent.toFixed(0),
            });
            setState({ ...state, videoList: arr });
        }
    };
    const delFile = (i: number) => {
        if (state.type === '2') {
            setState({
                ...state,
                introsVideo: {
                    label: 'Video',
                    name: '',
                    size: '',
                    url: '',
                    key: '',
                    loading: false,
                    percent: 0,
                    inputName: '',
                },
            });
            return;
        }
        const arr = [...state.videoList];
        arr[i] = Object.assign(arr[i], {
            name: '',
            size: '',
            url: '',
            loading: false,
            percent: 0,
        });
        setState({ ...state, videoList: arr });
    };
    const [loading, setLoading] = useState(false);
    const submit = () => {
        const params: defaultInfo = {
            exerciseName: state.actionName,
            exerciseType:
                state.type === '1'
                    ? state.actionType === '3'
                        ? 'Workouts'
                        : state.actionType === '2'
                        ? 'Cooldowns'
                        : 'WarmUp'
                    : 'Intros',
            estimatedAverageTimePerRep: state.actionTime ? state.actionTime : 0,
            beginnerUrl: state.videoList[0].key,
            beginnerName: state.videoList[0].inputName,
            intermediateUrl: state.videoList[1].key,
            intermediateName: state.videoList[1].inputName,
            advancedUrl: state.videoList[2].key,
            advancedName: state.videoList[2].inputName,
            introsUrl: state.type === '2' ? state.introsVideo.key : '',
        };
        setLoading(true);
        if (!state.exerciseId) {
            workoutApi.addExercise(params, res => {
                const { data, code, message } = res;
                setLoading(false);
                if (code === 200) {
                    navigate('/workouts/actionLibrary');
                } else {
                    window.format.alert('error', message);
                }
            });
        } else {
            params.exerciseId = state.exerciseId;
            workoutApi.editExercise(params, res => {
                const { data, code, message } = res;
                setLoading(false);
                if (code === 200) {
                    window.sessionStorage.removeItem('exerciseInfo');
                    navigate('/workouts/actionLibrary-detail');
                } else {
                    window.format.alert('error', message);
                }
            });
        }
    };
    const [isEmpty, setIsEmpty] = useState(true);
    useEffect(() => {
        let flag = true;
        if (state.type === '1') {
            if (state.actionType === '3') {
                flag =
                    !!state.actionName &&
                    !!state.actionType &&
                    state.videoList.findIndex(v => !v.url || !v.inputName) === -1 &&
                    !!Number(state.actionTime);
            } else {
                flag =
                    !!state.actionName &&
                    !!state.actionType &&
                    state.videoList.findIndex(v => !v.url || !v.inputName) === -1;
            }
        } else {
            flag = !!state.actionName && !!state.introsVideo.url;
        }
        setIsEmpty(!flag);
    }, [state]);
    return (
        <div className="createActionlibrary_main workout-list_main">
            <div className="top_back r-left">
                <div className="back_box r-left" onClick={back}>
                    <i className="back_icon"></i>
                    Back
                </div>
                <Button className="r-defaultBlick_btn marLeft" style={{ width: 146 }} onClick={back}>
                    Cancel
                </Button>
                <div className="space15"></div>
                <Button
                    className="r-primary_btn"
                    type="primary"
                    disabled={isEmpty}
                    loading={loading}
                    style={{ width: 146 }}
                    onClick={submit}
                >
                    Save
                </Button>
            </div>
            <section className="actionInfo_form">
                <div className="form_tit">{state.exerciseId ? 'Edit' : 'Add New'}</div>
                <p className="form_tip">Type</p>
                <Select
                    defaultValue="3"
                    value={state.type}
                    style={{ width: '100%' }}
                    onChange={val => handleChange(val, 0)}
                    className="r-select"
                    disabled={!!state.exerciseId}
                    suffixIcon={<i className="sel_arrow_icon"></i>}
                    options={[
                        { value: '1', label: 'Exercise' },
                        { value: '2', label: 'Intros' },
                    ]}
                />
                <p className="form_tip">{state.type === '2' ? 'Intros' : 'Exercise'} name</p>
                <Input
                    placeholder=""
                    className="r-input"
                    maxLength={50}
                    value={state.actionName}
                    disabled={!!state.workoutLocationAssociation.length}
                    onChange={e => {
                        setState({ ...state, actionName: e.target.value });
                    }}
                />
                {state.type === '1' ? (
                    <>
                        <p className="form_tip">Exercise type</p>
                        <ul className="r-left checkUl_new">
                            {[
                                { value: '1', label: 'Warm up' },
                                { value: '3', label: 'Workouts' },
                                { value: '2', label: 'Cooldown' },
                            ].map((item, i) => {
                                return (
                                    <li
                                        onClick={() => handleChange(item.value, 1)}
                                        className={`checkItem_new ${
                                            state.workoutLocationAssociation.length ? 'disabled' : ''
                                        }`}
                                    >
                                        {item.label}
                                        <i
                                            className={`check_icon ${
                                                item.value === state.actionType ? 'checked_icon' : ''
                                            }`}
                                        ></i>
                                    </li>
                                );
                            })}
                        </ul>
                        {/* <Select
                            defaultValue="3"
                            value={state.actionType}
                            style={{ width: '100%' }}
                            onChange={val => handleChange(val, 1)}
                            className="r-select"
                            disabled={!!state.workoutLocationAssociation.length}
                            suffixIcon={<i className="sel_arrow_icon"></i>}
                            options={[
                                { value: '1', label: 'Warm up' },
                                { value: '2', label: 'Cooldown' },
                                { value: '3', label: 'Workouts' },
                            ]}
                        /> */}
                        {state.actionType === '3' ? (
                            <>
                                <p className="form_tip">Estimated average time per rep</p>
                                <Input
                                    placeholder=""
                                    style={{ width: 300 }}
                                    maxLength={10}
                                    className="r-input"
                                    suffix={<span>sec (s)</span>}
                                    value={state.actionTime}
                                    onChange={e => {
                                        e.target.value = e.target.value.replace(/[^\d]/g, '');
                                        setState({ ...state, actionTime: e.target.value });
                                    }}
                                />
                            </>
                        ) : (
                            ''
                        )}
                        {state.videoList.map((item, i) => {
                            return (
                                <div key={i}>
                                    <p className="form_tip_mid">{item.label}</p>
                                    <p className="form_tip">{item.label} name</p>
                                    <Input
                                        placeholder=""
                                        className="r-input"
                                        maxLength={100}
                                        value={item.inputName}
                                        disabled={!!state.workoutLocationAssociation.length}
                                        onChange={e => {
                                            setState({
                                                ...state,
                                                videoList: state.videoList.map((item, k) => {
                                                    return Object.assign(item, {
                                                        inputName: i === k ? e.target.value : item.inputName,
                                                    });
                                                }),
                                            });
                                        }}
                                    />
                                    <Upload
                                        action={`${process.env.REACT_APP_URL}/admin/upload/file`}
                                        maxCount={1}
                                        accept={+state.actionType === 3 ? 'image/gif, video/mp4' : 'video/mp4'}
                                        showUploadList={false}
                                        data={{ sync: false }}
                                        onChange={val => uploadFile(val, i)}
                                        beforeUpload={val => beforeUpload(val)}
                                    >
                                        {!item.url && !item.loading ? (
                                            <div className="upload_text" style={{ marginTop: 20 }}>
                                                + Upload video file
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                    </Upload>
                                    {item.url || item.loading ? (
                                        <div className="file_showBox r-left">
                                            <div className="video_icon"></div>
                                            <div>
                                                <div className="video_name">{item.name}</div>
                                                {item.loading ? (
                                                    <div style={{ width: 170 }}>
                                                        <Progress percent={item.percent} size="small" />
                                                    </div>
                                                ) : (
                                                    <div className="video_size">{item.size ? item.size : '0Kb'}</div>
                                                )}
                                            </div>
                                            <i
                                                className="close_icon"
                                                style={{ display: item.loading ? 'none' : 'initial' }}
                                                onClick={() => delFile(i)}
                                            ></i>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            );
                        })}
                    </>
                ) : (
                    <>
                        <div>
                            <p className="form_tip_mid" style={{ margin: '30px 0 20px 0' }}>
                                {state.introsVideo.label}
                            </p>
                            <Upload
                                action={`${process.env.REACT_APP_URL}/admin/upload/file`}
                                maxCount={1}
                                accept={'video/mp4'}
                                showUploadList={false}
                                data={{ sync: false }}
                                onChange={val => uploadFile(val, 4)}
                                beforeUpload={val => beforeUpload(val)}
                            >
                                {!state.introsVideo.url && !state.introsVideo.loading ? (
                                    <div className="upload_text" style={{ margin: 0 }}>
                                        + Upload video file
                                    </div>
                                ) : (
                                    ''
                                )}
                            </Upload>
                            {state.introsVideo.url || state.introsVideo.loading ? (
                                <div className="file_showBox r-left">
                                    <div className="video_icon"></div>
                                    <div>
                                        <div className="video_name">{state.introsVideo.name}</div>
                                        {state.introsVideo.loading ? (
                                            <div style={{ width: 170 }}>
                                                <Progress percent={state.introsVideo.percent} size="small" />
                                            </div>
                                        ) : (
                                            <div className="video_size">
                                                {state.introsVideo.size ? state.introsVideo.size : '0Kb'}
                                            </div>
                                        )}
                                    </div>
                                    <i
                                        className="close_icon"
                                        style={{ display: state.introsVideo.loading ? 'none' : 'initial' }}
                                        onClick={() => delFile(4)}
                                    ></i>
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                    </>
                )}
            </section>
        </div>
    );
};
export default CreateActionLibrary;
