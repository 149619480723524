import { Input, Space, Modal, Button, DatePicker, Table, message } from 'antd';
import { HeartOutlined } from '@ant-design/icons';
import searchIcon from '@/assets/images/search.png';
import type { ColumnsType } from 'antd/es/table';
import { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Pagination from '@/components/pagination';
import TableBox from '@/components/tableBox';
import type { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import calendarIcon from '@/assets/images/calendar_icon.png';
import comeBackImg from '@/assets/images/back.png';
import debounce from 'lodash.debounce';
import './index.scss';
import SocialsApi from '@/api/socials';
interface commentObjType {
    fullName: string;
    avatarUrl: string;
    content: string;
    repliesNumber: number;
    numberLikes: number;
    createdTime: number;
    isLast: boolean;
    commentId: string;
    children?: commentObjType[];
}
interface getListParams {
    pageNum: number;
    pageSize: number;
    keyword: string;
}
const CommentList = (props: any) => {
    const query = new URLSearchParams(useLocation().search);
    useEffect(() => {
        if (query.get('identity')) {
            getPostComments(listParams);
        }
    }, []);
    const { RangePicker } = DatePicker;
    const navigate = useNavigate();
    const [status, setStatus] = useState('showData');
    const [listParams, setListParams] = useState<getListParams>({
        pageNum: 1,
        pageSize: 10,
        keyword: '',
    });
    const [totalRecord, setTotalRecord] = useState(0);
    const disabledDate: RangePickerProps['disabledDate'] = current => {
        return current && current > dayjs().endOf('day');
    };
    useEffect(() => {
        getPostComments(listParams);
    }, [listParams]);
    const handlePageChange = prop => {
        setListParams(state => ({ ...state, pageNum: prop }));
    };
    const debouncedSave = useCallback(
        debounce(nextValue => setListParams(nextValue), 1000),
        []
    );
    const inputChange = e => {
        debouncedSave({ ...listParams, keyword: e.target.value, pageNum: 1 });
    };
    const [currentComment, setCurrentComment] = useState('');
    const columns: ColumnsType<commentObjType> = [
        {
            title: 'COMMENT’S NAME',
            dataIndex: 'fullName',
            key: 'fullName',
            render: (name, record) => (
                <div className="flex-left indent-level-1">
                    <div
                        className="table_cell_line_vertical"
                        style={record.isLast ? { height: '50%' } : { height: '100%' }}
                    ></div>
                    <div className="table_cell_line_horizontal"></div>
                    <div className="user_info">
                        <img style={{ width: 25, height: 25 }} src={record.avatarUrl} alt="" />
                        {name}
                    </div>
                </div>
            ),
        },
        {
            title: 'COMMENT CONTENT',
            dataIndex: 'content',
            key: 'content',
            render: (content, record) => <div style={{ maxWidth: 529 }}>{content}</div>,
        },

        {
            title: 'REPORT DATE AND TIME',
            dataIndex: 'createdTime',
            key: 'createdTime',
            render: time => dayjs(+time).format('DD/MM/YYYY HH:mm'),
        },
        {
            width: 87,
            title: 'ADDITIONAL',
            dataIndex: 'repliesNumber',
            key: 'repliesNumber',
            render: (columns, record) => <span className={+columns > 0 ? 'view_more' : 'columns_num'}>{columns}</span>,
        },
        Table.EXPAND_COLUMN,
        {
            title: '',
            dataIndex: 'Comments',
            key: 'Comments',
            render: (Comments, record) => (
                <Space size={33}>
                    <span>
                        <HeartOutlined /> {record.numberLikes}
                    </span>

                    <Button
                        className="table_button"
                        onClick={e => {
                            e.stopPropagation();
                            setIsModalOpen(true);
                            setCurrentComment(record.commentId);
                        }}
                    >
                        Remove
                    </Button>
                </Space>
            ),
        },
    ];
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [comments, setComments] = useState<commentObjType[]>([]);
    const getPostComments = params => {
        setStatus('loading');
        SocialsApi.getComments({ postId: query.get('identity'), ...params }, res => {
            const { data, code } = res;
            if (code === 200) {
                if (data.records.length > 0) {
                    const commentsList = data.records;
                    commentsList.forEach(com => {
                        if (Number(com.repliesNumber) > 0) {
                            com.replies.forEach((i, index) => {
                                if (index === com.replies.length - 1) {
                                    i.isLast = true;
                                } else {
                                    i.isLast = false;
                                }
                            });
                        } else {
                            com.replies = [];
                        }
                    });
                    setComments(commentsList);
                    setStatus('showData');
                } else {
                    setStatus('empty');
                }
                setTotalRecord(data.total);
            } else {
                setStatus('empty');
                message.error(res.message);
            }
        });
    };
    const dateOnChange = (dates, dateStrings) => {
        setListParams(state => ({
            ...state,
            startTime: dates ? dayjs(`${dates[0].format('YYYY-MM-DD')} 00:00:00`).valueOf() : '',
            endTime: dates ? dayjs(`${dates[1].format('YYYY-MM-DD')} 23:59:59`).valueOf() : '',
        }));
    };
    const removeComment = async () => {
        await SocialsApi.deleteComment(currentComment, res => {
            if (res.code === 200) {
                message.success(res.message);
                getPostComments(listParams);
                setIsModalOpen(false);
            } else {
                message.error(res.message);
            }
        });
    };
    return (
        <div className="page-main CommentList table_page">
            <div className="flex-between page_head" style={{ marginTop: 72 }}>
                <div
                    className="come_back"
                    onClick={() => {
                        navigate(-1);
                    }}
                >
                    <img src={comeBackImg} alt="" />
                    Back
                </div>
            </div>
            <div className="search_box flex-between" style={{ marginTop: 40 }}>
                <div>
                    <Space wrap size={15}>
                        <Input
                            allowClear
                            className="search_input"
                            size="large"
                            placeholder="Search"
                            style={{ width: 210 }}
                            maxLength={50}
                            prefix={<img src={searchIcon} alt="" />}
                            onChange={inputChange}
                        />
                        <RangePicker
                            placeholder={['dd/mm/yyyy', 'dd/mm/yyyy']}
                            style={{ width: 258 }}
                            suffixIcon={<img src={calendarIcon} alt="" />}
                            format={'DD/MM/YYYY'}
                            disabledDate={disabledDate}
                            onChange={dateOnChange}
                        />
                    </Space>
                </div>
            </div>
            <TableBox
                status={status}
                columns={columns}
                data={comments}
                className="no_border"
                isTree={true}
                childrenColumnName="replies"
                rowKey="commentId"
            />
            {status === 'showData' ? (
                <Pagination total={totalRecord} pageChange={handlePageChange} pageNo={listParams.pageNum} />
            ) : (
                <></>
            )}
            <Modal
                className="modal-suspend"
                closable={false}
                maskClosable={false}
                centered={true}
                open={isModalOpen}
                onCancel={() => {
                    setIsModalOpen(false);
                }}
                footer={[]}
            >
                <div className="modal-tip">Are you sure you wish to remove this Comment?</div>
                <Space size={12} style={{ marginTop: 43 }}>
                    <Button
                        onClick={() => {
                            setIsModalOpen(false);
                        }}
                        style={{ width: 194, height: 54 }}
                    >
                        Cancel
                    </Button>
                    <Button type="primary" htmlType="submit" onClick={removeComment} style={{ width: 194, height: 54 }}>
                        Confirm
                    </Button>
                </Space>
            </Modal>
        </div>
    );
};
export default CommentList;
