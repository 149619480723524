import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input, Select, Button, Modal, Switch, DatePicker, Table } from 'antd';
import './style/index.scss';
import TableBox from '@/components/tableBox';
import Pagination from '@/components/pagination';
import brandApi from '@/api/brand';
import { useAliveController } from 'react-activation';
interface listParams {
    pageNo: number;
    totalRecord?: string | number;
}
const BrandDetail = (props: any) => {
    useEffect(() => getList({ ...state }), []);
    useEffect(() => {
        const path = props.prevLocation?.pathname;
        if (path === '/brand/create-location') {
            getList({ ...state });
        }
    }, [props.prevLocation]);
    const navigate = useNavigate();
    const { drop } = useAliveController();
    const back = () => {
        // navigate(-1);
        drop('BrandDetail');
        setTimeout(() => {
            navigate('/brand', { state: { from: 'location' } });
        }, 200);
    };
    const [status, setStatus] = useState('showData');
    const [state, setState] = useState({
        pageNo: 1,
        totalRecord: 0,
        isOffer: false,
    });
    const handlePageChange = prop => {
        // setState({ ...state, pageNo: prop });
        getList({ ...state, pageNo: prop });
    };
    const getList = (val: listParams) => {
        const params = {
            pageNum: val.pageNo,
            pageSize: 10,
            brandId: window.sessionStorage.getItem('brandId'),
        };
        setStatus('loading');
        brandApi.getBrandLocationList(params, res => {
            const { data, code, message } = res;
            if (code === 200) {
                if (data.records.length > 0) {
                    const list = data.records.map(item => {
                        return Object.assign(item, { geofencing: `${item.geofencing ?? 100}M` });
                    });
                    setTableData(list);
                    setStatus('showData');
                    setState({ ...state, totalRecord: data.total, isOffer: data.metaData, pageNo: val.pageNo });
                } else {
                    setStatus('empty');
                    setState({ ...state, totalRecord: 0, isOffer: data.metaData, pageNo: val.pageNo });
                }
            } else {
                window.format.alert('error', message);
                setStatus('empty');
            }
        });
    };
    const [tableData, setTableData] = useState([]);
    const columns = [
        {
            title: 'BRAND LOCATION',
            dataIndex: 'location',
            key: 'location',
        },
        {
            title: 'GEOFENCE RADIUS',
            dataIndex: 'geofencing',
            key: 'geofencing',
        },
        {
            title: '',
            dataIndex: '',
            key: 'brandLocationId',
            width: 250,
            render: val => {
                return (
                    <div className="r-left">
                        <div
                            className="remove_btn"
                            onClick={e => {
                                opeation(0, e, val);
                            }}
                        >
                            Edit
                        </div>
                        <span className="space15"></span>
                        <div
                            className="remove_btn"
                            onClick={e => {
                                opeation(1, e, val);
                            }}
                        >
                            Remove
                        </div>
                    </div>
                );
            },
        },
    ];
    const linkPage = (key: number) => {
        window.sessionStorage.removeItem('locationInfo');
        window.sessionStorage.removeItem('offerInfo');
        navigate(
            key === 1 ? (!state.isOffer ? '/brand/create-offer' : '/brand/offer-detail') : '/brand/create-location'
        );
    };
    const [opeationInfo, setOpeationInfo] = useState({
        brandLocationId: '',
        loading: false,
    });
    const opeation = (key: number, e: any, val: any) => {
        e.stopPropagation();
        if (key) {
            // del
            setModalVisibile(true);
            setOpeationInfo({ ...val, loading: false });
            return;
        }
        window.sessionStorage.setItem('locationInfo', JSON.stringify(val));
        navigate('/brand/create-location');
    };
    const confirmDel = () => {
        const params = {
            brandLocationId: opeationInfo.brandLocationId,
        };
        setOpeationInfo({ ...opeationInfo, loading: true });
        brandApi.deleteBrandLocation(params, res => {
            const { data, code, message } = res;
            setOpeationInfo({ ...opeationInfo, loading: false });
            if (code === 200) {
                setModalVisibile(false);
                getList({ ...state });
            } else {
                window.format.alert('error', message);
            }
        });
    };
    const [modalVisibile, setModalVisibile] = useState(false);
    return (
        <div className="add-reward-pool_main list_main table_page">
            <div className="top_back r-left" style={{ marginBottom: 40 }}>
                <div className="back_box r-left" onClick={back}>
                    <i className="back_icon"></i>
                    Back
                </div>
                <Button className="r-default_btn marLeft" icon={<></>} onClick={() => linkPage(1)}>
                    Offer Details
                </Button>
                <div className="space15"></div>
                <Button className="r-default_btn" icon={<></>} onClick={() => linkPage(2)}>
                    Add New Location
                </Button>
            </div>
            <TableBox
                status={status}
                columns={columns}
                rowKey="brandLocationId"
                data={tableData}
                height={false}
                className="r-table"
                rowClassName="r-table_row"
                onRow={record => {
                    // handClickRow(record);
                }}
            />
            {status === 'showData' ? (
                <Pagination total={state.totalRecord} pageChange={handlePageChange} pageNo={state.pageNo} />
            ) : (
                <></>
            )}

            <Modal
                className="r-modal modal_no_padding"
                maskClosable={false}
                centered={true}
                open={modalVisibile}
                onCancel={() => setModalVisibile(false)}
                footer={''}
                title={''}
                closable={false}
            >
                <div className="modal_form">
                    <p className="del_tip">Are you sure you want to delete this address?</p>
                    <div className="r-center" style={{ marginTop: 50 }}>
                        <Button
                            className="r-defaultBlick_btn"
                            style={{ flex: 1 }}
                            onClick={() => setModalVisibile(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="r-primary_btn"
                            type="primary"
                            loading={opeationInfo.loading}
                            style={{ flex: 1, marginLeft: 15 }}
                            onClick={() => confirmDel()}
                        >
                            Confirm
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};
export default BrandDetail;
