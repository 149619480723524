import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Input, Select, Button, Table, Modal } from 'antd';
import './style/index.scss';
import TableBox from '@/components/tableBox';
import Pagination from '@/components/pagination';
import { debounce, throttle } from 'lodash';
import workoutApi from '@/api/workout';
import { defaultInfo } from '@/typings/allType';
import { useSelector } from 'react-redux';
interface listParams {
    pageNo: string | number;
    search: string;
    category: string;
}
const Workouts = (props: any) => {
    const { feedBackCount, locationFeedBackCount } = useSelector((state: any) => state.state);
    const navigate = useNavigate();
    useEffect(() => {
        getList({ ...state });
        getWorkoutType();
    }, []);
    useEffect(() => {
        // console.log(props.prevLocation);
        const path = props.prevLocation?.pathname;
        if (path === '/workouts/detail' || path === '/workouts/create-workout') {
            getList({ ...state });
        }
    }, [props.prevLocation]);
    const [workoutTypeList, setWorkoutTypeList] = useState<defaultInfo[]>([]);
    // loading empty showData
    const [status, setStatus] = useState('empty');
    const [totalRecord, setTotalRecord] = useState(0);
    const [state, setState] = useState({
        pageNo: 1,
        category: '',
        search: '',
    });
    const handleChange = (value: string, type: number) => {
        setState({ ...state, [type === 1 ? 'category' : 'country']: value });
        getList({ ...state, [type === 1 ? 'category' : 'country']: value });
    };
    const searchList = useCallback(
        debounce(params => getList(params), 1000),
        []
    );
    const handlePageChange = prop => {
        setState({ ...state, pageNo: prop });
        getList({ ...state, pageNo: prop });
    };
    const getList = (val: listParams) => {
        if (status === 'loading') return;
        const params = {
            pageNum: val.pageNo,
            pageSize: 10,
            workoutTypeId: val.category !== '' ? val.category : '',
            keyword: val.search,
        };
        setStatus('loading');
        workoutApi.getWorkoutList(params, res => {
            const { data, code, message } = res;
            if (code === 200) {
                if (data.records.length > 0) {
                    const list = data.records.map(item => {
                        return Object.assign(item, { createTime: window.format.foramtTimeDMY(+item.createTime) });
                    });
                    setTableData(list);
                    setStatus('showData');
                    setTotalRecord(data.total);
                } else {
                    setStatus('empty');
                    setTotalRecord(data.total);
                }
            } else {
                window.format.alert('error', message);
                setStatus('empty');
            }
        });
    };
    const getWorkoutType = () => {
        workoutApi.getWorkoutTypeList({}, res => {
            const { data, code, message } = res;
            if (code === 200) {
                if (data && data.length) {
                    const arr = data.map(item => {
                        return Object.assign(item, {
                            value: item.workoutTypeId,
                            label: item.workoutTypeName,
                        });
                    });
                    setWorkoutTypeList([{ value: '', label: 'All Type' }, ...arr]);
                }
            } else {
                window.format.alert('error', message);
            }
        });
    };
    const [tableData, setTableData] = useState([]);
    const columns = [
        {
            title: 'WORKOUT LOCATION NAME',
            dataIndex: 'workoutName',
            key: 'workoutName',
            width: '35%',
        },
        {
            title: 'TYPE',
            dataIndex: 'workoutTypeName',
            key: 'workoutTypeName',
        },
        {
            title: 'CREATION DATE',
            dataIndex: 'createTime',
            key: 'createTime',
        },
        {
            title: 'NUMBER OF FAVOURITES',
            dataIndex: 'numberOfFavourites',
            key: 'numberOfFavourites',
        },
        {
            title: '',
            dataIndex: '',
            key: 'workoutId',
            render: val => (
                <div
                    className="remove_btn"
                    onClick={e => {
                        opeation(e, val);
                    }}
                >
                    Remove
                </div>
            ),
        },
    ];
    const handClickRow = (row: defaultInfo) => {
        //detail
        window.sessionStorage.setItem('workoutId', row.workoutId);
        navigate('/workouts/detail');
    };
    const linkToPage = (key: number) => {
        window.sessionStorage.removeItem('workoutId');
        window.sessionStorage.removeItem('workoutInfo');
        // arr or other page
        navigate(key === 1 ? '/workouts/feedback' : key === 2 ? '/workouts/actionLibrary' : '/workouts/create-workout');
    };
    const [modalVisibile, setModalVisibile] = useState(false);
    const [opeationInfo, setOpeationInfo] = useState({
        loading: false,
        id: '',
    });
    const opeation = (e, val) => {
        e.stopPropagation();
        console.log(val);
        setOpeationInfo({ ...opeationInfo, id: val.workoutId });
        setModalVisibile(true);
    };
    const confirmDel = () => {
        const params = {
            workoutId: opeationInfo.id,
        };
        setOpeationInfo({ ...opeationInfo, loading: true });
        workoutApi.deleteWorkout(params, res => {
            const { data, code, message } = res;
            setOpeationInfo({ ...opeationInfo, loading: false });
            if (code === 200) {
                setModalVisibile(false);
                setOpeationInfo({ id: '', loading: false });
                getList({ ...state });
            } else {
                window.format.alert('error', message);
            }
        });
    };
    return (
        <div className="workout_main table_page">
            <div className="workout_top r-left">
                <Input
                    placeholder="Search"
                    style={{ width: 210 }}
                    value={state.search}
                    allowClear
                    onChange={e => {
                        setState({ ...state, search: e.target.value });
                        searchList({ ...state, search: e.target.value });
                    }}
                    className="r-input r-input_serach"
                    prefix={<span className="search_icon"></span>}
                />
                <div className="space15"></div>
                <Select
                    value={state.category}
                    style={{ width: 138 }}
                    onChange={val => {
                        handleChange(val, 1);
                    }}
                    className="r-select"
                    suffixIcon={<i className="sel_arrow_icon"></i>}
                    options={workoutTypeList}
                />
                <Button className="r-default_btn marLeft" onClick={() => linkToPage(1)} icon={<></>}>
                    Reported Application
                    {feedBackCount + locationFeedBackCount ? (
                        <span className="roundNum_box">{feedBackCount + locationFeedBackCount}</span>
                    ) : (
                        ''
                    )}
                    {/* <span className="roundNum_box">{feedBackCount}</span> */}
                </Button>
                <div className="space15"></div>
                <Button className="r-default_btn" onClick={() => linkToPage(2)} icon={<></>}>
                    Exercise List
                </Button>
                <div className="space15"></div>
                <Button className="r-default_btn" onClick={() => linkToPage(3)} icon={<></>}>
                    Add New Workout Location
                </Button>
            </div>
            <TableBox
                status={status}
                columns={columns}
                data={tableData}
                rowKey={val => val.workoutId}
                height={false}
                destroyOnClose
                className="is_clickable r-table"
                rowClassName="r-table_row"
                onRow={record => {
                    handClickRow(record);
                }}
            />
            {status === 'showData' ? (
                <Pagination total={totalRecord} pageChange={handlePageChange} pageNo={state.pageNo} />
            ) : (
                <></>
            )}

            <Modal
                className="r-modal modal_no_padding"
                maskClosable={false}
                centered={true}
                open={modalVisibile}
                onCancel={() => setModalVisibile(false)}
                footer={''}
                destroyOnClose
                title={''}
                closable={false}
            >
                <div className="modal_form">
                    <p className="del_tip">This operation is irrevocable. Are you sure to delete this workout?</p>
                    <div className="r-center" style={{ marginTop: 50 }}>
                        <Button
                            className="r-defaultBlick_btn"
                            style={{ flex: 1 }}
                            onClick={() => setModalVisibile(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="r-primary_btn"
                            type="primary"
                            style={{ flex: 1, marginLeft: 15 }}
                            loading={opeationInfo.loading}
                            onClick={() => confirmDel()}
                        >
                            Confirm
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};
export default Workouts;
