import axios from '../utils/index';
import { Pat } from '@/typings/request';
const subscriptionApi = {
    getSubscriptionList(params: Pat, callback: (T: any) => void) {
        axios
            .get(`/admin/subscribe/list`, {
                params,
            })
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
};
export default subscriptionApi;
