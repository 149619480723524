import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Select, Button, Modal, Tooltip, Spin } from 'antd';
import './style/workout-detail.scss';
import workoutApi from '@/api/workout';
import { defaultInfo } from '@/typings/allType';
import { useSize } from 'ahooks';
const WorkoutDetail = (props: any) => {
    const navigate = useNavigate();
    const infoRef = useRef(null);
    const size = useSize(infoRef);
    useEffect(() => {
        const workoutId = window.sessionStorage.getItem('workoutId') ?? '';
        if (workoutId) {
            const from = window.format.getPageQuery(window.location.href, 'from');
            setPageFrom(from);
            workoutApi.getWorkoutInfo({ workoutId }, res => {
                const { data, code, message } = res;
                setStatus('showData');
                if (code === 200) {
                    setState({
                        ...state,
                        ...data,
                        name: data.workoutName,
                        workoutType: data.workoutType,
                        desc: data.workoutDescription,
                        warmUp: data.warmUpExercise.id,
                        workTime: data.workoutDuration,
                        setList: [
                            {
                                formatType: '',
                                timer: '',
                                exerciseList: [] as any,
                            },
                        ],
                        workoutContent:
                            data.workoutContent && data.workoutContent !== null
                                ? data.workoutContent.map(item => {
                                      const intros =
                                          item.set && item.set.length ? item.set.find(v => v.setType === 'Intros') : '';
                                      return Object.assign(item, {
                                          isHaveIntros: intros && intros !== null ? !!intros.exerciseId : false,
                                          introsInfo: intros,
                                      });
                                  })
                                : [],
                        coolDown: data.coolDownExercise.id,
                        undercoverWorkouts: data.undercoverWorkouts,
                        prizeWhich: data.prizeWhichIsFullyVisibleId ?? '',
                        mysteryBoxId: data.mysteryBoxId ?? '',
                        mysteryBox: data.mysteryBox ? data.mysteryBox : state.mysteryBox,
                        prizeWhichIsFullyVisible: data.prizeWhichIsFullyVisible
                            ? data.prizeWhichIsFullyVisible
                            : state.prizeWhichIsFullyVisible,
                        userStatisticList:
                            data.userStatisticList && data.userStatisticList.length
                                ? data.userStatisticList.map((item: defaultInfo) => {
                                      return Object.assign(item, {
                                          endTime: window.format.foramtTimeDMYHM(+item.endTime),
                                      });
                                  })
                                : [],
                    });
                    totalNumList.forEach((item, i) => {
                        const obj =
                            i === 0
                                ? data.totalNumberOfWorkoutsCompleted
                                : i === 1
                                ? data.numberOfUsersCompletedWorkout
                                : data.totalNumberOfFavourites;
                        item.value = obj['thisMonth'];
                    });
                    setTotalNumList([...totalNumList]);
                } else if (code === 7001) {
                    window.format.alert('error', message);
                    back();
                } else {
                    window.format.alert('error', message);
                }
            });
        }
    }, []);
    const [pageFrom, setPageFrom] = useState('');
    const [status, setStatus] = useState('loading');
    const [state, setState] = useState({
        workoutId: '',
        name: '',
        workoutType: '',
        workoutTypeName: '',
        desc: '',
        warmUp: '',
        warmUpExercise: { name: '' },
        workTime: '',
        workoutContent: [] as any,
        locationList: [] as any,
        coolDown: '',
        coolDownExercise: { name: '' },
        undercoverWorkouts: false,
        prizeWhich: '',
        mysteryBoxId: '',
        mysteryBox: { name: '' },
        prizeWhichIsFullyVisible: { name: '' },
        userStatisticList: [] as any,
        totalNumberOfWorkoutsCompleted: {
            beforeToday: '0',
            thisYear: '0',
            thisMonth: '0',
            today: '0',
        },
        numberOfUsersCompletedWorkout: {
            beforeToday: '0',
            thisYear: '0',
            thisMonth: '0',
            today: '0',
        },
        totalNumberOfFavourites: {
            beforeToday: '0',
            thisYear: '0',
            thisMonth: '0',
            today: '0',
        },
    });
    const handleChange = (value: string, i: number) => {
        const data =
            i === 0
                ? state.totalNumberOfWorkoutsCompleted
                : i === 1
                ? state.numberOfUsersCompletedWorkout
                : state.totalNumberOfFavourites;
        totalNumList[i].value = data[value];
        setTotalNumList([...totalNumList]);
    };
    const [totalNumList, setTotalNumList] = useState([
        { label: 'Total number of workouts completed', time: '', value: 0 },
        { label: 'Number of users completed workout', time: '', value: 0 },
        { label: 'Total number of favourites', time: '', value: 0 },
    ]);
    const back = () => {
        window.sessionStorage.removeItem('workoutId');
        navigate(pageFrom ? pageFrom : '/workouts');
    };
    const viewMore = () => {
        navigate('/workouts/statistics-list');
    };
    const toActionLibrary = (val: defaultInfo) => {
        window.sessionStorage.setItem('exerciseId', val.exerciseId);
        window.sessionStorage.setItem('isFromWork', '1');
        navigate('/workouts/actionLibrary-detail');
    };
    const [modalVisibile, setModalVisibile] = useState(false);
    const [opeationInfo, setOpeationInfo] = useState({
        loading: false,
    });
    const opeation = (key: number) => {
        if (key) {
            // del
            setModalVisibile(true);
            return;
        }
        window.sessionStorage.setItem('workoutInfo', JSON.stringify(state));
        navigate('/workouts/create-workout');
    };
    const confirmDel = () => {
        const params = {
            workoutId: state.workoutId,
        };
        setOpeationInfo({ ...opeationInfo, loading: true });
        workoutApi.deleteWorkout(params, res => {
            const { data, code, message } = res;
            setOpeationInfo({ ...opeationInfo, loading: false });
            if (code === 200) {
                setModalVisibile(false);
                setOpeationInfo({ loading: false });
                back();
            } else {
                window.format.alert('error', message);
            }
        });
    };
    return (
        <div className="workout-detail_main">
            <div className="top_back r-left">
                <div className="back_box r-left" onClick={back}>
                    <i className="back_icon"></i>
                    Back
                </div>
                <Button
                    className="r-defaultBlick_btn marLeft"
                    style={{ width: 146 }}
                    onClick={() => opeation(0)}
                    icon={<></>}
                >
                    Edit
                </Button>
                <div className="space15"></div>
                <Button className="r-defaultBlick_btn" style={{ width: 146 }} onClick={() => opeation(1)} icon={<></>}>
                    Remove
                </Button>
            </div>
            {status === 'loading' ? (
                <div className="data_loading flex-center" style={{ height: 'auto' }}>
                    <Spin />
                </div>
            ) : (
                <section className="detail_box">
                    <ul className="r-left topNum_ul">
                        {totalNumList.map((v, i) => {
                            return (
                                <li className={`wordout_card r-left ${i === 1 ? 'space_box' : ''}`} key={i}>
                                    <span className="card_left">
                                        <div className="card_label">{v.label}</div>
                                        <Select
                                            defaultValue="thisMonth"
                                            style={{ width: 158 }}
                                            onChange={val => handleChange(val, i)}
                                            className="r-select r-select_mini"
                                            suffixIcon={<i className="sel_arrow_icon"></i>}
                                            options={[
                                                { value: 'beforeToday', label: 'Before Today' },
                                                { value: 'thisYear', label: 'This Year' },
                                                { value: 'thisMonth', label: 'This Month' },
                                                { value: 'today', label: 'Today' },
                                            ]}
                                        />
                                    </span>
                                    <span className="card_val marLeft">{v.value}</span>
                                </li>
                            );
                        })}
                    </ul>
                    <div className="workout_infoBox r-left" style={{ alignItems: 'flex-start' }}>
                        <div className="info-leftbox" ref={infoRef}>
                            <div className="info_row r-top">
                                <span className="info_tip">WORKOUT LOCATION NAME :</span>
                                <span className="info_msg">{state.name}</span>
                            </div>
                            <div className="info_row r-top">
                                <span className="info_tip">WORKOUT LOCATION ID : </span>
                                <span className="info_msg">{state.workoutId}</span>
                            </div>
                            <div className="info_row r-top">
                                <span className="info_tip">WORKOUT TYPE :</span>
                                <span className="info_msg">{state.workoutTypeName}</span>
                            </div>
                            <div className="info_row r-top">
                                <span className="info_tip">DESCRIPTION :</span>
                                <span className="info_msg" style={{ whiteSpace: 'pre-line' }}>
                                    {state.desc}
                                </span>
                            </div>
                            <div className="info_row r-top">
                                <span className="info_tip">WORKOUT DURATION (APPROX) :</span>
                                <span className="info_msg">
                                    {+state.workTime > 1 ? `${state.workTime}mins` : `${state.workTime}min`}
                                </span>
                            </div>
                        </div>
                        <div className="info-rightbox" style={{ maxHeight: size?.height ?? 300 }}>
                            <div className="info_row r-top">
                                <span className="info_tip">LOCATIONS :</span>
                                <span className="info_msg">{state.locationList.length}</span>
                            </div>
                            {state.locationList.length ? (
                                state.locationList.map((v: defaultInfo) => {
                                    return (
                                        <div className="info_row r-top" key={v.locationId}>
                                            <span className="info_msg no-margin">{v.workoutLocation ?? ''}</span>
                                            {/* <span className="info_msg no-margin oneLine_text">
                                                <Tooltip title={v.workoutLocation} placement="topLeft">
                                                    {v.workoutLocation}
                                                </Tooltip>
                                            </span> */}
                                        </div>
                                    );
                                })
                            ) : (
                                <div className="not_data flex-center">No data</div>
                            )}
                        </div>
                    </div>
                    <div className="workout_composition r-left">
                        <div className="composition_card">
                            <p className="composition_tit">Warm up</p>
                            <p className="composition_val">{state.warmUpExercise.name}</p>
                        </div>
                        <div className="composition_card">
                            <p className="composition_tit">Cooldowns</p>
                            <p className="composition_val">{state.coolDownExercise.name}</p>
                        </div>
                    </div>
                    <div className="workouts_action">
                        <div className="action_tit">Workouts</div>
                        {state.workoutContent.map((v: defaultInfo, i: number) => {
                            return (
                                <div className="action_setBox" key={i}>
                                    <div
                                        className="action_setTit r-left"
                                        style={{
                                            display:
                                                (v.set && v.set.length === 1 && !v.isHaveIntros) ||
                                                (v.set && v.set.length > 1)
                                                    ? 'flex'
                                                    : 'none',
                                        }}
                                    >
                                        Set {i + 1}: Formats -
                                        {v.formats === 'Interval'
                                            ? ' Interval'
                                            : v.formats === 'AMRAPLadder'
                                            ? ' AMRAP/Ladder '
                                            : ' EMOM '}
                                        <i className="space30"></i>
                                        {v.formats !== 'Interval'
                                            ? `
                                            Timer - ${v.timer}${v.timer > 1 ? 'mins' : 'min'}
                                            `
                                            : ''}
                                        {v.isHaveIntros ? (
                                            <>
                                                <i className="space30"></i>
                                                Set Intros - {v.introsInfo.exerciseName}
                                            </>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                    <div
                                        className="r-left action_labelBox"
                                        style={{ flexWrap: 'wrap', display: v.set && v.set.length ? 'flex' : 'none' }}
                                    >
                                        {v.set && v.set.length
                                            ? v.set.map((item: defaultInfo, k: number) => {
                                                  return item.setType === 'Intros' ? (
                                                      ''
                                                  ) : (
                                                      <span className="r-left action_label" key={k}>
                                                          {item.setType === 'Exercise' ? (
                                                              <span
                                                                  className="exerciseNameBox"
                                                                  onClick={() => toActionLibrary(item)}
                                                              >
                                                                  {item.exerciseName}
                                                              </span>
                                                          ) : (
                                                              'Rest'
                                                          )}
                                                          <span style={{ margin: 5 }}>-</span>
                                                          {item.setType === 'Exercise'
                                                              ? item.timingMode === 'Duration'
                                                                  ? ` ${item.duration}${
                                                                        item.duration > 1 ? 'secs' : 'sec'
                                                                    }`
                                                                  : item.timingMode === 'Distance'
                                                                  ? ` ${item.duration}${
                                                                        item.duration > 1 ? 'meters' : 'meters'
                                                                    }`
                                                                  : ` ${item.duration}${
                                                                        item.duration > 1 ? 'reps' : 'rep'
                                                                    }`
                                                              : ` ${item.rest}${item.rest > 1 ? 'secs' : 'sec'}`}
                                                          <i className="space30"></i>
                                                      </span>
                                                  );
                                              })
                                            : ''}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    {state.prizeWhichIsFullyVisible.name || state.mysteryBox.name ? (
                        <div className="workouts_action mar20">
                            <div className="action_tit">Rewards</div>
                            <div
                                className="info_row r-top"
                                style={{ display: state.prizeWhichIsFullyVisible.name ? 'flex' : 'none' }}
                            >
                                <span className="info_tip">PRIZE WHICH IS FULLY VISIBLE :</span>
                                <span className="info_msg">{state.prizeWhichIsFullyVisible.name ?? '-'}</span>
                            </div>
                            <div
                                className="info_row r-top"
                                style={{ display: state.mysteryBox.name ? 'flex' : 'none' }}
                            >
                                <span className="info_tip">MYSTERY BOX :</span>
                                <span className="info_msg">{state.mysteryBox.name ?? '-'}</span>
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                    {state.userStatisticList && state.userStatisticList.length ? (
                        <div className="workout-location-statistics">
                            <div className="r-left location_statisticsTip">
                                WORKOUT LOCATION STATISTICS
                                <span className="view_box" onClick={viewMore}>
                                    view
                                </span>
                            </div>
                            <ul className="workout_list">
                                <li className="workout_item_header r-left">
                                    <span className="itemHeader_cell">USER’S NAME</span>
                                    <span className="itemHeader_cell">COMPLETED TIME</span>
                                </li>
                                {state.userStatisticList.map((item: defaultInfo, i: number) => {
                                    return (
                                        <li className="workout_item r-left" key={i}>
                                            <span className="item_cell r-left">
                                                <img src={item.avatarUrl} className="user_img" alt="" />
                                                {item.fullName ?? '-'}
                                            </span>
                                            <span className="item_cell item_cellMini">{item.endTime}</span>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    ) : (
                        ''
                    )}
                </section>
            )}

            <Modal
                className="r-modal modal_no_padding"
                maskClosable={false}
                centered={true}
                open={modalVisibile}
                onCancel={() => setModalVisibile(false)}
                footer={''}
                title={''}
                closable={false}
            >
                <div className="modal_form">
                    <p className="del_tip">This operation is irrevocable. Are you sure to delete this workout?</p>
                    <div className="r-center" style={{ marginTop: 50 }}>
                        <Button
                            className="r-defaultBlick_btn"
                            style={{ flex: 1 }}
                            onClick={() => setModalVisibile(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="r-primary_btn"
                            type="primary"
                            style={{ flex: 1, marginLeft: 15 }}
                            loading={opeationInfo.loading}
                            onClick={() => confirmDel()}
                        >
                            Confirm
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};
export default WorkoutDetail;
