import dayjs from 'dayjs';

const formatSplit = (s: any, type: any) => {
    if (s === null) {
        return null;
    }
    if (s === '') {
        return '';
    }
    if (s === 0 && type === 0) {
        return '0.00';
    }
    if (s === 0 && type !== 0) {
        return '0';
    }
    s = s < 0 && type === 0 ? s.toString().replace(/^(\d*)$/, '$1.') + '.' : s.toString().replace(/^(\d*)$/, '$1.');
    s = (s + '00').replace(/(\d*\.\d\d)\d*/, '$1');
    s = s.replace('.', ',');
    const re = /(\d)(\d{3},)/;
    while (re.test(s)) s = s.replace(re, '$1,$2');
    s = s.replace(/,(\d\d)$/, '.$1');

    if (type !== 0) {
        const a = s.split('.');
        if (a[1] === '00') {
            s = a[0];
        }
    }
    return s;
};
/*const formatPhone = (num: any) => {
    if (!num) return '-';
    if (num.length === 10) return `+44 ${num.slice(0, 2)} ${num.slice(2, 6)} ${num.slice(6)}`;
    else return num;
};*/
const formatPhone = (num: any) => {
    if (!num) return '';
    if (num.length === 10 && num.slice(0, 1) === '7') return `${num.slice(0, 2)} ${num.slice(2, 6)} ${num.slice(6)}`;
    else if (num.length === 11 && num.slice(0, 2) === '07')
        return `${num.slice(0, 3)} ${num.slice(3, 7)} ${num.slice(7)}`;
    else return num;
};
const formatNumer = (num: string) => {
    const temp = num
        .replace(/[^\d.]/g, '')
        .replace(/^\./g, '')
        .replace(/\.{2,}/g, '')
        .replace('.', '$#$')
        .replace(/\./g, '')
        .replace('$#$', '.')
        .replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');
    return Number(temp);
};
function isCurrentWeek(pastTime: number) {
    const today = new Date(new Date().toLocaleDateString());
    let day = today.getDay();
    day = day == 0 ? 7 : day;
    const oneDayTime = 60 * 60 * 24 * 1000;
    const monday = new Date(today.getTime() - oneDayTime * (day - 1));
    const nextMonday = new Date(today.getTime() + oneDayTime * (8 - day));
    if (monday.getTime() <= pastTime && nextMonday.getTime() > pastTime) {
        return true;
    } else {
        return false;
    }
}
const formatTimeStamp = (e: any) => {
    if (!e) return '-';
    if (typeof e === 'string') e = Number(e);
    if (e < 10000000000) e = e * 1000;
    const time = dayjs(e).format('DD.MM.YYYY HH:mm');
    const hourMinute = time.split(' ')[1];
    const hour = Number(hourMinute.split(':')[0]);
    const hourFinal = hour > 12 ? hour - 12 : hour;
    const minute = hourMinute.split(':')[1];
    return `${time.split(' ')[0]}, ${hourFinal}:${minute}${hour > 11 ? ' PM' : ' AM'}`;
    // 16.05.2022, 7:35 PM
};
const formatTimeStamp24 = (e: any) => {
    if (!e) return '-';
    if (typeof e === 'string') e = Number(e);
    if (e < 10000000000) e = e * 1000;
    const time = dayjs(e).format('DD.MM.YYYY | HH:mm');
    return `Date Added: ${time}`;
    // 16.05.2022, 7:35 PM
};

export default {
    formatSplit,
    isCurrentWeek,
    formatPhone,
    formatNumer,
    formatTimeStamp,
    formatTimeStamp24,
};
