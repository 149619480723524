import { Input, Space, Modal, Button, DatePicker, message } from 'antd';
import searchIcon from '@/assets/images/search.png';
import type { ColumnsType } from 'antd/es/table';
import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Pagination from '@/components/pagination';
import TableBox from '@/components/tableBox';
import type { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import calendarIcon from '@/assets/images/calendar_icon.png';
import comeBackImg from '@/assets/images/back.png';
import './index.scss';
import SocialsApi from '@/api/socials';
import debounce from 'lodash.debounce';
import { useAppDispatch } from '@/store/hooks';
import { setIsGetList } from '@/store/stateSlice';
import { useAliveController } from 'react-activation';
interface userObjType {
    createTime: string;
    hiddenStatus: boolean;
    postAvatarUrl: string;
    postFullName: string;
    postId: string;
    postUserId: string;
    reportingAvatarUrl: string;
    reportingFullName: string;
    reportingRecordsId: string;
    reportingUserId: string;
    fullName?: string;
    avatarUrl?: string;
    content?: string;
}
interface getListParams {
    pageNum: number;
    pageSize: number;
    keyword: string;
    startTime: string | number;
    endTime: string | number;
}

const ReportApplication = (props: any) => {
    const { RangePicker } = DatePicker;
    const navigate = useNavigate();
    const [status, setStatus] = useState('showData');
    const [listParams, setListParams] = useState<getListParams>({
        pageNum: 1,
        pageSize: 10,
        keyword: '',
        startTime: '',
        endTime: '',
    });
    const [totalRecord, setTotalRecord] = useState(0);
    const disabledDate: RangePickerProps['disabledDate'] = current => {
        return current && current > dayjs().endOf('day');
    };
    const handlePageChange = prop => {
        setListParams(state => ({ ...state, pageNum: prop }));
        getList({ ...listParams, pageNum: prop });
    };
    const [currentUser, setCurrentUser] = useState<userObjType | null>(null);
    const [postUnRead, setPostUnRead] = useState(false);
    const [commentUnRead, setCommentUnRead] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isHide, setIsHide] = useState(false);
    const [activeTab, setActiveTab] = useState(1);
    const changeTab = (type: number) => {
        if (activeTab === type) return;
        setActiveTab(type);
    };
    const { drop } = useAliveController();
    const columns: ColumnsType<userObjType> = [
        {
            title: 'REPORTED BY USER’S NAME',
            dataIndex: 'reportingFullName',
            key: 'reportingFullName',
            render: (name, record) => (
                <span className="user_info">
                    <img
                        style={{ width: 25, height: 25 }}
                        src={activeTab ? record.reportingAvatarUrl : record.avatarUrl}
                        alt=""
                    />
                    {activeTab ? record.reportingFullName : record.fullName}
                </span>
            ),
        },
        {
            title: 'POST ID',
            dataIndex: 'postId',
            key: 'postId',
            render: (text, record) => (
                <span
                    className="view_more"
                    onClick={() => {
                        navigate(`/socials/post?identity=${record.postId}&from=/socials/reportReportApplication`);
                    }}
                >
                    {text}
                </span>
            ),
        },
        {
            title: activeTab ? 'POSTER NAME' : 'COMMENT CONTENT',
            dataIndex: 'postFullName',
            key: 'postFullName',
            render: (name, record) =>
                activeTab ? (
                    <span className="user_info">
                        <img style={{ width: 25, height: 25 }} src={record.postAvatarUrl} alt="" />
                        {name}
                    </span>
                ) : (
                    record.content
                ),
        },
        {
            title: 'REPORT DATE AND TIME',
            dataIndex: 'createTime',
            key: 'createTime',
            render: time => dayjs(+time).format('DD/MM/YYYY HH:mm'),
        },
        {
            title: '',
            dataIndex: 'hiddenStatus',
            key: 'hiddenStatus',
            render: (hiddenStatus, record) => (
                <Space wrap size={15}>
                    <Button
                        className={hiddenStatus ? 'table_button disabled_btn' : 'table_button'}
                        onClick={e => {
                            e.stopPropagation();
                            setIsModalOpen(true);
                            setIsHide(true);
                            setCurrentUser(record);
                        }}
                        disabled={hiddenStatus}
                    >
                        {!hiddenStatus ? 'Hide' : 'Hidden'}
                    </Button>
                    <Button
                        className="table_button"
                        onClick={e => {
                            e.stopPropagation();
                            setIsHide(false);
                            setIsModalOpen(true);
                            setCurrentUser(record);
                        }}
                    >
                        Remove
                    </Button>
                </Space>
            ),
        },
    ];
    useEffect(() => {
        setListParams(state => ({ ...state, pageNum: 1 }));
        getList({ ...listParams, pageNum: 1 });
    }, [activeTab]);
    const debouncedSave = useCallback(
        debounce(nextValue => getList(nextValue), 1000),
        []
    );
    const inputChange = e => {
        setListParams(state => ({ ...state, keyword: e.target.value, pageNum: 1 }));
        debouncedSave({ ...listParams, keyword: e.target.value, pageNum: 1 });
    };
    const dateOnChange = (dates, dateStrings) => {
        setListParams(state => ({
            ...state,
            startTime: dates ? dayjs(`${dates[0].format('YYYY-MM-DD')} 00:00:00`).valueOf() : '',
            endTime: dates ? dayjs(`${dates[1].format('YYYY-MM-DD')} 23:59:59`).valueOf() : '',
        }));
        getList({
            ...listParams,
            startTime: dates ? dayjs(`${dates[0].format('YYYY-MM-DD')} 00:00:00`).valueOf() : '',
            endTime: dates ? dayjs(`${dates[1].format('YYYY-MM-DD')} 23:59:59`).valueOf() : '',
        });
    };
    const [list, setList] = useState<userObjType[]>([]);
    const dispatch = useAppDispatch();
    const getList = async params => {
        setStatus('loading');
        await SocialsApi.getReportList({ ...params, type: activeTab ? 'post' : 'comment' }, res => {
            const { code, data } = res;
            if (code === 200) {
                if (data.records.length > 0) {
                    setList(data.records);
                    setStatus('showData');
                } else {
                    setStatus('empty');
                }
                if (activeTab) {
                    setCommentUnRead(data.metaData);
                } else {
                    setPostUnRead(data.metaData);
                }
                dispatch(setIsGetList(true));
                setTotalRecord(data.total);
            } else {
                setStatus('empty');
                message.error(res.message);
            }
        });
    };
    const hideOrDeleteReport = () => {
        if (isHide) {
            SocialsApi.hideCommentOrReport({ reportingRecordsId: currentUser?.reportingRecordsId }, res => {
                if (res.code === 200) {
                    message.success(res.message);
                    getList(listParams);
                    setIsModalOpen(false);
                } else {
                    message.error(res.message);
                }
            });
        } else {
            SocialsApi.deleteReportPostOrComment(
                activeTab ? 'post' : 'comment',
                { reportingRecordsId: currentUser?.reportingRecordsId },
                res => {
                    if (res.code === 200) {
                        message.success(res.message);
                        getList(listParams);
                        setIsModalOpen(false);
                    } else {
                        message.error(res.message);
                    }
                }
            );
        }
    };
    return (
        <div className="page-main Report_Application table_page">
            <div className="flex-between page_head" style={{ marginTop: 72 }}>
                <div
                    className="come_back"
                    onClick={() => {
                        drop('ReportApplication');
                        navigate('/socials');
                    }}
                >
                    <img src={comeBackImg} alt="" />
                    Back
                </div>
            </div>
            <div className="search_box flex-between" style={{ marginTop: 40 }}>
                <Space wrap size={15}>
                    <Input
                        allowClear
                        value={listParams.keyword}
                        onChange={inputChange}
                        className="search_input"
                        size="large"
                        placeholder="Search"
                        style={{ width: 210 }}
                        maxLength={50}
                        prefix={<img src={searchIcon} alt="" />}
                    />
                    <RangePicker
                        placeholder={['dd/mm/yyyy', 'dd/mm/yyyy']}
                        style={{ width: 258 }}
                        suffixIcon={<img src={calendarIcon} alt="" />}
                        format={'DD/MM/YYYY'}
                        disabledDate={disabledDate}
                        onChange={dateOnChange}
                    />
                </Space>
            </div>
            <div className="select_tabs" style={{ marginBottom: 25 }}>
                <Space wrap size={20}>
                    <div
                        className={activeTab ? 'select_tabs_item tab_active' : 'select_tabs_item'}
                        onClick={() => {
                            changeTab(1);
                        }}
                    >
                        {postUnRead ? <span className="new_report"></span> : ''}
                        Posts
                    </div>
                    <div
                        className={!activeTab ? 'select_tabs_item tab_active' : 'select_tabs_item'}
                        onClick={() => {
                            changeTab(0);
                        }}
                    >
                        {commentUnRead ? <span className="new_report"></span> : ''}
                        Comments
                    </div>
                </Space>
            </div>
            <TableBox status={status} columns={columns} data={list} rowKey="createTime" />
            {status === 'showData' ? (
                <Pagination total={totalRecord} pageChange={handlePageChange} pageNo={listParams.pageNum} />
            ) : (
                <></>
            )}
            <Modal
                className="modal-suspend"
                closable={false}
                maskClosable={false}
                centered={true}
                open={isModalOpen}
                onCancel={() => {
                    setIsModalOpen(false);
                }}
                footer={[]}
            >
                <div className="modal-tip">
                    {isHide
                        ? 'When hidden, the post is not visible to the whistleblower. Are you sure you want to hide this post?'
                        : 'Are you sure you wish to remove this Post?'}
                </div>
                <Space size={12} style={{ marginTop: 43 }}>
                    <Button
                        onClick={() => {
                            setIsModalOpen(false);
                        }}
                        style={{ width: 194, height: 54 }}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="primary"
                        htmlType="submit"
                        onClick={hideOrDeleteReport}
                        style={{ width: 194, height: 54 }}
                    >
                        Confirm
                    </Button>
                </Space>
            </Modal>
        </div>
    );
};
export default ReportApplication;
