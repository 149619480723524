import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input, Button, Table, Modal, Spin } from 'antd';
import './style/index.scss';
import Pagination from '@/components/pagination';
import { debounce } from 'lodash';
import rewardsApi from '@/api/rewards';
import { defaultInfo } from '@/typings/allType';
import { useAliveController } from 'react-activation';
interface listParams {
    pageNo: string | number;
    search: string;
}
const RewardsTreasure = (props: any) => {
    const navigate = useNavigate();
    useEffect(() => {
        getList({ ...state });
    }, []);
    useEffect(() => {
        const path = props.prevLocation?.pathname;
        if (path === '/rewards/add-reward-treasure') {
            getList({ ...state });
        }
    }, [props.prevLocation]);
    const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
    const { drop } = useAliveController();
    const back = () => {
        drop('RewardsTreasure');
        navigate('/rewards');
    };
    const filterRowClass: (val: defaultInfo) => string = val => {
        const index = expandedRowKeys.findIndex(v => v === val.key);
        return `r-table_row ${index !== -1 ? 'hideBorder' : ''}`;
    };
    const toDetail = (key: number, val?: any) => {
        if (key === 1) {
            // EDIT
            window.sessionStorage.setItem('rewardTreasureInfo', JSON.stringify(val));
        } else {
            // add
            window.sessionStorage.removeItem('rewardTreasureInfo');
        }
        navigate(`/rewards/add-reward-treasure`);
    };
    const [status, setStatus] = useState('empty');
    const [totalRecord, setTotalRecord] = useState(0);
    const [state, setState] = useState({
        pageNo: 1,
        search: '',
    });
    const searchList = useCallback(
        debounce(params => getList(params), 1000),
        []
    );
    const handlePageChange = prop => {
        setExpandedRowKeys([]);
        setState({ ...state, pageNo: prop });
        getList({ ...state, pageNo: prop });
    };
    const getList = (val: listParams) => {
        if (status === 'loading') return;
        const params = {
            pageNum: val.pageNo,
            pageSize: 10,
            keyword: val.search,
        };
        setStatus('loading');
        rewardsApi.getTreasureList(params, res => {
            const { data, code, message } = res;
            if (code === 200) {
                if (data.records.length > 0) {
                    const list = data.records.map((item: defaultInfo, i: number) => {
                        return Object.assign(item, {
                            key: i,
                            createTime: window.format.foramtTimeDMY(item.createTime),
                        });
                    });
                    setTableData(list);
                    setStatus('showData');
                    setTotalRecord(data.total);
                } else {
                    setStatus('empty');
                    setTotalRecord(data.total);
                }
            } else {
                setStatus('empty');
                window.format.alert('error', message);
            }
        });
    };
    const [tableData, setTableData] = useState<defaultInfo[]>([]);
    const columns = [
        {
            title: 'TREASURE NAME',
            dataIndex: 'treasureName',
            key: 'treasureName',
            width: '40%',
        },
        {
            title: 'CREATION DATE',
            dataIndex: 'createTime',
            key: 'createTime',
        },
        {
            title: 'NUMBER OF FAVOURITES',
            dataIndex: 'likeCount',
            key: 'likeCount',
        },
        {
            title: '',
            dataIndex: '',
            key: 'treasureId',
            width: 150,
            render: val => (
                <div className="r-left">
                    <div className="actioned_btn" onClick={() => toDetail(1, val)}>
                        Edit
                    </div>
                    <div className="space15"></div>
                    <div className="actioned_btn" onClick={e => opeation(e, val)}>
                        Remove
                    </div>
                </div>
            ),
        },
    ];
    const [modalVisibile, setModalVisibile] = useState(false);
    const [opeationInfo, setOpeationInfo] = useState({
        loading: false,
        id: '',
    });
    const opeation = (e, val) => {
        e.stopPropagation();
        setOpeationInfo({ ...opeationInfo, id: val.treasureId });
        setModalVisibile(true);
    };
    const confirmDel = () => {
        const params = {
            treasureId: opeationInfo.id,
        };
        setOpeationInfo({ ...opeationInfo, loading: true });
        rewardsApi.delTreasure(params, res => {
            const { data, code, message } = res;
            setOpeationInfo({ ...opeationInfo, loading: false });
            if (code === 200) {
                setModalVisibile(false);
                setOpeationInfo({ id: '', loading: false });
                getList({ ...state });
            } else {
                window.format.alert('error', message);
            }
        });
    };
    return (
        <div className="list_main rewards_pool_main table_page">
            <div className="top_back">
                <div className="r-left">
                    <div className="back_box r-left" onClick={back}>
                        <i className="back_icon"></i>
                        Back
                    </div>
                    <Button
                        style={{ width: 231 }}
                        icon={<></>}
                        className="r-default_btn marLeft"
                        onClick={() => toDetail(0)}
                    >
                        Add New Treasure Location
                    </Button>
                </div>
                <div className="screening_box r-left">
                    <Input
                        placeholder="Search"
                        style={{ width: 210 }}
                        value={state.search}
                        allowClear
                        onChange={e => {
                            setState({ ...state, search: e.target.value, pageNo: 1 });
                            searchList({ ...state, search: e.target.value, pageNo: 1 });
                        }}
                        className="r-input r-input_serach"
                        prefix={<span className="search_icon"></span>}
                    />
                </div>
            </div>
            {status === 'loading' ? (
                <div className="data_loading flex-center" style={{ height: 'auto' }}>
                    <Spin />
                </div>
            ) : (
                <></>
            )}
            {status === 'empty' ? <div className="not_data flex-center">No data</div> : <></>}
            {status === 'showData' ? (
                <>
                    <Table
                        className="r-table flex_height table_box"
                        rowClassName={record => filterRowClass(record)}
                        dataSource={tableData}
                        pagination={false}
                        columns={columns}
                        rowKey={record => record.key}
                    />
                    <Pagination total={totalRecord} pageChange={handlePageChange} pageNo={state.pageNo} />
                </>
            ) : (
                <></>
            )}

            <Modal
                className="r-modal modal_no_padding"
                maskClosable={false}
                centered={true}
                open={modalVisibile}
                onCancel={() => setModalVisibile(false)}
                footer={''}
                title={''}
                closable={false}
            >
                <div className="modal_form">
                    <p className="del_tip">
                        This operation is irrevocable. Are you sure to delete this treasure location?
                    </p>
                    <div className="r-center" style={{ marginTop: 50 }}>
                        <Button
                            className="r-defaultBlick_btn"
                            style={{ flex: 1 }}
                            onClick={() => setModalVisibile(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="r-primary_btn"
                            type="primary"
                            loading={opeationInfo.loading}
                            style={{ flex: 1, marginLeft: 15 }}
                            onClick={() => confirmDel()}
                        >
                            Confirm
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};
export default RewardsTreasure;
