import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Input, Button, Modal, Avatar } from 'antd';
import './style/index.scss';
import TableBox from '@/components/tableBox';
import Pagination from '@/components/pagination';
import { debounce } from 'lodash';
// import { useActivate } from 'react-activation';
import brandApi from '@/api/brand';
import { defaultInfo } from '@/typings/allType';
interface listParams {
    pageNo: string | number;
    totalRecord?: string | number;
    search: string;
}
const Brands = (props: any) => {
    useEffect(() => getList({ ...state }), []);
    useEffect(() => {
        const path = props.prevLocation?.pathname;
        if (path === '/brand/create-brand' || path === '/brand/detail') {
            getList({ ...state });
        }
    }, [props.prevLocation]);
    const navigate = useNavigate();
    const location = useLocation();
    const [status, setStatus] = useState('showData');
    const [totalRecord, setTotalRecord] = useState(0);
    const [state, setState] = useState({
        pageNo: 1,
        search: '',
    });
    const searchList = useCallback(
        debounce(params => getList(params), 1000),
        []
    );
    const handlePageChange = prop => {
        setState({ ...state, pageNo: prop });
        getList({ ...state, pageNo: prop });
    };
    const getList = (val: listParams) => {
        const params = {
            pageNum: val.pageNo,
            pageSize: 10,
            keyword: val.search,
        };
        setStatus('loading');
        brandApi.getBrandList(params, res => {
            const { data, code, message } = res;
            if (code === 200) {
                if (data.records.length > 0) {
                    const list = data.records.map(item => {
                        return Object.assign(item, {
                            createTime: window.format.foramtTimeDMY(item.createTime),
                            businessWebsite: window.format.getWebsiteShow(item.businessWebsite),
                        });
                    });
                    setTableData(list);
                    setStatus('showData');
                    setTotalRecord(data.total);
                } else {
                    setStatus('empty');
                    setTotalRecord(0);
                }
            } else {
                window.format.alert('error', message);
                setStatus('empty');
            }
        });
    };
    const [tableData, setTableData] = useState([]);
    const columns = [
        {
            title: 'BUSINESS NAME',
            key: 'name',
            width: '300px',
            render: (val: defaultInfo) => (
                <div className="user_info r-left">
                    {val.businessLogo && val.businessLogo !== null ? (
                        <img src={val.businessLogo} className="user_img" alt="" />
                    ) : (
                        <span className="user_img_text">{window.format.getFirstLetter(val.businessName)}</span>
                    )}
                    {val.businessName}
                </div>
            ),
        },
        {
            title: 'WEBSITE',
            dataIndex: 'businessWebsite',
            key: 'businessWebsite',
            render: (text, record) => <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>{text}</div>,
        },
        {
            title: 'NUMBER OF LOCATIONS',
            dataIndex: 'locationsNumber',
            key: 'locationsNumber',
            width: '250px',
        },
        {
            title: 'CREATION DATE',
            dataIndex: 'createTime',
            key: 'createTime',
            width: '150px',
        },
        {
            title: '',
            dataIndex: '',
            key: 'opeation',
            width: 250,
            render: val => {
                return (
                    <div className="r-left">
                        <div
                            className="remove_btn"
                            onClick={e => {
                                opeation(0, e, val);
                            }}
                        >
                            Edit
                        </div>
                        <span className="space15"></span>
                        <div
                            className="remove_btn"
                            onClick={e => {
                                opeation(1, e, val);
                            }}
                        >
                            Remove
                        </div>
                    </div>
                );
            },
        },
    ];
    const handClickRow = row => {
        window.sessionStorage.setItem('brandId', row.brandId);
        navigate('/brand/detail');
    };
    const linkToPage = () => {
        navigate('/brand/create-brand');
    };
    const [opeationInfo, setOpeationInfo] = useState({
        brandId: '',
        loading: false,
    });
    const opeation = (key: number, e: any, val: any) => {
        e.stopPropagation();
        if (key) {
            // del
            setOpeationInfo({ ...val, loading: false });
            setModalVisibile(true);
            return;
        }
        window.sessionStorage.setItem('brandInfo', JSON.stringify(val));
        navigate('/brand/create-brand');
    };
    const confirmDel = () => {
        const params = {
            brandId: opeationInfo.brandId,
        };
        setOpeationInfo({ ...opeationInfo, loading: true });
        brandApi.deleteBrand(params, res => {
            const { data, code, message } = res;
            setOpeationInfo({ ...opeationInfo, loading: false });
            if (code === 200) {
                setModalVisibile(false);
                getList({ ...state });
            } else {
                window.format.alert('error', message);
            }
        });
    };
    const [modalVisibile, setModalVisibile] = useState(false);
    return (
        <div className="brands_main table_page">
            <div className="workout_top r-left">
                <Input
                    placeholder="Search"
                    style={{ width: 210 }}
                    className="r-input r-input_serach"
                    value={state.search}
                    allowClear
                    onChange={e => {
                        setState({ ...state, search: e.target.value, pageNo: 1 });
                        searchList({ ...state, search: e.target.value, pageNo: 1 });
                    }}
                    prefix={<span className="search_icon"></span>}
                />
                <Button
                    className="r-default_btn marLeft"
                    style={{ width: 164 }}
                    icon={<></>}
                    onClick={() => linkToPage()}
                >
                    Add New Brand
                </Button>
            </div>
            <TableBox
                status={status}
                columns={columns}
                data={tableData}
                rowKey="brandId"
                height={false}
                className="is_clickable r-table"
                rowClassName="r-table_row"
                onRow={record => {
                    handClickRow(record);
                }}
            />
            {status === 'showData' ? (
                <Pagination total={totalRecord} pageChange={handlePageChange} pageNo={state.pageNo} />
            ) : (
                <></>
            )}

            <Modal
                className="r-modal modal_no_padding"
                maskClosable={false}
                centered={true}
                open={modalVisibile}
                onCancel={() => setModalVisibile(false)}
                footer={''}
                title={''}
                closable={false}
            >
                <div className="modal_form">
                    <p className="del_tip">Are you sure you want to delete this brand?</p>
                    <div className="r-center" style={{ marginTop: 50 }}>
                        <Button
                            className="r-defaultBlick_btn"
                            style={{ flex: 1 }}
                            onClick={() => setModalVisibile(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="r-primary_btn"
                            type="primary"
                            style={{ flex: 1, marginLeft: 15 }}
                            onClick={() => confirmDel()}
                        >
                            Confirm
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};
export default Brands;
