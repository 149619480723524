import comeBackImg from '@/assets/images/back.png';
import { useNavigate, useLocation } from 'react-router-dom';
import { Space, Button, Avatar, Select, DatePicker, Modal, Form, Input, message, Spin } from 'antd';
import { useState, useEffect } from 'react';
import './user.scss';
import UserStatusModal from '@/components/changeUserStatusModal';
import dayjs from 'dayjs';
import calendarIcon from '@/assets/images/calendar_icon.png';
import closeIcon from '@/assets/images/Icons_Cross.png';
import selectIcon from '@/assets/images/select_icon.png';
import type { RangePickerProps } from 'antd/es/date-picker';
import { useAliveController } from 'react-activation';
import { useSelector } from 'react-redux';
import userApi from '@/api/user';
import utils from '@/utils/utils';
import { useAppDispatch } from '@/store/hooks';
import { setCurrentUserId, setIsGetList } from '@/store/stateSlice';
interface userObjType {
    avatarUrl: string;
    fullName: string;
    userId: string;
    userEmail: string;
    createTime: string;
    follower: string;
    following: string;
    birthday: string;
    blockedByOthers: string;
    blockedUser: string;
    country: string;
    state: string;
    suburbsId: string;
    suburbsName: string;
    disabled: boolean;
    subscriptionPlan: string;
    totalFitLoot: string | number;
    totalGeoPoints: string | number;
    totalNumberOfOffersRedemption: string;
    totalNumberOfRewardsRedemption: string;
    totalNumberOfWorkouts: string;
    userGroups: string;
    userPosts: string;
    workoutSkillType: string;
    thirdPartyType: string;
    favouritesWorkoutList: {
        name: string;
        id: string;
    }[];
    subscriptionExpiryTime: string;
    health?: string;
}
const UserDetail = (props: any) => {
    const dispatch = useAppDispatch();
    const query = new URLSearchParams(useLocation().search);
    const fromPath = query.get('from');
    const { currentUserId } = useSelector((state: any) => state.state);
    useEffect(() => {
        if (currentUserId) {
            getDetail();
        }
    }, []);
    const { drop } = useAliveController();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [PageLoading, setPageLoading] = useState(true);
    const [currentUser, setCurrentUser] = useState<userObjType>({
        avatarUrl: '',
        fullName: '',
        createTime: '',
        userId: '',
        userEmail: '',
        follower: '',
        following: '',
        birthday: '',
        blockedByOthers: '',
        blockedUser: '',
        country: '',
        state: '',
        suburbsId: '',
        suburbsName: '',
        disabled: false,
        thirdPartyType: '',
        subscriptionPlan: '',
        totalFitLoot: 0,
        totalGeoPoints: 0,
        totalNumberOfOffersRedemption: '',
        totalNumberOfRewardsRedemption: '',
        totalNumberOfWorkouts: '',
        userGroups: '',
        userPosts: '',
        subscriptionExpiryTime: '',
        workoutSkillType: '',
        favouritesWorkoutList: [],
    });
    const [isRemove, setRemove] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const userStatusModalParams = {
        isRemove,
        isModalOpen,
        currentUser,
        modalClose: type => {
            setIsModalOpen(false);
            setRemove(false);
            switch (type) {
                case 1:
                    // remove
                    dispatch(setIsGetList(true));
                    if (fromPath) {
                        if (fromPath.split('?')[0] === '/socials/post') {
                            navigate('/socials');
                        } else {
                            navigate(fromPath);
                        }
                    } else {
                        navigate('/user');
                    }
                    break;
                case 2:
                    // changeStatus
                    dispatch(setIsGetList(true));
                    getDetail();
                    break;

                default:
                    break;
            }
        },
    };
    const disabledDate: RangePickerProps['disabledDate'] = current => {
        return current && current > dayjs().endOf('day');
    };
    const getDetail = () => {
        userApi.getUserDetail({ userId: currentUserId }, res => {
            const { code, data } = res;
            if (code === 200) {
                setCurrentUser(data);
            } else {
                message.error(res.message);
            }
            setPageLoading(false);
        });
    };
    const [rankings, setRanking] = useState({
        ranking: 0,
        totalPoints: 0,
    });
    const [filterPoints, setFilterPoints] = useState<{ areaType: number; month: string }>({
        areaType: 0,
        month: dayjs().format('YYYY-MM'),
    });
    useEffect(() => {
        geoPointsRankingByMonth();
    }, [filterPoints]);
    const geoPointsRankingByMonth = async () => {
        const param: any = {};
        if (filterPoints.month) param.yearMonth = filterPoints.month;
        switch (filterPoints.areaType) {
            case 1:
                param.country = currentUser.country;
                break;
            case 2:
                param.state = currentUser.state;
                break;
            case 3:
                param.suburbsId = currentUser.suburbsId;
                break;
            default:
                break;
        }
        await userApi.geoPointsRanking(currentUserId, param, res => {
            const { code, data } = res;
            if (code === 200) {
                if (data) {
                    setRanking(data);
                } else {
                    setRanking({ ranking: 0, totalPoints: 0 });
                }
            } else {
                message.error(res.message);
            }
        });
    };
    //  edit points start
    const [editPointsModalShow, setEditPointsModalShow] = useState(false);
    const [editPoints, setEditPoints] = useState(false);
    const [editPointsForm] = Form.useForm();
    const onFinish = (values: { points: number }) => {
        setLoading(true);
        userApi.editPoints(
            {
                points: editPoints
                    ? values.points - Number(currentUser.totalGeoPoints)
                    : values.points - Number(currentUser.totalFitLoot),
                userId: currentUserId,
                types: editPoints ? 0 : 1,
            },
            res => {
                if (res.code === 200) {
                    if (editPoints) {
                        setCurrentUser(state => ({ ...state, totalGeoPoints: values.points }));
                    } else {
                        setCurrentUser(state => ({ ...state, totalFitLoot: values.points }));
                    }
                    setEditPointsModalShow(false);
                    setLoading(false);
                } else {
                    message.error(res.message);
                    setLoading(false);
                }
            }
        );
    };
    const onFinishFailed = (errorInfo: any) => {
        message.error(errorInfo.errorFields[0].errors[0]);
    };
    const hideModal = () => {
        setEditPointsModalShow(false);
        editPointsForm.resetFields();
    };
    //  edit points end
    return (
        <>
            {PageLoading ? (
                <div className="data_loading flex-center" style={{ height: 500 }}>
                    <Spin />
                </div>
            ) : (
                <div className="page-main user_detail">
                    <div className="flex-between" style={{ margin: '57px 0 40px' }}>
                        <div
                            className="come_back"
                            onClick={() => {
                                // Uninstall this cache component
                                drop('UserDetail');
                                if (fromPath) {
                                    if (sessionStorage.getItem('entrance')) {
                                        dispatch(setCurrentUserId(sessionStorage.getItem('entrance') || ''));
                                        sessionStorage.removeItem('entrance');
                                    }
                                    navigate(fromPath);
                                } else {
                                    navigate('/user');
                                }
                            }}
                        >
                            <img src={comeBackImg} alt="" />
                            Back
                        </div>
                        <Space size={15}>
                            <Button
                                icon={<></>}
                                className={currentUser.disabled ? 'table_button' : 'table_button active_btn'}
                                danger={currentUser.disabled}
                                style={{ width: 146, height: 48 }}
                                onClick={() => {
                                    setRemove(false);
                                    setIsModalOpen(true);
                                }}
                            >
                                {currentUser.disabled ? 'Inactive' : 'Active'}
                            </Button>
                            <Button
                                icon={<></>}
                                className="default_btn"
                                style={{ width: 146 }}
                                onClick={() => {
                                    setRemove(true);
                                    setIsModalOpen(true);
                                }}
                            >
                                Remove
                            </Button>
                        </Space>
                    </div>
                    <div style={{ marginBottom: 20, display: 'flex', justifyContent: 'space-between' }}>
                        <div className="user_info_card">
                            <div className="info_item">
                                <p className="info_label">FULL NAME :</p>
                                <p style={{ display: 'flex', alignItems: 'center' }}>
                                    <Avatar size={78} src={currentUser.avatarUrl} />
                                    <span
                                        className="info_content"
                                        style={{ fontSize: 24, fontWeight: 600, lineHeight: '44px', flex: 1 }}
                                    >
                                        {currentUser.fullName}
                                    </span>
                                </p>
                            </div>
                            <div className="info_item">
                                <span className="info_label">USER ID :</span>
                                <span className="info_content">{currentUser.userId}</span>
                            </div>
                            <div className="info_item">
                                <span className="info_label">CONNECTED WITH :</span>
                                <span className="info_content">{currentUser.thirdPartyType}</span>
                            </div>
                            <div className="info_item">
                                <span className="info_label">EMAIL ADDRESS :</span>
                                <span className="info_content">{currentUser.userEmail || '-'}</span>
                            </div>
                            <div className="info_item">
                                <span className="info_label">COUNTRY :</span>
                                <span className="info_content">{currentUser.country}</span>
                            </div>
                            <div className="info_item">
                                <span className="info_label">LOCATION :</span>
                                <span className="info_content">
                                    {currentUser.state} {currentUser.suburbsName}
                                </span>
                            </div>
                            <div className="info_item">
                                <span className="info_label">DOB :</span>
                                <span className="info_content">{dayjs(currentUser.birthday).format('DD/MM/YYYY')}</span>
                            </div>
                            <div className="info_item">
                                <span className="info_label">REGISTERED DATE :</span>
                                <span className="info_content">
                                    {dayjs(Number(currentUser.createTime)).format('DD/MM/YYYY')}
                                </span>
                            </div>
                            {currentUser.health ? (
                                <div className="info_item">
                                    <span className="info_label">SYNCED SERVICES :</span>
                                    <span className="info_content">{currentUser.health}</span>
                                </div>
                            ) : (
                                ''
                            )}
                            <div className="info_item">
                                <span className="info_label">SUBSCRIPTION :</span>
                                <span className="info_content">{currentUser.subscriptionPlan}</span>
                            </div>
                            {currentUser.subscriptionPlan !== 'Free' && currentUser.subscriptionPlan !== 'Lifetime' ? (
                                <div className="info_item">
                                    <span className="info_label">NEXT BILLING DATE :</span>
                                    <span className="info_content">
                                        {dayjs(+currentUser.subscriptionExpiryTime).format('DD/MM/YYYY')}
                                    </span>
                                </div>
                            ) : (
                                ''
                            )}
                            <div className="info_item">
                                <span className="info_label">SKILL LEVEL :</span>
                                <span className="info_content">{currentUser.workoutSkillType}</span>
                            </div>
                        </div>
                        <div className="user_data">
                            <div className="monthly_leaderboard user_data_card">
                                <div className="user_data_card_title" style={{ margin: '0 30px' }}>
                                    Geonasium monthly leaderboard
                                </div>
                                <div style={{ margin: '9px 30px 55px' }}>
                                    <Space size={15}>
                                        <Select
                                            suffixIcon={<img alt="" src={selectIcon}></img>}
                                            defaultValue={0}
                                            style={{ width: 120 }}
                                            options={[
                                                { label: 'Global', value: 0 },
                                                { label: 'Country', value: 1 },
                                                { label: 'State', value: 2 },
                                                { label: 'Suburb', value: 3 },
                                            ]}
                                            onChange={value => {
                                                setFilterPoints(state => ({ ...state, areaType: value }));
                                            }}
                                        />
                                        <DatePicker
                                            allowClear={false}
                                            style={{ width: 236 }}
                                            placeholder="mm/yyyy"
                                            suffixIcon={<img src={calendarIcon} alt="" />}
                                            defaultValue={dayjs().endOf('day')}
                                            format={'MMM YYYY'}
                                            picker="month"
                                            disabledDate={disabledDate}
                                            onChange={month => {
                                                setFilterPoints(state => ({
                                                    ...state,
                                                    month: month ? dayjs(month).format('YYYY-MM') : '',
                                                }));
                                            }}
                                        />
                                    </Space>
                                </div>
                                <div className="flex-between" style={{ alignItems: 'normal' }}>
                                    <div style={{ width: '45%', paddingLeft: 15 }}>
                                        <div className="user_data_card_quantity">
                                            {Number(rankings.ranking) > 1000
                                                ? `${(Number(rankings.ranking) / 1000).toFixed(1)}k`
                                                : rankings.ranking}
                                        </div>
                                        <div className="user_data_card_tip">RANKING</div>
                                    </div>
                                    <div className="split_line"></div>
                                    <div style={{ width: '45%', paddingRight: 15 }}>
                                        <div className="user_data_card_quantity">
                                            {Number(rankings.totalPoints) > 1000
                                                ? `${(Number(rankings.totalPoints) / 1000).toFixed(1)}k`
                                                : rankings.totalPoints}
                                        </div>
                                        <div className="user_data_card_tip">TOTAL POINTS COLLECTED</div>
                                    </div>
                                </div>
                            </div>
                            <div className="total_points user_data_card flex-between">
                                <div>
                                    <div className="user_data_card_title">Total number of Geonasium points</div>
                                    <Space size={30}>
                                        <span
                                            className="view_more"
                                            onClick={() => {
                                                editPointsForm.setFieldsValue({ points: currentUser.totalGeoPoints });
                                                setEditPointsModalShow(true);
                                                setEditPoints(true);
                                            }}
                                        >
                                            Edit
                                        </span>
                                        <span
                                            className="view_more"
                                            onClick={() => {
                                                navigate('/user/points?types=0');
                                            }}
                                        >
                                            view
                                        </span>
                                    </Space>
                                </div>
                                <div className="user_data_card_quantity">
                                    {currentUser.totalGeoPoints
                                        ? Number(currentUser.totalGeoPoints) > 1000
                                            ? `${(Number(currentUser.totalGeoPoints) / 1000).toFixed(1)}k`
                                            : currentUser.totalGeoPoints
                                        : 0}
                                </div>
                            </div>
                            <div className="total_fitloot user_data_card flex-between">
                                <div>
                                    <div className="user_data_card_title">Total number of FitLoot</div>
                                    <Space size={30}>
                                        <span
                                            className="view_more"
                                            onClick={() => {
                                                editPointsForm.setFieldsValue({ points: currentUser.totalFitLoot });
                                                setEditPointsModalShow(true);
                                                setEditPoints(false);
                                            }}
                                        >
                                            Edit
                                        </span>
                                        <span
                                            className="view_more"
                                            onClick={() => {
                                                navigate('/user/points?types=1');
                                            }}
                                        >
                                            view
                                        </span>
                                    </Space>
                                </div>
                                <div className="user_data_card_quantity">
                                    {currentUser.totalFitLoot
                                        ? Number(currentUser.totalFitLoot) > 1000
                                            ? `${(Number(currentUser.totalFitLoot) / 1000).toFixed(1)}k`
                                            : currentUser.totalFitLoot
                                        : 0}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="total_card_item_title">Followers / Following</div>
                    <div className="total_card_item flex-between">
                        <div>
                            <span className="total_card_item_label">FOLLOWING :</span>
                            <span className="total_card_item_quantity">{currentUser.following}</span>
                        </div>
                        {Number(currentUser.following) ? (
                            <div
                                className="view_more"
                                onClick={() => {
                                    navigate('/user/relation?type=2');
                                }}
                            >
                                view
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                    <div className="total_card_item flex-between">
                        <div>
                            <span className="total_card_item_label">FOLLOWER :</span>
                            <span className="total_card_item_quantity">{currentUser.follower}</span>
                        </div>
                        {Number(currentUser.follower) ? (
                            <div
                                className="view_more"
                                onClick={() => {
                                    navigate('/user/relation?type=1');
                                }}
                            >
                                view
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                    <div className="total_card_item flex-between">
                        <div>
                            <span className="total_card_item_label">BLOCKED USER :</span>
                            <span className="total_card_item_quantity">{currentUser.blockedUser}</span>
                        </div>
                        {Number(currentUser.blockedUser) ? (
                            <div
                                className="view_more"
                                onClick={() => {
                                    navigate('/user/relation?type=3');
                                }}
                            >
                                view
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                    <div className="total_card_item flex-between">
                        <div>
                            <span className="total_card_item_label">BLOCKED BY OTHERS :</span>
                            <span className="total_card_item_quantity">{currentUser.blockedByOthers}</span>
                        </div>
                        {Number(currentUser.blockedByOthers) ? (
                            <div
                                className="view_more"
                                onClick={() => {
                                    navigate('/user/relation?type=4');
                                }}
                            >
                                view
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                    <div className="total_card_item_title">Groups</div>
                    <div className="total_card_item flex-between">
                        <div>
                            <span className="total_card_item_label">USER GROUPS :</span>
                            <span className="total_card_item_quantity">{currentUser.userGroups}</span>
                        </div>
                        {Number(currentUser.userGroups) ? (
                            <div
                                className="view_more"
                                onClick={() => {
                                    navigate('/user/group');
                                }}
                            >
                                view
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                    <div className="total_card_item_title">Posts</div>
                    <div className="total_card_item flex-between">
                        <div>
                            <span className="total_card_item_label">USER POSTS :</span>
                            <span className="total_card_item_quantity">{currentUser.userPosts}</span>
                        </div>
                        {Number(currentUser.userPosts) ? (
                            <div
                                className="view_more"
                                onClick={() => {
                                    navigate('/user/post');
                                }}
                            >
                                view
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                    <div className="total_card_item_title">Favourites</div>
                    <div className="total_card_item">
                        <div>
                            <span className="total_card_item_label">FAVOURITES WORKOUT :</span>
                            <span className="total_card_item_quantity">{currentUser.favouritesWorkoutList.length}</span>
                        </div>
                        <div>
                            <Space size={38} style={{ marginTop: 20 }} wrap={true}>
                                {currentUser.favouritesWorkoutList.map(workout => {
                                    return (
                                        <span
                                            className="view_more"
                                            key={workout.id}
                                            onClick={() => {
                                                sessionStorage.setItem('workoutId', workout.id);
                                                navigate('/workouts/detail?from=/user/detail');
                                            }}
                                        >
                                            {workout.name}
                                        </span>
                                    );
                                })}
                            </Space>
                        </div>
                    </div>
                    <div className="total_card_item_title">Activities</div>
                    <div className="total_card_item flex-between">
                        <div>
                            <span className="total_card_item_label">TOTAL NUMBER OF WORKOUTS :</span>
                            <span className="total_card_item_quantity">{currentUser.totalNumberOfWorkouts}</span>
                        </div>
                        {Number(currentUser.totalNumberOfWorkouts) ? (
                            <div
                                className="view_more"
                                onClick={() => {
                                    navigate('/user/workoutRecord');
                                }}
                            >
                                view
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                    <div className="total_card_item_title">Offers Redemption Record</div>
                    <div className="total_card_item flex-between">
                        <div>
                            <span className="total_card_item_label">TOTAL NUMBER OF OFFERS REDEMPTION :</span>
                            <span className="total_card_item_quantity">
                                {currentUser.totalNumberOfOffersRedemption}
                            </span>
                        </div>
                        {Number(currentUser.totalNumberOfOffersRedemption) ? (
                            <div
                                className="view_more"
                                onClick={() => {
                                    navigate('/user/userRewardsRedemption?type=1');
                                }}
                            >
                                view
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                    <div className="total_card_item_title">Rewards Redemption Record</div>
                    <div className="total_card_item flex-between">
                        <div>
                            <span className="total_card_item_label">TOTAL NUMBER OF REWARDS REDEMPTION :</span>
                            <span className="total_card_item_quantity">
                                {currentUser.totalNumberOfRewardsRedemption}
                            </span>
                        </div>
                        {Number(currentUser.totalNumberOfRewardsRedemption) ? (
                            <div
                                className="view_more"
                                onClick={() => {
                                    navigate('/user/userRewardsRedemption?type=2');
                                }}
                            >
                                view
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                    <UserStatusModal params={userStatusModalParams}></UserStatusModal>
                    <Modal
                        destroyOnClose={true}
                        width={500}
                        className="modal-editPoints modal_no_padding modal_form_w400"
                        maskClosable={false}
                        centered={true}
                        closeIcon={<img src={closeIcon} alt="" className="close-icon" />}
                        open={editPointsModalShow}
                        onCancel={hideModal}
                        footer={[<div className="flex-center"></div>]}
                        title={[
                            <div className="modal-top-title">
                                {editPoints ? 'Edit Geonasium Points' : 'Edit FitLoot'}
                            </div>,
                        ]}
                    >
                        <Form
                            name="editPoints-form"
                            layout="vertical"
                            form={editPointsForm}
                            initialValues={{ remember: true }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            validateTrigger="onBlur"
                            autoComplete="off"
                            preserve={false}
                        >
                            <Form.Item
                                label={editPoints ? 'Geonasium points' : 'FitLoot'}
                                name="points"
                                rules={[
                                    { required: true, message: 'Please type!' },
                                    { pattern: /^[1-9]\d*|0$/, message: 'Please enter a positive integer' },
                                ]}
                            >
                                <Input className="default_input" maxLength={10} />
                            </Form.Item>
                            <Form.Item>
                                <Space size={12} style={{ marginTop: 40 }}>
                                    <Button className="submit-btn" onClick={hideModal}>
                                        Cancel
                                    </Button>
                                    <Button className="submit-btn" type="primary" htmlType="submit">
                                        Save
                                    </Button>
                                </Space>
                            </Form.Item>
                        </Form>
                    </Modal>
                </div>
            )}
        </>
    );
};
export default UserDetail;
