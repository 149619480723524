import { Row, Col, Space, Button, DatePicker, Modal, message, Spin } from 'antd';
import comeBackImg from '@/assets/images/back.png';
import type { ColumnsType } from 'antd/es/table';
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import TableBox from '@/components/tableBox';
import dayjs from 'dayjs';
import type { RangePickerProps } from 'antd/es/date-picker';
import calendarIcon from '@/assets/images/calendar_icon.png';
import userApi from '@/api/user';
import utils from '@/utils/utils';
import { useDispatch } from 'react-redux';
import { setIsGetList } from '@/store/stateSlice';
interface memberListType {
    userId: string;
    fullName: string;
    avatarUrl: string;
    createTime: string;
}
interface DataType {
    groupName: string;
    groupId: string;
    createTime: string;
    memberList: memberListType[];
}
interface groupRanking {
    rangking: string;
    fullName: string;
    avatarUrl: string;
    totalPoints: string;
}
interface getListParams {
    pageNo: number;
    pageSize: number;
    keyWord: string;
}
const GroupDetail = (props: any) => {
    const query = new URLSearchParams(useLocation().search);
    const navigate = useNavigate();
    const [status, setStatus] = useState('showData');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const disabledDate: RangePickerProps['disabledDate'] = current => {
        return current && current > dayjs().month(dayjs().month() - 1);
    };
    const columns: ColumnsType<groupRanking> = [
        {
            title: 'RANKING',
            dataIndex: 'ranking',
            key: 'ranking',
        },
        {
            title: 'USER’S NAME',
            dataIndex: 'fullName',
            key: 'fullName',
            render: (name, record) => (
                <span className="user_info">
                    <img style={{ width: 25, height: 25 }} src={record.avatarUrl} alt="" />
                    {name}
                </span>
            ),
        },
        {
            title: 'TOTAL GEONASIUM POINTS',
            dataIndex: 'totalPoints',
            key: 'totalPoints',
            render: points => `${points} points`,
        },
    ];
    const membersColumns: ColumnsType<memberListType> = [
        {
            title: 'USER’S NAME',
            dataIndex: 'fullName',
            key: 'fullName',
            render: (name, record) => (
                <span className="user_info">
                    <img style={{ width: 25, height: 25 }} src={record.avatarUrl} alt="" />
                    {name}
                </span>
            ),
        },
        {
            title: 'JOIN DATE',
            dataIndex: 'createTime',
            key: 'createTime',
            render: time => dayjs(+time).format('DD/MM/YYYY'),
        },
    ];
    useEffect(() => {
        if (query.get('identity')) {
            getGroupDetail();
        }
    }, []);
    const [groupDetail, setGroupDetail] = useState<DataType>();
    const getGroupDetail = () => {
        userApi.getGroupDetail(query.get('identity') || '', res => {
            if (res.code === 200) {
                setGroupDetail(res.data);
            } else {
                message.error(res.message);
            }
            setPageLoading(false);
        });
    };
    const [yearMonth, setYearMonth] = useState(
        `${utils.getPreMonth().split('/')[1]}-${utils.getPreMonth().split('/')[0]}`
    );
    useEffect(() => {
        getGroupRanking();
    }, [yearMonth]);
    const [groupRankingTable, setGroupRankingTable] = useState<groupRanking[]>([]);
    const getGroupRanking = () => {
        setStatus('loading');
        userApi.getGroupRanking(query.get('identity') || '', { yearMonth }, res => {
            if (res.code === 200) {
                if (res.data.length > 0) {
                    setStatus('showData');
                    setGroupRankingTable(res.data);
                } else {
                    setStatus('empty');
                }
            } else {
                setStatus('empty');
                message.error(res.message);
            }
        });
    };
    const [PageLoading, setPageLoading] = useState(true);
    const dispatch = useDispatch();
    const onDeleteGroup = () => {
        userApi.deleteGroup(query.get('identity') || '', res => {
            if (res.code === 200) {
                setIsModalOpen(false);
                dispatch(setIsGetList(true));
                navigate('/user/group');
            } else {
                message.error(res.message);
            }
        });
    };
    return (
        <>
            {PageLoading ? (
                <div className="data_loading flex-center" style={{ height: 500 }}>
                    <Spin />
                </div>
            ) : (
                <div className="page-main group_detail">
                    <div className="page_head flex-between  t57_b25">
                        <div
                            className="come_back"
                            onClick={() => {
                                navigate('/user/group');
                            }}
                        >
                            <img src={comeBackImg} alt="" />
                            Back
                        </div>
                        <Button
                            style={{ width: 146, height: 48 }}
                            onClick={() => {
                                setIsModalOpen(true);
                            }}
                        >
                            Remove
                        </Button>
                    </div>
                    <div className="group_detail_container">
                        <Row gutter={22}>
                            <Col span={9}>
                                <div className="group_detail_container_left">
                                    <div className="group_detail_introduction">
                                        <div className="group_detail_introduction_item" style={{ display: 'flex' }}>
                                            <span className="group_detail_introduction_title" style={{ width: 156 }}>
                                                GROUP NAME :
                                            </span>
                                            <span className="group_detail_introduction_value">
                                                {groupDetail?.groupName}
                                            </span>
                                        </div>
                                        <div className="group_detail_introduction_item">
                                            <span className="group_detail_introduction_title">GROUP ID :</span>
                                            <span className="group_detail_introduction_value">
                                                {groupDetail?.groupId}
                                            </span>
                                        </div>
                                        <div className="group_detail_introduction_item">
                                            <span className="group_detail_introduction_title">CREATION DATE :</span>
                                            <span className="group_detail_introduction_value">
                                                {dayjs(Number(groupDetail?.createTime)).format('DD/MM/YYYY')}
                                            </span>
                                        </div>
                                        <div className="group_detail_introduction_item">
                                            <span className="group_detail_introduction_title">GROUP MEMBERS :</span>
                                            <span className="group_detail_introduction_value">
                                                {groupDetail?.memberList.length}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="group_detail_members search_condition">
                                        <div className="group_detail_members_title" style={{ marginBottom: 12 }}>
                                            MEMBERS
                                        </div>
                                        <TableBox
                                            status="showData"
                                            columns={membersColumns}
                                            data={groupDetail?.memberList}
                                            height={400}
                                            className="no_border"
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col span={15}>
                                <div className="group_detail_container_right">
                                    <DatePicker
                                        allowClear={false}
                                        style={{ width: 236, marginLeft: 16, marginBottom: 12 }}
                                        placeholder="mm/yyyy"
                                        suffixIcon={<img src={calendarIcon} alt="" />}
                                        defaultValue={dayjs('05/2023', 'MM/YYYY')}
                                        format={'MM/YYYY'}
                                        picker="month"
                                        disabledDate={disabledDate}
                                        onChange={month => {
                                            setYearMonth(dayjs(month).format('YYYY-MM'));
                                        }}
                                    />
                                    <TableBox
                                        status={status}
                                        columns={columns}
                                        data={groupRankingTable}
                                        className="no_border"
                                        rowKey="groupId"
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <Modal
                        className="modal-suspend"
                        closable={false}
                        maskClosable={false}
                        centered={true}
                        open={isModalOpen}
                        onCancel={() => {
                            setIsModalOpen(false);
                        }}
                        footer={[]}
                    >
                        <div className="modal-tip">
                            After deleting this group, all data in this group will be deleted. This operation is
                            irrevocable. Are you sure you want to continue?
                        </div>
                        <Space size={12} style={{ marginTop: 43 }}>
                            <Button
                                onClick={() => {
                                    setIsModalOpen(false);
                                }}
                                style={{ width: 194, height: 54 }}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="primary"
                                htmlType="submit"
                                onClick={onDeleteGroup}
                                style={{ width: 194, height: 54 }}
                            >
                                Confirm
                            </Button>
                        </Space>
                    </Modal>
                </div>
            )}
        </>
    );
};
export default GroupDetail;
