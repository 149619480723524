import axios from '../utils/index';
import { Pat, HttpResponse } from '@/typings/request';
const rewardsApi = {
    getWorkoutRewardList(params: Pat, callback: (T: any) => void) {
        axios
            .get(`/admin/workout-rewards-entity/list`, {
                params,
            })
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    delWorkoutReward(params: Pat, callback: (T: any) => void) {
        axios
            .delete(`/admin/workout-rewards-entity/delete/${params.id}`)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    addWorkoutReward(params: Pat, callback: (T: any) => void) {
        axios
            .post(`/admin/workout-rewards-entity/create/workout-rewards`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    editWorkoutReward(params: Pat, callback: (T: any) => void) {
        axios
            .put(`/admin/workout-rewards-entity/update`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getWorkoutRewardInfo(params: Pat, callback: (T: any) => void) {
        axios
            .get(`/admin/workout-rewards-entity/info/${params.redeemRewardsId}`, { params })
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getRedeemRewardList(params: Pat, callback: (T: any) => void) {
        axios
            .get(`/admin/redeem-rewards-entity/list`, { params })
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    delRedeemReward(params: Pat, callback: (T: any) => void) {
        axios
            .delete(`/admin/redeem-rewards-entity/delete/${params.id}`)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    addRedeemReward(params: Pat, callback: (T: any) => void) {
        axios
            .post(`/admin/redeem-rewards-entity/add`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getShopifyList(params: Pat, callback: (T: any) => void) {
        axios
            .get(`/admin/shopify/products/list?shopifyProductId=${params.shopifyProductId}`)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    editRedeemReward(params: Pat, callback: (T: any) => void) {
        axios
            .put(`/admin/redeem-rewards-entity/update`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getRedeemRewardInfo(params: Pat, callback: (T: any) => void) {
        axios
            .get(`/admin/redeem-rewards-entity/info/${params.redeemRewardsId}`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getRewardPoolList(params: Pat, callback: (T: any) => void) {
        axios
            .get(`/admin/reward-pool/list`, { params })
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    addRewardPool(params: Pat, callback: (T: any) => void) {
        axios
            .post(`/admin/reward-pool/create/pool`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    editRewardPool(params: Pat, callback: (T: any) => void) {
        axios
            .put(`/admin/reward-pool/update/pool`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    delRewardPool(params: Pat, callback: (T: any) => void) {
        axios
            .get(`/admin/reward-pool/delete/${params.rewardPoolId}`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getRewardPoolInfo(params: Pat, callback: (T: any) => void) {
        axios
            .get(`/admin/reward-pool/info/${params.rewardPoolId}`, { params })
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getTreasureList(params: Pat, callback: (T: any) => void) {
        axios
            .get(`/admin/treasure-entity/list`, { params })
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    delTreasure(params: Pat, callback: (T: any) => void) {
        axios
            .delete(`/admin/treasure-entity/delete/${params.treasureId}`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    addTreasure(params: Pat, callback: (T: any) => void) {
        axios
            .post(`/admin/treasure-entity/create/treasure`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getTreasureInfo(params: Pat, callback: (T: any) => void) {
        axios
            .get(`/admin/treasure-entity/detail/${params.treasureId}`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    editTreasure(params: Pat, callback: (T: any) => void) {
        axios
            .put(`/admin/treasure-entity/update`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getAllTreasureLocation(params: Pat, callback: (T: any) => void) {
        axios
            .get(`/admin/treasure-entity/location/list`, { params })
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getWorkoutRewardRoolList(params: Pat, callback: (T: any) => void) {
        axios
            .get(`/admin/reward-pool/list`, { params })
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getWorkoutRewardsEntityList(params: Pat, callback: (T: any) => void) {
        axios
            .get(`/admin/workout-rewards-entity/list`, { params })
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
};
export default rewardsApi;
