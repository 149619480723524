import axios from '../utils/index';
import { Pat, HttpResponse } from '@/typings/request';
const brandApi = {
    getBrandList(params: Pat, callback: (T: any) => void) {
        axios
            .get(`/admin/brand-entity/query/brand/list`, {
                params,
            })
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    deleteBrand(params: Pat, callback: (T: any) => void) {
        axios
            .delete(`/admin/brand-entity/delete/${params.brandId}`)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    addBrand(params: Pat, callback: (T: any) => void) {
        axios
            .post(`/admin/brand-entity/create/brand`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    editBrand(params: Pat, callback: (T: any) => void) {
        axios
            .put(`/admin/brand-entity/update/brand`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getBrandInfo(params: Pat, callback: (T: any) => void) {
        axios
            .get(`/admin/brand-entity/query/brand/info/${params.brandId}`, { params })
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getBrandLocationList(params: Pat, callback: (T: any) => void) {
        axios
            .get(`/admin/brand-entity/list/${params.brandId}`, { params })
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    deleteBrandLocation(params: Pat, callback: (T: any) => void) {
        axios
            .delete(`/admin/brand-entity/delete/brand-location/${params.brandLocationId}`)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    addBrandLocation(params: Pat, callback: (T: any) => void) {
        axios
            .post(`/admin/brand-entity/create/location`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    editBrandLocation(params: Pat, callback: (T: any) => void) {
        axios
            .put(`/admin/brand-entity/update/location`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getBrrandLocationInfo(params: Pat, callback: (T: any) => void) {
        axios
            .get(`/admin/brand-entity/query/location/info/${params.brandLocationId}`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    addBrandOffer(params: Pat, callback: (T: any) => void) {
        axios
            .post(`/admin/brand-offers-entity/create`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    editBrandOffer(params: Pat, callback: (T: any) => void) {
        axios
            .put(`/admin/brand-offers-entity/update`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    delBrandOffer(params: Pat, callback: (T: any) => void) {
        axios
            .delete(`/admin/brand-offers-entity/delete/${params.brandOffersId}`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getBrandOfferEntityInfo(params: Pat, callback: (T: any) => void) {
        axios
            .get(`/admin/brand-offers-entity/info`, { params })
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getBrandOfferEntityExchangeList(params: Pat, callback: (T: any) => void) {
        axios
            .get(`/admin/brand-offers-entity/exchange/list/${params.brandOffersId}`, { params })
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
};
export default brandApi;
