import { createSlice, PayloadAction } from '@reduxjs/toolkit';
interface stateObj {
    countryList: { value: string | number; label: string }[];
    areaData: { country: string; areaList: { value: string; label: string }[] }[];
}
const initialState: stateObj = {
    countryList: [],
    areaData: [],
};

export const stateSlice = createSlice({
    name: 'state',
    initialState,
    reducers: {
        setCountry: (state, action: PayloadAction<stateObj['countryList']>) => {
            state.countryList = action.payload;
        },
        setArea: (state, action: PayloadAction<stateObj['areaData']>) => {
            state.areaData = [...state.areaData, ...action.payload];
        },
    },
});

export const { setCountry, setArea } = stateSlice.actions;

export default stateSlice.reducer;
