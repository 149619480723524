import { Input, Space, DatePicker, message } from 'antd';
import comeBackImg from '@/assets/images/back.png';
import searchIcon from '@/assets/images/search.png';
import calendarIcon from '@/assets/images/calendar_icon.png';
import type { ColumnsType } from 'antd/es/table';
import { useState, useCallback, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Pagination from '@/components/pagination';
import TableBox from '@/components/tableBox';
import dayjs from 'dayjs';
import type { RangePickerProps } from 'antd/es/date-picker';
import debounce from 'lodash.debounce';
import userApi from '@/api/user';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '@/store/hooks';
import { setCurrentUserId } from '@/store/stateSlice';
import { useAliveController } from 'react-activation';
interface DataType {
    fullName: string;
    avatarUrl: string;
    updateTime: string;
    targetId: string;
}
interface getListParams {
    pageNum: number;
    pageSize: number;
    keyword: string;
    startTime: string | number;
    endTime: string | number;
}

const FollowingList = (props: any) => {
    useEffect(() => {
        getList(listParams);
    }, []);
    const dispatch = useAppDispatch();
    const { currentUserId } = useSelector((state: any) => state.state);
    const query = new URLSearchParams(useLocation().search);
    const fromType = query.get('type');
    const { RangePicker } = DatePicker;
    const navigate = useNavigate();
    const [status, setStatus] = useState('showData');
    const [listParams, setListParams] = useState<getListParams>({
        pageNum: 1,
        pageSize: 10,
        keyword: '',
        startTime: '',
        endTime: '',
    });
    const [totalRecord, setTotalRecord] = useState(0);
    const disabledDate: RangePickerProps['disabledDate'] = current => {
        return current && current > dayjs().endOf('day');
    };
    const { drop } = useAliveController();
    const columns: ColumnsType<DataType> = [
        {
            title: 'USER’S NAME',
            dataIndex: 'name',
            key: 'name',
            render: (name, record) => (
                <span
                    className="user_info"
                    style={{ cursor: 'pointer' }}
                    onClick={async () => {
                        // Uninstall this cache component
                        sessionStorage.setItem('entrance', currentUserId);
                        dispatch(setCurrentUserId(record.targetId));
                        await drop('UserDetail');
                        navigate(`/user/detail?from=/user/relation?type=${query.get('type')}`);
                    }}
                >
                    <img style={{ width: 25, height: 25 }} src={record.avatarUrl} alt="" />
                    {record.fullName}
                </span>
            ),
        },
        {
            title: fromType === '3' || fromType === '4' ? 'BLOCKED DATE' : 'CONNECTION DATE',
            dataIndex: 'updateTime',
            key: 'updateTime',
            render: time => dayjs(Number(time)).format('DD/MM/YYYY'),
        },
    ];
    const handlePageChange = prop => {
        setListParams(state => ({ ...state, pageNum: prop }));
        getList({ ...listParams, pageNum: prop });
    };
    const dateOnChange = (dates, dateStrings) => {
        setListParams(state => ({
            ...state,
            startTime: dates ? dayjs(`${dates[0].format('YYYY-MM-DD')} 00:00:00`).valueOf() : '',
            endTime: dates ? dayjs(`${dates[1].format('YYYY-MM-DD')} 23:59:59`).valueOf() : '',
        }));
        getList({
            ...listParams,
            startTime: dates ? dayjs(`${dates[0].format('YYYY-MM-DD')} 00:00:00`).valueOf() : '',
            endTime: dates ? dayjs(`${dates[1].format('YYYY-MM-DD')} 23:59:59`).valueOf() : '',
        });
    };
    const [List, setList] = useState<DataType[]>([]);
    const debouncedSave = useCallback(
        debounce(nextValue => getList(nextValue), 1000),
        []
    );
    const inputChange = e => {
        setListParams(state => ({ ...state, keyword: e.target.value, pageNum: 1 }));
        debouncedSave({ ...listParams, keyword: e.target.value, pageNum: 1 });
    };
    const getList = params => {
        setStatus('loading');
        userApi.getUserRelationList({ ...params, userId: currentUserId, type: Number(fromType) }, res => {
            const { code, data } = res;
            if (code === 200) {
                if (data.records.length > 0) {
                    setList(data.records);
                    setStatus('showData');
                } else {
                    setStatus('empty');
                }
                setTotalRecord(data.total);
            } else {
                setStatus('empty');
                message.error(res.message);
            }
        });
    };
    return (
        <div className="page-main points table_page">
            <div className="flex-between page_head t72_b50">
                <div
                    className="come_back"
                    onClick={() => {
                        navigate('/user/detail');
                        drop('RelationList');
                    }}
                >
                    <img src={comeBackImg} alt="" />
                    Back
                </div>
            </div>
            <div className="search_box">
                <Space wrap>
                    <Input
                        allowClear
                        value={listParams.keyword}
                        onChange={inputChange}
                        className="search_input"
                        size="large"
                        placeholder="Search"
                        style={{ width: 210 }}
                        maxLength={50}
                        prefix={<img src={searchIcon} alt="" />}
                    />
                    <RangePicker
                        placeholder={['dd/mm/yyyy', 'dd/mm/yyyy']}
                        style={{ width: 258 }}
                        suffixIcon={<img src={calendarIcon} alt="" />}
                        format={'DD/MM/YYYY'}
                        disabledDate={disabledDate}
                        onChange={dateOnChange}
                    />
                </Space>
            </div>
            <TableBox status={status} columns={columns} data={List} rowKey="targetId" />
            {status === 'showData' ? (
                <Pagination total={totalRecord} pageChange={handlePageChange} pageNo={listParams.pageNum} />
            ) : (
                <></>
            )}
        </div>
    );
};
export default FollowingList;
