import { useState, useEffect } from 'react';
import { Select, Button } from 'antd';
import type { SelectProps } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import './index.scss';

function Pagination(props) {
    const options: SelectProps['options'] = [];
    const pageTotal = Number(props.total) < 1 ? 1 : Math.ceil(Number(props.total) / 10);
    if (pageTotal > 0) {
        for (let i = 0; i < pageTotal; i++) {
            options.push({
                value: (i + 1).toString(),
                label: i + 1,
            });
        }
    } else {
        options.push({
            value: '1',
            label: 1,
        });
    }
    const handleChange = (value: string) => {
        props.pageChange(Number(value));
    };
    return (
        <div className="pagination flex-between">
            <div className="total">
                {props.pageNo === 1
                    ? `1 - ${pageTotal === 1 ? props.total : '10'} `
                    : props.pageNo !== pageTotal
                    ? `${(props.pageNo - 1) * 10 + 1} - ${props.pageNo * 10} `
                    : `${(props.pageNo - 1) * 10 + 1} - ${props.total} `}
                of {props.total}
            </div>
            <div className="flex-center">
                <div>The page you are on</div>
                <div className="pageNum-box flex-center">
                    <Select
                        value={props.pageNo.toString()}
                        suffixIcon={<i className="sel_arrow_icon"></i>}
                        style={{ width: 67 }}
                        onChange={handleChange}
                        options={options}
                    ></Select>
                </div>
                <div className="left-icon flex-center">
                    <Button
                        className="r-center"
                        disabled={props.pageNo === 1}
                        icon={<span className="pageBtnIcon"></span>}
                        onClick={() => {
                            props.pageChange(props.pageNo - 1);
                        }}
                    />
                </div>
                <div className="right-icon flex-center">
                    <Button
                        className="r-center"
                        disabled={props.pageNo === pageTotal}
                        icon={<span className="pageBtnIcon next_icon"></span>}
                        onClick={() => {
                            props.pageChange(props.pageNo + 1);
                        }}
                    />
                </div>
            </div>
        </div>
    );
}
export default Pagination;
