import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input, Select, Button, Table, DatePicker, Modal } from 'antd';
import './style/index.scss';
import TableBox from '@/components/tableBox';
import Pagination from '@/components/pagination';
import { debounce } from 'lodash';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import rewardsApi from '@/api/rewards';
import { defaultInfo } from '@/typings/allType';
const disabledDate: RangePickerProps['disabledDate'] = current => {
    return current && current > dayjs().endOf('day');
};
interface listParams {
    pageNo: string | number;
    search: string;
    startTime: string | number;
    endTime: string | number;
    type: number;
}
const Rewards = (props: any) => {
    const navigate = useNavigate();
    const { RangePicker } = DatePicker;
    useEffect(() => {
        getList({ ...state }, pageType);
    }, []);
    useEffect(() => {
        const path = props.prevLocation?.pathname;
        if (
            path === '/rewards/workout-reward' ||
            path === '/rewards/fitloot-reward' ||
            path === '/rewards/fitloot-detail' ||
            path === '/rewards/detail'
        ) {
            getList({ ...state }, pageType);
        }
    }, [props.prevLocation]);
    const [pageType, setPageType] = useState(1);
    const handchangeType = (value: number) => {
        if (status === 'loading') return;
        setPageType(value);
        setState({
            pageNo: 1,
            search: '',
            startTime: '',
            endTime: '',
            type: pageType,
        });
        setTableData([]);
        getList({ pageNo: 1, search: '', startTime: '', endTime: '', type: value }, value);
    };
    const [status, setStatus] = useState('empty');
    const [totalRecord, setTotalRecord] = useState(0);
    const [state, setState] = useState({
        pageNo: 1,
        search: '',
        startTime: '' as any,
        endTime: '' as any,
        type: 1,
    });
    const searchList: any = useCallback(
        debounce(params => {
            getList({ ...params }, params.type === 1 ? 2 : 1);
        }, 1000),
        []
    );
    const changeTime = val => {
        if (val && val !== null) {
            const startTime = dayjs(`${dayjs(val[0]).format('YYYY-MM-DD')} 00:00:00`).valueOf();
            const endTime = dayjs(`${dayjs(val[1]).format('YYYY-MM-DD')} 23:59:59`).valueOf();
            setState({ ...state, startTime, endTime, pageNo: 1 });
            getList({ ...state, startTime, endTime, pageNo: 1 }, pageType);
            return;
        }
        setState({ ...state, startTime: '', endTime: '', pageNo: 1 });
        getList({ ...state, startTime: '', endTime: '', pageNo: 1 }, pageType);
    };
    const handlePageChange = prop => {
        setState({ ...state, pageNo: prop });
        getList({ ...state, pageNo: prop }, pageType);
    };
    const getList = (val: listParams, type: number = state.type) => {
        if (status === 'loading') return;
        const params = {
            pageNum: val.pageNo,
            pageSize: 10,
            startTime: val.startTime,
            endTime: val.endTime,
            keyword: val.search,
        };
        setStatus('loading');
        if (type === 2) {
            rewardsApi.getRedeemRewardList(params, res => callback(res, 2));
            return;
        }
        rewardsApi.getWorkoutRewardList(params, res => callback(res, 1));
    };
    const callback = (res: defaultInfo, type: number) => {
        const { data, code, message } = res;
        if (code === 200) {
            if (data.records.length > 0) {
                const list = data.records.map(item => {
                    if (type === 1) {
                        return Object.assign(item, {
                            redeemDate:
                                item.redeemDate && item.redeemDate !== null && item.redeemDate !== 'Forever'
                                    ? window.format.foramtTimeDMYHM(+item.redeemDate)
                                    : 'Forever',
                        });
                    } else {
                        return Object.assign(item, {
                            workoutRewardsId: item.redeemRewardsId,
                            fitLootAmountShow: `${item.fitLootAmount} FitLoot`,
                            redeemDate:
                                item.expiryDate && item.expiryDate !== null && item.expiryDate !== 'Forever'
                                    ? window.format.foramtTimeDMYHM(+item.expiryDate)
                                    : 'Forever',
                        });
                    }
                });
                setTableData(list);
                setStatus('showData');
                setTotalRecord(data.total);
            } else {
                setStatus('empty');
                setTotalRecord(data.total);
            }
        } else {
            setStatus('empty');
            window.format.alert('error', message);
        }
    };
    const [tableData, setTableData] = useState([]);
    const columns = [
        {
            title: 'REWARDS NAME',
            dataIndex: '',
            key: 'rewardsName',
            width: '35%',
            render: val => (
                <div className="user_info r-left">
                    {val.rewardsImage && val.rewardsImage !== null ? (
                        <img src={val.rewardsImage} className="user_img" alt="" />
                    ) : (
                        <span className="user_img_text">{window.format.getFirstLetter(val.rewardsName)}</span>
                    )}
                    {val.rewardsName}
                </div>
            ),
        },
        {
            title: 'EXPIRY DATE',
            dataIndex: 'redeemDate',
            key: 'redeemDate',
            width: 200,
        },
        {
            title: pageType === 2 ? 'REDEMPTION AMOUNT' : '',
            dataIndex: pageType === 2 ? 'fitLootAmountShow' : '',
            key: pageType === 2 ? 'fitLootAmountShow' : '',
            width: pageType === 2 ? 200 : 0,
        },
        {
            title: 'NUMBER OF REWARDS TO UNLOCK',
            dataIndex: 'number',
            key: 'number',
            width: 300,
        },
        {
            title: '',
            dataIndex: '',
            key: 'redeemRewardsId',
            width: 180,
            render: val => (
                <div
                    className="remove_btn"
                    onClick={e => {
                        opeation(e, val);
                    }}
                >
                    Remove
                </div>
            ),
        },
    ];
    const handClickRow = row => {
        window.sessionStorage.setItem('workoutRewardsId', row.workoutRewardsId);
        if (pageType === 1) {
            navigate('/rewards/detail');
            return;
        }
        navigate('/rewards/fitloot-detail');
    };
    const linkToPage = (key: number) => {
        window.sessionStorage.removeItem('fitlootRewardInfo');
        window.sessionStorage.removeItem('workoutRewardInfo');
        window.sessionStorage.removeItem('workoutRewardsId');
        navigate(
            key === 1
                ? '/rewards/pool'
                : key === 2
                ? '/rewards/fitloot-reward'
                : key === 3
                ? '/rewards/workout-reward'
                : '/rewards/treasure'
        );
    };
    const [modalVisibile, setModalVisibile] = useState(false);
    const [opeationInfo, setOpeationInfo] = useState({
        loading: false,
        id: '',
    });
    const opeation = (e, val) => {
        e.stopPropagation();
        setOpeationInfo({ ...opeationInfo, id: val.workoutRewardsId });
        setModalVisibile(true);
    };
    const confirmDel = () => {
        const params = {
            id: opeationInfo.id,
        };
        setOpeationInfo({ ...opeationInfo, loading: true });
        if (pageType === 1) {
            rewardsApi.delWorkoutReward(params, res => delCallback(res, 1));
            return;
        }
        rewardsApi.delRedeemReward(params, res => delCallback(res, 2));
    };
    const delCallback = (res: defaultInfo, type: number) => {
        const { data, code, message } = res;
        setOpeationInfo({ ...opeationInfo, loading: false });
        if (code === 200) {
            setModalVisibile(false);
            setOpeationInfo({ id: '', loading: false });
            getList({ ...state }, type);
        } else if (code === 1000) {
            setModalVisibile(false);
            setAlertModalVisibile(true);
        } else {
            window.format.alert('error', message);
        }
    };
    const [alertModalVisibile, setAlertModalVisibile] = useState(false);
    return (
        <div className="rewards_main table_page">
            <div className="workout_top r-left">
                <Input
                    placeholder="Search"
                    style={{ width: 210 }}
                    value={state.search}
                    allowClear
                    onChange={e => {
                        setState({ ...state, search: e.target.value, pageNo: 1 });
                        searchList({ ...state, search: e.target.value, pageNo: 1 });
                    }}
                    className="r-input r-input_serach"
                    prefix={<span className="search_icon"></span>}
                />
                <div className="space15"></div>
                <RangePicker
                    className="r-rangePicker"
                    style={{ width: 258 }}
                    format="DD/MM/YYYY"
                    onChange={changeTime}
                    suffixIcon={<i className="sel_time_icon"></i>}
                    placeholder={['dd/mm/yyyy', 'dd/mm/yyyy']}
                />
                <div className="space15"></div>
                <Button
                    className="r-default_btn marLeft"
                    icon={<></>}
                    style={{ width: 146 }}
                    onClick={() => linkToPage(1)}
                >
                    Reward Pool
                </Button>
                <div className="space15"></div>
                <Button className="r-default_btn" icon={<></>} style={{ width: 155 }} onClick={() => linkToPage(4)}>
                    Treasure Location
                </Button>
                <div className="space15"></div>
                <Button className="r-default_btn" icon={<></>} style={{ width: 220 }} onClick={() => linkToPage(2)}>
                    Add New FitLoot Rewards
                </Button>
                <div className="space15"></div>
                <Button className="r-default_btn" icon={<></>} style={{ width: 230 }} onClick={() => linkToPage(3)}>
                    Add New Workout Rewards
                </Button>
            </div>
            <div className="r-left action_tab">
                <span
                    style={{ cursor: status === 'loading' ? 'no-drop' : 'pointer' }}
                    className={`action_tab_item ${pageType === 1 ? 'active' : ''}`}
                    onClick={() => handchangeType(1)}
                >
                    Workout Rewards
                </span>
                <span
                    style={{ cursor: status === 'loading' ? 'no-drop' : 'pointer' }}
                    className={`action_tab_item ${pageType === 2 ? 'active' : ''}`}
                    onClick={() => handchangeType(2)}
                >
                    FitLoot rewards
                </span>
            </div>
            <TableBox
                status={status}
                columns={columns}
                data={tableData}
                rowKey={val => val.workoutRewardsId}
                height={false}
                className="is_clickable r-table table_main"
                rowClassName="r-table_row"
                onRow={record => {
                    handClickRow(record);
                }}
            />
            {status === 'showData' ? (
                <Pagination total={totalRecord} pageChange={handlePageChange} pageNo={state.pageNo} />
            ) : (
                <></>
            )}

            <Modal
                className="r-modal modal_no_padding"
                maskClosable={false}
                centered={true}
                open={modalVisibile}
                onCancel={() => setModalVisibile(false)}
                footer={''}
                title={''}
                key="rewardModal"
                closable={false}
            >
                <div className="modal_form">
                    <p className="del_tip">Are you sure to delete this reward?</p>
                    <div className="r-center" style={{ marginTop: 50 }}>
                        <Button
                            className="r-defaultBlick_btn"
                            style={{ flex: 1 }}
                            onClick={() => setModalVisibile(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="r-primary_btn"
                            type="primary"
                            style={{ flex: 1, marginLeft: 15 }}
                            loading={opeationInfo.loading}
                            onClick={() => confirmDel()}
                        >
                            Confirm
                        </Button>
                    </div>
                </div>
            </Modal>

            <Modal
                className="r-modal modal_no_padding"
                maskClosable={false}
                centered={true}
                open={alertModalVisibile}
                onCancel={() => setAlertModalVisibile(false)}
                key="rewardModalCon"
                footer={''}
                title={''}
                closable={false}
            >
                <div className="modal_form">
                    <p className="del_tip">The reward is being used in the reward pool, please remove it first.</p>
                    <div className="r-center" style={{ marginTop: 50 }}>
                        <Button
                            className="r-primary_btn"
                            type="primary"
                            style={{ width: 194 }}
                            onClick={() => {
                                setAlertModalVisibile(false);
                                setModalVisibile(false);
                            }}
                        >
                            Okay
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};
export default Rewards;
