import { Row, Col, Space, Select, DatePicker, message } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import type { ColumnsType } from 'antd/es/table';
import TableBox from '@/components/tableBox';
import dayjs from 'dayjs';
import type { RangePickerProps } from 'antd/es/date-picker';
import calendarIcon from '@/assets/images/calendar_icon.png';
import selectIcon from '@/assets/images/select_icon.png';
import homeApi from '@/api/dashboard';
import { useDispatch } from 'react-redux';
import { setArea, setCountry } from '@/store/countrySlice';
import { useSelector } from 'react-redux';
interface DataType {
    ranking: string;
    fullName: string;
    avatarUrl: string;
    totalPoints: string;
    workoutName: string;
    completedTimes: string;
}
const pointsColumns: ColumnsType<DataType> = [
    {
        title: 'RANKING',
        dataIndex: 'ranking',
        key: 'ranking',
    },
    {
        title: 'USER’S NAME',
        dataIndex: 'fullName',
        key: 'fullName',
        render: (name, record) => (
            <span className="user_info">
                <img style={{ width: 25, height: 25 }} src={record.avatarUrl} alt="" />
                {name}
            </span>
        ),
    },
    {
        title: 'POINTS ACCRUED',
        dataIndex: 'totalPoints',
        key: 'totalPoints',
        render: points => `${points} points`,
    },
];
const fitlootColumns: ColumnsType<DataType> = [
    {
        title: 'RANKING',
        dataIndex: 'ranking',
        key: 'ranking',
    },
    {
        title: 'USER’S NAME',
        dataIndex: 'fullName',
        key: 'fullName',
        render: (name, record) => (
            <span className="user_info">
                <img style={{ width: 25, height: 25 }} src={record.avatarUrl} alt="" />
                {name}
            </span>
        ),
    },
    {
        title: 'FITLOOT',
        dataIndex: 'totalPoints',
        key: 'totalPoints',
    },
];
const workoutColumns: ColumnsType<DataType> = [
    {
        title: 'RANKING',
        dataIndex: 'ranking',
        key: 'ranking',
    },
    {
        title: 'WORKOUT LOCATIONS NAME',
        dataIndex: 'workoutName',
        key: 'workoutName',
    },
    {
        title: 'NUMBER OF COMPLETION',
        dataIndex: 'completedTimes',
        key: 'completedTimes',
    },
];
const Dashboard = (props: any) => {
    useEffect(() => {
        getCountry();
    }, []);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { countryList, areaData } = useSelector((state: any) => state.country);
    const getCountry = () => {
        const countryList: { value: string; label: string }[] = [];
        homeApi.getCountry(res => {
            if (res.code === 200) {
                res.data.forEach((i, index) => {
                    countryList.push({ value: i, label: i });
                    getState(i);
                });
                dispatch(setCountry(countryList));
            } else {
                message.error(res.message);
            }
        });
    };
    const getState = country => {
        if (!country) return;
        const stateList: { value: string; label: string }[] = [];
        homeApi.getState({ country }, res => {
            if (res.code === 200) {
                res.data.forEach((i, index) => {
                    stateList.push({ value: i, label: i });
                });
                dispatch(setArea([{ country, areaList: stateList }]));
            } else {
                message.error(res.message);
            }
        });
    };
    const selectArea = (country, type) => {
        if (!country) return;
        const area = areaData.filter(i => {
            return i.country === country;
        });
        switch (type) {
            case 1:
                setPointsStateOptions(area[0].areaList);
                break;
            case 2:
                setFitlootStateOptions(area[0].areaList);
                break;
            case 3:
                setWorkoutStateOptions(area[0].areaList);
                break;
            default:
                break;
        }
    };
    // ------------------points start--------------------------
    const [pointsData, setPointsData] = useState<{
        country: number | string;
        state: string | null;
        yearMonth: string;
    }>({
        country: 0,
        state: null,
        yearMonth: dayjs().format('YYYY-MM'),
    });
    useEffect(() => {
        getRanking();
    }, [pointsData]);
    const [pointsStatus, setPointsStatus] = useState('loading');
    const [pointsStateOptions, setPointsStateOptions] = useState<{ value: string; label: string }[]>([]);
    const [pointsRangkingTable, setPointsRangkingTable] = useState<DataType[]>([]);
    const getRanking = () => {
        setPointsStatus('loading');
        const params = {
            pageNum: 1,
            pageSize: 10,
            type: 0,
            country: pointsData.country ? pointsData.country : null,
            state: pointsData.country ? pointsData.state : null,
            yearMonth: pointsData.yearMonth,
        };
        homeApi.getPointsRangking(params, res => {
            if (res.code === 200) {
                if (res.data.records.length > 0) {
                    setPointsRangkingTable(res.data.records);
                    setPointsStatus('showData');
                } else {
                    setPointsStatus('empty');
                }
            } else {
                message.error(res.message);
                setPointsStatus('empty');
            }
        });
    };
    // ------------------fitloot start--------------------------
    const [fitlootStatus, setFitlootStatus] = useState('showData');
    const [fitlootStateOptions, setFitlootStateOptions] = useState<{ value: string; label: string }[]>([]);

    const [fitlootData, setFitlootData] = useState<{
        country: number | string;
        state: string | null;
    }>({
        country: 0,
        state: null,
    });
    useEffect(() => {
        getFitlootRanking();
    }, [fitlootData]);
    const [fitlootRangkingTable, setFitllotRangkingTable] = useState<DataType[]>([]);
    const getFitlootRanking = () => {
        setFitlootStatus('loading');
        const params = {
            pageNum: 1,
            pageSize: 10,
            type: 1,
            country: fitlootData.country ? fitlootData.country : null,
            state: fitlootData.country ? fitlootData.state : null,
        };
        homeApi.getPointsRangking(params, res => {
            if (res.code === 200) {
                if (res.data.records.length > 0) {
                    setFitllotRangkingTable(res.data.records);
                    setFitlootStatus('showData');
                } else {
                    setFitlootStatus('empty');
                }
            } else {
                message.error(res.message);
                setFitlootStatus('empty');
            }
        });
    };
    // ------------------workout start--------------------------
    const [workoutStatus, setWorkoutStatus] = useState('showData');
    const [workoutStateOptions, setWorkoutStateOptions] = useState<{ value: string; label: string }[]>([]);

    const [workoutData, setworkouttData] = useState<{
        country: number | string;
        state: string | null;
    }>({
        country: 0,
        state: null,
    });
    const [workoutRangkingTable, setWorkoutRangkingTable] = useState<
        { workoutName: string; ranking: string; completedTimes: string }[]
    >([]);
    useEffect(() => {
        getWorkoutRanking();
    }, [workoutData]);
    const getWorkoutRanking = () => {
        setWorkoutStatus('loading');
        const params = {
            pageNum: 1,
            pageSize: 10,
            country: workoutData.country ? workoutData.country : null,
            state: workoutData.country ? workoutData.state : null,
        };
        homeApi.getWorkoutRangking(params, res => {
            if (res.code === 200) {
                if (res.data.records.length > 0) {
                    setWorkoutRangkingTable(res.data.records);
                    setWorkoutStatus('showData');
                } else {
                    setWorkoutStatus('empty');
                }
            } else {
                message.error(res.message);
                setWorkoutStatus('empty');
            }
        });
    };
    const disabledDate: RangePickerProps['disabledDate'] = current => {
        return current && current > dayjs().endOf('day');
    };
    return (
        <div className="page-main dashboard">
            <h1 className="top-title">Leaderboard</h1>
            <Row gutter={22}>
                <Col span={12}>
                    <div className="geonasium-points main_round_box">
                        <div className="content_box_item_header flex-between">
                            <span className="title">Geonasium Points</span>
                            {pointsRangkingTable.length > 0 ? (
                                <span
                                    className="view_more"
                                    onClick={() => {
                                        navigate('/dashboard/points?type=0');
                                    }}
                                >
                                    view
                                </span>
                            ) : (
                                ''
                            )}
                        </div>
                        <div className="search_condition" style={{ marginBottom: 12 }}>
                            <Space wrap size={15}>
                                <Select
                                    suffixIcon={<img alt="" src={selectIcon}></img>}
                                    defaultValue={0}
                                    style={{ width: 120 }}
                                    onChange={(value: string | number) => {
                                        setPointsData(state => ({
                                            ...state,
                                            country: value,
                                            state: null,
                                        }));
                                        selectArea(value, 1);
                                    }}
                                    options={[{ value: 0, label: 'Global' }, ...countryList]}
                                />
                                <Select
                                    suffixIcon={<img alt="" src={selectIcon}></img>}
                                    value={pointsData.state}
                                    placeholder="State"
                                    disabled={!pointsData.country}
                                    style={{ width: 120 }}
                                    onChange={(value: string) => {
                                        setPointsData(state => ({
                                            ...state,
                                            state: value,
                                        }));
                                    }}
                                    options={pointsData.country ? pointsStateOptions : []}
                                />
                                <DatePicker
                                    allowClear={false}
                                    style={{ width: 236 }}
                                    placeholder="mm/yyyy"
                                    suffixIcon={<img src={calendarIcon} alt="" />}
                                    defaultValue={dayjs().endOf('day')}
                                    format={'MMM YYYY'}
                                    picker="month"
                                    disabledDate={disabledDate}
                                    onChange={month => {
                                        setPointsData(state => ({
                                            ...state,
                                            yearMonth: month ? dayjs(month).format('YYYY-MM') : '',
                                        }));
                                    }}
                                />
                            </Space>
                        </div>
                        <TableBox
                            status={pointsStatus}
                            columns={pointsColumns}
                            data={pointsRangkingTable}
                            rowKey="userId"
                            height={486}
                            className="no_border"
                        />
                    </div>
                </Col>
                <Col span={12}>
                    <div className="fitLoot main_round_box">
                        <div className="content_box_item_header flex-between">
                            <span className="title">FitLoot</span>
                            {fitlootRangkingTable.length > 0 ? (
                                <span
                                    className="view_more"
                                    onClick={() => {
                                        navigate('/dashboard/points?type=1');
                                    }}
                                >
                                    view
                                </span>
                            ) : (
                                ''
                            )}
                        </div>
                        <div className="search_condition" style={{ marginBottom: 12 }}>
                            <Space wrap size={15}>
                                <Select
                                    defaultValue={0}
                                    suffixIcon={<img alt="" src={selectIcon}></img>}
                                    style={{ width: 120 }}
                                    onChange={(value: number) => {
                                        setFitlootData(state => ({
                                            country: value,
                                            state: null,
                                        }));
                                        selectArea(value, 2);
                                    }}
                                    options={[{ value: 0, label: 'Global' }, ...countryList]}
                                />
                                <Select
                                    suffixIcon={<img alt="" src={selectIcon}></img>}
                                    value={fitlootData.state}
                                    placeholder="State"
                                    disabled={!fitlootData.country}
                                    style={{ width: 120 }}
                                    onChange={(value: string) => {
                                        setFitlootData(state => ({
                                            ...state,
                                            state: value,
                                        }));
                                    }}
                                    options={fitlootData.country ? fitlootStateOptions : []}
                                />
                            </Space>
                        </div>
                        <TableBox
                            status={fitlootStatus}
                            columns={fitlootColumns}
                            rowKey="userId"
                            data={fitlootRangkingTable}
                            height={486}
                            className="no_border"
                        />
                    </div>
                </Col>
            </Row>
            <div className="workout_locations main_round_box" style={{ marginTop: '20px' }}>
                <div className="content_box_item_header flex-between">
                    <span className="title">Workout Locations</span>
                    {workoutRangkingTable.length > 0 ? (
                        <span
                            className="view_more"
                            onClick={() => {
                                navigate('/dashboard/workoutLocations');
                            }}
                        >
                            view
                        </span>
                    ) : (
                        ''
                    )}
                </div>
                <div className="search_condition" style={{ marginBottom: 12 }}>
                    <Space wrap size={15}>
                        <Select
                            defaultValue={0}
                            suffixIcon={<img alt="" src={selectIcon}></img>}
                            style={{ width: 120 }}
                            onChange={(value: number) => {
                                setworkouttData(state => ({
                                    country: value,
                                    state: null,
                                }));
                                selectArea(value, 3);
                            }}
                            options={[{ value: 0, label: 'Global' }, ...countryList]}
                        />
                        <Select
                            suffixIcon={<img alt="" src={selectIcon}></img>}
                            value={workoutData.state}
                            placeholder="State"
                            disabled={!workoutData.country}
                            style={{ width: 120 }}
                            onChange={(value: string) => {
                                setworkouttData(state => ({
                                    ...state,
                                    state: value,
                                }));
                            }}
                            options={workoutData.country ? workoutStateOptions : []}
                        />
                    </Space>
                </div>
                <TableBox
                    status={workoutStatus}
                    columns={workoutColumns}
                    data={workoutRangkingTable}
                    height={486}
                    rowKey="workoutId"
                    className="no_border"
                />
            </div>
        </div>
    );
};
export default Dashboard;
