import { useEffect, useRef, useState } from 'react';
// import useGoogleMap from '@/hook/useGoogleMap';
import './index.scss';
const SlideBar = props => {
    const [position, setPosition] = useState(0);
    const sliderRef = useRef(null);
    const knobRef = useRef(null);
    useEffect(() => {
        const val = Number(+(100 + position / 3.72).toFixed(0)) > 150 ? 150 : +(100 + position / 3.72).toFixed(0);
        props.changeRoundMap(val);
    }, [position]);
    useEffect(() => {
        const radius = Number(props.state.radiusMeters ? props.state.radiusMeters : 100);
        setPosition(radius === 100 ? 0 : +((radius - 100) * 3.75).toFixed(0));
    }, [props.state]);
    useEffect(() => {
        const slider = sliderRef.current as any;
        const knob = knobRef.current as any;

        const handleMouseDown = event => {
            event.preventDefault();

            document.addEventListener('mousemove', handleMouseMove);
            document.addEventListener('mouseup', handleMouseUp);
        };
        const handleMouseMove = event => {
            const sliderWidth = slider.offsetWidth;
            const knobWidth = knob.offsetWidth;
            const sliderLeft = slider.getBoundingClientRect().left;
            const x = event.pageX - sliderLeft - knobWidth / 2;

            let newNum = 0;
            if (x < 0) {
                setPosition(0);
            } else if (x > sliderWidth - knobWidth) {
                setPosition(sliderWidth - knobWidth);
                newNum = sliderWidth - knobWidth;
            } else {
                setPosition(+x > 184 ? 184 : x);
                newNum = +x > 184 ? 184 : x;
            }
            // const val = Number(+(100 + newNum / 3.72).toFixed(0)) > 150 ? 150 : +(100 + newNum / 3.72).toFixed(0);
            // props.changeRoundMap(val);
        };

        const handleMouseUp = () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };

        knob.addEventListener('mousedown', handleMouseDown);

        return () => {
            knob.removeEventListener('mousedown', handleMouseDown);
        };
    }, []);

    return (
        <div ref={sliderRef} className="r-slider">
            <div
                style={{
                    height: 6,
                    background: '#0085FF',
                    borderRadius: 20,
                    width: position,
                }}
            ></div>
            <div
                ref={knobRef}
                className="r-knob"
                style={{
                    left: `${position}px`,
                }}
            >
                {Number((100 + position / 3.72).toFixed(0)) > 150 ? 150 : (100 + position / 3.72).toFixed(0)} metres
            </div>
        </div>
    );
};

export default SlideBar;
