import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input, DatePicker, Table } from 'antd';
import './style/index.scss';
import dayjs from 'dayjs';
import { RangePickerProps } from 'antd/es/date-picker';
import TableBox from '@/components/tableBox';
import Pagination from '@/components/pagination';
import { debounce } from 'lodash';
import workoutApi from '@/api/workout';
import { defaultInfo } from '@/typings/allType';
import { useAppDispatch } from '@/store/hooks';
import { setCurrentUserId } from '@/store/stateSlice';
import { useAliveController } from 'react-activation';
interface statisticsInfo {
    pageNo: number;
    search: string;
    workoutId: string;
    startTime: any;
    endTime: any;
}
const disabledDate: RangePickerProps['disabledDate'] = current => {
    return current && current > dayjs().endOf('day');
};
const StatisticsList = (props: any) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { RangePicker } = DatePicker;
    useEffect(() => {
        const workoutId = window.sessionStorage.getItem('workoutId') ?? '';
        if (workoutId) {
            getList({ ...state, workoutId });
            setState({ ...state, workoutId });
        }
    }, []);
    const [status, setStatus] = useState('showData');
    const [state, setState] = useState({
        workoutId: '',
        pageNo: 1,
        search: '',
        startTime: '' as any,
        endTime: '' as any,
    });
    const changeTime = val => {
        // console.log(val);
        if (val && val !== null) {
            const startTime = dayjs(`${dayjs(val[0]).format('YYYY-MM-DD')} 00:00:00`).valueOf();
            const endTime = dayjs(`${dayjs(val[0]).format('YYYY-MM-DD')} 23:59:59`).valueOf();
            setState({ ...state, startTime, endTime, pageNo: 1 });
            getList({ ...state, startTime, endTime, pageNo: 1 });
            return;
        }
        setState({ ...state, startTime: '', endTime: '', pageNo: 1 });
        getList({ ...state, startTime: '', endTime: '', pageNo: 1 });
    };
    const [totalRecord, setTotalRecord] = useState(0);
    const searchList = useCallback(
        debounce(params => getList(params), 1000),
        []
    );
    const handlePageChange = prop => {
        setState({ ...state, pageNo: prop });
        getList({ ...state, pageNo: prop });
    };
    const getList = (val: statisticsInfo) => {
        const params = {
            pageNum: val.pageNo,
            pageSize: 10,
            keyword: val.search,
            startTime: val.startTime,
            endTime: val.endTime,
            workoutId: val.workoutId,
        };
        setStatus('loading');
        workoutApi.getUserStatisticList(params, res => {
            const { data, code, message } = res;
            if (code === 200) {
                if (data.records.length > 0) {
                    const list = data.records.map((item, i) => {
                        return Object.assign(item, {
                            endTime: window.format.foramtTimeDMYHM(+item.endTime),
                            startTime: window.format.foramtTimeDMYHM(+item.startTime),
                            key: i,
                            satisfyLevel:
                                item.satisfyLevel !== null && Number(item.satisfyLevel) ? item.satisfyLevel : '-',
                        });
                    });
                    setTableData(list);
                    setStatus('showData');
                    setTotalRecord(data.total);
                } else {
                    setStatus('empty');
                    setTotalRecord(data.total);
                }
            } else {
                window.format.alert('error', message);
            }
        });
    };
    const handClickRow = val => {
        dispatch(setCurrentUserId(val.userId));
        // dispatch(setCurrentUserId('1668137656916824065'));
        navigate('/user/detail?from=/workouts/statistics-list');
    };
    const { drop } = useAliveController();
    const back = () => {
        drop('WorkoutStatisticsList');
        setTimeout(() => {
            navigate('/workouts/detail');
        }, 200);
    };
    const columns = [
        {
            title: 'USER’S NAME',
            dataIndex: '',
            key: 'name',
            render: (val: defaultInfo) => {
                return (
                    <div className="user_info r-left">
                        {val.avatarUrl && val.avatarUrl !== null ? (
                            <img src={val.avatarUrl} className="user_img" alt="" />
                        ) : (
                            <span className="user_img_text">{window.format.getFirstLetter(val.fullName)}</span>
                        )}
                        {val.fullName ?? '-'}
                    </div>
                );
            },
        },
        {
            title: 'STARTED DATE AND TIME',
            dataIndex: 'startTime',
            key: 'startTime',
        },
        {
            title: 'COMPLETED DATE AND TIME',
            dataIndex: 'endTime',
            key: 'endTime',
        },
        {
            title: 'INTENSITY',
            dataIndex: 'difficultyLevel',
            key: 'difficultyLevel',
        },
        {
            title: 'SATISFACTION',
            dataIndex: 'satisfyLevel',
            key: 'satisfyLevel',
        },
    ];
    const [tableData, setTableData] = useState([]);
    return (
        <div className="workout-list_main user_statistics_main table_page">
            <div className="top_back">
                <div className="back_box r-left" onClick={back}>
                    <i className="back_icon"></i>
                    Back
                </div>
                <div className="screening_box r-left">
                    <Input
                        placeholder="Search"
                        style={{ width: 210 }}
                        className="r-input"
                        value={state.search}
                        allowClear
                        onChange={e => {
                            setState({ ...state, search: e.target.value, pageNo: 1 });
                            searchList({ ...state, search: e.target.value, pageNo: 1 });
                        }}
                        prefix={<span className="search_icon"></span>}
                    />
                    <div className="space15"></div>
                    <RangePicker
                        className="r-rangePicker"
                        style={{ width: 258 }}
                        disabledDate={disabledDate}
                        format="DD/MM/YYYY"
                        onChange={changeTime}
                        suffixIcon={<i className="sel_time_icon"></i>}
                        placeholder={['dd/mm/yyyy', 'dd/mm/yyyy']}
                    />
                </div>
            </div>
            <TableBox
                status={status}
                columns={columns}
                data={tableData}
                height={false}
                className="is_clickable r-table table_main"
                rowClassName="r-table_row"
                onRow={record => {
                    handClickRow(record);
                }}
            />
            {status === 'showData' ? (
                <Pagination total={totalRecord} pageChange={handlePageChange} pageNo={state.pageNo} />
            ) : (
                <></>
            )}
        </div>
    );
};
export default StatisticsList;
