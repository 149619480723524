import { Button, Modal, Input, AutoComplete, Slider } from 'antd';
import closeIcon from '@/assets/images/Icons_Cross.png';
import { useEffect, useRef, useState } from 'react';
import { useSetState } from 'ahooks';
import useGoogleMap from '@/hook/useGoogleMap';
import SlideBar from './slider-bar';
import { debounce } from 'lodash';
import pinked from '@/assets/images/mark-hover.png';
import pinkedTreasure from '@/assets/images/icon-blue.png';
import hoverMark from '@/assets/images/radius-mark.png';
import hoverMarkerMax from '@/assets/images/hoverMarkerMax.png';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
const MapModal = (props: any) => {
    const { queryLocationSearchAsync, getLocationDetail, location, geoCode, info, getLoactionName } = useGoogleMap();
    const [state, setState] = useSetState({
        address: '',
        location: '',
        latitude: '',
        longitude: '',
        radiusMeters: '100',
        locationId: '',
    });
    const [options, setOptions] = useState<{ value: string }[]>([]);
    const getPanelValue = (searchText: string) => {
        if (!searchText) {
            setOptions([]);
        } else {
            queryLocationSearchAsync(searchText);
        }
    };
    const onSelect = (data: string, option: any) => {
        // sel adress add marker
        getLocationDetail(option.place_id);
        setState({ ...state, location: data, address: data });
    };
    const confirmInfo = (key: number) => {
        if (!key) {
            //clsoe
            props.setLocationInfo({
                ...props.locationInfo,
                dialogVisible: false,
                isEdit: false,
                address: '',
                location: '',
                latitude: '',
                longitude: '',
                radiusMeters: '100',
            });
        } else {
            // sunmit
            const obj = {
                ...props.locationInfo,
                dialogVisible: false,
                address: state.address,
                location: state.location,
                latitude: state.latitude,
                longitude: state.longitude,
                radiusMeters: state.radiusMeters,
                locationId: state.locationId,
            };
            props.confirmLocationInfo(obj);
        }
        setState({
            address: '',
            location: '',
            latitude: '',
            longitude: '',
            radiusMeters: '100',
            locationId: '',
        });
        setOptions([]);
    };
    const changeInput = (type: string, val: string) => {
        let temp = '';
        if (type !== 'radiusMeters') {
            temp = val
                .replace(/[^-\d.]/g, '')
                .replace(/^\./g, '')
                .replace(/\.{2,}/g, '')
                .replace('.', '$#$')
                .replace(/\./g, '')
                .replace('$#$', '.');
        }
        setState({ ...state, [type]: temp });
        console.log(456);
    };
    const changePosition = () => {
        console.log(123);
        if (!state.latitude || !state.longitude) return;
        setMarkerCenter(state.latitude, state.longitude);
    };
    const changeRoundMap = debounce((radius: number) => {
        setState({ ...state, radiusMeters: radius.toString() });
        setRoundMap({ lat: +state.latitude, lng: +state.longitude }, radius);
    }, 300);
    // const [thisMap, setThisMap] = useState<any>({});
    const thisMap = useRef({}) as any;
    const [marker, setMarker] = useState<any>('');
    const [circle, setCircle] = useState<any>('');
    const addMarker = (lat, lng, radius?, map?) => {
        const position = { lat: +lat ?? -33.91722, lng: +lng ?? 151.23064 };
        if (marker && marker !== null) {
            // marker.setMap(null);
            marker.setPosition(position);
            setMapCenter(position);
            setRoundMap(position, radius ? radius : 100, map);
            return;
        }
        const markerNew = new window.google.maps.Marker({
            position: position,
            map: map ? map : thisMap.current,
            icon: type === 'treasure' ? pinkedTreasure : pinked,
            optimized: true,
        });
        setMarker(markerNew);
        if (map) {
            map.setCenter(position);
            setRoundMap(position, radius ? radius : 100, map);
            return;
        }
        setMapCenter(position);
        setRoundMap(position, radius ? radius : 100, map);
    };
    // let circle: any = null;
    const setRoundMap = (position, radius, map?) => {
        console.log('====================================');
        console.log(position);
        console.log('====================================');
        radius = radius ? +radius : 100; // meters
        if (circle && circle !== null) {
            // const latlng = new window.google.maps.LatLng(position.lat, position.lng);
            // circle?.setCenter(latlng);
            // circle?.setRadius(radius);
            circle.setMap(null);
        }
        const circleNew = new window.google.maps.Circle({
            strokeColor: '#FF0000',
            strokeOpacity: 0.8,
            strokeWeight: 0,
            fillColor: '#0085FF',
            fillOpacity: 0.15,
            map: map ? map : thisMap.current,
            center: position,
            radius: radius,
        });
        setCircle(circleNew);
    };
    const setMarkerCenter = (lat, lng) => {
        // console.log(lat, lng);
        const position = { lat: +lat ?? -33.91722, lng: +lng ?? 151.23064 };
        marker.setPosition(position);
        setMapCenter(position);
    };
    const createPopup = (position: any, content: HTMLElement) => {
        class Popup extends window.google.maps.OverlayView {
            position;
            containerDiv;
            constructor(position, content) {
                super();
                this.position = position;
                content.classList.add('popup-bubble');

                // This zero-height div is positioned at the bottom of the bubble.
                const bubbleAnchor = document.createElement('div');

                bubbleAnchor.classList.add('popup-bubble-anchor');
                bubbleAnchor.appendChild(content);
                // This zero-height div is positioned at the bottom of the tip.
                this.containerDiv = document.createElement('div');
                this.containerDiv.classList.add('popup-container');
                this.containerDiv.appendChild(bubbleAnchor);
                // Optionally stop clicks, etc., from bubbling up to the map.
                Popup.preventMapHitsAndGesturesFrom(this.containerDiv);
                this.containerDiv.style.top = this.containerDiv.style.top - 30;
                this.containerDiv.style.left = this.containerDiv.style.left + 50;
                this.containerDiv.style.display = 'none';
            }
            /** Called when the popup is added to the map. */
            onAdd() {
                this.getPanes().floatPane.appendChild(this.containerDiv);
            }
            /** Called when the popup is removed from the map. */
            onRemove() {
                if (this.containerDiv.parentElement) {
                    this.containerDiv.parentElement.removeChild(this.containerDiv);
                }
            }
            /** Called each frame when the popup needs to draw itself. */
            draw() {
                const divPosition = this.getProjection().fromLatLngToDivPixel(this.position);
                // Hide the popup when it is far out of view.
                const display = Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000 ? 'block' : 'none';

                if (display === 'block') {
                    this.containerDiv.style.left = divPosition.x + 50 + 'px';
                    this.containerDiv.style.top = divPosition.y - 30 + 'px';
                }
                this.containerDiv.style.display = 'none';
                // if (this.containerDiv.style.display !== display) {
                //     this.containerDiv.style.display = display;
                // }
            }
        }
        return new Popup(position, content);
    };
    const createWorkoutIconNew = (list: any, map: any) => {
        console.log('====================================');
        console.log(list);
        console.log('====================================');
        if (!list || !list.length) return;
        const markList: any = [];
        list.forEach(item => {
            const marker = new window.google.maps.Marker({
                position: new window.google.maps.LatLng(item.lat, item.lon),
                map: map,
                icon: hoverMark,
                optimized: false,
            });
            markList.push(marker);
            const contentString = `<p class='store_name'>${item.name}</p>`;
            const content = document.createElement('div');
            content.innerHTML = contentString;
            const popup = createPopup(new window.google.maps.LatLng(item.lat, item.lon), content);
            popup.setMap(map);

            // marker.popup = popup;
            marker.addListener('mouseover', e => {
                // console.log('移入');
                popup.containerDiv.style.display = 'block';
            });
            marker.addListener('mouseout', e => {
                // console.log('移出');
                popup.containerDiv.style.display = 'none';
            });
        });
        const renderer = {
            render: ({ count, position }) =>
                new window.google.maps.Marker({
                    label: { text: String(count), color: 'white', fontSize: '10px' },
                    position,
                    icon: {
                        url: hoverMarkerMax,
                        scaledSize: new window.google.maps.Size(40, 40),
                    },
                    // adjust zIndex to be above other markers
                    zIndex: Number(window.google.maps.Marker.MAX_ZINDEX) + count,
                }),
        };
        new MarkerClusterer({
            markers: markList,
            map,
            renderer,
        });
    };
    const styleNew = [
        {
            featureType: 'administrative.locality',
            elementType: 'labels.text',
            stylers: [
                {
                    visibility: 'simplified',
                },
            ],
        },
        {
            featureType: 'administrative.neighborhood',
            elementType: 'labels.text',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'administrative.land_parcel',
            elementType: 'labels.text',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'administrative.land_parcel',
            elementType: 'labels.icon',
            stylers: [
                {
                    visibility: 'simplified',
                },
            ],
        },
        {
            featureType: 'landscape.man_made',
            elementType: 'geometry.fill',
            stylers: [
                {
                    visibility: 'on',
                },
            ],
        },
        {
            featureType: 'landscape.man_made',
            elementType: 'labels.icon',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'landscape.natural.landcover',
            elementType: 'labels.icon',
            stylers: [
                {
                    visibility: 'on',
                },
            ],
        },
        {
            featureType: 'poi.attraction',
            elementType: 'geometry',
            stylers: [
                {
                    visibility: 'on',
                },
            ],
        },
        {
            featureType: 'poi.attraction',
            elementType: 'labels.icon',
            stylers: [
                {
                    visibility: 'off',
                },
                {
                    hue: '#ff0000',
                },
            ],
        },
        {
            featureType: 'poi.business',
            elementType: 'labels.icon',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'poi.government',
            elementType: 'labels.icon',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'poi.medical',
            elementType: 'labels.icon',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'poi.park',
            elementType: 'labels.icon',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'poi.place_of_worship',
            elementType: 'labels.icon',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'poi.school',
            elementType: 'labels.icon',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'poi.sports_complex',
            elementType: 'labels.icon',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'road.highway',
            elementType: 'labels.text',
            stylers: [
                {
                    visibility: 'on',
                },
            ],
        },
        {
            featureType: 'road.highway',
            elementType: 'labels.icon',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'road.local',
            elementType: 'labels.text',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'transit.line',
            elementType: 'labels.text',
            stylers: [
                {
                    visibility: 'simplified',
                },
            ],
        },
        {
            featureType: 'transit.station.airport',
            elementType: 'labels.icon',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'transit.station.bus',
            elementType: 'labels.icon',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'transit.station.rail',
            elementType: 'labels.icon',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'water',
            elementType: 'geometry.fill',
            stylers: [
                {
                    visibility: 'on',
                },
                {
                    color: '#c6def2',
                },
            ],
        },
    ];
    const initMap = (id = '', isEdit?: boolean, data?: any): void => {
        const uluru = { lat: -33.91722, lng: 151.23064 };
        // The map, centered at Uluru
        const map = new window.google.maps.Map(document.getElementById(id) as HTMLElement, {
            zoom: 16,
            center: uluru,
            disableDefaultUI: true,
            gestureHandling: 'cooperative', // none  ,  cooperative
            // styles: mapStyle,
            styles: styleNew,
        });
        thisMap.current = map;
        map.addListener('click', e => {
            // console.log(e);
            getLoactionName(e.latLng.lat(), e.latLng.lng());
        });
        createWorkoutIconNew(props.markerInfoArrNew, map);
        if (isEdit) {
            addMarker(+data.latitude, +data.longitude, +data.radiusMeters, map);
        }
    };
    const setMapCenter = (val: any) => {
        thisMap.current.setCenter(val);
    };
    const styleOption = {
        width: '100%',
        height: 383,
        background: '#ccc',
        marginTop: 20,
    };
    const [type, setType] = useState('');
    useEffect(() => {
        if (props.dialogVisible) {
            const data = JSON.parse(JSON.stringify(props.locationInfo));
            console.log(data);
            const type = props.type ? props.type : '';
            setType(type);
            initMap('modalMap', props.locationInfo.isEdit, data);
            setState({
                ...state,
                address: data.address,
                location: data.address,
                latitude: data.latitude,
                longitude: data.longitude,
                radiusMeters: data.radiusMeters,
                locationId: data.locationId,
            });
        } else {
            if (circle !== null && circle) {
                circle.setMap(null);
                setCircle(null);
            }
            if (marker !== null && marker) {
                marker.setMap(null);
                setMarker(null);
            }
        }
    }, [props.dialogVisible]);
    useEffect(() => {
        setState({
            ...state,
            latitude: geoCode.lat !== 0 ? geoCode.lat.toString() : '',
            longitude: geoCode.lng !== 0 ? geoCode.lng.toString() : '',
        });
        if (geoCode.lat !== 0 || geoCode.lng !== 0) {
            // console.log(geoCode.lat, geoCode.lng);
            addMarker(geoCode.lat, geoCode.lng);
        }
    }, [geoCode]);
    useEffect(() => {
        // console.log(location);
        if (location && location.length) {
            const arr = location.map((item: any, i: number) => {
                return Object.assign(item, { value: item.description, key: i });
            });
            setOptions([...arr]);
        } else {
            setOptions([]);
        }
    }, [location]);
    useEffect(() => {
        // console.log(location);
        if (info.lat && info.lng) {
            setState({
                ...state,
                address: info.address,
                location: info.address,
                latitude: info.lat,
                longitude: info.lng,
            });
            addMarker(info.lat, info.lng, state.radiusMeters ?? 100);
        }
    }, [info]);
    return (
        <Modal
            className="r-modal modal_no_padding"
            maskClosable={false}
            centered={true}
            open={props.dialogVisible}
            closeIcon={<img src={closeIcon} alt="" className="close-icon" />}
            onCancel={() => confirmInfo(0)}
            footer={''}
            key={'01'}
            title={[<div className="modal-top-title">{props.locationInfo.isEdit ? 'Edit' : 'Add'} location</div>]}
        >
            <div className="modal_form">
                <AutoComplete
                    value={state.address}
                    onChange={e => {
                        setState({ ...state, address: e });
                    }}
                    options={options}
                    className="r-input_autoComplate"
                    onSelect={onSelect}
                    onSearch={text => getPanelValue(text)}
                    placeholder="Search location..."
                />
                <div className="r-left" style={{ marginTop: 15 }}>
                    <Input
                        value={state.longitude}
                        placeholder="Longitude"
                        className="r-input"
                        maxLength={100}
                        onChange={e => {
                            changeInput('longitude', e.target.value);
                        }}
                        onBlur={() => changePosition()}
                    />
                    <span className="space15"></span>
                    <Input
                        value={state.latitude}
                        placeholder="Latitude"
                        className="r-input"
                        maxLength={100}
                        onChange={e => {
                            changeInput('latitude', e.target.value);
                        }}
                        onBlur={() => changePosition()}
                    />
                </div>
                <div id="modalMap" style={styleOption}></div>
                {props.dialogVisible && state.latitude && state.longitude ? (
                    <SlideBar changeRoundMap={changeRoundMap} state={state} />
                ) : (
                    ''
                )}
                <div className="r-center" style={{ marginTop: 50 }}>
                    <Button className="r-defaultBlick_btn" style={{ flex: 1 }} onClick={() => confirmInfo(0)}>
                        Cancel
                    </Button>
                    <Button
                        className="r-primary_btn"
                        type="primary"
                        style={{ flex: 1, marginLeft: 15 }}
                        onClick={() => confirmInfo(1)}
                    >
                        {props.locationInfo.isEdit ? 'Save' : 'Add'}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default MapModal;
