import comeBackImg from '@/assets/images/back.png';
import { message } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Pagination from '@/components/pagination';
import TableBox from '@/components/tableBox';
import userApi from '@/api/user';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
interface DataType {
    createTime: string;
    status: string;
    points: string;
}
interface getListParams {
    pageNum: number;
    pageSize: number;
}

const methodOfObtaining: string[] = ['Workout completed', 'Admin', 'FitLoot reward'];

const GeonasiumPoints = (props: any) => {
    const { currentUserId } = useSelector((state: any) => state.state);
    const query = new URLSearchParams(useLocation().search);
    useEffect(() => {
        if (query.get('types')) {
            getList(listParams);
        }
    }, []);
    const navigate = useNavigate();
    const [status, setStatus] = useState('showData');
    const [listParams, setListParams] = useState<getListParams>({
        pageNum: 1,
        pageSize: 10,
    });
    const [totalRecord, setTotalRecord] = useState(0);
    const columns: ColumnsType<DataType> = [
        {
            title: 'DATE AND TIME PUBLISHED AWARDED',
            dataIndex: 'createTime',
            key: 'createTime',
            render: time => dayjs(Number(time)).format('DD/MM/YYYY HH:mm'),
        },
        {
            title: 'COME FROM',
            dataIndex: 'status',
            key: 'status',
            render: status => methodOfObtaining[status],
        },
        {
            title: query.get('types') ? 'FITLOOT RECEIVED' : 'POINTS RECEIVED',
            dataIndex: 'points',
            key: 'points',
            render: points => (
                <span className={Number(points) > 0 ? 'points_received' : 'points_received_negative'}>{points}</span>
            ),
        },
    ];
    const handlePageChange = prop => {
        setListParams(state => ({ ...state, pageNum: prop }));
        getList({ ...listParams, pageNum: prop });
    };
    const [points, setPoints] = useState<DataType[]>();
    const getList = params => {
        setStatus('loading');
        userApi.getPointsList({ ...params, types: query.get('types'), userId: currentUserId }, res => {
            const { code, data } = res;
            if (code === 200) {
                if (data.records.length > 0) {
                    setPoints(data.records);
                    setStatus('showData');
                } else {
                    setStatus('empty');
                }
                setTotalRecord(data.total);
            } else {
                setStatus('empty');
                message.error(res.message);
            }
        });
    };
    return (
        <div className="page-main fitLoot table_page">
            <div className="flex-between page_head t72_b50">
                <div
                    className="come_back"
                    onClick={() => {
                        navigate('/user/detail');
                    }}
                >
                    <img src={comeBackImg} alt="" />
                    Back
                </div>
            </div>
            <TableBox status={status} columns={columns} data={points} rowKey="createTime" />
            {status === 'showData' ? (
                <Pagination total={totalRecord} pageChange={handlePageChange} pageNo={listParams.pageNum} />
            ) : (
                <></>
            )}
        </div>
    );
};
export default GeonasiumPoints;
