import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input, Select, Button, Table, Modal, Spin } from 'antd';
import './style/index.scss';
import brandApi from '@/api/brand';
import uploadApi from '@/api/upload';
const OfferDetail = (props: any) => {
    const navigate = useNavigate();
    const [status, setStatus] = useState('loading');
    const [state, setState] = useState({
        brandOffersId: '',
        offerImage: '',
        offerImageKey: '',
        offerName: '',
        description: '',
        redemptionCriteria: false,
        expiryDate: '',
        expiryDateShow: '',
        redemptionTypes: false,
        expiryStatus: false,
        quantity: '',
        brandId: '',
        totalRedemptionUsers: '0',
        totalNumberRedemptionDiscounts: '0',
        collectionRecordVOS: [],
    });
    useEffect(() => {
        const brandId = window.sessionStorage.getItem('brandId') as string;
        if (brandId) {
            setState({ ...state, brandId });
            const params = {
                brandId,
                totalRedemptionUsers: totalNumList[0].type,
                totalNumberRedemptionDiscounts: totalNumList[1].type,
            };
            getInfo(params);
        }
    }, []);
    const getInfo = (params: any) => {
        brandApi.getBrandOfferEntityInfo(params, res => {
            const { data, code, message } = res;
            setStatus('showData');
            if (code === 200) {
                getExChangeList(data.brandOffersId);
                if (!data.expiryStatus && data.expiryDate) {
                    data.expiryDateShow = window.format.foramtTimeDMYHM(+data.expiryDate);
                }
                setState({ ...state, ...data, offerImageKey: window.format.getFilePathName(data.offerImage) });
            } else {
                window.format.alert('error', message);
            }
        });
    };
    const [tableData, setTableData] = useState<
        {
            userName: string;
            exchangeBrandOffersId: number;
            avatarUrl: string;
            createTime: number;
        }[]
    >([]);
    const getExChangeList = (brandOffersId: string) => {
        brandApi.getBrandOfferEntityExchangeList({ brandOffersId, pageNum: 1, pageSize: -1 }, res => {
            const { data, code, message } = res;
            if (code === 200) {
                const list = data.records.map(item => {
                    return Object.assign(item, { createTime: window.format.foramtTimeDMYHM(+item.collectionTime) });
                });
                setTableData(list);
            } else {
                window.format.alert('error', message);
            }
        });
    };
    const handleChange = (key: number, value: string) => {
        const arr = JSON.parse(JSON.stringify(totalNumList));
        arr[key].type = value;
        setTotalNumList([...arr]);
        const params = {
            brandId: state.brandId,
            totalRedemptionUsers: arr[0].type,
            totalNumberRedemptionDiscounts: arr[1].type,
        };
        getInfo(params);
    };
    const back = () => {
        navigate('/brand/detail');
    };
    const [totalNumList, setTotalNumList] = useState([
        { type: '2', label: 'Total number of users redeemed offer', time: '', value: 0 },
        { type: '2', label: 'Total number of offers redeemed', time: '', value: 0 },
    ]);
    const [showMore, setShowMore] = useState(false);
    const opeation = (key: number) => {
        if (key) {
            // del
            setModalVisibile(true);
            return;
        }
        const obj = JSON.parse(JSON.stringify(state));
        delete obj.totalRedemptionUsers;
        delete obj.totalNumberRedemptionDiscounts;
        delete obj.collectionRecordVOS;
        window.sessionStorage.setItem('offerInfo', JSON.stringify(obj));
        navigate('/brand/create-offer');
    };
    const confirmDel = () => {
        const params = {
            brandOffersId: state.brandOffersId,
        };
        setDelLoading(true);
        brandApi.delBrandOffer(params, res => {
            const { data, code, message } = res;
            setDelLoading(false);
            if (code === 200) {
                setModalVisibile(false);
                back();
            } else {
                window.format.alert('error', message);
            }
        });
    };
    const [modalVisibile, setModalVisibile] = useState(false);
    const [delLoading, setDelLoading] = useState(false);
    return (
        <div className="offerDetail_detail_main list_main">
            <div className="top_back r-left">
                <div className="back_box r-left" onClick={back}>
                    <i className="back_icon"></i>
                    Back
                </div>
                <Button className="r-defaultBlick_btn marLeft" style={{ width: 146 }} onClick={() => opeation(0)}>
                    Edit
                </Button>
                <div className="space15"></div>
                <Button className="r-defaultBlick_btn" style={{ width: 146 }} onClick={() => opeation(1)}>
                    Remove
                </Button>
            </div>
            {status === 'loading' ? (
                <div className="data_loading flex-center" style={{ height: 'auto' }}>
                    <Spin />
                </div>
            ) : (
                <section className="detail_box">
                    <div className="r-top" style={{ marginTop: 35 }}>
                        <div className="workouts_action" style={{ flex: 2, maxWidth: '67%' }}>
                            <div className="info_row">
                                <div className="info_tip">OFFER NAME : </div>
                                <div className="reward_name r-left" style={{ marginTop: 17 }}>
                                    <img src={state.offerImage} className="reward_img" alt="" />
                                    {state.offerName}
                                </div>
                            </div>
                            <div className="info_row r-top">
                                <span className="info_tip">DESCRIPTION : </span>
                                <span className="info_msg" style={{ whiteSpace: 'pre-line' }}>
                                    {state.description}
                                </span>
                            </div>
                            <div className="info_row r-top">
                                <span className="info_tip">REDEMPTION CRITERIA :</span>
                                <span className="info_msg">
                                    {state.redemptionCriteria ? 'Quantity based' : 'Time based'}
                                </span>
                            </div>
                            {state.redemptionCriteria ? (
                                <div className="info_row r-top">
                                    <span className="info_tip">TOTAL AMOUNT :</span>
                                    <span className="info_msg">{state.quantity ?? 0}</span>
                                </div>
                            ) : (
                                <></>
                            )}
                            <div className="info_row r-top">
                                <span className="info_tip">EXPIRY DATE :</span>
                                <span className="info_msg">
                                    {state.expiryStatus ? 'Forever' : state.expiryDateShow}
                                </span>
                            </div>
                            <div className="info_row r-top">
                                <span className="info_tip">REDEMPTION MODE :</span>
                                <span className="info_msg">{state.redemptionTypes ? 'Repeat' : 'Once off'}</span>
                            </div>
                            {/* <div className="info_row r-top">
                                <span className="info_tip">REDEEM URL :</span>
                                <span className="info_msg">250</span>
                            </div> */}
                        </div>
                        <div className="space15"></div>
                        <div style={{ flex: 1 }}>
                            <ul className="topNum_ul_NEW">
                                {totalNumList.map((v, i) => {
                                    return (
                                        <li key={i} className={`wordout_card r-left ${i === 1 ? 'space_box' : ''}`}>
                                            <span className="card_left">
                                                <div className="card_label">{v.label}</div>
                                                <Select
                                                    defaultValue="2"
                                                    value={v.type}
                                                    style={{ width: 158 }}
                                                    onChange={val => handleChange(i, val)}
                                                    className="r-select r-select_mini"
                                                    suffixIcon={<i className="sel_arrow_icon"></i>}
                                                    options={[
                                                        { value: '0', label: 'Before Today' },
                                                        { value: '1', label: 'This Year' },
                                                        { value: '2', label: 'This Month' },
                                                        { value: '3', label: 'Today' },
                                                    ]}
                                                />
                                            </span>
                                            <span className="card_val marLeft">
                                                {i !== 1
                                                    ? state.totalRedemptionUsers
                                                    : state.totalNumberRedemptionDiscounts}
                                            </span>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                    <div
                        className="workout-location-statistics"
                        style={{ marginTop: 20, display: tableData.length ? 'block' : 'none' }}
                    >
                        <div className="r-left location_statisticsTip">
                            USER REDEEMED OFFER BREAKDOWN LIST
                            <div className="r-left marLeft">
                                <span
                                    className="view_box"
                                    onClick={() => {
                                        setShowMore(!showMore);
                                    }}
                                >
                                    view
                                </span>
                                <i className={`downIcon_btm ${showMore ? 'roteIcon' : ''}`}></i>
                            </div>
                        </div>
                        {showMore ? (
                            <ul className={`workout_list user_listBox ${showMore ? 'showList' : ''}`}>
                                <li className="workout_item_header r-left">
                                    <span className="itemHeader_cell">USER’S NAME</span>
                                    <span className="itemHeader_cell">REDEEMED TIME</span>
                                </li>
                                {tableData.map(item => {
                                    return (
                                        <li className={`workout_item r-left`}>
                                            <span className="item_cell r-left">
                                                <img src={item.avatarUrl} className="user_img" alt="" />
                                                {item.userName}
                                            </span>
                                            <span className="item_cell item_cellMini">{item.createTime}</span>
                                        </li>
                                    );
                                })}
                            </ul>
                        ) : (
                            ''
                        )}
                    </div>
                </section>
            )}

            <Modal
                className="r-modal modal_no_padding"
                maskClosable={false}
                centered={true}
                open={modalVisibile}
                onCancel={() => setModalVisibile(false)}
                footer={''}
                title={''}
                closable={false}
            >
                <div className="modal_form">
                    <p className="del_tip">Are you sure you want to delete this offer?</p>
                    <div className="r-center" style={{ marginTop: 50 }}>
                        <Button
                            className="r-defaultBlick_btn"
                            style={{ flex: 1 }}
                            onClick={() => setModalVisibile(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="r-primary_btn"
                            type="primary"
                            style={{ flex: 1, marginLeft: 15 }}
                            loading={delLoading}
                            onClick={() => confirmDel()}
                        >
                            Confirm
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};
export default OfferDetail;
