import axios from 'axios';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
const instance = axios.create({
    baseURL: process.env.REACT_APP_URL,
    timeout: 360000,
});
instance.interceptors.request.use(
    (config: AxiosRequestConfig) => {
        const token = sessionStorage.getItem('geonasium-admin-satoken');
        if (token) {
            (config.headers as AxiosRequestHeaders)['geonasium-admin-satoken'] = token;
        }
        (config.headers as AxiosRequestHeaders)['Accept-Language'] = process.env.REACT_APP_ACCEPT_LANGUAGE ?? 'en-US';
        (config.headers as AxiosRequestHeaders)['timestamp'] = new Date().getTime();
        return config;
    },
    error => {
        Promise.reject(error);
    }
);

const AxiosInterceptor = ({ children }: any) => {
    const navigate = useNavigate();
    const resInterceptor = (response: any) => {
        const code = response.data.code;
        if (code === 50000 || code === 5001) {
            sessionStorage.clear();
            localStorage.clear();
            navigate('/login');
            return;
        } else {
            if (response.headers['geonasium-admin-satoken'])
                sessionStorage.setItem('geonasium-admin-satoken', response.headers['geonasium-admin-satoken']);
            return response;
        }
    };

    const errInterceptor = (error: any) => {
        return Promise.reject(error);
    };

    const interceptor = instance.interceptors.response.use(resInterceptor, errInterceptor);
    useEffect(() => {
        return () => instance.interceptors.response.eject(interceptor);
    }, [navigate]);

    return children;
};

export default instance;
export { AxiosInterceptor };
