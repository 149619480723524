import { createSlice, PayloadAction } from '@reduxjs/toolkit';
interface stateObj {
    currentUserId: string;
    isGetList: boolean;
    feedBackCount: number;
    locationFeedBackCount: number;
}
const initialState: stateObj = {
    currentUserId: '',
    isGetList: false,
    feedBackCount: 0,
    locationFeedBackCount: 0,
};

export const stateSlice = createSlice({
    name: 'state',
    initialState,
    reducers: {
        setCurrentUserId: (state, action: PayloadAction<string>) => {
            state.currentUserId = action.payload;
        },
        setIsGetList: (state, action: PayloadAction<boolean>) => {
            state.isGetList = action.payload;
        },
        setFeedBackCount: (state, action: PayloadAction<number>) => {
            state.feedBackCount = action.payload;
        },
        setlocationFeedBackCount: (state, action: PayloadAction<number>) => {
            state.locationFeedBackCount = action.payload;
        },
    },
});

export const { setCurrentUserId, setIsGetList, setFeedBackCount, setlocationFeedBackCount } = stateSlice.actions;

export default stateSlice.reducer;
