import axios from '../utils/index';
import { Pat, HttpResponse } from '@/typings/request';
const SocialsApi = {
    getPostList(params: Pat, callback: (T: any) => void) {
        axios
            .get(`/admin/post-entity/query/post/list`, {
                params,
            })
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getPostDetail(params: Pat, callback: (T: any) => void) {
        axios
            .get(`/admin/post-entity/query/post/info/${params.postId}`)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getComments(params: Pat, callback: (T: any) => void) {
        axios
            .get(`/admin/post-entity/query/post/comment/list`, { params })
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    deleteComment(commentId: string, callback: (T: any) => void) {
        axios
            .delete(`/admin/post-entity/delete/comment/${commentId}`)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    deletePost(params: Pat, callback: (T: any) => void) {
        axios
            .delete(`/admin/post-entity/delete/${params.postId}`)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getReportList(params: Pat, callback: (T: any) => void) {
        axios
            .get(`/admin/reporting-records/query/${params.type}/list`, {
                params,
            })
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    deleteReportPostOrComment(type: string, params: Pat, callback: (T: any) => void) {
        axios
            .delete(`/admin/reporting-records/delete/${type}/${params.reportingRecordsId}`)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    hideCommentOrReport(params: Pat, callback: (T: any) => void) {
        axios
            .post(`/admin/reporting-records/hide/${params.reportingRecordsId}`)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
};
export default SocialsApi;
