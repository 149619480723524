import { useRef, useState } from 'react';
import pinked from '@/assets/images/mark-hover.png';
const useGoogleMap = () => {
    const mapRef = useRef({}) as any;
    const [thisMap, setThisMap] = useState<any>({});
    const [marker, setMarker] = useState<any>('');
    const [circle, setCircle] = useState<any>('');
    const [location, setLocation] = useState<any[]>([]);
    const [geoCode, setGeoCode] = useState({ lat: 0, lng: 0, city: '' });
    const [info, setInfo] = useState({
        address: '',
        lat: '',
        lng: '',
    });
    const queryLocationSearchAsync = (keyword: string) => {
        // Search address
        const request = {
            input: keyword,
            language: 'en',
            componentRestrictions: {
                country: ['au', 'nz'],
            },
        };
        const AutocompleteService = new window.google.maps.places.AutocompleteService();
        AutocompleteService.getPlacePredictions(request, (results: any, status: any) => {
            // console.log(results);
            if (status === 'OK' && results) {
                setLocation([...results]);
            } else {
                setLocation([]);
            }
        });
    };
    const getLocationDetail = (placeId: string) => {
        // Obtain details based on the address ID
        const divEle = document.createElement('div');
        const serve = new window.google.maps.places.PlacesService(divEle);
        serve.getDetails(
            {
                placeId: placeId,
                language: 'en',
            },
            (result: any, status: any) => {
                if (status === 'OK' && result) {
                    // console.log(result);
                    let city = [];
                    if (result.vicinity) {
                        city = result.vicinity.split(',');
                    }
                    setGeoCode({
                        ...geoCode,
                        lat: result.geometry.location.lat(),
                        lng: result.geometry.location.lng(),
                        city: city.length ? city[city.length - 1] : '',
                    });
                }
            }
        );
    };
    const getLoactionName = (lat, lng) => {
        const geocoder = new window.google.maps.Geocoder();
        const latlng = { lat: lat, lng: lng };
        geocoder.geocode({ location: latlng, language: 'en' }, function (results, status) {
            if (status === 'OK') {
                if (results[0]) {
                    const address = results[0].formatted_address;
                    setInfo({
                        ...info,
                        address,
                        lat,
                        lng,
                    });
                } else {
                    console.log('No results found');
                }
            } else {
                console.log('Geocoder failed due to: ' + status);
            }
        });
    };
    const addMarker = (lat, lng, radius?) => {
        console.log(marker);
        if (marker && marker !== null) {
            marker?.setMap(null);
        }
        const position = { lat: +lat ?? -33.91722, lng: +lng ?? 151.23064 };
        const markerNew = new window.google.maps.Marker({
            position: position,
            map: mapRef,
            icon: pinked,
            optimized: false,
        });
        // console.log(markerNew);
        // setMarker(markerNew);
        // setMapCenter(position);
        // setRoundMap(position, radius ? radius : 100);
    };
    const setRoundMap = (position, radius) => {
        if (circle) {
            circle?.setMap(null);
        }
        radius = radius ? +radius : 100; // meters
        const circleNew = new window.google.maps.Circle({
            strokeColor: '#FF0000',
            strokeOpacity: 0.8,
            strokeWeight: 0,
            fillColor: '#0085FF',
            fillOpacity: 0.15,
            map: thisMap,
            center: position,
            radius: radius,
        });
        setCircle(circleNew);
    };
    const setMarkerCenter = (lat, lng) => {
        // console.log(lat, lng);
        const position = { lat: +lat ?? -33.91722, lng: +lng ?? 151.23064 };
        marker.setPosition(position);
        setMapCenter(position);
    };
    const mapStyle = [
        {
            featureType: 'poi',
            stylers: [{ visibility: 'off' }],
        },
        // {
        //     featureType: 'road.arterial',
        //     elementType: 'geometry',
        //     stylers: [{ color: '#BEF2A9' }],
        // },
    ];
    const initMap = (id = '', lat?: number, lng?: number): void => {
        const uluru = { lat: lat ?? -33.91722, lng: lng ?? 151.23064 };
        // The map, centered at Uluru
        const map = new window.google.maps.Map(document.getElementById(id) as HTMLElement, {
            zoom: 16,
            center: uluru,
            disableDefaultUI: true,
            mapTypeControl: false,
            gestureHandling: 'cooperative', // none  ,  cooperative
            styles: mapStyle,
        });
        map.addListener('click', e => {
            console.log(e);
            getLoactionName(e.latLng.lat(), e.latLng.lng());
        });
        setThisMap(map);
        mapRef.current = map;
    };
    const setMapCenter = (val: any) => {
        thisMap.setCenter(val);
    };
    return {
        queryLocationSearchAsync,
        getLocationDetail,
        location,
        geoCode,
        initMap,
        setMapCenter,
        addMarker,
        setMarkerCenter,
        info,
        setRoundMap,
        getLoactionName,
    };
};
export default useGoogleMap;
