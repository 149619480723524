import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input, Select, Button, Table, Modal, Spin } from 'antd';
import './style/index.scss';
import workoutApi from '@/api/workout';
const ActionLibraryDetail = (props: any) => {
    const navigate = useNavigate();
    const [status, setStatus] = useState('loading');
    const back = () => {
        const isFromWork = window.sessionStorage.getItem('isFromWork') ?? '0';
        window.sessionStorage.removeItem('exerciseId');
        window.sessionStorage.removeItem('isFromWork');
        navigate(Number(isFromWork) ? '/workouts/detail' : '/workouts/actionLibrary');
    };
    useEffect(() => {
        const exerciseId = window.sessionStorage.getItem('exerciseId') ?? '';
        if (exerciseId) {
            setStatus('loading');
            workoutApi.getExerciseInfo({ exerciseId }, res => {
                const { data, code, message } = res;
                setStatus('showData');
                if (code === 200) {
                    setState({ ...state, ...data, type: data.exerciseType === 'Intros' ? 2 : 1 });
                    if (data.exerciseType === 'Intros') {
                        const size = window.format.getFileSize(data.introsUrl);
                        setIntrosVideo({
                            label: '',
                            key: '',
                            nameKey: '',
                            url: data.introsUrl,
                            urlShow: window.format.getFileThumbnail(data.introsUrl),
                            isVideo:
                                data.introsUrl && data.introsUrl !== null
                                    ? data.introsUrl.includes('mp4')
                                        ? true
                                        : false
                                    : false,
                            size: window.format.formatFileSize(size) ?? '0Kb',
                        });
                        return;
                    }
                    const arr = videoList;
                    arr.forEach(item => {
                        const size = window.format.getFileSize(data[item.key]);
                        item.size = window.format.formatFileSize(size);
                        item.url = data[item.key];
                        const Thumbnail = window.format.getFileThumbnail(data[item.key]);
                        item.urlShow = Thumbnail;
                        item.inputName = data[item.nameKey] ?? '';
                        item.isVideo =
                            data[item.key] && data[item.key] !== null
                                ? data[item.key].includes('mp4')
                                    ? true
                                    : false
                                : false;
                    });
                    setVideoList([...arr]);
                } else {
                    window.format.alert('error', message);
                }
            });
        }
    }, []);
    const [state, setState] = useState({
        type: 2,
        exerciseId: '',
        exerciseName: '',
        exerciseType: '',
        estimatedAverageTimePerRep: '',
        beginner: '',
        beginnerName: '',
        intermediate: '',
        intermediateName: '',
        advanced: '',
        advancedName: '',
        workoutLocationAssociation: [] as { name: string; id: string }[],
    });
    const [videoList, setVideoList] = useState([
        {
            label: 'Beginner',
            key: 'beginnerUrl',
            nameKey: 'beginnerName',
            url: '',
            urlShow: '',
            isVideo: false,
            size: '0Kb',
            inputName: '',
        },
        {
            label: 'Intermediate',
            nameKey: 'intermediateName',
            key: 'intermediateUrl',
            url: '',
            urlShow: '',
            isVideo: false,
            size: '0Kb',
            inputName: '',
        },
        {
            label: 'Advanced',
            nameKey: 'advancedName',
            key: 'advancedUrl',
            url: '',
            urlShow: '',
            isVideo: false,
            size: '0Kb',
            inputName: '',
        },
    ]);
    const [introsVideo, setIntrosVideo] = useState({
        label: '',
        key: '',
        nameKey: '',
        url: '',
        urlShow: '',
        isVideo: false,
        size: '0Kb',
    });
    const opeation = (key: number) => {
        if (key) {
            // del
            setModalVisibile(true);
            return;
        }
        window.sessionStorage.setItem('exerciseInfo', JSON.stringify(state));
        window.sessionStorage.setItem('videoList', JSON.stringify(videoList));
        window.sessionStorage.setItem('introsVideo', JSON.stringify(introsVideo));
        navigate('/workouts/create-actionLibrary');
    };
    const confirmDel = () => {
        const params = {
            exerciseId: state.exerciseId,
        };
        setLoading(true);
        workoutApi.deleteExercise(params, res => {
            const { data, code, message } = res;
            setLoading(false);
            if (code === 200) {
                setModalVisibile(false);
                back();
            } else if (code === 1000) {
                setAlertModalVisibile(true);
            } else {
                window.format.alert('error', message);
            }
        });
    };
    const [loading, setLoading] = useState(false);
    const [modalVisibile, setModalVisibile] = useState(false);
    const [alertModalVisibile, setAlertModalVisibile] = useState(false);
    const [videoModalVisibile, setVideoModalVisibile] = useState(false);
    const [videoInfo, setVideoInfo] = useState({
        label: '',
        url: '',
        isVideo: false,
    });
    const openVideo = val => {
        if (!val.url || val.url === null) return;
        setVideoInfo({ ...val });
        setVideoModalVisibile(true);
    };
    return (
        <>
            <div className="actionlibrary_detail_main workout-list_main">
                <div className="top_back r-left">
                    <div className="back_box r-left" onClick={back}>
                        <i className="back_icon"></i>
                        Back
                    </div>
                    <Button className="r-defaultBlick_btn marLeft" style={{ width: 146 }} onClick={() => opeation(0)}>
                        Edit
                    </Button>
                    <div className="space15"></div>
                    <Button className="r-defaultBlick_btn" style={{ width: 146 }} onClick={() => opeation(1)}>
                        Remove
                    </Button>
                </div>
                {status === 'loading' ? (
                    <div className="data_loading flex-center" style={{ height: 'auto' }}>
                        <Spin />
                    </div>
                ) : (
                    <section className="detail_box">
                        <div className="workout_infoBox r-left">
                            {state.type === 1 ? (
                                <>
                                    <div className="info-leftbox">
                                        <div className="action_msgBox marB20">
                                            <div
                                                className="info_row r-top"
                                                style={{
                                                    margin: state.exerciseType === 'Workouts' ? '0 0 20px 0' : '0',
                                                }}
                                            >
                                                <span className="info_tip">EXERCISE NAME :</span>
                                                <span className="info_msg">{state.exerciseName}</span>
                                            </div>
                                            <div
                                                className="info_row r-top"
                                                style={{
                                                    display: state.exerciseType === 'Workouts' ? 'block' : 'none',
                                                }}
                                            >
                                                <span className="info_tip">ESTIMATED AVERAGE TIME PER REP :</span>
                                                <span className="info_msg">
                                                    {`${state.estimatedAverageTimePerRep}${
                                                        +state.estimatedAverageTimePerRep > 1 ? 'secs' : 'sec'
                                                    }`}
                                                </span>
                                            </div>
                                        </div>
                                        {videoList.map((item, k) => {
                                            return (
                                                <div className="action_msgBox marB20" key={item.label}>
                                                    <div className="info_row r-top">
                                                        <span className="info_mini_tit">{item.label}</span>
                                                    </div>
                                                    <div className="info_row r-top">
                                                        <span className="info_tip">
                                                            {k === 0
                                                                ? 'BEGINNER NAME'
                                                                : k === 1
                                                                ? 'INTERMEDIATE NAME'
                                                                : 'ADVANCED NAME'}
                                                            :
                                                        </span>
                                                        <span className="info_msg">{item.inputName ?? '-'}</span>
                                                    </div>
                                                    <div className="info_row r-top">
                                                        <span className="info_tip">VIDEOS :</span>
                                                        <span
                                                            className="info_video_box"
                                                            onClick={() => {
                                                                openVideo(item);
                                                            }}
                                                        >
                                                            <i className="play_icon"></i>
                                                            {item.isVideo ? (
                                                                <video
                                                                    className="info_video"
                                                                    loop
                                                                    src={item.url}
                                                                ></video>
                                                            ) : (
                                                                <div
                                                                    className="gif-container"
                                                                    style={{ backgroundImage: `url(${item.urlShow})` }}
                                                                ></div>
                                                            )}
                                                        </span>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </>
                            ) : (
                                <div className="info-leftbox">
                                    <div className="action_msgBox">
                                        <div className="info_row r-top">
                                            <span className="info_tip">TYPE</span>
                                            <span className="info_msg">Intros</span>
                                        </div>
                                        <div className="info_row r-top">
                                            <span className="info_tip">NAME</span>
                                            <span className="info_msg">{state.exerciseName ?? '-'}</span>
                                        </div>
                                        <div className="info_row r-top">
                                            <span className="info_tip">VIDEOS :</span>
                                            <span
                                                className="info_video_box"
                                                onClick={() => {
                                                    openVideo(introsVideo);
                                                }}
                                            >
                                                <i className="play_icon"></i>
                                                {introsVideo.isVideo ? (
                                                    <video className="info_video" loop src={introsVideo.url}></video>
                                                ) : (
                                                    <div
                                                        className="gif-container"
                                                        style={{ backgroundImage: `url(${introsVideo.urlShow})` }}
                                                    ></div>
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="info-rightbox">
                                <div className="info_row r-top">
                                    <span className="info_tit_action">Workout location association</span>
                                </div>
                                {state.workoutLocationAssociation.length ? (
                                    state.workoutLocationAssociation.map((v, i) => {
                                        return (
                                            <div className="info_row r-top" key={i}>
                                                <span className="info_msg no-margin">{v.name}</span>
                                            </div>
                                        );
                                    })
                                ) : (
                                    <div className="not_data flex-center">No data</div>
                                )}
                            </div>
                        </div>
                    </section>
                )}

                <Modal
                    className="r-modal modal_no_padding"
                    maskClosable={false}
                    centered={true}
                    open={modalVisibile}
                    onCancel={() => setModalVisibile(false)}
                    footer={''}
                    title={''}
                    closable={false}
                >
                    <div className="modal_form">
                        <p className="del_tip">
                            Are you sure you want to delete this {state.type === 2 ? 'Intros' : 'exercise'}?
                        </p>
                        <div className="r-center" style={{ marginTop: 50 }}>
                            <Button
                                className="r-defaultBlick_btn"
                                style={{ flex: 1 }}
                                onClick={() => setModalVisibile(false)}
                            >
                                Cancel
                            </Button>
                            <Button
                                className="r-primary_btn"
                                type="primary"
                                style={{ flex: 1, marginLeft: 15 }}
                                loading={loading}
                                onClick={() => confirmDel()}
                            >
                                Confirm
                            </Button>
                        </div>
                    </div>
                </Modal>
                <Modal
                    className="r-modal modal_no_padding"
                    maskClosable={false}
                    centered={true}
                    open={alertModalVisibile}
                    onCancel={() => setAlertModalVisibile(false)}
                    footer={''}
                    title={''}
                    closable={false}
                >
                    <div className="modal_form">
                        <p className="del_tip">
                            {state.type === 2
                                ? 'This Intros has been constructed as a workout and cannot be deleted. Please cancel this Intros from the workout first.'
                                : 'This exercise has been constructed as a workout and cannot be deleted. Please cancel this exercise from the workout first.'}
                        </p>
                        <div className="r-center" style={{ marginTop: 50 }}>
                            <Button
                                className="r-primary_btn"
                                type="primary"
                                style={{ width: 194 }}
                                onClick={() => {
                                    setAlertModalVisibile(false);
                                    setModalVisibile(false);
                                }}
                            >
                                Okay
                            </Button>
                        </div>
                    </div>
                </Modal>

                <Modal
                    className="r-modal modal_no_padding"
                    maskClosable={false}
                    width={600}
                    centered={true}
                    open={videoModalVisibile}
                    destroyOnClose
                    onCancel={() => setVideoModalVisibile(false)}
                    footer={''}
                    title={''}
                    closable={false}
                >
                    <div className="modal_form_vidoe">
                        <div style={{ maxHeight: 500 }}>
                            {videoInfo.isVideo ? (
                                <video className="info_video" muted autoPlay controls loop src={videoInfo.url}></video>
                            ) : (
                                <img src={videoInfo.url} className="info_video img_info" alt="" />
                            )}
                        </div>
                        <div className="r-center" style={{ marginTop: 50 }}>
                            <Button
                                className="r-primary_btn"
                                type="primary"
                                style={{ width: 194 }}
                                onClick={() => {
                                    setVideoModalVisibile(false);
                                }}
                            >
                                Colse
                            </Button>
                        </div>
                    </div>
                </Modal>
            </div>
        </>
    );
};
export default ActionLibraryDetail;
