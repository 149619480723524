import comeBackImg from '@/assets/images/back.png';
import type { ColumnsType } from 'antd/es/table';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Pagination from '@/components/pagination';
import TableBox from '@/components/tableBox';
interface DataType {
    Ranking: string;
    name: string;
    key: number;
}
interface getListParams {
    pageNo: number;
    pageSize: number;
    keyWord: string;
}
const data: DataType[] = [
    {
        Ranking: '12/06/2020 15:39',
        name: 'Workout completed',
        key: 32,
    },
    {
        Ranking: '2',
        name: 'Admin',
        key: -33,
    },
    {
        Ranking: '3',
        name: 'Joe Black',
        key: -5,
    },
    {
        Ranking: '4',
        name: 'Joe Black',
        key: 35,
    },
    {
        Ranking: '5',
        name: 'Joe Black',
        key: 36,
    },
    {
        Ranking: '6',
        name: 'Joe Black',
        key: 37,
    },
    {
        Ranking: '7',
        name: 'Joe Black',
        key: 38,
    },
    {
        Ranking: '8',
        name: 'Joe Black',
        key: 39,
    },
];
const columns: ColumnsType<DataType> = [
    {
        title: 'DATE AND TIME PUBLISHED AWARDED',
        dataIndex: 'Ranking',
        key: 'Ranking',
    },
    {
        title: 'COME FROM',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'FITLOOT RECEIVED',
        dataIndex: 'key',
        key: 'key',
        render: num => <span className={num > 0 ? 'points_received' : 'points_received_negative'}>{num}</span>,
    },
];

const FitlootList = (props: any) => {
    const navigate = useNavigate();
    const [status, setStatus] = useState('showData');
    const [listParams, setListParams] = useState<getListParams>({
        pageNo: 1,
        pageSize: 10,
        keyWord: '',
    });
    const [totalRecord, setTotalRecord] = useState(0);

    const handlePageChange = prop => {
        // setListParams(state => ({ ...state, pageNo: prop }));
        // getList({ ...listParams, pageNo: prop });
    };
    return (
        <div className="page-main fitLoot table_page">
            <div className="flex-between page_head t72_b50">
                <div
                    className="come_back"
                    onClick={() => {
                        navigate('/user/detail');
                    }}
                >
                    <img src={comeBackImg} alt="" />
                    Back
                </div>
            </div>
            <TableBox status={status} columns={columns} data={data} />
            {status === 'showData' ? (
                <Pagination total={totalRecord} pageChange={handlePageChange} pageNo={listParams.pageNo} />
            ) : (
                <></>
            )}
        </div>
    );
};
export default FitlootList;
