import './index.scss';
import { useNavigate } from 'react-router-dom';

const Setting = (props: any) => {
    const navigate = useNavigate();
    return (
        <>
            <div className="page-main setting">
                <h1 className="top-title">Setting</h1>
                <div
                    className="setting-item flex-between"
                    onClick={() => {
                        navigate('/setting/workoutType');
                    }}
                >
                    <div className="left-label">Workout Type</div>
                </div>
                <div
                    className="setting-item flex-between"
                    onClick={() => {
                        navigate('/setting/versions');
                    }}
                >
                    <div className="left-label">Versions</div>
                </div>
            </div>
        </>
    );
};

export default Setting;
