import axios from '../utils/index';
import { Pat, HttpResponse } from '@/typings/request';
const uploadApi = {
    uploadFile(params: Pat, callback: (T: any) => void) {
        axios
            .post(`/admin/upload/file`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    uploadMultiFile(params: Pat, callback: (T: any) => void) {
        axios
            .post(`/admin/upload/multi-file`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getFilePresignedUrl(params: Pat, callback: (T: any) => void) {
        axios
            .get(`/admin/upload/presigned-url`, { params })
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
};
export default uploadApi;
