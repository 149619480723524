import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input, Button, Table, Modal, Spin, Image } from 'antd';
import './style/index.scss';
import Pagination from '@/components/pagination';
import { debounce } from 'lodash';
import workoutApi from '@/api/workout';
import { defaultInfo } from '@/typings/allType';
import { useAliveController } from 'react-activation';
interface listParams {
    pageNo: string | number;
    search: string;
    type: string;
}
const WorkoutActionLibrary = (props: any) => {
    useEffect(() => getList({ ...state, type }), []);
    useEffect(() => {
        getList({ ...state, type });
    }, [props.prevLocation]);
    const navigate = useNavigate();
    const [status, setStatus] = useState('showData');
    const [state, setState] = useState({
        pageNo: 1,
        search: '',
    });
    const [totalRecord, setTotalRecord] = useState(0);
    const searchList = useCallback(
        debounce(params => getList(params), 1000),
        []
    );
    const handlePageChange = prop => {
        setState({ ...state, pageNo: prop });
        getList({ ...state, pageNo: prop, type });
    };
    const getList = (val: listParams) => {
        const params = {
            pageNum: val.pageNo,
            pageSize: 10,
            exerciseType: val.type === 'Warm up' ? 'WarmUp' : val.type === 'SetIntros' ? 'Intros' : val.type,
            keyword: val.search,
        };
        setStatus('loading');
        workoutApi.getExerciseList(params, res => {
            const { data, code, message } = res;
            if (code === 200) {
                if (data.records.length > 0) {
                    const list = data.records.map((item, i) => {
                        return Object.assign(item, {
                            createTime: window.format.foramtTimeDMYHM(+item.createTime),
                            key: i,
                            imgUrl:
                                item.introsUrl && item.introsUrl !== null
                                    ? window.format.getFileThumbnail(item.introsUrl)
                                    : '',
                            exerciseSkillInfoList:
                                item.exerciseSkillInfoList && item.exerciseSkillInfoList !== null
                                    ? item.exerciseSkillInfoList.map(val => {
                                          return Object.assign(val, {
                                              imgUrl: window.format.getFileThumbnail(val.workoutSkillUrl),
                                          });
                                      })
                                    : [],
                        });
                    });
                    setTableData(list);
                    setStatus('showData');
                    setTotalRecord(data.total);
                } else {
                    setStatus('empty');
                    setTotalRecord(data.total);
                }
            } else {
                window.format.alert('error', message);
                setStatus('empty');
            }
        });
    };
    const [type, setType] = useState('Workouts');
    const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
    const handchangeType = (value: string) => {
        if (status === 'loading') return;
        getList({ type: value, pageNo: 1, search: '' });
        setState({ pageNo: 1, search: '' });
        setTableData([]);
        setTotalRecord(0);
        setType(value);
        setExpandedRowKeys([]);
    };
    const { drop } = useAliveController();
    const back = () => {
        drop('WorkoutActionLibrary');
        setTimeout(() => {
            navigate('/workouts');
        }, 200);
    };
    const handClickCell = (val: defaultInfo) => {
        const index = expandedRowKeys.findIndex(v => v === val.key);
        const newKeys = expandedRowKeys;
        if (index !== -1) {
            newKeys.splice(index, 1);
        } else {
            newKeys.push(val.key);
        }
        setExpandedRowKeys([...newKeys]);
    };
    const filterRowClass: (val: defaultInfo) => string = val => {
        const index = expandedRowKeys.findIndex(v => v === val.key);
        return `r-table_row ${index !== -1 ? 'hideBorder' : ''}`;
    };
    const toDetail = (key: number, val?: any) => {
        if (key) {
            window.sessionStorage.setItem('exerciseId', val.exerciseId);
        }
        window.sessionStorage.removeItem('exerciseInfo');
        navigate(key ? '/workouts/actionLibrary-detail' : '/workouts/create-actionLibrary');
    };
    const columns = [
        {
            title: type === 'SetIntros' ? 'NAME' : 'EXERCISE NAME',
            dataIndex: 'exerciseName',
            key: 'exerciseName',
        },
        {
            title: type === 'Workouts' ? 'EXERCISE AVERAGE TIME PER REP' : '',
            key: '',
            width: 350,
            render: val =>
                type === 'Workouts' ? (
                    <span>{`${val.estimatedAverageTimePerRep}${
                        +val.estimatedAverageTimePerRep > 1 ? 'secs' : 'sec'
                    }`}</span>
                ) : (
                    <></>
                ),
        },
        {
            title: '',
            dataIndex: '',
            key: 'key',
            render: val => (
                <div
                    className="skill_levelbox"
                    onClick={e => {
                        e.stopPropagation();
                        handClickCell(val);
                    }}
                >
                    Skill level
                </div>
            ),
        },
        {
            title: '',
            key: 'key',
            width: 150,
            render: val => (
                <div
                    className="actioned_btn"
                    onClick={e => {
                        e.stopPropagation();
                        opeation(val);
                    }}
                >
                    Remove
                </div>
            ),
        },
    ];
    const columnsIntros = [
        {
            title: 'NAME',
            dataIndex: 'exerciseName',
            key: 'exerciseName',
        },
        {
            title: 'VIDEO',
            dataIndex: '',
            key: 'key',
            render: val => (
                <div>
                    <Image
                        style={{ borderRadius: 4, backgroundColor: '#ccc' }}
                        preview={false}
                        width={35}
                        height={35}
                        src={val.imgUrl}
                        fallback={require('@/assets/images/video-file.png')}
                    />
                </div>
            ),
        },
        {
            title: '',
            key: 'key',
            width: 150,
            render: val => (
                <div
                    className="actioned_btn"
                    onClick={e => {
                        e.stopPropagation();
                        opeation(val);
                    }}
                >
                    Remove
                </div>
            ),
        },
    ];
    const [tableData, setTableData] = useState<any[]>([]);
    const [modalVisibile, setModalVisibile] = useState(false);
    const [alertModalVisibile, setAlertModalVisibile] = useState(false);
    const [opeationInfo, setOpeationInfo] = useState({
        id: '',
        loading: false,
    });
    const opeation = val => {
        // console.log(val);
        setOpeationInfo({ ...opeationInfo, id: val.exerciseId });
        setModalVisibile(true);
    };
    const confirmDel = () => {
        const params = {
            exerciseId: opeationInfo.id,
        };
        setOpeationInfo({ ...opeationInfo, loading: true });
        workoutApi.deleteExercise(params, res => {
            const { data, code, message } = res;
            setOpeationInfo({ ...opeationInfo, loading: false });
            if (code === 200) {
                setModalVisibile(false);
                setOpeationInfo({ id: '', loading: false });
                getList({ ...state, type });
            } else if (code === 1000) {
                setOpeationInfo({ id: '', loading: false });
                setAlertModalVisibile(true);
            } else {
                window.format.alert('error', message);
            }
        });
    };
    return (
        <div className="workout-list_main workoutActionLibrary_main table_page">
            <div className="top_back">
                <div className="r-left">
                    <div className="back_box r-left" onClick={back}>
                        <i className="back_icon"></i>
                        Back
                    </div>
                    <Button
                        className="r-default_btn marLeft"
                        style={{ width: 146 }}
                        onClick={() => toDetail(0)}
                        icon={<></>}
                    >
                        Add New
                    </Button>
                </div>
                <div className="screening_box r-left">
                    <Input
                        placeholder="Search"
                        style={{ width: 210 }}
                        value={state.search}
                        allowClear
                        onChange={e => {
                            setState({ ...state, search: e.target.value });
                            searchList({ ...state, search: e.target.value, type });
                        }}
                        className="r-input r-input_serach"
                        prefix={<span className="search_icon"></span>}
                    />
                </div>
                <div className="r-left action_tab">
                    {[
                        { label: 'Workout Exercises', value: 'Workouts' },
                        { label: 'Warm Ups', value: 'Warm up' },
                        { label: 'Cooldowns', value: 'Cooldowns' },
                        { label: 'Set Intros', value: 'SetIntros' },
                    ].map(item => (
                        <span
                            key={item.value}
                            style={{ cursor: status === 'loading' ? 'no-drop' : 'pointer' }}
                            className={`action_tab_item ${type === item.value ? 'active' : ''}`}
                            onClick={() => handchangeType(item.value)}
                        >
                            {item.label}
                        </span>
                    ))}
                </div>
            </div>
            {status === 'loading' ? (
                <div className="data_loading flex-center" style={{ height: 'auto' }}>
                    <Spin />
                </div>
            ) : (
                <></>
            )}
            {status === 'empty' ? <div className="not_data flex-center">No data</div> : <></>}
            {status === 'showData' ? (
                <>
                    <Table
                        className="r-table flex_height table_box action_library_list pointer_table"
                        rowClassName={record => filterRowClass(record)}
                        dataSource={tableData}
                        pagination={false}
                        columns={type === 'SetIntros' ? columnsIntros : columns}
                        rowKey={record => record.key}
                        onRow={record => {
                            return {
                                onClick: e => {
                                    e.stopPropagation();
                                    toDetail(1, record);
                                },
                            };
                        }}
                        expandable={{
                            expandedRowRender: record => (
                                <div className="leavel_box r-left ">
                                    <div className="leavel_left">
                                        <div className="border_levael"></div>
                                        <div className="border_levael_m"></div>
                                        <div className="border_levael_m"></div>
                                    </div>
                                    <div className="leavel_right">
                                        <div className="tip_levael">
                                            Beginner - {record.exerciseSkillInfoList[0].workoutSkillName}
                                            <div className="file_iconImg">
                                                <Image
                                                    style={{ borderRadius: 4, background: '#ccc' }}
                                                    preview={false}
                                                    width={35}
                                                    height={35}
                                                    src={record.exerciseSkillInfoList[0].imgUrl}
                                                    fallback={require('@/assets/images/video-file.png')}
                                                />
                                            </div>
                                        </div>
                                        <div className="tip_levael">
                                            Intermediate - {record.exerciseSkillInfoList[1].workoutSkillName}
                                            <div className="file_iconImg">
                                                <Image
                                                    style={{ borderRadius: 4, background: '#ccc' }}
                                                    preview={false}
                                                    width={35}
                                                    height={35}
                                                    src={record.exerciseSkillInfoList[1].imgUrl}
                                                    fallback={require('@/assets/images/video-file.png')}
                                                />
                                            </div>
                                        </div>
                                        <div className="tip_levael">
                                            Advanced - {record.exerciseSkillInfoList[2].workoutSkillName}
                                            <div className="file_iconImg">
                                                <Image
                                                    style={{ borderRadius: 4, background: '#ccc' }}
                                                    preview={false}
                                                    width={35}
                                                    height={35}
                                                    src={record.exerciseSkillInfoList[2].imgUrl}
                                                    fallback={require('@/assets/images/video-file.png')}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ),
                            expandedRowKeys: expandedRowKeys,
                            expandedRowClassName: () => 'r-expandedRowClassName',
                            expandIconColumnIndex: -1,
                        }}
                    />
                    <Pagination total={totalRecord} pageChange={handlePageChange} pageNo={state.pageNo} />
                </>
            ) : (
                <></>
            )}

            <Modal
                className="r-modal modal_no_padding"
                maskClosable={false}
                centered={true}
                open={modalVisibile}
                onCancel={() => setModalVisibile(false)}
                footer={''}
                title={''}
                closable={false}
            >
                <div className="modal_form">
                    <p className="del_tip">
                        Are you sure you want to delete this {type === 'SetIntros' ? 'Intros' : 'exercise'}?
                    </p>
                    <div className="r-center" style={{ marginTop: 50 }}>
                        <Button
                            className="r-defaultBlick_btn"
                            style={{ flex: 1 }}
                            onClick={() => setModalVisibile(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="r-primary_btn"
                            type="primary"
                            style={{ flex: 1, marginLeft: 15 }}
                            loading={opeationInfo.loading}
                            onClick={() => confirmDel()}
                        >
                            Confirm
                        </Button>
                    </div>
                </div>
            </Modal>
            <Modal
                className="r-modal modal_no_padding"
                maskClosable={false}
                centered={true}
                open={alertModalVisibile}
                onCancel={() => setAlertModalVisibile(false)}
                footer={''}
                title={''}
                closable={false}
            >
                <div className="modal_form">
                    <p className="del_tip">
                        {type === 'SetIntros'
                            ? 'This Intros has been constructed as a workout and cannot be deleted. Please cancel this Intros from the workout first.'
                            : 'This exercise has been constructed as a workout and cannot be deleted. Please cancel this exercise from the workout first.'}
                    </p>
                    <div className="r-center" style={{ marginTop: 50 }}>
                        <Button
                            className="r-primary_btn"
                            type="primary"
                            style={{ width: 194 }}
                            onClick={() => {
                                setAlertModalVisibile(false);
                                setModalVisibile(false);
                            }}
                        >
                            Okay
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};
export default WorkoutActionLibrary;
