import React, { useState, useEffect } from 'react';
import { Layout, Button, Modal, Spin, Menu, Popover, Space, message, Form, Input } from 'antd';
import type { MenuProps } from 'antd';
import loginApi from '@/api/login';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import NavLogo from '@/assets/images/nav-logo.png';
import './index.scss';
import closeIcon from '@/assets/images/Icons_Cross.png';
import ArrowsUp from '@/assets/images/arrows_up.png';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '@/store/hooks';
import { useAppSelector } from '@/store/hooks';
import { setPassword } from '@/store/userinfoSlice';
import KeepAlive, { useAliveController } from 'react-activation';
import md5 from 'js-md5';
import usePrevious from '@/hook/usePrevious';
import useFireBase from '@/hook/useFairBase';
import JSEncrypt from 'jsencrypt';
const { Header, Content, Sider } = Layout;
const LayoutMain = props => {
    const { clear } = useAliveController();
    const commonplace = useSelector((state: any) => state.commonplace);
    const userInfo = useSelector((state: any) => state.userInfo);
    const location = useLocation();
    useEffect(() => {
        filterRoute();
    }, [location]);
    const filterRoute = () => {
        const currentPath = props.routes.filter(i => {
            return location.pathname.includes(i.path) && i.isShowOnNav;
        });
        if (currentPath[0] && currentPath[0].path) setCurrent(currentPath[0].path);
    };

    const prevLocation: any = usePrevious(location);
    // useEffect(() => {
    //     if (prevLocation) {
    //         console.log('From:', prevLocation.pathname);
    //         console.log('To:', location.pathname);
    //     }
    // }, [location, prevLocation]);
    const { initDataBase, offDataBase } = useFireBase();
    useEffect(() => {
        initDataBase();
    }, []);
    const navigate = useNavigate();
    const [current, setCurrent] = useState('/dashboard');
    const onClick: MenuProps['onClick'] = e => {
        // clear cache component
        clear();
        setCurrent(e.key);
        navigate(e.key);
    };
    const logOut = () => {
        setIsModalOpen(false);
        loginApi.logOut(res => {
            if (res.code === 200) {
                sessionStorage.clear();
                offDataBase();
                navigate('/login');
            }
        });
    };
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = value => {
        setIsModalOpen(true);
    };
    // ------------------------
    const dispatch = useAppDispatch();
    const encrypt = new JSEncrypt();
    const password = useAppSelector(state => state.userInfo.password);
    const [isVerifyOldPassword, setVerifyOldPassword] = useState(false);
    const [updatePasswordForm] = Form.useForm();
    const currentPassword = Form.useWatch('currentPassword', updatePasswordForm);
    const newPassword = Form.useWatch('newPassword', updatePasswordForm);
    const reEnterPassword = Form.useWatch('reEnterPassword', updatePasswordForm);
    const onFinish = (values: any) => {
        console.log('Success:', values);
        if (isVerifyOldPassword) {
            // save new password
            if (values.newPassword === values.reEnterPassword) {
                if (values.newPassword === password) {
                    // new password === old password
                    message.warning('The new password cannot be the same as the current password');
                } else {
                    loginApi.getSecret(res => {
                        const { code, metaData } = res;
                        if (code === 200) {
                            encrypt.setPublicKey(metaData);
                            loginApi.updatePassword({ password: encrypt.encrypt(values.newPassword) }, res => {
                                if (res.code === 200) {
                                    dispatch(setPassword(values.newPassword));
                                    message.success(res.message);
                                    updatePasswordHandleCancel();
                                } else {
                                    message.error(res.message);
                                }
                            });
                        } else {
                            message.error(res.message);
                        }
                    });
                }
            } else {
                message.warning('The password you have entered does not match. Please try again.');
                updatePasswordForm.resetFields();
            }
        } else {
            // Verify old password
            if (values.currentPassword === password) {
                setVerifyOldPassword(true);
            } else {
                message.warning('The current password input is incorrect');
                updatePasswordForm.resetFields();
            }
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        message.error(errorInfo.errorFields[0].errors[0]);
        console.log('Failed:', errorInfo);
    };
    const onReset = () => {
        updatePasswordForm.resetFields();
    };
    const [isUpdatePasswordModalOpen, updatePasswordModal] = useState(false);
    const updatePasswordHandleCancel = () => {
        onReset();
        setVerifyOldPassword(false);
        updatePasswordModal(false);
    };
    // ------------------------------------------------------
    const [popoverOpen, setPopoverOpen] = useState(false);
    const handleOpenChange = (newOpen: boolean) => {
        setPopoverOpen(newOpen);
    };
    return (
        <>
            <Spin spinning={commonplace.loading} tip={commonplace.text} wrapperClassName={commonplace.class}>
                <Layout className="layout-container">
                    <Header className="header">
                        <div className="logo">
                            <img src={NavLogo} alt="" />
                        </div>
                        <Menu
                            className="menu_items"
                            onClick={onClick}
                            selectedKeys={[current]}
                            mode="horizontal"
                            items={props.routes.map(i => {
                                return i.isShowOnNav
                                    ? {
                                          label: i.name,
                                          key: i.path,
                                      }
                                    : '';
                            })}
                        />
                        <div className="user_module">
                            <Popover
                                placement="bottomRight"
                                arrow={false}
                                content={
                                    <div className="user_popover">
                                        <div className="user_email">{userInfo.email}</div>
                                        <div
                                            className="update_password"
                                            onClick={() => {
                                                updatePasswordModal(true);
                                            }}
                                        >
                                            Update Password
                                        </div>
                                        <div className="log_out" onClick={showModal}>
                                            Log Out
                                        </div>
                                    </div>
                                }
                                trigger="click"
                                onOpenChange={handleOpenChange}
                            >
                                <Space size={15}>
                                    <div className="user_avatar">A</div>
                                    <img
                                        className={
                                            popoverOpen ? 'arrowTransformReturn ArrowsUp' : 'arrowTransform ArrowsUp'
                                        }
                                        src={ArrowsUp}
                                        alt=""
                                    />
                                </Space>
                            </Popover>
                        </div>
                    </Header>
                    <Layout className="layout-bottom">
                        <Layout style={{ padding: '0 0 0 0' }}>
                            <Content
                                style={{
                                    margin: 0,
                                    minHeight: 280,
                                }}
                            >
                                {/* content */}
                                <Routes>
                                    {props.routes.map((route: any, index: any) => {
                                        return route.KeepAlive ? (
                                            <Route
                                                path={route.path}
                                                key={index}
                                                element={
                                                    <KeepAlive
                                                        cacheKey={route.path}
                                                        saveScrollPosition="screen"
                                                        name={route.name}
                                                    >
                                                        <route.component
                                                            prevLocation={prevLocation}
                                                            routes={route.childrens ? route.childrens : []}
                                                        />
                                                    </KeepAlive>
                                                }
                                            ></Route>
                                        ) : (
                                            <Route
                                                path={route.path}
                                                key={index}
                                                element={
                                                    <route.component
                                                        prevLocation={prevLocation}
                                                        routes={route.childrens ? route.childrens : []}
                                                    />
                                                }
                                            ></Route>
                                        );
                                    })}
                                </Routes>
                            </Content>
                        </Layout>
                    </Layout>
                </Layout>
                <Modal
                    className="modal-suspend modal-logout"
                    maskClosable={false}
                    closable={false}
                    centered={true}
                    open={isModalOpen}
                    footer={[]}
                >
                    <div className="modal-tip">Do you want to log out of the current account?</div>
                    <Space size={12} style={{ marginTop: 43 }}>
                        <Button
                            onClick={() => {
                                setIsModalOpen(false);
                            }}
                            style={{ width: 194, height: 54 }}
                        >
                            Cancel
                        </Button>
                        <Button
                            danger
                            type="primary"
                            htmlType="submit"
                            onClick={logOut}
                            style={{ width: 194, height: 54 }}
                        >
                            Confirm logout
                        </Button>
                    </Space>
                </Modal>
            </Spin>
            <Modal
                className="update_password_modal modal_no_padding modal_form_w400"
                maskClosable={false}
                centered={true}
                open={isUpdatePasswordModalOpen}
                closeIcon={<img src={closeIcon} alt="" className="close-icon" />}
                onCancel={updatePasswordHandleCancel}
                footer={[<div className="flex-center"></div>]}
                title={[<div className="modal-top-title">Update Password </div>]}
            >
                <Form
                    name="update_password_form"
                    className="update_password_form"
                    form={updatePasswordForm}
                    layout="vertical"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    validateTrigger="onBlur"
                    autoComplete="off"
                >
                    {isVerifyOldPassword ? (
                        <>
                            <Form.Item
                                label="Create New Password"
                                name="newPassword"
                                rules={[
                                    { required: true, message: 'Please type your new password!' },
                                    { min: 6, max: 8, message: 'Please create a password of 6 - 8 characters' },
                                ]}
                            >
                                <Input.Password className="default_input" maxLength={8} />
                            </Form.Item>
                            <Form.Item
                                label="Re-type New Password"
                                name="reEnterPassword"
                                rules={[
                                    { required: true, message: 'Please type your new password!' },
                                    { min: 6, max: 8, message: 'Please create a password of 6 - 8 characters' },
                                ]}
                            >
                                <Input.Password className="default_input" maxLength={8} />
                            </Form.Item>
                        </>
                    ) : (
                        <>
                            <Form.Item
                                label="Enter Current Password"
                                name="currentPassword"
                                rules={[
                                    { required: true, message: 'Please type your password!' },
                                    { min: 6, max: 8, message: 'Password must be between 6 and 8' },
                                ]}
                            >
                                <Input.Password className="default_input" maxLength={8} />
                            </Form.Item>
                        </>
                    )}
                    <Form.Item>
                        <Space size={12} style={{ marginTop: 40 }}>
                            <Button className="submit-btn" onClick={updatePasswordHandleCancel}>
                                Cancel
                            </Button>
                            <Button
                                className="submit-btn"
                                type="primary"
                                htmlType="submit"
                                disabled={isVerifyOldPassword ? !newPassword || !reEnterPassword : !currentPassword}
                            >
                                {isVerifyOldPassword ? 'Confirm' : 'Next'}
                            </Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default LayoutMain;
