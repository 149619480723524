import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input, Button } from 'antd';
import './style/index.scss';
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import Tailor from '@/components/tailor/tailor';
import uploadApi from '@/api/upload';
import brandApi from '@/api/brand';
import { useAliveController } from 'react-activation';
const CreateBrand = (props: any) => {
    const navigate = useNavigate();
    useEffect(() => {
        const info = JSON.parse(window.sessionStorage.getItem('brandInfo') ?? '{}');
        if (info.brandId) {
            setState({
                brandId: info.brandId,
                brandName: info.businessName,
                brandUrl: info.businessWebsite,
            });
            setAvatar({
                url: info.businessLogo && info.businessLogo !== 'null' ? info.businessLogo : '',
                key:
                    info.businessLogo && info.businessLogo !== 'null'
                        ? window.format.getFilePathName(info.businessLogo)
                        : '',
                base64: '',
                file: null,
                uploading: false,
                dialogVisible: false,
                warncontent: '',
            });
        }
    }, []);
    const [state, setState] = useState({
        brandId: '',
        brandName: '',
        brandUrl: '',
    });
    const { drop } = useAliveController();
    const back = () => {
        window.sessionStorage.removeItem('brandInfo');
        navigate('/brand');
        // navigate('/brand', { state: { from: 'brandInfo' } });
    };
    const [avatar, setAvatar] = useState({
        url: '',
        key: '',
        base64: '',
        file: null,
        uploading: false,
        dialogVisible: false,
        warncontent: '',
    }) as any;
    const handleFileChange = (e: any) => {
        if (!e) {
            return;
        }
        const file = e.target.files[0].name.split('.');
        if (file[file.length - 1].toLowerCase() === 'png' || file[file.length - 1].toLowerCase() === 'jpg') {
            if (e.target.files[0].size > 10 * 1048576) {
                window.format.alert('error', 'Pictures cannot exceed 10MB');
                (document.getElementById('imgInput') as any).value = null;
                return;
            }
            const reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            reader.onload = function (ev: any) {
                setAvatar(
                    Object.assign({}, avatar, {
                        file: ev.target.result,
                        dialogVisible: true,
                    })
                );
                (document.getElementById('imgInput') as any).value = null;
            };
        } else {
            window.format.alert('error', 'File format error');
            (document.getElementById('imgInput') as any).value = null;
        }
    };
    const getResult = (e: any) => {
        setAvatar(
            Object.assign({}, avatar, {
                url: '',
                base64: '',
                dialogVisible: false,
                file: null,
                uploading: true,
            })
        );
        const param = new FormData();
        param.append('file', e);
        const reader = new FileReader();
        reader.readAsDataURL(e);
        reader.onload = function (ev: any) {
            const dataURL = ev.target.result;
            uploadApi.uploadFile(param, (res: any) => {
                if (!res) {
                    return;
                }
                if (res.code === 200) {
                    setAvatar(
                        Object.assign({}, avatar, {
                            url: res.data,
                            key: window.format.getFilePathName(res.data),
                            base64: dataURL,
                            dialogVisible: false,
                            file: null,
                            uploading: false,
                        })
                    );
                } else {
                    setAvatar({
                        url: '',
                        key: '',
                        base64: '', //
                        file: null,
                        uploading: false,
                        dialogVisible: false,
                        warncontent: '',
                    });
                    window.format.alert('error', res.data.message);
                }
            });
        };
    };
    const stopUplaod = () => {
        setAvatar({
            url: '',
            key: '',
            base64: '',
            file: null,
            uploading: false,
            dialogVisible: false,
        });
    };
    const [isEmpty, setIsEmpty] = useState(true);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        const flag = !state.brandName || !state.brandUrl || !avatar.url;
        setIsEmpty(flag);
    }, [state, avatar]);
    const submit = () => {
        if (!window.format.isWebSite(state.brandUrl)) {
            window.format.alert('error', 'Please enter the correct URL');
            return;
        }
        const params: {
            businessLogo: string;
            businessName: string;
            businessWebsite: string;
            brandId?: string | number;
        } = {
            businessLogo: avatar.key,
            businessName: state.brandName,
            businessWebsite: state.brandUrl,
        };
        setLoading(true);
        if (!state.brandId) {
            brandApi.addBrand(params, res => {
                const { data, code, message } = res;
                setLoading(false);
                if (code === 200) {
                    back();
                } else {
                    window.format.alert('error', message);
                }
            });
        } else {
            params.brandId = state.brandId;
            brandApi.editBrand(params, res => {
                const { data, code, message } = res;
                setLoading(false);
                if (code === 200) {
                    back();
                } else {
                    window.format.alert('error', message);
                }
            });
        }
    };
    return (
        <div className="list_main">
            <div className="top_back r-left">
                <div className="back_box r-left" onClick={back}>
                    <i className="back_icon"></i>
                    Back
                </div>
                <Button className="r-defaultBlick_btn marLeft" style={{ width: 146 }} onClick={back}>
                    Cancel
                </Button>
                <div className="space15"></div>
                <Button
                    className="r-primary_btn"
                    type="primary"
                    style={{ width: 146 }}
                    onClick={submit}
                    disabled={isEmpty}
                    loading={loading}
                >
                    Save
                </Button>
            </div>
            <section className="actionInfo_form">
                <div className="form_tit">{!state.brandId ? 'Add New' : 'Edit'} Brand</div>
                <p className="form_tip" style={{ marginTop: 40 }}>
                    Business logo
                </p>
                <label
                    className="upload_imgBox"
                    htmlFor="imgInput"
                    style={{
                        backgroundImage: `url(${avatar.base64 ? avatar.base64 : avatar.url})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center center',
                    }}
                >
                    {avatar.base64 || avatar.url ? (
                        <></>
                    ) : (
                        <>
                            {!avatar.uploading ? (
                                <PlusOutlined style={{ fontSize: 22, color: '#a4a4a4' }} />
                            ) : (
                                <LoadingOutlined style={{ fontSize: 22 }} />
                            )}
                        </>
                    )}
                    <input type="file" id="imgInput" onChange={e => handleFileChange(e)} accept="image/*" title="" />
                </label>
                <p className="form_tip">Business name</p>
                <Input
                    placeholder=" "
                    className="r-input"
                    maxLength={100}
                    value={state.brandName}
                    onChange={e => {
                        setState({ ...state, brandName: e.target.value });
                    }}
                />
                <p className="form_tip">Business website</p>
                <Input
                    placeholder=" "
                    className="r-input"
                    maxLength={200}
                    value={state.brandUrl}
                    onChange={e => {
                        setState({ ...state, brandUrl: e.target.value });
                    }}
                />
            </section>

            <Tailor
                dialogVisible={avatar.dialogVisible}
                imgFile={avatar.file}
                aspectRatioWidth="343"
                aspectRatioHeight="400"
                getResult={getResult}
                isFile={true}
                closeWindow={stopUplaod}
            />
        </div>
    );
};
export default CreateBrand;
