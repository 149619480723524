import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import type { ColumnsType } from 'antd/es/table';
import { Button, message, Space } from 'antd';
import api from '@/api/settings';
import dayjs from 'dayjs';
import comeBackImg from '@/assets/images/back.png';
import TableBox from '@/components/tableBox';
const Versions = (props: any) => {
    const query = new URLSearchParams(useLocation().search);
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState(Number(query.get('type')) || 1);
    const changeTab = (type: number) => {
        if (activeTab === type) return;
        setActiveTab(type);
    };
    useEffect(() => {
        getversionList();
    }, [activeTab]);
    const [status, setStatus] = useState('showData');
    interface DataType {
        minVersion: string;
        versionDescribed: string;
        currentVersion: string;
        updateTime: string;
    }
    const columns: ColumnsType<DataType> = [
        {
            title: 'VERSION',
            dataIndex: 'currentVersion',
            key: 'currentVersion',
            render: version => 'V ' + version,
        },
        {
            title: 'MINIMUM VERSION',
            dataIndex: 'minVersion',
            key: 'minVersion',
            render: version => 'V ' + version,
        },
        {
            title: 'UPLOAD TIME',
            dataIndex: 'updateTime',
            key: 'updateTime',
            render: time => dayjs(Number(time)).format('DD/MM/YYYY HH:mm'),
        },
        {
            title: 'UPLOAD DETAILS',
            dataIndex: 'versionDescribed',
            key: 'versionDescribed',
        },
    ];
    const [tableData, setTableData] = useState<DataType[]>([]);
    function getversionList() {
        setStatus('loading');
        api.getVersionList(
            {
                deviceType: activeTab,
                sortColumn: 'createTime',
                sortAsc: false,
                pageSize: -1,
                pageNum: 1,
            },
            res => {
                const { code, data } = res;
                if (code === 200) {
                    if (data.records.length > 0) {
                        setStatus('showData');
                        setTableData(data.records);
                    } else {
                        setStatus('empty');
                    }
                } else {
                    setStatus('empty');
                    message.error(res.message);
                }
            }
        );
    }
    return (
        <div className="page-main versions">
            <div className="flex-between" style={{ margin: '57px 0 40px' }}>
                <div
                    className="come_back"
                    onClick={() => {
                        navigate('/setting');
                    }}
                >
                    <img src={comeBackImg} alt="" />
                    Back
                </div>
                <Button
                    icon={<></>}
                    style={{
                        width: 220,
                        height: 48,
                        borderRadius: '4px',
                        marginLeft: 15,
                        color: '#0085FF',
                        borderColor: '#0085FF',
                    }}
                    onClick={() => {
                        navigate('/setting/uploadVersion');
                    }}
                >
                    Upload version details
                </Button>
            </div>
            <div className="select_tabs">
                <Space wrap size={10}>
                    <div
                        className={activeTab === 1 ? 'select_tabs_item tab_active' : 'select_tabs_item'}
                        onClick={() => {
                            changeTab(1);
                        }}
                    >
                        IOS App
                    </div>
                    <div
                        className={activeTab === 2 ? 'select_tabs_item tab_active' : 'select_tabs_item'}
                        onClick={() => {
                            changeTab(2);
                        }}
                    >
                        Android App
                    </div>
                </Space>
            </div>
            <TableBox status={status} columns={columns} data={tableData} />
        </div>
    );
};

export default Versions;
