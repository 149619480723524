import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Row, Col, Space, Avatar, Image, Modal, Collapse, message, Spin } from 'antd';
import comeBackImg from '@/assets/images/back.png';
import ArrowsUp from '@/assets/images/down.png';
import reactions1 from '@/assets/images/Group25.png';
import reactions2 from '@/assets/images/Group.png';
import reactions3 from '@/assets/images/Group23.png';
import reactions4 from '@/assets/images/Group24.png';
import { useEffect, useState } from 'react';
import SocialsApi from '@/api/socials';
import dayjs from 'dayjs';
import { useAppDispatch } from '@/store/hooks';
import { setCurrentUserId, setIsGetList } from '@/store/stateSlice';
import { useAliveController } from 'react-activation';
import { async } from '@firebase/util';
interface postDataTypes {
    fullName: string;
    avatarUrl: string;
    postContent: string;
    postId: string;
    createTime: number;
    personallyVisible: boolean;
    countComment: string;
    countLike: string;
    userId: string;
    postFile: string[];
    postWorkoutList: {
        workoutName: string;
        workoutLocation: string;
        workoutId: string;
    }[];
    heartUserListVOS: {
        avatarUrl: string;
        fullName: string;
    }[];
    fireUserListVOS: {
        avatarUrl: string;
        fullName: string;
    }[];
    fistUserListVOS: {
        avatarUrl: string;
        fullName: string;
    }[];
    armUserListVOS: {
        avatarUrl: string;
        fullName: string;
    }[];
}
const PostDetail = (props: any) => {
    const dispatch = useAppDispatch();
    const query = new URLSearchParams(useLocation().search);
    useEffect(() => {
        if (query.get('identity')) {
            getPostDetail();
        }
    }, []);
    const navigate = useNavigate();
    const [viewMore, setViewMore] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { Panel } = Collapse;
    const [postData, setPostData] = useState<postDataTypes>({
        postContent: '',
        fullName: '',
        avatarUrl: '',
        postId: '',
        createTime: 0,
        personallyVisible: false,
        countComment: '',
        countLike: '',
        userId: '',
        postFile: [],
        postWorkoutList: [],
        fireUserListVOS: [],
        heartUserListVOS: [],
        fistUserListVOS: [],
        armUserListVOS: [],
    });
    const getPostDetail = () => {
        SocialsApi.getPostDetail({ postId: query.get('identity') }, res => {
            const { code, data } = res;
            if (code === 200) {
                setPostData(data);
            } else {
                message.error(res.message);
            }
            setPageLoading(false);
        });
    };
    const [videoModalVisibile, setVideoModalVisibile] = useState(false);
    const [videoInfo, setVideoInfo] = useState({
        label: '',
        url: '',
        isVideo: false,
    });
    const openVideo = val => {
        setVideoInfo(val);
        setVideoModalVisibile(true);
    };
    const removePost = () => {
        SocialsApi.deletePost({ postId: postData.postId }, res => {
            if (res.code === 200) {
                setIsModalOpen(false);
                if (query.get('from')) {
                    navigate(query.get('from') || '');
                } else {
                    navigate('/socials');
                }
                dispatch(setIsGetList(true));
                message.success(res.message);
            } else {
                message.error(res.message);
            }
        });
    };
    const { drop } = useAliveController();
    const [PageLoading, setPageLoading] = useState(true);
    return (
        <>
            {PageLoading ? (
                <div className="data_loading flex-center" style={{ height: 500 }}>
                    <Spin />
                </div>
            ) : (
                <div className="page-main post_detail">
                    <div className="flex-between page_head" style={{ margin: '57px 0 40px' }}>
                        <div
                            className="come_back"
                            onClick={() => {
                                if (query.get('from')) {
                                    navigate(query.get('from') || '');
                                } else {
                                    drop('PostDetail');
                                    navigate('/socials');
                                }
                            }}
                        >
                            <img src={comeBackImg} alt="" />
                            Back
                        </div>
                        <Button
                            style={{ width: 146, height: 48 }}
                            onClick={() => {
                                setIsModalOpen(true);
                            }}
                        >
                            Remove
                        </Button>
                    </div>
                    <Row gutter={20}>
                        <Col span={12}>
                            <div className="post_user">
                                <div className="post_card_label" style={{ marginBottom: 10 }}>
                                    POSTER NAME :
                                </div>
                                <div className="post_user_box flex-left">
                                    <Avatar size={78} src={postData.avatarUrl} />
                                    <div className="post_user_name" style={{ flex: 1 }}>
                                        <div style={{ fontSize: 24 }}>{postData.fullName}</div>
                                        <div
                                            className="to_user_detail"
                                            onClick={async () => {
                                                await drop('UserDetail');
                                                dispatch(setCurrentUserId(postData.userId));
                                                navigate(
                                                    '/user/detail?from=/socials/post?identity=' + query.get('identity')
                                                );
                                            }}
                                        >
                                            User Profile
                                        </div>
                                    </div>
                                </div>
                                <div className="post_card_label" style={{ marginBottom: 10 }}>
                                    POSTER ID :<span className="post_card_value">{postData.postId}</span>
                                </div>
                                <div className="post_card_label" style={{ marginBottom: 10 }}>
                                    DATE AND TIME PUBLISHED :
                                    <span className="post_card_value">
                                        {dayjs(Number(postData.createTime)).format('DD/MM/YYYY HH:mm')}
                                    </span>
                                </div>
                                <div className="post_card_label" style={{ marginBottom: 10 }}>
                                    WHO CAN VIEW :
                                    <span className="post_card_value">
                                        {postData.personallyVisible ? 'Only me' : 'Followers'}
                                    </span>
                                </div>
                            </div>
                        </Col>
                        <Col span={12}>
                            {postData.postWorkoutList && postData.postWorkoutList.length > 0 ? (
                                <div className="post_workout">
                                    {postData.postWorkoutList.map((workout, workoutIndex) => {
                                        return (
                                            <div key={workout.workoutId}>
                                                <div className="post_card_label" style={{ marginBottom: 10 }}>
                                                    WORKOUT NAME {workoutIndex + 1} :
                                                    <span className="post_card_value">{workout.workoutName}</span>
                                                </div>
                                                <div className="post_card_label" style={{ marginBottom: 10 }}>
                                                    LOCATION {workoutIndex + 1} :
                                                    <span className="post_card_value">{workout.workoutLocation}</span>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            ) : (
                                ''
                            )}
                        </Col>
                    </Row>
                    <div className="post_content_card">
                        <div className="post_card_label">CONTENT : </div>
                        <div className="post_card_value" style={{ marginLeft: 0, marginBottom: 30 }}>
                            {postData.postContent}
                        </div>
                        <div className="post_card_label">PHOTOS/VIDEOS : </div>
                        <div>
                            <Image.PreviewGroup>
                                <Space size={8}>
                                    {postData.postFile.map((url, index) => {
                                        const searchParams = new URLSearchParams(url.split('?')[1]);
                                        return searchParams.get('X-NT-ContentType')?.split('/')[0] === 'video' ? (
                                            <div className="workout_video_box">
                                                <i className="play_icon"></i>
                                                <video
                                                    style={{ width: 120, height: 120, cursor: 'pointer' }}
                                                    poster={searchParams.get('X-NT-Thumbnail') || ''}
                                                    onClick={() => {
                                                        openVideo({ url, isVideo: true });
                                                    }}
                                                >
                                                    <source src={url} />
                                                </video>
                                            </div>
                                        ) : (
                                            <Image
                                                key={index + 'url'}
                                                width={120}
                                                height={120}
                                                src={url}
                                                // onClick={() => {
                                                //     openVideo({ url, isVideo: false });
                                                // }}
                                            />
                                        );
                                    })}
                                </Space>
                            </Image.PreviewGroup>
                        </div>
                    </div>
                    <div className="post_reactions_card">
                        <Collapse ghost>
                            <Panel
                                header={
                                    <div className="flex-between">
                                        <div className="post_card_label">
                                            REACTIONS :<span className="post_card_value">{postData.countLike}</span>
                                        </div>
                                        {Number(postData.countLike) ? (
                                            <Space size={15}>
                                                <div
                                                    className="view_more"
                                                    onClick={() => {
                                                        setViewMore(!viewMore);
                                                    }}
                                                >
                                                    view
                                                </div>
                                                <img
                                                    className={
                                                        viewMore
                                                            ? 'arrowTransform ArrowsUp'
                                                            : 'arrowTransformReturn ArrowsUp'
                                                    }
                                                    src={ArrowsUp}
                                                    alt=""
                                                />
                                            </Space>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                }
                                key="1"
                                style={{ border: 'none', padding: 0 }}
                                showArrow={false}
                            >
                                <div className="reactions_types">
                                    {postData.fireUserListVOS.length > 0 ? (
                                        <div className="reactions_type">
                                            <div style={{ marginRight: 32 }}>
                                                <img
                                                    style={{ width: 20, height: 20, marginTop: 6 }}
                                                    src={reactions1}
                                                    alt=""
                                                />
                                            </div>
                                            <div className="flex-warp">
                                                {postData.fireUserListVOS.map(i => {
                                                    return (
                                                        <div className="reactions_all" key={`reactions_all${i}`}>
                                                            <Avatar size={24} src={i.avatarUrl} />
                                                            <span className="response_person">{i.fullName}</span>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                    {postData.heartUserListVOS.length > 0 ? (
                                        <div className="reactions_type">
                                            <div style={{ marginRight: 32 }}>
                                                <img
                                                    style={{ width: 20, height: 20, marginTop: 6 }}
                                                    src={reactions3}
                                                    alt=""
                                                />
                                            </div>
                                            <div className="flex-warp">
                                                {postData.heartUserListVOS &&
                                                    postData.heartUserListVOS.map(i => {
                                                        return (
                                                            <div className="reactions_all" key={`reactions_all${i}3`}>
                                                                <Avatar size={24} src={i.avatarUrl} />
                                                                <span className="response_person">{i.fullName}</span>
                                                            </div>
                                                        );
                                                    })}
                                            </div>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                    {postData.fistUserListVOS.length > 0 ? (
                                        <div className="reactions_type">
                                            <div style={{ marginRight: 32 }}>
                                                <img
                                                    style={{ width: 20, height: 20, marginTop: 6 }}
                                                    src={reactions2}
                                                    alt=""
                                                />
                                            </div>
                                            <div className="flex-warp">
                                                {postData.fistUserListVOS.map(i => {
                                                    return (
                                                        <div className="reactions_all" key={`reactions_all${i}2`}>
                                                            <Avatar size={24} src={i.avatarUrl} />
                                                            <span className="response_person">{i.fullName}</span>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                    {postData.armUserListVOS.length > 0 ? (
                                        <div className="reactions_type">
                                            <div style={{ marginRight: 32 }}>
                                                <img
                                                    style={{ width: 20, height: 20, marginTop: 6 }}
                                                    src={reactions4}
                                                    alt=""
                                                />
                                            </div>
                                            <div className="flex-warp">
                                                {postData.armUserListVOS.map(i => {
                                                    return (
                                                        <div className="reactions_all" key={`reactions_all${i}`}>
                                                            <Avatar size={24} src={i.avatarUrl} />
                                                            <span className="response_person">{i.fullName}</span>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </Panel>
                        </Collapse>
                        {/* {viewMore ? (
                    
                ) : (
                    ''
                )} */}
                    </div>
                    <div className="post_comments_card flex-between">
                        <div className="post_card_label">
                            COMMENTS :<span className="post_card_value">{postData.countComment}</span>
                        </div>
                        {Number(postData.countComment) ? (
                            <div
                                className="view_more"
                                style={{ marginRight: 32 }}
                                onClick={() => {
                                    navigate(`/socials/comments?identity=${postData.postId}`);
                                }}
                            >
                                view
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                    <Modal
                        className="modal-suspend"
                        closable={false}
                        maskClosable={false}
                        centered={true}
                        open={isModalOpen}
                        onCancel={() => {
                            setIsModalOpen(false);
                        }}
                        footer={[]}
                    >
                        <div className="modal-tip">Are you sure you wish to remove this Post?</div>
                        <Space size={12} style={{ marginTop: 43 }}>
                            <Button
                                onClick={() => {
                                    setIsModalOpen(false);
                                }}
                                style={{ width: 194, height: 54 }}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="primary"
                                htmlType="submit"
                                onClick={removePost}
                                style={{ width: 194, height: 54 }}
                            >
                                Confirm
                            </Button>
                        </Space>
                    </Modal>
                    <Modal
                        className="r-modal modal_no_padding"
                        maskClosable={false}
                        width={600}
                        centered={true}
                        open={videoModalVisibile}
                        onCancel={() => setVideoModalVisibile(false)}
                        footer={''}
                        title={''}
                        closable={false}
                    >
                        <div className="modal_form_vidoe">
                            <div style={{ maxHeight: 500 }}>
                                {videoInfo.isVideo ? (
                                    <video
                                        className="info_video"
                                        muted
                                        autoPlay
                                        controls
                                        loop
                                        src={videoInfo.url}
                                    ></video>
                                ) : (
                                    <img src={videoInfo.url} className="info_video img_info" alt="" />
                                )}
                            </div>
                            <div className="r-center" style={{ marginTop: 50 }}>
                                <Button
                                    className="r-primary_btn"
                                    type="primary"
                                    style={{ width: 194 }}
                                    onClick={() => {
                                        setVideoModalVisibile(false);
                                    }}
                                >
                                    Colse
                                </Button>
                            </div>
                        </div>
                    </Modal>
                </div>
            )}
        </>
    );
};
export default PostDetail;
