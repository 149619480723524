import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input, Select, Button, Modal, Switch, DatePicker } from 'antd';
import './style/index.scss';
import closeIcon from '@/assets/images/Icons_Cross.png';
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import Tailor from '@/components/tailor/tailor';
import type { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import uploadApi from '@/api/upload';
import rewardsApi from '@/api/rewards';
import { defaultInfo } from '@/typings/allType';

dayjs.extend(customParseFormat);

// eslint-disable-next-line arrow-body-style
const disabledDate: RangePickerProps['disabledDate'] = current => {
    // Can not select days before today and today
    return current && current < dayjs().endOf('day').add(-1, 'day');
};
const WorkoutReward = (props: any) => {
    const navigate = useNavigate();
    const { RangePicker } = DatePicker;
    useEffect(() => {
        const info = JSON.parse(window.sessionStorage.getItem('workoutRewardInfo') ?? '{}');
        if (info.workoutRewardsId) {
            setState({
                ...info,
                name: info.rewardsName,
                redemptionCriteria: !info.redemptionCriteria ? 0 : 1, // 0 time 1 number
                redemptionTypes: !info.redemptionTypes ? 0 : 1,
                expiryStatus: !info.expiryStatus,
                expiryDate: !info.expiryStatus && info.expiryDate ? dayjs(+info.expiryDate) : '',
                redeemUrl: info.redeemUrl,
            });
            setAvatar({
                url: info.rewardsImage ?? '',
                key: info.rewardsImage ? window.format.getFilePathName(info.rewardsImage) : '',
                base64: '',
                file: null,
                uploading: false,
                dialogVisible: false,
                warncontent: '',
            });
        }
    }, []);
    const [state, setState] = useState({
        workoutRewardsId: '',
        name: '',
        redemptionCriteria: 0, // 0 time 1 number
        quantity: '',
        expiryStatus: false,
        expiryDate: '' as any,
        redeemUrl: '',
    });
    const handleChange = val => {
        setState({ ...state, redemptionCriteria: Number(val), quantity: '' });
    };
    const back = () => {
        navigate(-1);
    };
    const submit = () => {
        if (!window.format.isWebSite(state.redeemUrl)) {
            window.format.alert('error', 'Please enter the correct URL');
            return;
        }
        const params: any = {
            quantity: state.quantity,
            redemptionCriteria: state.redemptionCriteria,
            rewardsName: state.name,
            redeemUrl: state.redeemUrl,
            rewardsImage: avatar.key,
            expiryStatus: state.expiryStatus ? 0 : 1,
            expiryDate: state.expiryDate ? Date.parse(new Date(state.expiryDate) as any) : '',
        };
        setLoading(true);
        if (!state.workoutRewardsId) {
            rewardsApi.addWorkoutReward(params, res => callBack(res));
            return;
        }
        params.workoutRewardsId = state.workoutRewardsId;
        rewardsApi.editWorkoutReward(params, res => callBack(res));
    };
    const callBack = (res: defaultInfo) => {
        const { data, code, message } = res;
        setLoading(false);
        if (code === 200) {
            back();
        } else {
            window.format.alert('error', message);
        }
    };
    const [avatar, setAvatar] = useState({
        url: '',
        key: '',
        base64: '',
        file: null,
        uploading: false,
        dialogVisible: false,
        warncontent: '',
    }) as any;
    const handleFileChange = (e: any) => {
        if (!e) {
            return;
        }
        const file = e.target.files[0].name.split('.');
        if (file[file.length - 1].toLowerCase() === 'png' || file[file.length - 1].toLowerCase() === 'jpg') {
            if (e.target.files[0].size > 10 * 1048576) {
                window.format.alert('error', 'Pictures cannot exceed 10MB');
                (document.getElementById('imgInput') as any).value = null;
                return;
            }
            const reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            reader.onload = function (ev: any) {
                setAvatar(
                    Object.assign({}, avatar, {
                        file: ev.target.result,
                        dialogVisible: true,
                    })
                );
                (document.getElementById('imgInput') as any).value = null;
            };
        } else {
            window.format.alert('error', 'File format error');
            (document.getElementById('imgInput') as any).value = null;
        }
    };
    const getResult = (e: any) => {
        setAvatar(
            Object.assign({}, avatar, {
                url: '',
                base64: '',
                dialogVisible: false,
                file: null,
                uploading: true,
            })
        );
        const param = new FormData();
        param.append('file', e);
        const reader = new FileReader();
        reader.readAsDataURL(e);
        reader.onload = function (ev: any) {
            const dataURL = ev.target.result;
            uploadApi.uploadFile(param, (res: any) => {
                if (!res) {
                    return;
                }
                if (res.code === 200) {
                    setAvatar(
                        Object.assign({}, avatar, {
                            url: res.data,
                            key: window.format.getFilePathName(res.data),
                            base64: dataURL,
                            dialogVisible: false,
                            file: null,
                            uploading: false,
                        })
                    );
                } else {
                    setAvatar({
                        url: '',
                        key: '',
                        base64: '', //
                        file: null,
                        uploading: false,
                        dialogVisible: false,
                        warncontent: '',
                    });
                    window.format.alert('error', res.data.message);
                }
            });
        };
    };
    const stopUplaod = () => {
        setAvatar({
            url: '',
            key: '',
            base64: '',
            file: null,
            uploading: false,
            dialogVisible: false,
        });
    };
    const [isEmpty, setIsEmpty] = useState(true);
    useEffect(() => {
        const flag =
            !state.name ||
            !state.redeemUrl ||
            !avatar.url ||
            (state.redemptionCriteria && !state.quantity) ||
            (state.expiryStatus && !state.expiryDate);
        setIsEmpty(flag);
    }, [state, avatar]);
    const [loading, setLoading] = useState(false);
    return (
        <div className="add-reward-pool_main list_main">
            <div className="top_back r-left">
                <div className="back_box r-left" onClick={back}>
                    <i className="back_icon"></i>
                    Back
                </div>
                <Button className="r-defaultBlick_btn marLeft" style={{ width: 146 }} onClick={back}>
                    Cancel
                </Button>
                <div className="space15"></div>
                <Button
                    className="r-primary_btn"
                    type="primary"
                    loading={loading}
                    disabled={isEmpty}
                    style={{ width: 146 }}
                    onClick={submit}
                >
                    Save
                </Button>
            </div>
            <section className="actionInfo_form">
                <div className="form_tit">{state.workoutRewardsId ? 'Edit' : 'Add New'} Workout Rewards</div>
                <p className="form_tip">Rewards image</p>
                <label
                    className="upload_imgBox"
                    htmlFor="imgInput"
                    style={{
                        backgroundImage: `url(${avatar.base64 ? avatar.base64 : avatar.url})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center center',
                    }}
                >
                    {avatar.base64 || avatar.url ? (
                        <></>
                    ) : (
                        <>
                            {!avatar.uploading ? (
                                <PlusOutlined style={{ fontSize: 22, color: '#a4a4a4' }} />
                            ) : (
                                <LoadingOutlined style={{ fontSize: 22 }} />
                            )}
                        </>
                    )}
                    <input type="file" id="imgInput" onChange={e => handleFileChange(e)} accept="image/*" title="" />
                </label>
                <p className="form_tip">Rewards name</p>
                <Input
                    placeholder=""
                    className="r-input"
                    value={state.name}
                    maxLength={100}
                    onChange={e => {
                        setState({ ...state, name: e.target.value });
                    }}
                />
                <div className="r-left">
                    <div style={{ width: 300 }}>
                        <p className="form_tip">Redemption criteria</p>
                        <Select
                            defaultValue={0}
                            value={state.redemptionCriteria}
                            style={{ width: '100%' }}
                            onChange={handleChange}
                            className="r-select"
                            suffixIcon={<i className="sel_arrow_icon"></i>}
                            options={[
                                { key: 0, value: 0, label: 'Time based' },
                                { key: 1, value: 1, label: 'Quantity based' },
                            ]}
                        />
                    </div>
                    <div className="space15"></div>
                    {state.redemptionCriteria === 1 ? (
                        <div style={{ width: 300 }}>
                            <p className="form_tip">Quantity</p>
                            <Input
                                placeholder=""
                                className="r-input"
                                maxLength={10}
                                value={state.quantity}
                                onChange={e => {
                                    e.target.value = e.target.value.replace(/\D/g, '');
                                    setState({ ...state, quantity: e.target.value });
                                }}
                            />
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
                <p className="form_tip">Expiry date</p>
                <div className="r-left">
                    <Switch
                        checked={state.expiryStatus}
                        className="r-switch-mini"
                        onChange={val => {
                            setState({ ...state, expiryStatus: val, expiryDate: '' });
                        }}
                    />
                    <span className="expiry_status">{!state.expiryStatus ? 'Off' : 'On'}</span>
                    {state.expiryStatus ? (
                        <DatePicker
                            style={{ width: 300 }}
                            value={state.expiryDate}
                            onChange={e => setState({ ...state, expiryDate: e })}
                            className="r-datePicker"
                            format="DD/MM/YYYY HH:mm"
                            suffixIcon=""
                            disabledDate={disabledDate}
                            placeholder="dd/mm/yy HH:MM"
                            showTime={{ defaultValue: dayjs('00:00', 'HH:mm') }}
                        />
                    ) : (
                        ''
                    )}
                </div>
                <p className="form_tip">Redeem URL</p>
                <Input
                    placeholder="Please enter the redeem url "
                    className="r-input"
                    value={state.redeemUrl}
                    onChange={e => {
                        setState({ ...state, redeemUrl: e.target.value });
                    }}
                />
            </section>

            <Tailor
                dialogVisible={avatar.dialogVisible}
                imgFile={avatar.file}
                aspectRatioWidth="343"
                aspectRatioHeight="400"
                getResult={getResult}
                isFile={true}
                closeWindow={stopUplaod}
            />
        </div>
    );
};
export default WorkoutReward;
