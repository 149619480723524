import axios from '../utils/index';
import { Pat } from '@/typings/request';
const homeApi = {
    getCountry(callback: (T: any) => void) {
        axios
            .get(`/admin/common/country`)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getState(params: Pat, callback: (T: any) => void) {
        axios
            .get(`/admin/common/state`, { params })
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getWorkoutRangking(params: Pat, callback: (T: any) => void) {
        axios
            .get(`/admin/dashboard/workout-list`, {
                params,
            })
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getPointsRangking(params: Pat, callback: (T: any) => void) {
        axios
            .get(`/admin/dashboard/points-list`, {
                params,
            })
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
};
export default homeApi;
