import geoLogo from '@/assets/images/share/geonasium_logo.png';
import downloadToGoogle from '@/assets/images/share/to_googleplay.png';
import downloadToAppstore from '@/assets/images/share/to_appstore.png';
import './index.scss';
const DownLoadApp = (props: any) => {
    return (
        <div className="geo_download_page">
            <div style={{ width: 223 }}>
                <div className="geo_download_logo">
                    <img src={geoLogo} alt="" />
                </div>
                <div className="to_appstore">
                    <img src={downloadToAppstore} alt="" />
                </div>
                <div className="to_google">
                    <img src={downloadToGoogle} alt="" />
                </div>
            </div>
        </div>
    );
};
export default DownLoadApp;
