import axios from '../utils/index';
import { Pat, HttpResponse } from '@/typings/request';
const loginApi = {
    getSecret(callback: (T: any) => void) {
        axios
            .get(`/admin/secret`)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    login(data: Pat, callback: (T: any) => void) {
        axios
            .post(`/admin/login`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    logOut(callback: (T: any) => void) {
        axios
            .post(`/admin/logout`)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    verifyEmail(data: Pat, callback: (T: any) => void) {
        axios
            .post(`/admin/admin-forgotPassword-checkSessionId`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    forgotPassword(data: Pat, callback: (T: any) => void) {
        axios
            .post(`/admin/forgot-password/send-email/${data.email}`)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    updatePassword(data: Pat, callback: (T: any) => void) {
        axios
            .post(`/admin/update-password`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    resetPassword(data: Pat, callback: (T: any) => void) {
        axios
            .post(`/admin/forgot-password/reset-password`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    checkValidatecode(params: Pat, callback: (T: any) => void) {
        axios
            .get(`/admin/public/validate/check`, {
                params,
            })
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
};

export default loginApi;
