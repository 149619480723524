import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input, AutoComplete, Button, Spin } from 'antd';
import './style/index.scss';
import useGoogleMap from '@/hook/useGoogleMap';
import brandApi from '@/api/brand';
const CreateLocation = (props: any) => {
    const navigate = useNavigate();
    const { queryLocationSearchAsync, getLocationDetail, location, geoCode } = useGoogleMap();
    const [status, setStatus] = useState('showData');
    useEffect(() => {
        const info = JSON.parse(window.sessionStorage.getItem('locationInfo') ?? '{}');
        if (info.brandLocationId) {
            setStatus('loading');
            brandApi.getBrrandLocationInfo({ brandLocationId: info.brandLocationId }, res => {
                const { data, code, message } = res;
                setStatus('showData');
                if (code === 200) {
                    setState({
                        ...state,
                        brandLocationId: info.brandLocationId,
                        location: data.location,
                        radiusMeters: data.geofencing,
                        latitude: data.latitudeLongitude.lat,
                        longitude: data.latitudeLongitude.lon,
                    });
                    setValue(data.location);
                } else {
                    window.format.alert('error', message);
                }
            });
        }
    }, []);
    useEffect(() => {
        if (!geoCode.lat || !geoCode.lng) return;
        setState({
            ...state,
            latitude: geoCode.lat !== 0 ? geoCode.lat.toString() : '',
            longitude: geoCode.lng !== 0 ? geoCode.lng.toString() : '',
        });
    }, [geoCode]);
    useEffect(() => {
        if (location.length) {
            const arr = location.map((item: any) => {
                return Object.assign(item, { value: item.description });
            });
            setOptions([...arr]);
        }
    }, [location]);

    const [state, setState] = useState({
        brandLocationId: '',
        location: '',
        latitude: '',
        longitude: '',
        radiusMeters: '100',
    });
    const back = () => {
        navigate(-1);
    };
    const [isEmpty, setIsEmpty] = useState(true);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        const flag = !state.location || !state.latitude || !state.longitude || !state.radiusMeters;
        setIsEmpty(flag);
    }, [state]);
    const submit = () => {
        const params: {
            brandLocationId?: string;
            location: string;
            brandId: string;
            latitudeLongitude: {
                lon: number | string;
                lat: number | string;
            };
            geofencing: number | string;
        } = {
            brandId: window.sessionStorage.getItem('brandId') ?? '',
            location: state.location,
            latitudeLongitude: {
                lon: state.longitude,
                lat: state.latitude,
            },
            geofencing: +state.radiusMeters,
        };
        setLoading(true);
        if (!state.brandLocationId) {
            brandApi.addBrandLocation(params, res => {
                const { data, code, message } = res;
                setLoading(false);
                if (code === 200) {
                    back();
                } else {
                    window.format.alert('error', message);
                }
            });
        } else {
            params.brandLocationId = state.brandLocationId;
            brandApi.editBrandLocation(params, res => {
                const { data, code, message } = res;
                setLoading(false);
                if (code === 200) {
                    back();
                } else {
                    window.format.alert('error', message);
                }
            });
        }
    };
    const [value, setValue] = useState('');
    const [options, setOptions] = useState<{ value: string }[]>([]);
    const getPanelValue = (searchText: string) => {
        if (!searchText) {
            setOptions([]);
        } else {
            queryLocationSearchAsync(searchText);
        }
    };
    const onSelect = (data: string, option: any) => {
        getLocationDetail(option.place_id);
        setState({ ...state, location: data });
        setValue(data);
    };
    const changeInput = (type: string, val: string) => {
        let temp = '';
        if (type !== 'radiusMeters') {
            temp = val
                .replace(/[^-\d.]/g, '')
                .replace(/^\./g, '')
                .replace(/\.{2,}/g, '')
                .replace('.', '$#$')
                .replace(/\./g, '')
                .replace('$#$', '.');
        } else {
            temp = val.replace(/[^\d]/g, '');
            // temp = Number(temp) > 150 ? '150' : Number(temp) < 100 ? '100' : temp;
        }
        setState({ ...state, [type]: temp });
    };
    return (
        <div className="list_main">
            <div className="top_back r-left">
                <div className="back_box r-left" onClick={back}>
                    <i className="back_icon"></i>
                    Back
                </div>
                <Button className="r-defaultBlick_btn marLeft" style={{ width: 146 }} onClick={back}>
                    Cancel
                </Button>
                <div className="space15"></div>
                <Button
                    className="r-primary_btn"
                    type="primary"
                    disabled={isEmpty}
                    loading={loading}
                    style={{ width: 146 }}
                    onClick={submit}
                >
                    Save
                </Button>
            </div>
            {status === 'loading' ? (
                <div className="data_loading flex-center" style={{ height: 'auto' }}>
                    <Spin />
                </div>
            ) : (
                <section className="actionInfo_form">
                    <div className="form_tit">{state.brandLocationId ? 'Edit' : 'Add New'} location</div>
                    <p className="form_tip" style={{ marginTop: 40 }}>
                        Location
                    </p>
                    <AutoComplete
                        value={value}
                        options={options}
                        className="r-input_autoComplate"
                        onSelect={onSelect}
                        onSearch={text => getPanelValue(text)}
                        onChange={e => setValue(e)}
                        maxLength={100}
                        placeholder=""
                    />
                    <div className="r-left" style={{ marginTop: 15 }}>
                        <Input
                            value={state.longitude}
                            placeholder="Longitude"
                            className="r-input"
                            maxLength={100}
                            onChange={e => {
                                changeInput('longitude', e.target.value);
                            }}
                        />
                        <span className="space15"></span>
                        <Input
                            value={state.latitude}
                            placeholder="Latitude"
                            className="r-input"
                            maxLength={100}
                            onChange={e => {
                                changeInput('latitude', e.target.value);
                            }}
                        />
                    </div>
                    <p className="form_tip">Geofencing</p>
                    <div className="r-left" style={{ alignItems: 'flex-end' }}>
                        <Input
                            placeholder=" "
                            value={state.radiusMeters}
                            className="r-input"
                            maxLength={3}
                            style={{ width: 300 }}
                            suffix={<span>metre (s)</span>}
                            onChange={e => {
                                changeInput('radiusMeters', e.target.value);
                            }}
                            onBlur={e => {
                                let temp = e.target.value;
                                temp = Number(temp) > 150 ? '150' : Number(temp) < 100 ? '100' : temp;
                                setState({ ...state, radiusMeters: temp });
                            }}
                        />
                        <span className="gro_tip">（Radius 100 - 150 meters）</span>
                    </div>
                </section>
            )}
        </div>
    );
};
export default CreateLocation;
