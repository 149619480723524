import { Input, Space, Select, Button, message } from 'antd';
import searchIcon from '@/assets/images/search.png';
import selectIcon from '@/assets/images/down.png';
import selectOptions from '@/utils/selectOptions';
import type { ColumnsType } from 'antd/es/table';
import { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Pagination from '@/components/pagination';
import UserStatusModal from '@/components/changeUserStatusModal';
import TableBox from '@/components/tableBox';
import debounce from 'lodash.debounce';
import userApi from '@/api/user';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '@/store/hooks';
import { setCurrentUserId, setIsGetList } from '@/store/stateSlice';
interface userObjType {
    fullName: string;
    avatarUrl: string;
    userId: number;
    key: number;
    createTime: string;
    userEmail: string;
    subscriptionPlan: string;
    disabled: boolean;
}
interface getListParams {
    pageNum: number;
    pageSize: number;
    keyword: string;
    subscriptionPlan: number | string;
    disabled: number | boolean;
}

const User = (props: any) => {
    const { isGetList } = useSelector((state: any) => state.state);
    useEffect(() => {
        getList(listParams);
    }, []);
    useEffect(() => {
        if (isGetList) {
            getList(listParams);
            dispatch(setIsGetList(false));
        }
    }, [isGetList]);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [status, setStatus] = useState('showData');
    const [subscriptionType, setSubscriptionType] = useState(0);
    const [accountStatus, setAccountStatus] = useState(0);
    const [listParams, setListParams] = useState<getListParams>({
        pageNum: 1,
        pageSize: 10,
        keyword: '',
        subscriptionPlan: 0,
        disabled: 0,
    });
    const [totalRecord, setTotalRecord] = useState(0);

    const handlePageChange = prop => {
        setListParams(state => ({ ...state, pageNum: prop }));
        getList({ ...listParams, pageNum: prop });
    };
    const [currentUser, setCurrentUser] = useState<userObjType | null>(null);
    const columns: ColumnsType<userObjType> = [
        {
            title: 'FULL NAME',
            render: (name, record) => (
                <span className="user_info">
                    <img
                        style={{ width: 25, height: 25 }}
                        src={
                            record.avatarUrl
                                ? record.avatarUrl
                                : 'https://tse4-mm.cn.bing.net/th/id/OIP-C.hn1_19uVnit9c5QNAL_3_AAAAA?w=204&h=204&c=7&r=0&o=5&pid=1.7'
                        }
                        alt=""
                    />
                    {record.fullName}
                </span>
            ),
        },
        {
            title: 'EMAIL ADDRESS',
            dataIndex: 'userEmail',
            key: 'userEmail',
        },

        {
            title: 'REGISTERED DATE',
            dataIndex: 'createTime',
            key: 'createTime',
            render: time => (time ? dayjs(Number(time)).format('DD/MM/YYYY') : ''),
        },
        {
            title: 'SUBSCRIPTION PLAN',
            dataIndex: 'subscriptionPlan',
            key: 'subscriptionPlan',
        },
        {
            title: 'STATUS',
            dataIndex: 'disabled',
            key: 'disabled',
            render: (disabled, record) => (
                <Space size={15}>
                    <Button
                        icon={<></>}
                        className={disabled ? 'table_button' : 'table_button active_btn'}
                        danger={disabled}
                        onClick={e => {
                            e.stopPropagation();
                            setRemove(false);
                            setIsModalOpen(true);
                            setCurrentUser(record);
                        }}
                    >
                        {disabled ? 'Inactive' : 'Active'}
                    </Button>
                    <Button
                        icon={<></>}
                        className="table_button"
                        onClick={e => {
                            e.stopPropagation();
                            setRemove(true);
                            setIsModalOpen(true);
                            setCurrentUser(record);
                        }}
                    >
                        Remove
                    </Button>
                </Space>
            ),
        },
    ];
    const [isRemove, setRemove] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const userStatusModalParams = {
        isRemove,
        isModalOpen,
        currentUser,
        modalClose: isGetList => {
            setIsModalOpen(false);
            setRemove(false);
            if (isGetList) getList(listParams);
        },
    };
    const debouncedSave = useCallback(
        debounce(nextValue => getList(nextValue), 1000),
        []
    );
    const inputChange = e => {
        setListParams(state => ({ ...state, keyword: e.target.value, pageNum: 1 }));
        debouncedSave({ ...listParams, keyword: e.target.value, pageNum: 1 });
    };
    const [tableData, setTableData] = useState<userObjType[]>();
    const getList = params => {
        if (params.subscriptionPlan === 0) {
            delete params.subscriptionPlan;
        }
        if (params.disabled) {
            params.disabled = params.disabled === 1 ? false : true;
        } else {
            delete params.disabled;
        }
        if (!params.keyword) {
            delete params.keyword;
        }
        setStatus('loading');
        userApi.getUserList(params, res => {
            const { data, code } = res;
            if (code === 200) {
                if (data.records.length > 0) {
                    setTableData(data.records);
                    setStatus('showData');
                } else {
                    setStatus('empty');
                }
                setTotalRecord(data.total);
            } else {
                setStatus('empty');
                message.error(res.message);
            }
        });
    };
    return (
        <div className="page-main user table_page">
            <div className="search_box" style={{ marginTop: 50 }}>
                <Space wrap size={15}>
                    <Input
                        allowClear
                        value={listParams.keyword}
                        className="search_input"
                        onChange={inputChange}
                        size="large"
                        placeholder="Search"
                        style={{ width: 210 }}
                        maxLength={50}
                        prefix={<img src={searchIcon} alt="" />}
                    />
                    <Select
                        value={subscriptionType}
                        suffixIcon={<img src={selectIcon} alt="" />}
                        style={{ width: 210 }}
                        onChange={(value: number) => {
                            setSubscriptionType(value);
                            setListParams(state => ({ ...state, subscriptionPlan: value, pageNum: 1 }));
                            getList({ ...listParams, subscriptionPlan: value, pageNum: 1 });
                        }}
                        options={selectOptions.subscriptionPlan}
                    />
                    <Select
                        suffixIcon={<img src={selectIcon} alt="" />}
                        value={accountStatus}
                        style={{ width: 138 }}
                        onChange={(value: number) => {
                            setAccountStatus(value);
                            setListParams(state => ({ ...state, disabled: value, pageNum: 1 }));
                            getList({ ...listParams, disabled: value, pageNum: 1 });
                        }}
                        options={[
                            { value: 0, label: 'All Status' },
                            { value: 1, label: 'Active' },
                            { value: 2, label: 'Inactive' },
                        ]}
                    />
                </Space>
            </div>
            <TableBox
                status={status}
                columns={columns}
                data={tableData}
                rowKey="userId"
                className="is_clickable"
                onRow={record => {
                    setCurrentUser(record);
                    dispatch(setCurrentUserId(record.userId));
                    navigate('/user/detail');
                }}
            />
            {status === 'showData' ? (
                <Pagination total={totalRecord} pageChange={handlePageChange} pageNo={listParams.pageNum} />
            ) : (
                <></>
            )}
            <UserStatusModal params={userStatusModalParams}></UserStatusModal>
        </div>
    );
};
export default User;
