import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input, Select, Button, Tooltip } from 'antd';
import './style/index.scss';
import useGoogleMapMark from '@/views/pages/Workouts/hook/useGoogleMapMark';
import MapModal from '@/views/pages/Workouts/components/map-modal';
import rewardsApi from '@/api/rewards';
import { debounce } from 'lodash';
import { defaultInfo } from '@/typings/allType';
const AddRewardsTreasure = () => {
    const navigate = useNavigate();
    const [state, setState] = useState({
        treasureId: '',
        name: '',
        prizeWhich: '',
        mysteryBox: '',
    });
    const back = () => {
        navigate(-1);
    };
    const [isEmpty, setIsEmpty] = useState(true);
    useEffect(() => {
        const flag = !state.name || !state.prizeWhich || !state.mysteryBox;
        setIsEmpty(flag);
    }, [state]);
    const [loading, setLoading] = useState(false);
    const submit = () => {
        const pName = rewardPoolList.find(v => v.value === state.prizeWhich)?.label;
        const boxName = rewardPoolList.find(v => v.value === state.mysteryBox)?.label;
        const params: any = {
            treasureName: state.name,
            prizeWhichIsFullyVisibleId: state.prizeWhich,
            prizeWhichIsFullyVisibleName: pName,
            mysteryBoxId: state.mysteryBox,
            mysteryBoxName: boxName,
        };
        setLoading(true);
        if (!state.treasureId) {
            params.treasureLocationDtos = markerInfoArr.map(item => {
                return {
                    position: {
                        lon: item.lon,
                        lat: item.lat,
                    },
                    latitudeLongitude: {
                        lon: item.lon,
                        lat: item.lat,
                    },
                    location: item.location,
                    geofencing: Number(item.radiusMetres),
                };
            });
            rewardsApi.addTreasure(params, res => {
                const { data, code, message } = res;
                setLoading(false);
                if (code === 200) {
                    back();
                } else {
                    window.format.alert('error', message);
                }
            });
        } else {
            params.treasureId = state.treasureId;
            params.treasureLocationVos = markerInfoArr.map(item => {
                return {
                    // treasureLocationId: item.locationId ? item.locationId : '',
                    // deleted: item.deleted ? item.deleted : false,
                    treasureId: state.treasureId ? state.treasureId : '',
                    position: {
                        lon: item.lon,
                        lat: item.lat,
                    },
                    latitudeLongitude: {
                        lon: item.lon,
                        lat: item.lat,
                    },
                    location: item.location,
                    geofencing: Number(item.radiusMetres),
                };
            });
            rewardsApi.editTreasure(params, res => {
                const { data, code, message } = res;
                setLoading(false);
                if (code === 200) {
                    window.sessionStorage.removeItem('rewardTreasureInfo');
                    navigate('/rewards/treasure');
                } else {
                    window.format.alert('error', message);
                }
            });
        }
    };
    // reward
    const [rewardPoolList, setRewardPoolList] = useState<any>([]);
    const getRewardPoolList = () => {
        const params = {
            pageNum: 1,
            pageSize: -1,
        };
        rewardsApi.getWorkoutRewardRoolList(params, res => {
            const { data, code, message } = res;
            if (code === 200) {
                const list = data.records.map(item => {
                    return Object.assign(item, {
                        label: item.poolName,
                        value: item.rewardPoolId,
                        key: item.rewardPoolId,
                    });
                });
                setRewardPoolList(list);
            } else {
                window.format.alert('error', message);
            }
        });
    };
    const [markShowList, setMarkShowList] = useState<any>([]);
    const getMarkList = (id?: string) => {
        rewardsApi.getAllTreasureLocation({ treasureId: id ?? '' }, res => {
            const { data, code, message } = res;
            if (code === 200) {
                const list = data.map(item => {
                    return Object.assign(item, {
                        name: item.treasureName ? item.treasureName : item.location,
                        radiusMetres: item.geofencing,
                        lon: item.latitudeLongitude !== null ? item.latitudeLongitude.lon : '',
                        lat: item.latitudeLongitude !== null ? item.latitudeLongitude.lat : '',
                        position: {
                            lon: item.latitudeLongitude !== null ? item.latitudeLongitude.lon : '',
                            lat: item.latitudeLongitude !== null ? item.latitudeLongitude.lat : '',
                        },
                    });
                });
                setMarkShowList(list);
                setMarkerInfoArrNew([...list]);
            } else {
                window.format.alert('error', message);
            }
        });
    };
    // setInfo
    const setEditInfo = () => {
        const data = JSON.parse(window.sessionStorage.getItem('rewardTreasureInfo') ?? '{}');
        if (data && data.treasureId) {
            getMarkList(data.treasureId);
            rewardsApi.getTreasureInfo({ treasureId: data.treasureId }, res => {
                const { data, code, message } = res;
                if (code === 200) {
                    setState({
                        treasureId: data.treasureId,
                        name: data.treasureName,
                        prizeWhich: data.prizeWhichIsFullyVisibleId ? data.prizeWhichIsFullyVisibleId : '',
                        mysteryBox: data.mysteryBoxId ? data.mysteryBoxId : '',
                    });
                    const newLocationList =
                        data.treasureLocationVos && data.treasureLocationVos !== null
                            ? data.treasureLocationVos.map(item => {
                                  return Object.assign(item, {
                                      name: data.treasureName ? data.treasureName : item.location,
                                      locationId: item.treasureLocationId,
                                      deleted: false,
                                      location: item.location,
                                      radiusMetres: `${item.geofencing}`,
                                      lon: item.latitudeLongitude.lon,
                                      lat: item.latitudeLongitude.lat,
                                      position: new window.google.maps.LatLng(
                                          item.latitudeLongitude.lat,
                                          item.latitudeLongitude.lon
                                      ),
                                  });
                              })
                            : [];
                    setMarkerInfoArr([...newLocationList]);
                } else {
                    window.format.alert('error', message);
                }
            });
        } else {
            getMarkList();
        }
    };
    // map marker info
    const {
        initMap,
        nowIndex,
        setNowIndex,
        markerInfoArr,
        setMarkerInfoArr,
        setMapCenter,
        isClick,
        markerInfoArrNew,
        setMarkerInfoArrNew,
    } = useGoogleMapMark();
    const locationHover = debounce((type, index, params) => {
        if (type === 0) {
            setNowIndex(-1);
            return;
        }
        setNowIndex(index);
        setMapCenter(params.position);
    }, 200);
    const [locationInfo, setLocationInfo] = useState({
        dialogVisible: false,
        isEdit: false,
        address: '',
        location: '',
        latitude: '',
        longitude: '',
        radiusMeters: '100',
        index: -1,
        locationId: '',
    });
    const confirmLocationInfo = (obj: defaultInfo) => {
        // console.log(obj);
        if (!locationInfo.isEdit) {
            setMarkerInfoArr([
                ...markerInfoArr,
                ...[
                    {
                        name: state.name ? state.name : obj.address,
                        location: obj.address,
                        radiusMetres: `${obj.radiusMeters}`,
                        lon: obj.longitude,
                        lat: obj.latitude,
                        position: new window.google.maps.LatLng(obj.latitude, obj.longitude),
                    },
                ],
            ]);
        } else {
            const Obj = {
                name: state.name ? state.name : obj.address,
                location: obj.address,
                radiusMetres: `${obj.radiusMeters}`,
                lon: obj.longitude,
                lat: obj.latitude,
                position: new window.google.maps.LatLng(obj.latitude, obj.longitude),
                locationId: obj.locationId ?? '',
            };
            const arr = JSON.parse(JSON.stringify(markerInfoArr));
            arr.splice(obj.index, 1, Obj);
            setMarkerInfoArr([...arr]);
        }
        setLocationInfo({
            dialogVisible: false,
            isEdit: false,
            address: '',
            location: '',
            latitude: '',
            longitude: '',
            radiusMeters: '100',
            index: -1,
            locationId: '',
        });
    };
    useEffect(() => {
        if (nowIndex !== -1) {
            editLocation(nowIndex);
        }
    }, [isClick]);
    const editLocation = (i: number) => {
        // edit mark
        const nowList: any = JSON.parse(JSON.stringify(markerInfoArr));
        const nowListNew: any = JSON.parse(JSON.stringify(markerInfoArrNew));
        nowList.splice(i, 1);
        setMarkShowList([...nowListNew, ...nowList]);
        setLocationInfo({
            dialogVisible: true,
            isEdit: true,
            address: markerInfoArr[i].location,
            location: markerInfoArr[i].location,
            latitude: markerInfoArr[i].lat.toString(),
            longitude: markerInfoArr[i].lon.toString(),
            radiusMeters: markerInfoArr[i].radiusMetres,
            index: i,
            locationId: markerInfoArr[i].locationId ?? '',
        });
    };
    const mapMax = useRef(null);
    // const [markerList, setMarkList] = useState<MarkerInfo[]>([]);
    useEffect(() => {
        getRewardPoolList();
        initMap('treasure');
        setEditInfo();
    }, []);
    return (
        <div className="add_workOut_page list_main">
            <div className="top_back r-left">
                <div className="back_box r-left" onClick={back}>
                    <i className="back_icon"></i>
                    Back
                </div>
                <Button className="r-defaultBlick_btn marLeft" style={{ width: 146 }} onClick={back}>
                    Cancel
                </Button>
                <div className="space10"></div>
                <Button
                    className="r-primary_btn"
                    type="primary"
                    style={{ width: 146 }}
                    loading={loading}
                    onClick={submit}
                    disabled={isEmpty || !markerInfoArr.length}
                >
                    Save
                </Button>
            </div>
            <section className="actionInfo_form">
                <div className="form_tit">{state.treasureId ? 'Edit' : 'Add New'} Treasure location</div>
                <p className="form_tip">Treasure name</p>
                <Input
                    placeholder=""
                    className="r-input"
                    maxLength={50}
                    value={state.name}
                    onChange={e => {
                        setState({ ...state, name: e.target.value });
                    }}
                />
                <p className="form_tip">Location</p>
                <div id="googleMapMainNew">
                    <div id="googleMap" ref={mapMax}></div>
                </div>
                <ul className={`location_list ${markerInfoArr.length ? '' : 'hide_css'}`}>
                    {markerInfoArr.map((item, i) => {
                        return !item.deleted ? (
                            <li
                                key={i}
                                className={`location_item ${nowIndex === i ? 'hovered' : ''}`}
                                onMouseEnter={() => {
                                    locationHover(1, i, item);
                                }}
                                onMouseLeave={() => {
                                    locationHover(0, i, item);
                                }}
                            >
                                <span className="location_text oneLine_text" style={{ flex: 2, paddingRight: 20 }}>
                                    <Tooltip title={item.location} placement="topLeft">
                                        {item.location}
                                    </Tooltip>
                                </span>
                                <span className="location_text">{item.radiusMetres ?? 100} metres</span>
                                <span className="location_text">{item.lon}</span>
                                <span className="location_text">{item.lat}</span>
                                <i className="opeation_edit_icon" onClick={() => editLocation(i)}></i>
                                <i
                                    className="opeation_del_icon"
                                    onClick={() => {
                                        const arr = JSON.parse(JSON.stringify(markerInfoArr));
                                        // if (arr[i].locationId) {
                                        //     arr[i].deleted = true;
                                        // } else {
                                        //     arr.splice(i, 1);
                                        // }
                                        arr.splice(i, 1);
                                        setNowIndex(-1);
                                        setMarkerInfoArr([...arr]);
                                    }}
                                ></i>
                            </li>
                        ) : (
                            ''
                        );
                    })}
                </ul>
                <div className={markerInfoArr.length ? 'hide_css' : 'space20'}></div>
                <div
                    className="add_new_btn"
                    onClick={() => {
                        const nowList: any = JSON.parse(JSON.stringify(markerInfoArr));
                        const nowListNew: any = JSON.parse(JSON.stringify(markerInfoArrNew));
                        setMarkShowList([...nowListNew, ...nowList]);
                        setLocationInfo({ ...locationInfo, dialogVisible: true });
                    }}
                >
                    + Add Location
                </div>
                <p className="primary_tit">Treasure </p>
                <div className="r-left">
                    <div style={{ width: 300 }}>
                        <p className="form_tip">Prize which is fully visible</p>
                        <Select
                            style={{ width: '100%' }}
                            allowClear
                            value={state.prizeWhich ? state.prizeWhich : undefined}
                            placeholder="Select"
                            onChange={e => setState({ ...state, prizeWhich: !e ? '' : e })}
                            className="r-select r-input_sel"
                            suffixIcon={<i className="sel_arrow_icon"></i>}
                            options={rewardPoolList}
                        />
                    </div>
                </div>
                <div className="r-left">
                    <div style={{ width: 300 }}>
                        <p className="form_tip">Mystery box</p>
                        <Select
                            style={{ width: '100%' }}
                            allowClear
                            value={state.mysteryBox ? state.mysteryBox : undefined}
                            placeholder="Select"
                            onChange={e => {
                                setState({ ...state, mysteryBox: !e ? '' : e });
                            }}
                            className="r-select r-input_sel"
                            suffixIcon={<i className="sel_arrow_icon"></i>}
                            options={rewardPoolList}
                        />
                    </div>
                </div>
            </section>

            <MapModal
                dialogVisible={locationInfo.dialogVisible}
                locationInfo={locationInfo}
                setLocationInfo={setLocationInfo}
                confirmLocationInfo={confirmLocationInfo}
                markerInfoArrNew={markShowList}
                type="treasure"
            />
            {/* {locationInfo.dialogVisible ? (
                <MapModal
                    dialogVisible={locationInfo.dialogVisible}
                    locationInfo={locationInfo}
                    setLocationInfo={setLocationInfo}
                    confirmLocationInfo={confirmLocationInfo}
                    markerInfoArrNew={markShowList}
                    type="treasure"
                />
            ) : (
                ''
            )} */}
        </div>
    );
};
export default AddRewardsTreasure;
