import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input, Select, Button, Modal } from 'antd';
import './style/index.scss';
import closeIcon from '@/assets/images/Icons_Cross.png';
import rewardsApi from '@/api/rewards';
import { defaultInfo } from '@/typings/allType';
const AddRewardPool = (props: any) => {
    const navigate = useNavigate();
    useEffect(() => {
        getWorkoutRewardlist();
        const info = JSON.parse(window.sessionStorage.getItem('rewardPoolInfo') ?? '{}');
        if (info.rewardPoolId) {
            setState({
                poolName: info.poolName,
                rewardList: info.workoutRewardsList,
                workoutReward: '',
                rewardPoolId: info.rewardPoolId,
            });
        }
    }, []);
    const [state, setState] = useState({
        poolName: '',
        rewardList: [] as any,
        workoutReward: '',
        rewardPoolId: '',
    });
    const addReward = () => {
        const val = state.workoutReward;
        const index = tableDataShow.findIndex(v => v.workoutRewardsId === val);
        const arr = [...state.rewardList, tableDataShow[index]];
        setState({ ...state, rewardList: arr, workoutReward: '' });
        tableDataShow.splice(index, 1);
        setTableDataShow([...tableDataShow]);
        setModalVisibile(false);
    };
    const delReward = (i: number) => {
        state.rewardList.splice(i, 1);
        const newArr: defaultInfo[] = [];
        tableData.forEach((item, i) => {
            if (state.rewardList.findIndex(v => v.workoutRewardsId === item.workoutRewardsId) === -1) {
                newArr.push(item);
            }
        });
        setTableDataShow([...newArr]);
        setState({ ...state, workoutReward: '' });
    };
    const back = () => {
        navigate(-1);
    };
    const [isEmpty, setIsEmpty] = useState(true);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        const flag = !state.poolName || !state.rewardList.length;
        setIsEmpty(flag);
    }, [state]);
    const submit = () => {
        const params: defaultInfo = {
            poolName: state.poolName,
        };
        setLoading(true);
        if (!state.rewardPoolId) {
            params.rewardsIds = state.rewardList.map(v => v.workoutRewardsId);
            rewardsApi.addRewardPool(params, res => callBack(res));
            return;
        }
        params.rewardsVOList = state.rewardList;
        params.rewardPoolId = state.rewardPoolId;
        rewardsApi.editRewardPool(params, res => callBack(res));
    };
    const callBack = (res: defaultInfo) => {
        const { data, code, message } = res;
        setLoading(false);
        if (code === 200) {
            back();
        } else {
            window.format.alert('error', message);
        }
    };
    const [tableData, setTableData] = useState<defaultInfo[]>([]);
    const [tableDataShow, setTableDataShow] = useState<defaultInfo[]>([]);
    const getWorkoutRewardlist = () => {
        const params = {
            pageNum: 1,
            pageSize: -1,
        };
        rewardsApi.getWorkoutRewardList(params, res => {
            const { data, code, message } = res;
            if (code === 200) {
                if (data.records.length > 0) {
                    const list = data.records.map((item: defaultInfo, i: number) => {
                        return Object.assign(item, {
                            key: i,
                            value: item.workoutRewardsId,
                            label: item.rewardsName,
                            isChecked: false,
                        });
                    });
                    setTableData(list);
                    const info = JSON.parse(window.sessionStorage.getItem('rewardPoolInfo') ?? '{}');
                    if (info.rewardPoolId) {
                        if (info.workoutRewardsList && info.workoutRewardsList.length) {
                            const arr = JSON.parse(JSON.stringify(list));
                            info.workoutRewardsList.forEach((item: defaultInfo) => {
                                const index = arr.findIndex(v => v.workoutRewardsId === item.workoutRewardsId);
                                arr.splice(index, 1);
                            });
                            setTableDataShow([...arr]);
                        }
                    } else {
                        setTableDataShow(JSON.parse(JSON.stringify(list)));
                    }
                }
            } else {
                window.format.alert('error', message);
            }
        });
    };
    const [modalVisibile, setModalVisibile] = useState(false);
    return (
        <div className="add-reward-pool_main list_main">
            <div className="top_back r-left">
                <div className="back_box r-left" onClick={back}>
                    <i className="back_icon"></i>
                    Back
                </div>
                <Button className="r-defaultBlick_btn marLeft" style={{ width: 146 }} onClick={back}>
                    Cancel
                </Button>
                <div className="space15"></div>
                <Button
                    className="r-primary_btn"
                    type="primary"
                    disabled={isEmpty}
                    loading={loading}
                    style={{ width: 146 }}
                    onClick={submit}
                >
                    Save
                </Button>
            </div>
            <section className="actionInfo_form">
                <div className="form_tit">{state.rewardPoolId ? 'Edit' : 'Add New'} Reward Pool</div>
                <p className="form_tip">Rewards pool name</p>
                <Input
                    placeholder=""
                    className="r-input"
                    maxLength={100}
                    value={state.poolName}
                    onChange={e => {
                        setState({ ...state, poolName: e.target.value });
                    }}
                />
                <div className="add_rewardBtn" onClick={() => setModalVisibile(true)}>
                    + Add Reward
                </div>
                <p className="form_tip_mid" style={{ display: state.rewardList.length ? 'block' : 'none' }}>
                    Added
                </p>
                <ul className="reward_ul">
                    {state.rewardList.map((item, i) => {
                        return (
                            <li className="reward_item" key={i}>
                                {item.rewardsName}
                                <span className="line_li"></span>
                                <span className="close_icon" onClick={() => delReward(i)}></span>
                            </li>
                        );
                    })}
                </ul>
            </section>

            <Modal
                className="r-modal modal_no_padding"
                maskClosable={false}
                centered={true}
                open={modalVisibile}
                closeIcon={<img src={closeIcon} alt="" className="close-icon" />}
                onCancel={() => {
                    setModalVisibile(false);
                    setState({ ...state, workoutReward: '' });
                }}
                footer={''}
                key={1}
                title={[<div className="modal-top-title">Add new reward</div>]}
            >
                <div className="modal_form">
                    <p className="form_tip">Reward name</p>
                    <Select
                        defaultValue=""
                        style={{ width: '100%' }}
                        className="r-select"
                        value={state.workoutReward}
                        suffixIcon={<i className="sel_arrow_icon"></i>}
                        options={tableDataShow}
                        onChange={val => setState({ ...state, workoutReward: val })}
                    />
                    <div className="r-center" style={{ marginTop: 50 }}>
                        <Button
                            className="r-defaultBlick_btn"
                            style={{ flex: 1 }}
                            onClick={() => {
                                setModalVisibile(false);
                                setState({ ...state, workoutReward: '' });
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="r-primary_btn"
                            type="primary"
                            disabled={!state.workoutReward}
                            style={{ flex: 1, marginLeft: 15 }}
                            onClick={addReward}
                        >
                            Add
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};
export default AddRewardPool;
