import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input, Select, Table } from 'antd';
import './style/index.scss';
import TableBox from '@/components/tableBox';
import Pagination from '@/components/pagination';
import { debounce } from 'lodash';
import workoutApi from '@/api/workout';
import { useSelector } from 'react-redux';
interface listParams {
    pageNo: string | number;
    totalRecord?: string | number;
    search: string;
    type: boolean | string;
}
const WorkoutFeedBack = (props: any) => {
    const { feedBackCount, locationFeedBackCount } = useSelector((state: any) => state.state);
    const navigate = useNavigate();
    const back = () => {
        navigate(-1);
    };
    const [totalRecord, setTotalRecord] = useState(0);
    const [feedbackType, setFeedbackType] = useState(1);
    const handchangeType = (value: number) => {
        setFeedbackType(value);
    };
    useEffect(() => {
        setSearch('');
        setTotalRecord(0);
        setState({ ...state, type: 'all', pageNo: 1 });
        setTableData([]);
        getList({ type: '', pageNo: 1, totalRecord: 0, search: '' });
    }, [feedbackType]);
    const [status, setStatus] = useState('showData');
    const [state, setState] = useState({
        pageNo: 1,
        type: 'all',
    });
    const handleChange = (value: string) => {
        getList({ ...state, type: value, search });
        setState({ ...state, type: value });
    };
    const [search, setSearch] = useState<string>('');
    const searchList = useCallback(
        debounce(params => {
            getList({ ...params }, params.pageType);
        }, 1000),
        []
    );
    const handlePageChange = prop => {
        setState({ ...state, pageNo: prop });
        getList({ ...state, pageNo: prop, search });
    };
    const getList = (val: listParams, type?: number) => {
        type = type ? type : feedbackType;
        const params = {
            pageNum: val.pageNo,
            pageSize: 10,
            keyword: val.search,
            actioned: val.type === 'all' || !val.type ? '' : val.type === 'new' ? false : true,
            sortColumn: 'createTime',
        };

        setStatus('loading');

        const api = type === 1 ? workoutApi.getWorkoutLocationRequestList : workoutApi.getWorkoutFlaggedreportList;

        api(params, res => {
            const { data, code, message } = res;
            if (code === 200) {
                if (data.records.length > 0) {
                    const list = data.records.map(item => {
                        return Object.assign(item, { createTime: window.format.foramtTimeDMYHM(+item.createTime) });
                    });
                    setTableData(list);
                    setStatus('showData');
                    setTotalRecord(data.total);
                } else {
                    setStatus('empty');
                    setTotalRecord(data.total);
                }
            } else {
                window.format.alert('error', message);
                setStatus('empty');
            }
        });
    };
    const noEvent = () => {
        console.log(1);
    };
    const changeFeedbackStatus = val => {
        if (val.actioned) return;
        if (feedbackType === 1) {
            workoutApi.serWorkoutLocationStatus({ requestId: val.requestId }, res => {
                const { data, code, message } = res;
                if (code === 200) {
                    getList({ ...state, search });
                } else {
                    window.format.alert('error', message);
                }
            });
            return;
        }
        workoutApi.serWorkoutFlaggedreportStatus({ requestId: val.reportId }, res => {
            const { data, code, message } = res;
            if (code === 200) {
                getList({ ...state, search });
            } else {
                window.format.alert('error', message);
            }
        });
    };
    const columns = [
        {
            title: 'USER’S NAME',
            dataIndex: '',
            key: 'name',
            width: '30%',
            render: val => {
                return (
                    <div className="user_info r-left">
                        <img src={val.avatarUrl} className="user_img" alt="" />
                        {val.fullName}
                    </div>
                );
            },
        },
        {
            title: feedbackType === 1 ? 'REQUESTED COUNTRY' : 'WORKOUT NAME',
            dataIndex: feedbackType === 1 ? 'requestedCountry' : 'workoutName',
            key: feedbackType === 1 ? 'requestedCountry' : 'workoutName',
            width: 250,
        },
        {
            title: feedbackType === 1 ? 'REQUESTED STATE' : 'LOCATION',
            dataIndex: feedbackType === 1 ? 'requestedState' : 'workoutLocation',
            key: feedbackType === 1 ? 'requestedState' : 'workoutLocation',
            width: 180,
        },
        {
            title: feedbackType === 1 ? 'REQUESTED SUBURB' : 'REPORT DATE AND TIME',
            dataIndex: feedbackType === 1 ? 'requestedSuburb' : 'createTime',
            key: feedbackType === 1 ? 'requestedSuburb' : 'createTime',
            width: 220,
        },
        {
            textWrap: 'word-break',
            title: feedbackType === 1 ? 'REQUESTED DATE AND TIME' : 'REPORT TYPE',
            dataIndex: feedbackType === 1 ? 'createTime' : 'feedbackContent',
            key: feedbackType === 1 ? 'createTime' : 'feedbackContent',
        },
        {
            title: 'STATUS',
            dataIndex: '',
            key: 'key',
            render: val => (
                <div
                    className={`new_btn ${val.actioned ? 'actioned_btn' : ''}`}
                    onClick={() => changeFeedbackStatus(val)}
                >
                    {!val.actioned ? 'New' : 'Actioned'}
                </div>
            ),
        },
    ];
    const [tableData, setTableData] = useState([]);
    // useEffect(() => getList({ ...state, search }), []);
    return (
        <div className="workout-list_main user_feedback_main table_page">
            <div className="top_back">
                <div className="back_box r-left" onClick={back}>
                    <i className="back_icon"></i>
                    Back
                </div>
                <div className="screening_box r-left">
                    <Input
                        value={search}
                        onChange={e => {
                            setSearch(e.target.value);
                            searchList({ ...state, search: e.target.value, pageType: feedbackType, pageNo: 1 });
                        }}
                        allowClear
                        placeholder="Search"
                        style={{ width: 210 }}
                        className="r-input r-input_serach"
                        prefix={<span className="search_icon"></span>}
                    />
                    <div className="space15"></div>
                    <Select
                        defaultValue="all"
                        style={{ width: 138 }}
                        onChange={handleChange}
                        value={state.type}
                        className="r-select"
                        suffixIcon={<i className="sel_arrow_icon"></i>}
                        options={[
                            { key: '1', value: 'all', label: 'All Status' },
                            { key: '2', value: 'new', label: 'New' },
                            { key: '3', value: 'actioned', label: 'Actioned' },
                        ]}
                    />
                </div>
                <div className="r-left feedback_tab">
                    <span
                        className={`feedback_tab_item ${feedbackType === 1 ? 'active' : ''}`}
                        onClick={() => handchangeType(1)}
                    >
                        {locationFeedBackCount ? <i className="new_round"></i> : <></>}
                        New Location Requests
                    </span>
                    <span
                        className={`feedback_tab_item ${feedbackType === 2 ? 'active' : ''}`}
                        onClick={() => handchangeType(2)}
                    >
                        {feedBackCount ? <i className="new_round"></i> : <></>}
                        Flagged Report
                    </span>
                </div>
            </div>
            <TableBox
                status={status}
                columns={columns}
                data={tableData}
                rowKey={val => (val.requestId ? val.requestId : val.reportId)}
                height={false}
                className="is_clickable r-table"
                rowClassName="r-table_row"
                // onRow={val => noEvent()}
            />
            {status === 'showData' ? (
                <Pagination total={totalRecord} pageChange={handlePageChange} pageNo={state.pageNo} />
            ) : (
                <></>
            )}
        </div>
    );
};
export default WorkoutFeedBack;
