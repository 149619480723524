import { useNavigate } from 'react-router-dom';
import { Button, Tabs, Input, Row, Col, Form, Select, message, Space } from 'antd';
import api from '@/api/settings';
import comeBackImg from '@/assets/images/back.png';
import SelectIcon from '@/assets/images/select_icon.png';
import './index.scss';
const { TextArea } = Input;
const UploadVersion = (props: any) => {
    const navigate = useNavigate();
    const [uploadVersionForm] = Form.useForm();
    const deviceType = Form.useWatch('deviceType', uploadVersionForm);
    const currentVersion = Form.useWatch('currentVersion', uploadVersionForm);
    const minVersion = Form.useWatch('minVersion', uploadVersionForm);
    const versionDescribed = Form.useWatch('versionDescribed', uploadVersionForm);
    const onFinish = (values: any) => {
        api.versionAdd(values, res => {
            if (res.code === 200) {
                navigate(`/setting/versions?type=${values.deviceType}`);
            } else {
                message.error(res.message);
            }
        });
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
        message.error(errorInfo.errorFields[0].errors[0]);
    };
    const onReset = () => {
        uploadVersionForm.resetFields();
    };
    const versionChange = e => {
        e.target.value = e.target.value.replace(/[^\d^\.]/g, '');
    };
    return (
        <div>
            <div className="page-main versions">
                <div className="flex-between" style={{ margin: '57px 0 40px' }}>
                    <div
                        className="come_back"
                        onClick={() => {
                            navigate('/setting/versions');
                        }}
                    >
                        <img src={comeBackImg} alt="" />
                        Back
                    </div>
                    <div>
                        <Space size={15}>
                            <Button
                                color="#0085FF"
                                style={{ width: 146, height: 48, borderRadius: '4px' }}
                                onClick={() => {
                                    navigate(`/setting/versions?type=${deviceType || 1}`);
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                style={{ width: 146, height: 48, borderRadius: '4px' }}
                                type="primary"
                                disabled={!deviceType || !currentVersion || !minVersion || !versionDescribed}
                                onClick={() => {
                                    uploadVersionForm.submit();
                                }}
                            >
                                Save
                            </Button>
                        </Space>
                    </div>
                </div>
            </div>
            <div style={{ width: 920 }}>
                <Form
                    name="upload-version-form"
                    className="upload-version-form"
                    form={uploadVersionForm}
                    layout="vertical"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    validateTrigger="onBlur"
                    autoComplete="off"
                >
                    <Form.Item
                        label="Mobile device"
                        name="deviceType"
                        rules={[{ required: true, message: 'Please select mobile device!' }]}
                    >
                        <Select
                            className="form-select"
                            suffixIcon={<img src={SelectIcon} style={{ width: 16, height: 16 }} alt="" />}
                        >
                            <Select.Option value="1">Ios</Select.Option>
                            <Select.Option value="2">Android</Select.Option>
                        </Select>
                    </Form.Item>
                    <Row gutter={10}>
                        <Col span={12}>
                            <Form.Item
                                label="Version"
                                name="currentVersion"
                                rules={[
                                    { required: true, message: 'Please type version!' },
                                    { max: 20, message: 'The maximum length of Version is 20' },
                                    {
                                        pattern: /^[1-9]\d?(\.([1-9]?\d)){2}$/,
                                        message: 'The version number format is: X.Y.Z（1-99.0-99.0-99）',
                                    },
                                ]}
                            >
                                <Input className="default_input" maxLength={20} onInput={versionChange} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Minimum"
                                name="minVersion"
                                rules={[
                                    { required: true, message: 'Please type minimum!' },
                                    { max: 20, message: 'The maximum length of Minimum is 20' },
                                    {
                                        pattern: /^[1-9]\d?(\.([1-9]?\d)){2}$/,
                                        message: 'The minimum version number format is: X.Y.Z（1-99.0-99.0-99）',
                                    },
                                ]}
                            >
                                <Input className="default_input" maxLength={20} onInput={versionChange} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item
                        label="Upload details"
                        name="versionDescribed"
                        rules={[
                            { required: true, message: 'Please type upload details!' },
                            { max: 500, message: 'The maximum length of Upload details is 500' },
                        ]}
                    >
                        <TextArea style={{ height: 131, resize: 'none' }} maxLength={500} showCount />
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};

export default UploadVersion;
