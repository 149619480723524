const selectOptions = {
    subscriptionPlan: [
        { value: 0, label: 'All Subscription Plan' },
        { value: 'Free', label: 'Free' },
        { value: 'Monthly', label: 'Monthly' },
        { value: 'SixMonthly', label: '6 monthly' },
        { value: 'Annually', label: 'Annually' },
        { value: 'Lifetime', label: 'Lifetime' },
    ],
};
export default selectOptions;
