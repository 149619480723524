import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input, Select, Button, Switch, Tooltip } from 'antd';
import './style/index.scss';
import useGoogleMapMark from './hook/useGoogleMapMark';
import MapModal from './components/map-modal';
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import Tailor from '@/components/tailor/tailor';
import uploadApi from '@/api/upload';
import workoutApi from '@/api/workout';
import { defaultInfo } from '@/typings/allType';
import Modal from 'antd/es/modal/Modal';
const { TextArea } = Input;
interface MarkerInfo {
    location: string;
    radiusMetres: string;
    lon: string | number;
    lat: string | number;
    position: any;
    deleted?: boolean;
    locationId?: string;
}
const CreateWorkout = () => {
    const navigate = useNavigate();
    const [state, setState] = useState({
        workoutId: '',
        name: '',
        workoutType: '',
        desc: '',
        warmUp: '',
        workTime: '',
        setList: [
            {
                formatType: '',
                timer: '',
                exerciseList: [] as any,
            },
        ],
        coolDown: '',
        undercoverWorkouts: false,
        prizeWhich: '',
        mysteryBox: '',
    });
    const addSetItem = () => {
        const arr = state.setList;
        arr.push({
            formatType: '',
            timer: '',
            exerciseList: [],
        });
        setState({ ...state, setList: arr });
    };
    const handleFormatTypeChange = (i: number, val: string) => {
        state.setList[i].formatType = val;
        // state.setList[i].timer = val === '1' ? '' : state.setList[i].timer;
        state.setList[i].timer = '';
        state.setList[i].exerciseList = [];
        setState({ ...state });
    };
    const [modalVisibile, setModalVisibile] = useState(false);
    const [opeationInfo, setOpeationInfo] = useState({
        loading: false,
        index: -1,
    });
    const confirmDel = () => {
        if (state.setList.length === 1) {
            // Only one was deleted
            state.setList.splice(opeationInfo.index, 1, {
                formatType: '',
                timer: '',
                exerciseList: [],
            });
        } else {
            state.setList.splice(opeationInfo.index, 1);
        }
        setState({ ...state });
        setModalVisibile(false);
        setOpeationInfo({ ...opeationInfo, index: -1 });
    };
    const opeationSetInfo = (key: number, i: number) => {
        if (key === 4) {
            // del
            setOpeationInfo({ ...opeationInfo, index: i });
            setModalVisibile(true);
            return;
        }
        if (key === 3) {
            // copy
            const val = JSON.parse(JSON.stringify(state.setList[i]));
            state.setList.push(val);
            setState({ ...state });
            return;
        }
        if (key === 5) {
            state.setList[i].exerciseList.splice(0, 0, {
                // 1 exercise 2 rest 5 intros
                type: 5,
                name: '',
                timingMode: '1',
                duration: '',
                rest: '',
            });
        } else {
            state.setList[i].exerciseList.push({
                // 1 exercise 2 rest 5 intros
                type: key,
                name: '',
                timingMode: '1',
                duration: '',
                rest: '',
            });
        }
        window.format.addIndexToMatches(state.setList[i].exerciseList, 'type', 1);
        setState({ ...state });
    };
    const delExercise = (i: number, k: number) => {
        state.setList[i].exerciseList.splice(k, 1);
        window.format.addIndexToMatches(state.setList[i].exerciseList, 'type', 1);
        setState({ ...state });
    };
    const back = () => {
        navigate(-1);
    };
    const [loading, setLoading] = useState(false);
    const submit = () => {
        if (!state.setList[0].exerciseList.length) {
            window.format.alert('error', 'Create at least one exercise.');
            return;
        }
        const params: any = {
            workoutImage: avatar.key,
            workoutName: state.name,
            workoutTypeId: state.workoutType,
            workoutDescription: state.desc,
            workoutLocationList: markerInfoArr.map(item => {
                return {
                    locationId: item.locationId ? item.locationId : '',
                    deleted: item.deleted ? item.deleted : false,
                    workoutGeoPoint: {
                        lon: item.lon,
                        lat: item.lat,
                    },
                    workoutLocation: item.location,
                    workoutLocationRadius: Number(item.radiusMetres),
                };
            }),
            workoutDuration: state.workTime,
            warmUpExerciseId: state.warmUp,
            coolDownExerciseId: state.coolDown,
            workoutContent: state.setList.map(item => {
                const obj: any = {
                    // Interval,AMRAPLadder,EMOM
                    formats: item.formatType === '1' ? 'Interval' : item.formatType === '2' ? 'AMRAPLadder' : 'EMOM',
                    set: item.exerciseList.map(val => {
                        if (val.type === 1) {
                            return {
                                // Exercise,Rest,Intros
                                setType: val.type === 1 ? 'Exercise' : +val.type === 5 ? 'Intros' : 'Rest',
                                exerciseId: val.name,
                                // :Duration,Distance,Rep
                                timingMode:
                                    val.timingMode === '1' ? 'Duration' : val.timingMode === '2' ? 'Distance' : 'Rep',
                                duration: val.duration ? val.duration : 0,
                            };
                        } else if (val.type === 5) {
                            return {
                                setType: val.type === 1 ? 'Exercise' : +val.type === 5 ? 'Intros' : 'Rest',
                                exerciseId: val.name,
                            };
                        } else {
                            return {
                                setType: val.type === 1 ? 'Exercise' : +val.type === 5 ? 'Intros' : 'Rest',
                                rest: val.rest ? val.rest : 0,
                            };
                        }
                    }),
                };
                if (item.formatType !== '1') {
                    obj.timer = item.timer ? Number(item.timer) : 0;
                }
                return obj;
            }),
            undercoverWorkouts: state.undercoverWorkouts,
            prizeWhichIsFullyVisibleId: state.prizeWhich,
            mysteryBoxId: state.mysteryBox,
        };
        setLoading(true);
        if (!state.workoutId) {
            workoutApi.addWorkout(params, res => {
                const { data, code, message } = res;
                setLoading(false);
                if (code === 200) {
                    back();
                } else {
                    window.format.alert('error', message);
                }
            });
        } else {
            params.workoutId = state.workoutId;
            workoutApi.editWorkout(params, res => {
                const { data, code, message } = res;
                setLoading(false);
                if (code === 200) {
                    window.sessionStorage.removeItem('workoutInfo');
                    navigate('/workouts/detail');
                } else {
                    window.format.alert('error', message);
                }
            });
        }
    };
    // image
    const [avatar, setAvatar] = useState({
        url: '',
        key: '',
        base64: '',
        file: null,
        uploading: false,
        dialogVisible: false,
        warncontent: '',
    }) as any;
    const handleFileChange = (e: any) => {
        if (!e) {
            return;
        }
        const file = e.target.files[0].name.split('.');
        if (file[file.length - 1].toLowerCase() === 'png' || file[file.length - 1].toLowerCase() === 'jpg') {
            if (e.target.files[0].size > 10 * 1048576) {
                window.format.alert('error', 'Pictures cannot exceed 10MB');
                (document.getElementById('imgInput') as any).value = null;
                return;
            }
            const reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            reader.onload = function (ev: any) {
                setAvatar(
                    Object.assign({}, avatar, {
                        file: ev.target.result,
                        dialogVisible: true,
                    })
                );
                (document.getElementById('imgInput') as any).value = null;
            };
        } else {
            window.format.alert('error', 'File format error');
            (document.getElementById('imgInput') as any).value = null;
        }
    };
    const getResult = (e: any) => {
        setAvatar(
            Object.assign({}, avatar, {
                url: '',
                base64: '',
                dialogVisible: false,
                file: null,
                uploading: true,
            })
        );
        const param = new FormData();
        param.append('file', e);
        const reader = new FileReader();
        reader.readAsDataURL(e);
        reader.onload = function (ev: any) {
            const dataURL = ev.target.result;
            uploadApi.uploadFile(param, (res: any) => {
                if (!res) {
                    return;
                }
                if (res.code === 200) {
                    setAvatar(
                        Object.assign({}, avatar, {
                            url: res.data,
                            key: window.format.getFilePathName(res.data),
                            base64: dataURL,
                            dialogVisible: false,
                            file: null,
                            uploading: false,
                        })
                    );
                } else {
                    setAvatar({
                        url: '',
                        key: '',
                        base64: '', //
                        file: null,
                        uploading: false,
                        dialogVisible: false,
                        warncontent: '',
                    });
                    window.format.alert('error', res.data.message);
                }
            });
        };
    };
    const stopUplaod = () => {
        setAvatar({
            url: '',
            key: '',
            base64: '',
            file: null,
            uploading: false,
            dialogVisible: false,
        });
    };
    const [isEmpty, setIsEmpty] = useState(false);
    useEffect(() => {
        const setFlag = state.setList.some(item => {
            if (item.formatType !== '1' && !item.timer) {
                return true;
            }
            if (item.exerciseList.length) {
                return item.exerciseList.some(val => {
                    if (val.type === 1 && (!val.name || !val.timingMode || !val.duration)) {
                        return true;
                    }
                    if (val.type === 2 && !val.rest) {
                        return true;
                    }
                    if (val.type === 5 && !val.name) {
                        return true;
                    }
                });
            }
        });
        const flag =
            !state.name ||
            !state.workoutType ||
            !avatar.key ||
            !state.desc ||
            !state.warmUp ||
            !state.workTime ||
            !state.coolDown ||
            // !state.prizeWhich ||
            // !state.mysteryBox ||
            setFlag;
        setIsEmpty(flag);
    }, [state, avatar]);
    // workout type
    const [workoutTypeList, setWorkoutTypeList] = useState<defaultInfo[]>([]);
    const getWorkoutType = () => {
        workoutApi.getWorkoutTypeList({}, res => {
            const { data, code, message } = res;
            if (code === 200) {
                if (data && data.length) {
                    const arr = data.map(item => {
                        return Object.assign(item, {
                            value: item.workoutTypeId,
                            label: item.workoutTypeName,
                        });
                    });
                    setWorkoutTypeList([...arr]);
                }
            } else {
                window.format.alert('error', message);
            }
        });
    };
    // action library
    const [introsList, setIntrosList] = useState([]);
    const getINtrosList = () => {
        const params = {
            pageNum: 1,
            pageSize: -1,
            exerciseType: 'Intros',
        };
        workoutApi.getExerciseList(params, res => {
            const { data, code, message } = res;
            if (code === 200) {
                const list = data.records.map(item => {
                    return Object.assign(item, {
                        label: item.exerciseName,
                        value: item.exerciseId,
                        key: item.exerciseId,
                    });
                });
                setIntrosList(list);
            } else {
                window.format.alert('error', message);
            }
        });
    };
    const [warmUpList, setWarmList] = useState([]);
    const getWarmUpList = () => {
        const params = {
            pageNum: 1,
            pageSize: -1,
            exerciseType: 'WarmUp',
        };
        workoutApi.getExerciseList(params, res => {
            const { data, code, message } = res;
            if (code === 200) {
                const list = data.records.map(item => {
                    return Object.assign(item, {
                        label: item.exerciseName,
                        value: item.exerciseId,
                        key: item.exerciseId,
                    });
                });
                setWarmList(list);
            } else {
                window.format.alert('error', message);
            }
        });
    };
    const [workoutsList, setWorkoutsList] = useState([]);
    const getWorkoutsList = () => {
        const params = {
            pageNum: 1,
            pageSize: -1,
            exerciseType: 'Workouts',
        };
        workoutApi.getExerciseList(params, res => {
            const { data, code, message } = res;
            if (code === 200) {
                const list = data.records.map(item => {
                    return Object.assign(item, {
                        label: item.exerciseName,
                        value: item.exerciseId,
                        key: item.exerciseId,
                    });
                });
                setWorkoutsList(list);
            } else {
                window.format.alert('error', message);
            }
        });
    };
    const [cooldownsList, setCooldownsList] = useState([]);
    const getCooldownsList = () => {
        const params = {
            pageNum: 1,
            pageSize: -1,
            exerciseType: 'Cooldowns',
        };
        workoutApi.getExerciseList(params, res => {
            const { data, code, message } = res;
            if (code === 200) {
                const list = data.records.map(item => {
                    return Object.assign(item, {
                        label: item.exerciseName,
                        value: item.exerciseId,
                        key: item.exerciseId,
                    });
                });
                setCooldownsList(list);
            } else {
                window.format.alert('error', message);
            }
        });
    };
    // reward
    const [rewardPoolList, setRewardPoolList] = useState([]);
    const getRewardPoolList = () => {
        const params = {
            pageNum: 1,
            pageSize: -1,
        };
        workoutApi.getWorkoutRewardRoolList(params, res => {
            const { data, code, message } = res;
            if (code === 200) {
                const list = data.records.map(item => {
                    return Object.assign(item, {
                        label: item.poolName,
                        value: item.rewardPoolId,
                        key: item.rewardPoolId,
                    });
                });
                setRewardPoolList(list);
            } else {
                window.format.alert('error', message);
            }
        });
    };
    // setInfo
    const setEditInfo = () => {
        const data = JSON.parse(window.sessionStorage.getItem('workoutInfo') ?? '{}');
        if (data && data.workoutId) {
            getMarkList(data.workoutId);
            setAvatar(
                Object.assign({}, avatar, {
                    url: data.workoutImage,
                    key: window.format.getFilePathName(data.workoutImage),
                    base64: '',
                    dialogVisible: false,
                    file: null,
                    uploading: false,
                })
            );
            data.workoutContent.forEach((item: defaultInfo) => {
                item.set = window.format.addIndexToMatches(item.set, 'setType', 'Exercise');
            });
            setState({
                workoutId: data.workoutId,
                name: data.workoutName,
                workoutType: data.workoutTypeId,
                desc: data.workoutDescription,
                warmUp: data.warmUpExercise.id,
                workTime: data.workoutDuration,
                setList:
                    data.workoutContent && data.workoutContent.length
                        ? data.workoutContent.map((item: defaultInfo) => {
                              return {
                                  formatType:
                                      item.formats === 'Interval' ? '1' : item.formats === 'AMRAPLadder' ? '2' : '3',
                                  timer: item.formats !== 'Interval' ? item.timer : '12',
                                  exerciseList: item.set.map((val: defaultInfo) => {
                                      return {
                                          index: val.index ?? '',
                                          type: val.setType === 'Exercise' ? 1 : val.setType === 'Intros' ? 5 : 2,
                                          name: val.exerciseId ? val.exerciseId : '',
                                          timingMode:
                                              val.timingMode === 'Duration'
                                                  ? '1'
                                                  : val.timingMode === 'Distance'
                                                  ? '2'
                                                  : '3',
                                          duration: val.duration ? val.duration : 0,
                                          rest: val.rest ? val.rest : 0,
                                      };
                                  }),
                              };
                          })
                        : [],
                coolDown: data.coolDownExercise.id,
                undercoverWorkouts: data.undercoverWorkouts,
                prizeWhich: data.prizeWhichIsFullyVisibleId ?? '',
                mysteryBox: data.mysteryBoxId ? data.mysteryBoxId : state.mysteryBox,
            });
            const newLocationList = data.locationList.map(item => {
                return Object.assign({
                    name: data.workoutName ? data.workoutName : item.location,
                    locationId: item.locationId,
                    deleted: false,
                    location: item.workoutLocation,
                    radiusMetres: `${item.workoutLocationRadius}`,
                    lon: item.workoutGeoPoint.lon,
                    lat: item.workoutGeoPoint.lat,
                    position: new window.google.maps.LatLng(item.workoutGeoPoint.lat, item.workoutGeoPoint.lon),
                });
            });
            setMarkerInfoArr([...newLocationList]);
        } else {
            getMarkList();
        }
    };
    // map marker info
    const {
        initMap,
        nowIndex,
        setNowIndex,
        markerInfoArr,
        setMarkerInfoArr,
        setMapCenter,
        isClick,
        markerInfoArrNew,
        setMarkerInfoArrNew,
    } = useGoogleMapMark();
    const [locationInfo, setLocationInfo] = useState({
        dialogVisible: false,
        isEdit: false,
        address: '',
        location: '',
        latitude: '',
        longitude: '',
        radiusMeters: '100',
        index: -1,
        locationId: '',
    });
    const [markShowList, setMarkShowList] = useState<any>([]);
    const getMarkList = (id?: string) => {
        workoutApi.getWorkoutLocationList({ excludeWorkoutId: id ?? '' }, res => {
            const { data, code, message } = res;
            if (code === 200) {
                const list = data.map(item => {
                    return Object.assign(item, {
                        name: item.workoutName ? item.workoutName : item.workoutLocation,
                        location: item.workoutLocation,
                        radiusMetres: item.workoutLocationRadius,
                        lon: item.workoutGeoPoint.lon,
                        lat: item.workoutGeoPoint.lat,
                        position: {
                            lon: item.workoutGeoPoint.lon,
                            lat: item.workoutGeoPoint.lat,
                        },
                    });
                });
                setMarkShowList(list);
                setMarkerInfoArrNew([...list]);
            } else {
                window.format.alert('error', message);
            }
        });
    };
    const confirmLocationInfo = (obj: defaultInfo) => {
        // console.log(obj);
        if (!locationInfo.isEdit) {
            setMarkerInfoArr([
                ...markerInfoArr,
                ...[
                    {
                        name: state.name ? state.name : obj.address,
                        location: obj.address,
                        radiusMetres: `${obj.radiusMeters}`,
                        lon: obj.longitude,
                        lat: obj.latitude,
                        position: new window.google.maps.LatLng(obj.latitude, obj.longitude),
                    },
                ],
            ]);
        } else {
            const Obj = {
                name: state.name ? state.name : obj.address,
                location: obj.address,
                radiusMetres: `${obj.radiusMeters}`,
                lon: obj.longitude,
                lat: obj.latitude,
                position: new window.google.maps.LatLng(obj.latitude, obj.longitude),
                locationId: obj.locationId ?? '',
            };
            const arr = JSON.parse(JSON.stringify(markerInfoArr));
            arr.splice(obj.index, 1, Obj);
            setMarkerInfoArr([...arr]);
        }
        setLocationInfo({
            dialogVisible: false,
            isEdit: false,
            address: '',
            location: '',
            latitude: '',
            longitude: '',
            radiusMeters: '100',
            index: -1,
            locationId: '',
        });
    };
    useEffect(() => {
        if (nowIndex !== -1) {
            editLocation(nowIndex);
        }
    }, [isClick]);
    const editLocation = (i: number) => {
        // edit mark
        const nowList: any = JSON.parse(JSON.stringify(markerInfoArr));
        const nowListNew: any = JSON.parse(JSON.stringify(markerInfoArrNew));
        nowList.splice(i, 1);
        setMarkShowList([...nowListNew, ...nowList]);
        setLocationInfo({
            dialogVisible: true,
            isEdit: true,
            address: markerInfoArr[i].location,
            location: markerInfoArr[i].location,
            latitude: markerInfoArr[i].lat.toString(),
            longitude: markerInfoArr[i].lon.toString(),
            radiusMeters: markerInfoArr[i].radiusMetres,
            index: i,
            locationId: markerInfoArr[i].locationId ?? '',
        });
    };
    const mapMax = useRef(null);
    // const [markerList, setMarkList] = useState<MarkerInfo[]>([]);
    useEffect(() => {
        setEditInfo();
        initMap();
        getWarmUpList();
        getWorkoutsList();
        getCooldownsList();
        getRewardPoolList();
        getWorkoutType();
        getINtrosList();
    }, []);
    return (
        <div className="add_workOut_page list_main">
            <div className="top_back r-left">
                <div className="back_box r-left" onClick={back}>
                    <i className="back_icon"></i>
                    Back
                </div>
                <Button className="r-defaultBlick_btn marLeft" style={{ width: 146 }} onClick={back}>
                    Cancel
                </Button>
                <div className="space10"></div>
                <Button
                    className="r-primary_btn"
                    type="primary"
                    style={{ width: 146 }}
                    loading={loading}
                    onClick={submit}
                    disabled={isEmpty || !markerInfoArr.length || markerInfoArr.findIndex(v => !v.deleted) === -1}
                >
                    Save
                </Button>
            </div>
            <section className="actionInfo_form">
                <div className="form_tit">{state.workoutId ? 'Edit' : 'Add New'} Workout Location</div>
                <p className="form_tip">Workout image</p>
                <label
                    className="upload_imgBox"
                    htmlFor="imgInput"
                    style={{
                        backgroundImage: `url(${avatar.base64 ? avatar.base64 : avatar.url})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center center',
                    }}
                >
                    {avatar.base64 || avatar.url ? (
                        <></>
                    ) : (
                        <>
                            {!avatar.uploading ? (
                                <PlusOutlined style={{ fontSize: 22, color: '#a4a4a4' }} />
                            ) : (
                                <LoadingOutlined style={{ fontSize: 22 }} />
                            )}
                        </>
                    )}
                    <input type="file" id="imgInput" onChange={e => handleFileChange(e)} accept="image/*" title="" />
                </label>
                <p className="form_tip">Workout name</p>
                <Input
                    placeholder=""
                    className="r-input"
                    maxLength={100}
                    value={state.name}
                    onChange={e => {
                        setState({ ...state, name: e.target.value });
                    }}
                />
                <p className="form_tip">Workout type</p>
                <Select
                    // defaultValue=""
                    placeholder="Select"
                    style={{ width: '100%' }}
                    onChange={val => setState({ ...state, workoutType: val })}
                    className="r-select"
                    value={state.workoutType ? state.workoutType : undefined}
                    suffixIcon={<i className="sel_arrow_icon"></i>}
                    options={workoutTypeList}
                />
                <p className="form_tip">Description</p>
                <TextArea
                    className="r-textarea"
                    placeholder=""
                    value={state.desc}
                    onChange={e => {
                        setState({ ...state, desc: e.target.value });
                    }}
                    autoSize={{ minRows: 8 }}
                    maxLength={500}
                    showCount
                />
                <p className="form_tip">Location</p>
                <div id="googleMapMainNew">
                    <div id="googleMap" ref={mapMax}></div>
                </div>
                <ul
                    className={`location_list ${
                        markerInfoArr.length && markerInfoArr.findIndex(v => !v.deleted) !== -1 ? '' : 'hide_css'
                    }`}
                >
                    {markerInfoArr.map((item, i) => {
                        return !item.deleted ? (
                            <li
                                key={i}
                                className={`location_item ${nowIndex === i ? 'hovered' : ''}`}
                                onMouseEnter={() => {
                                    setNowIndex(i);
                                    setMapCenter(item.position);
                                }}
                                onMouseLeave={() => {
                                    setNowIndex(-1);
                                }}
                            >
                                <span className="location_text oneLine_text" style={{ flex: 2, paddingRight: 20 }}>
                                    <Tooltip title={item.location} placement="topLeft">
                                        {item.location}
                                    </Tooltip>
                                </span>
                                <span className="location_text">{item.radiusMetres ?? 100} metres</span>
                                <span className="location_text">{item.lon}</span>
                                <span className="location_text">{item.lat}</span>
                                <i className="opeation_edit_icon" onClick={() => editLocation(i)}></i>
                                <i
                                    className="opeation_del_icon"
                                    onClick={() => {
                                        const arr = JSON.parse(JSON.stringify(markerInfoArr));
                                        if (arr[i].locationId) {
                                            arr[i].deleted = true;
                                        } else {
                                            arr.splice(i, 1);
                                        }
                                        setNowIndex(-1);
                                        setMarkerInfoArr([...arr]);
                                    }}
                                ></i>
                            </li>
                        ) : (
                            ''
                        );
                    })}
                </ul>
                <div
                    className={
                        markerInfoArr.length && markerInfoArr.findIndex(v => !v.deleted) !== -1 ? 'hide_css' : 'space20'
                    }
                ></div>
                <div
                    className="add_new_btn"
                    onClick={() => {
                        const nowList: any = JSON.parse(JSON.stringify(markerInfoArr));
                        const nowListNew: any = JSON.parse(JSON.stringify(markerInfoArrNew));
                        setMarkShowList([...nowListNew, ...nowList]);
                        setLocationInfo({ ...locationInfo, dialogVisible: true });
                    }}
                >
                    + Add Location
                </div>
                <p className="primary_tit">Warm Up</p>
                <div style={{ width: 300 }}>
                    <p className="form_tip">Warm up</p>
                    <Select
                        style={{ width: '100%' }}
                        value={state.warmUp ? state.warmUp : undefined}
                        onChange={e => setState({ ...state, warmUp: e })}
                        className="r-select"
                        placeholder="Select exercise"
                        suffixIcon={<i className="sel_arrow_icon"></i>}
                        options={warmUpList}
                    />
                </div>
                <p className="primary_tit">Workouts</p>
                <div style={{ width: 300 }}>
                    <p className="form_tip">Workout duration (approx)</p>
                    <Input
                        placeholder=" "
                        className="r-input"
                        value={state.workTime}
                        maxLength={10}
                        onChange={e => {
                            let temp = e.target.value;
                            temp = temp.replace(/[^0-9]/g, '');
                            state.workTime = temp;
                            setState({ ...state });
                        }}
                        style={{ width: 300 }}
                        suffix={<span>min (s)</span>}
                    />
                </div>
                {/* set list */}
                {state.setList.map((item, i) => {
                    return (
                        <div key={i}>
                            <p className="medium_tit">Set {i + 1}</p>
                            <div className="r-left">
                                <div style={{ width: 300 }}>
                                    <p className="form_tip">Formats</p>
                                    <Select
                                        placeholder="Select"
                                        value={item.formatType ? item.formatType : undefined}
                                        style={{ width: '100%' }}
                                        onChange={val => {
                                            handleFormatTypeChange(i, val);
                                        }}
                                        className="r-select"
                                        suffixIcon={<i className="sel_arrow_icon"></i>}
                                        options={[
                                            { key: 1, value: '1', label: 'Interval' },
                                            { key: 2, value: '2', label: 'AMRAP/Ladder' },
                                            { key: 3, value: '3', label: 'EMOM' },
                                        ]}
                                    />
                                </div>
                                <span className="space10"></span>
                                <div
                                    style={{
                                        width: 300,
                                        display: item.formatType && item.formatType !== '1' ? 'initial' : 'none',
                                    }}
                                >
                                    <p className="form_tip">Timer</p>
                                    <Input
                                        placeholder=" "
                                        className="r-input"
                                        maxLength={100}
                                        value={item.timer}
                                        onChange={e => {
                                            let temp = e.target.value;
                                            temp = temp.replace(/[^0-9]/g, '');
                                            state.setList[i].timer = temp;
                                            setState({ ...state });
                                        }}
                                        style={{ width: 300 }}
                                        suffix={<span>min (s)</span>}
                                    />
                                </div>
                            </div>
                            {item.exerciseList.map((val, k) => {
                                return (
                                    <div className="r-left" key={k}>
                                        {/* duanlian */}
                                        <div className={val.type === 1 ? 'r-left positon_r' : 'hide_css'}>
                                            <div style={{ width: 300 }}>
                                                <p className="form_tip">
                                                    Exercise {val.index ? val.index : k + 1} name
                                                </p>
                                                <Select
                                                    defaultValue=""
                                                    style={{ width: '100%' }}
                                                    className="r-select"
                                                    value={val.name}
                                                    suffixIcon={<i className="sel_arrow_icon"></i>}
                                                    onChange={v => {
                                                        state.setList[i].exerciseList[k].name = v;
                                                        setState({ ...state });
                                                    }}
                                                    options={workoutsList}
                                                />
                                            </div>
                                            <span className="space10"></span>
                                            <div style={{ width: 300 }}>
                                                <p className="form_tip">Timing mode</p>
                                                <Select
                                                    value={val.timingMode}
                                                    style={{ width: '100%' }}
                                                    onChange={v => {
                                                        state.setList[i].exerciseList[k].timingMode = v;
                                                        state.setList[i].exerciseList[k].duration = '';
                                                        setState({ ...state });
                                                    }}
                                                    className="r-select"
                                                    suffixIcon={<i className="sel_arrow_icon"></i>}
                                                    options={[
                                                        { key: 1, value: '1', label: 'Duration' },
                                                        { key: 2, value: '2', label: 'Distance' },
                                                        { key: 3, value: '3', label: 'Rep' },
                                                    ]}
                                                />
                                            </div>
                                            <span className="space10"></span>
                                            <div style={{ width: 300 }}>
                                                <p className="form_tip">
                                                    {val.timingMode !== '3' ? 'Duration' : 'Rep'}
                                                </p>
                                                <Input
                                                    placeholder=" "
                                                    className="r-input"
                                                    value={val.duration}
                                                    maxLength={100}
                                                    onChange={e => {
                                                        let temp = e.target.value;
                                                        temp = temp.replace(/[^0-9]/g, '');
                                                        state.setList[i].exerciseList[k].duration = temp;
                                                        setState({ ...state });
                                                    }}
                                                    style={{ width: 300 }}
                                                    suffix={
                                                        <span>
                                                            {val.timingMode === '1'
                                                                ? 'sec (s)'
                                                                : val.timingMode === '2'
                                                                ? 'meter (s)'
                                                                : 'rep (s)'}
                                                        </span>
                                                    }
                                                />
                                            </div>
                                            <i
                                                className="opeation_del_icon del_icon_posi"
                                                onClick={() => delExercise(i, k)}
                                            ></i>
                                        </div>
                                        {/* xiuxi */}
                                        <div className={val.type === 2 ? 'positon_r' : 'hide_css'}>
                                            <div style={{ width: 300 }}>
                                                <p className="form_tip">Rest</p>
                                                <Input
                                                    placeholder=""
                                                    value={val.rest}
                                                    className="r-input"
                                                    maxLength={10}
                                                    onChange={e => {
                                                        let temp = e.target.value;
                                                        temp = temp.replace(/[^0-9]/g, '');
                                                        state.setList[i].exerciseList[k].rest = temp;
                                                        setState({ ...state });
                                                    }}
                                                    style={{ width: 300 }}
                                                    suffix={<span>sec (s)</span>}
                                                />
                                            </div>
                                            <i
                                                className="opeation_del_icon del_icon_posi"
                                                onClick={() => delExercise(i, k)}
                                            ></i>
                                        </div>
                                        {/* yulanshipin */}
                                        <div className={val.type === 5 ? 'r-left positon_r' : 'hide_css'}>
                                            <div style={{ width: 300 }}>
                                                <p className="form_tip">Set Intros</p>
                                                <Select
                                                    defaultValue=""
                                                    style={{ width: '100%' }}
                                                    className="r-select"
                                                    value={val.name}
                                                    suffixIcon={<i className="sel_arrow_icon"></i>}
                                                    onChange={v => {
                                                        state.setList[i].exerciseList[k].name = v;
                                                        setState({ ...state });
                                                    }}
                                                    options={introsList}
                                                />
                                            </div>
                                            <span className="space10"></span>
                                            <i
                                                className="opeation_del_icon del_icon_posi"
                                                onClick={() => delExercise(i, k)}
                                            ></i>
                                        </div>
                                    </div>
                                );
                            })}
                            <div className={`r-left ${item.formatType ? '' : 'hide_css'}`} style={{ marginTop: 20 }}>
                                <div className="add_new_btn marR20" onClick={() => opeationSetInfo(1, i)}>
                                    + Add Exercise
                                </div>
                                {item.formatType !== '3' ? (
                                    <div className="add_new_btn marR20" onClick={() => opeationSetInfo(2, i)}>
                                        + Add Rest
                                    </div>
                                ) : (
                                    ''
                                )}
                                {item.exerciseList.findIndex(v => v.type === 5) === -1 ? (
                                    <div className="add_new_btn marR20" onClick={() => opeationSetInfo(5, i)}>
                                        Set Intros
                                    </div>
                                ) : (
                                    ''
                                )}
                                <div className="add_new_btn marR20" onClick={() => opeationSetInfo(3, i)}>
                                    Duplicate Set
                                </div>
                                <div className="del_new_btn" onClick={() => opeationSetInfo(4, i)}>
                                    Remove Set
                                </div>
                            </div>
                        </div>
                    );
                })}
                <div className="add_new_btn" style={{ marginTop: 40 }} onClick={addSetItem}>
                    + Add Another Set
                </div>
                {/* set list */}
                <p className="primary_tit">Cool Down</p>
                <div style={{ width: 300 }}>
                    <p className="form_tip">Cool down</p>
                    <Select
                        style={{ width: '100%' }}
                        value={state.coolDown ? state.coolDown : undefined}
                        placeholder="Select exercise"
                        onChange={e => setState({ ...state, coolDown: e })}
                        className="r-select"
                        suffixIcon={<i className="sel_arrow_icon"></i>}
                        options={cooldownsList}
                    />
                </div>
                <div className="r-left" style={{ marginTop: 40 }}>
                    <span className="form_tip workout_switchTip">Undercover workouts</span>
                    <Switch
                        className="r-switch"
                        checked={state.undercoverWorkouts}
                        onChange={val => {
                            setState({ ...state, undercoverWorkouts: val });
                        }}
                    />
                </div>
                <p className="primary_tit">Reward </p>
                <div className="r-left">
                    <div style={{ width: 300 }}>
                        <p className="form_tip">Prize which is fully visible</p>
                        <Select
                            style={{ width: '100%' }}
                            allowClear
                            value={state.prizeWhich ? state.prizeWhich : undefined}
                            placeholder="Select"
                            onChange={e => setState({ ...state, prizeWhich: !e ? '' : e })}
                            className="r-select r-input_sel"
                            suffixIcon={<i className="sel_arrow_icon"></i>}
                            options={rewardPoolList}
                        />
                    </div>
                </div>
                <div className="r-left">
                    <div style={{ width: 300 }}>
                        <p className="form_tip">Mystery box</p>
                        <Select
                            style={{ width: '100%' }}
                            allowClear
                            value={state.mysteryBox ? state.mysteryBox : undefined}
                            placeholder="Select"
                            onChange={e => {
                                setState({ ...state, mysteryBox: !e ? '' : e });
                            }}
                            className="r-select r-input_sel"
                            suffixIcon={<i className="sel_arrow_icon"></i>}
                            options={rewardPoolList}
                        />
                    </div>
                </div>
            </section>

            <Modal
                className="r-modal modal_no_padding"
                maskClosable={false}
                centered={true}
                open={modalVisibile}
                onCancel={() => setModalVisibile(false)}
                footer={''}
                title={''}
                closable={false}
            >
                <div className="modal_form">
                    <p className="del_tip">Are you sure you want to remove this set?</p>
                    <div className="r-center" style={{ marginTop: 50 }}>
                        <Button
                            className="r-defaultBlick_btn"
                            style={{ flex: 1 }}
                            onClick={() => setModalVisibile(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="r-primary_btn"
                            type="primary"
                            loading={opeationInfo.loading}
                            style={{ flex: 1, marginLeft: 15 }}
                            onClick={() => confirmDel()}
                        >
                            Confirm
                        </Button>
                    </div>
                </div>
            </Modal>

            <Tailor
                dialogVisible={avatar.dialogVisible}
                imgFile={avatar.file}
                aspectRatioWidth="343"
                aspectRatioHeight="200"
                getResult={getResult}
                isFile={true}
                closeWindow={stopUplaod}
            />
            <MapModal
                dialogVisible={locationInfo.dialogVisible}
                locationInfo={locationInfo}
                setLocationInfo={setLocationInfo}
                confirmLocationInfo={confirmLocationInfo}
                markerInfoArrNew={markShowList}
            />
        </div>
    );
};
export default CreateWorkout;
