import { Input, Space, DatePicker, message } from 'antd';
import comeBackImg from '@/assets/images/back.png';
import searchIcon from '@/assets/images/search.png';
import calendarIcon from '@/assets/images/calendar_icon.png';
import type { ColumnsType } from 'antd/es/table';
import { useState, useEffect, useCallback } from 'react';
import debounce from 'lodash.debounce';
import { useNavigate } from 'react-router-dom';
import Pagination from '@/components/pagination';
import TableBox from '@/components/tableBox';
import dayjs from 'dayjs';
import type { RangePickerProps } from 'antd/es/date-picker';
import userApi from '@/api/user';
import { useSelector } from 'react-redux';
import { useAliveController } from 'react-activation';
interface DataType {
    workoutName: string;
    createTime: string;
    totalPoints: string;
    fitLoots: string;
    caloriesBurned: string;
}
interface getListParams {
    pageNum: number;
    pageSize: number;
    keyword: string;
    startTime: string | number;
    endTime: string | number;
}
const WorkoutRecord = (props: any) => {
    const { currentUserId, isGetList } = useSelector((state: any) => state.state);
    useEffect(() => {
        getList(listParams);
    }, []);
    useEffect(() => {
        getList(listParams);
    }, [isGetList]);
    const { RangePicker } = DatePicker;
    const navigate = useNavigate();
    const [status, setStatus] = useState('showData');
    const [listParams, setListParams] = useState<getListParams>({
        pageNum: 1,
        pageSize: 10,
        keyword: '',
        startTime: '',
        endTime: '',
    });
    const [totalRecord, setTotalRecord] = useState(0);
    const disabledDate: RangePickerProps['disabledDate'] = current => {
        return current && current > dayjs().endOf('day');
    };
    const handlePageChange = prop => {
        setListParams(state => ({ ...state, pageNum: prop }));
        getList({ ...listParams, pageNum: prop });
    };
    const columns: ColumnsType<DataType> = [
        {
            title: 'WORKOUT NAME',
            dataIndex: 'workoutName',
            key: 'workoutName',
        },
        {
            title: 'COMPLETED DATE AND TIME',
            dataIndex: 'createTime',
            key: 'createTime',
            render: time => (time ? dayjs(Number(time)).format('DD/MM/YYYY HH:mm') : ''),
        },
        {
            title: 'POINTS EARNED',
            dataIndex: 'totalPoints',
            key: 'totalPoints',
        },
        {
            title: 'FITLOOT EARNED',
            dataIndex: 'fitLoots',
            key: 'fitLoots',
        },
        {
            title: 'NUMBER OF CALORIES BURNT',
            dataIndex: 'caloriesBurned',
            key: 'caloriesBurned',
            render: caloriesBurned => caloriesBurned || '-',
        },
    ];
    const dateOnChange = (dates, dateStrings) => {
        setListParams(state => ({
            ...state,
            startTime: dates ? dayjs(`${dates[0].format('YYYY-MM-DD')} 00:00:00`).valueOf() : '',
            endTime: dates ? dayjs(`${dates[1].format('YYYY-MM-DD')} 23:59:59`).valueOf() : '',
        }));
        getList({
            ...listParams,
            startTime: dates ? dayjs(`${dates[0].format('YYYY-MM-DD')} 00:00:00`).valueOf() : '',
            endTime: dates ? dayjs(`${dates[1].format('YYYY-MM-DD')} 23:59:59`).valueOf() : '',
        });
    };
    const [workoutList, setWorkoutList] = useState<DataType[]>([]);
    const debouncedSave = useCallback(
        debounce(nextValue => getList(nextValue), 1000),
        []
    );
    const inputChange = e => {
        setListParams(state => ({ ...state, keyword: e.target.value, pageNum: 1 }));
        debouncedSave({ ...listParams, keyword: e.target.value, pageNum: 1 });
    };
    const getList = params => {
        setStatus('loading');
        userApi.getUserWorkoutList({ ...params, userId: currentUserId }, res => {
            const { code, data } = res;
            if (code === 200) {
                if (data.records.length > 0) {
                    setWorkoutList(data.records);
                    setStatus('showData');
                } else {
                    setStatus('empty');
                }
                setTotalRecord(data.total);
            } else {
                setStatus('empty');
                message.error(res.message);
            }
        });
    };
    const { drop } = useAliveController();
    return (
        <div className="page-main points table_page">
            <div className="flex-between page_head t72_b50">
                <div
                    className="come_back"
                    onClick={() => {
                        drop('UserWorkoutRecord');
                        navigate('/user/detail');
                    }}
                >
                    <img src={comeBackImg} alt="" />
                    Back
                </div>
            </div>
            <div className="search_box">
                <Space wrap>
                    <Input
                        allowClear
                        value={listParams.keyword}
                        onChange={inputChange}
                        className="search_input"
                        size="large"
                        placeholder="Search"
                        style={{ width: 210 }}
                        maxLength={50}
                        prefix={<img src={searchIcon} alt="" />}
                    />
                    <RangePicker
                        placeholder={['dd/mm/yyyy', 'dd/mm/yyyy']}
                        style={{ width: 258 }}
                        suffixIcon={<img src={calendarIcon} alt="" />}
                        onChange={dateOnChange}
                        format={'DD/MM/YYYY'}
                        disabledDate={disabledDate}
                    />
                </Space>
            </div>
            <TableBox
                status={status}
                columns={columns}
                data={workoutList}
                rowKey="groupId"
                className="is_clickable"
                onRow={record => {
                    navigate(`/user/workoutDetail?groupId=${record.groupId}`);
                }}
            />
            {status === 'showData' ? (
                <Pagination total={totalRecord} pageChange={handlePageChange} pageNo={listParams.pageNum} />
            ) : (
                <></>
            )}
        </div>
    );
};
export default WorkoutRecord;
