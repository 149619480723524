import React, { ReactNode, Suspense } from 'react';
import { HashRouter, Routes, Route } from 'react-router-dom';
import { AxiosInterceptor } from './utils/index';
import '@/assets/fonts/font.scss';
import routes from './router';
import NProgress from './components/nprogress';
const App = () => {
    return (
        <HashRouter>
            <AxiosInterceptor>
                <Suspense>
                    <Routes>
                        {routes.map((route, index) => {
                            return (
                                <Route
                                    path={route.path}
                                    key={index}
                                    element={<route.component routes={route.childrens ? route.childrens : []} />}
                                ></Route>
                            );
                        })}
                    </Routes>
                </Suspense>
            </AxiosInterceptor>
        </HashRouter>
    );
};

export default App;
