import React, { useState, useEffect } from 'react';
import LayoutMain from '@/layout';
import { useNavigate } from 'react-router-dom';
const Main = (props: any) => {
    const navigate = useNavigate();
    const [token, setToken] = useState(sessionStorage.getItem('geonasium-admin-satoken'));
    useEffect(() => {
        if (!token) navigate('/login');
    });

    useEffect(() => {
        function rightCartData() {
            const item = JSON.parse(sessionStorage.getItem('geonasium-admin-satoken') as any);
            if (item) {
                setToken(item);
            }
        }
        window.addEventListener('storage', rightCartData);

        return () => {
            window.removeEventListener('storage', rightCartData);
        };
    }, []);

    return (
        <>
            {token ? (
                <div>
                    <LayoutMain routes={props.routes ? props.routes : []} />
                </div>
            ) : (
                ''
            )}
        </>
    );
};

export default Main;
