import shareImg1 from '@/assets/images/share/share_type1.png';
import shareImg2 from '@/assets/images/share/share_type2.png';
import shareImg3 from '@/assets/images/share/share_type3.png';
import shareImg4 from '@/assets/images/share/share_type4.png';
import shareImg5 from '@/assets/images/share/share_type5.png';
import shareImg6 from '@/assets/images/share/share_type6.png';
import rangking1 from '@/assets/images/share/rangking1.png';
import rangking2 from '@/assets/images/share/rangking2.png';
import rangking3 from '@/assets/images/share/rangking3.png';
import geoLogo from '@/assets/images/share/geo_logo.png';
import noBody from '@/assets/images/share/no_body.png';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './index.scss';
import userApi from '@/api/user';
import CallApp from 'callapp-lib';
import { message } from 'antd';
const Share = (props: any) => {
    const navigate = useNavigate();
    const query = new URLSearchParams(useLocation().search);
    const [shareType, setShareType] = useState(query.get('shareType'));
    const [shareImg, setShareImg] = useState();
    const [callAppParams, setCallAppParams] = useState<{
        post?: string | null;
        location?: string | null;
        group?: string | null;
        yearMonth?: string | null;
    }>({});
    useEffect(() => {
        switch (shareType) {
            case '1':
                setShareImg(shareImg1);
                break;
            case '2':
                setShareImg(shareImg2);
                setCallAppParams({ post: query.get('post') });
                break;
            case '3':
                setShareImg(shareImg3);
                setCallAppParams({ location: query.get('location') });
                break;
            case '4':
                setShareImg(shareImg4);
                setCallAppParams({ location: query.get('location') });
                break;
            case '5':
                setShareImg(shareImg5);
                break;
            case '6':
                setShareImg(shareImg6);
                break;
            case '7':
                groupRangking(query.get('group'), query.get('yearMonth'));
                setCallAppParams({ group: query.get('group'), yearMonth: query.get('yearMonth') });
                break;
            default:
                break;
        }
    }, []);
    const [groupRangkingData, setGroupRangkingData] = useState<{ avatarUrl: string; fullName: string }[]>([]);
    const groupRangking = (groupId: string | null, yearMonth: string | null) => {
        if (!groupId || !yearMonth) return;
        userApi.getGroupRanking(groupId || '', { yearMonth }, res => {
            if (res.code === 200) {
                setGroupRangkingData(res.data);
                console.log(res.data);
            } else {
                message.error(res.message);
            }
        });
    };
    const callLib = new CallApp({
        scheme: {
            protocol: 'geonasium',
            host: 'share',
            // port: '80',
        },
        intent: {
            package: 'au.com.geonasium.user',
            scheme: 'geonasium',
        },
        fallback: '',
        appstore: '',
    });
    const callApp = async () => {
        await callLib.open({
            param: { ...callAppParams, type: shareType },
            path: 'openwith',
            callback: function () {
                navigate('/downLoadApp');
            },
        });
    };
    return (
        <div className="flex-center share_page">
            {shareType !== '7' ? (
                <img className="share_img" src={shareImg} alt="" onClick={callApp} />
            ) : (
                <div className="group_rangking">
                    <h2>Last Months Results</h2>
                    <h3>AUGUST</h3>
                    <div className="flex-center">
                        <div className="rangking_2">
                            <div className="member_avatar">
                                <img src={groupRangkingData.length > 1 ? groupRangkingData[1].avatarUrl : ''} alt="" />
                            </div>
                            <div className="medal_box">
                                <img src={rangking2} alt="" />
                            </div>
                        </div>
                        <div className="rangking_1">
                            <div className="member_avatar">
                                <img src={groupRangkingData.length > 0 ? groupRangkingData[0].avatarUrl : ''} alt="" />
                            </div>
                            <div className="medal_box">
                                <img src={rangking1} alt="" />
                            </div>
                        </div>
                        <div className="rangking_3">
                            <div className="member_avatar">
                                {groupRangkingData.length > 2 ? (
                                    <img src={groupRangkingData[2].avatarUrl} alt="" />
                                ) : (
                                    <div className="sky_blue flex-center">
                                        <img src={noBody} style={{ width: 20, height: 20 }} alt="" />
                                    </div>
                                )}
                            </div>
                            <div className="medal_box">
                                <img src={rangking3} alt="" />
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div className="member_name top2">
                            {groupRangkingData.length > 1 ? groupRangkingData[1].fullName : ''}
                        </div>
                        <div className="member_name top1">
                            {groupRangkingData.length > 0 ? groupRangkingData[0].fullName : ''}
                        </div>
                        <div className="member_name top3">
                            {groupRangkingData.length > 2 ? groupRangkingData[2].fullName : '-'}
                        </div>
                    </div>
                    <div className="geo_logo">
                        <img src={geoLogo} alt="" />
                    </div>
                </div>
            )}
        </div>
    );
};
export default Share;
