import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'babel-polyfill';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './assets/css/default.scss';
import './assets/fonts/font.scss';
import 'antd/dist/reset.css';
import '@/style/base.scss';
import '@/style/public.scss';
import '@/assets/css/antd-base.scss';
import { ConfigProvider } from 'antd';
import store from './store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import Utils from './utils/format';
import utilsNew from './utils/utils';
import { AliveScope } from 'react-activation';
(window as any).utils = Utils;
(window as any).format = utilsNew;
const persistor = persistStore(store);
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <Provider store={store}>
        {/* <React.StrictMode> */}
        <PersistGate loading={null} persistor={persistor}>
            <ConfigProvider theme={{ token: { colorPrimary: '#0085FF' } }}>
                <AliveScope>
                    <App />
                </AliveScope>
            </ConfigProvider>
        </PersistGate>
        {/* </React.StrictMode> */}
    </Provider>
);
