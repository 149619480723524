import axios from '../utils/index';
import { Pat, HttpResponse } from '@/typings/request';
const userApi = {
    getUserList(params: Pat, callback: (T: any) => void) {
        axios
            .get(`/admin/user/list`, {
                params,
            })
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    disableUser(params: Pat, callback: (T: any) => void) {
        axios
            .put(`/admin/user/disable/${params.userId}?isDisable=${params.isDisable}`)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    deleteUser(params: Pat, callback: (T: any) => void) {
        axios
            .delete(`/admin/user/delete/${params.userId}`)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getUserDetail(params: Pat, callback: (T: any) => void) {
        axios
            .get(`/admin/user/detail/${params.userId}`)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    geoPointsRanking(userId: string, params: Pat, callback: (T: any) => void) {
        axios
            .get(`/admin/user/geo-points-ranking/${userId}`, { params })
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    editPoints(data: Pat, callback: (T: any) => void) {
        axios
            .put(`/admin/user/points-update`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getPointsList(params: Pat, callback: (T: any) => void) {
        axios
            .get(`/admin/user/points-list/${params.userId}`, { params })
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getUserWorkoutList(params: Pat, callback: (T: any) => void) {
        axios
            .get(`/admin/user/workout-list/${params.userId}`, { params })
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getUserWorkoutDetail(params: Pat, callback: (T: any) => void) {
        axios
            .get(`/admin/user/workout-detail/${params.userId}/${params.groupId}`)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    deleteUserWorkout(userId: string, groupId: string, callback: (T: any) => void) {
        axios
            .delete(`/admin/user/workout-delete/${userId}/${groupId}`)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getUserRelationList(params: Pat, callback: (T: any) => void) {
        axios
            .get(`/admin/user/follow-list/${params.userId}`, { params })
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getUserRewardsList(url: string, userId: string, params: Pat, callback: (T: any) => void) {
        axios
            .get(`${url}${userId}`, { params })
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getUserGroupList(userId: string, params: Pat, callback: (T: any) => void) {
        axios
            .get(`/admin/user/group-list/${userId}`, { params })
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    deleteGroup(groupId: string, callback: (T: any) => void) {
        axios
            .delete(`/admin/user/group-delete/${groupId}`)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getGroupDetail(groupId: string, callback: (T: any) => void) {
        axios
            .get(`/admin/user/group-detail/${groupId}`)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getGroupRanking(groupId: string, params: Pat, callback: (T: any) => void) {
        axios
            .get(`/admin/user/group-ranking/${groupId}`, { params })
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
};
export default userApi;
