import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input, Select, Button, Table, Modal, Spin } from 'antd';
import './style/reward-detail.scss';
import downIcon from '@/assets/images/down.png';
import rewardsApi from '@/api/rewards';
import { defaultInfo } from '@/typings/allType';
import { useAliveController } from 'react-activation';
const RewardDetail = (props: any) => {
    const navigate = useNavigate();
    const [status, setStatus] = useState('loading');
    const [state, setState] = useState({
        workoutRewardsId: '',
        rewardsName: '',
        rewardsImage: '',
        redemptionCriteria: '',
        expiryDateShow: '',
        quantity: '',
        workoutListVOS: [] as defaultInfo[],
        treasureVOS: [] as defaultInfo[],
        collectionRecordVOS: [] as defaultInfo[],
    });
    useEffect(() => {
        getReward();
    }, []);
    useEffect(() => {
        const path = props.prevLocation?.pathname;
        if (path === '/rewards/workout-reward' || path === '/rewards' || path === '/rewards/pool') {
            getReward();
        }
    }, [props.prevLocation]);
    const getReward = () => {
        const workoutRewardsId = window.sessionStorage.getItem('workoutRewardsId') as string;
        if (workoutRewardsId) {
            const from = window.format.getPageQuery(window.location.href, 'from');
            setPageFrom(from);
            setState({ ...state, workoutRewardsId });
            const params = {
                redeemRewardsId: workoutRewardsId,
                numberUnlockedUsers: totalNumList[0].type,
                associatedQuantity: totalNumList[1].type,
                totalNumberUnlocks: totalNumList[2].type,
            };
            getInfo(params, []);
        }
    };
    const getInfo = (params: any, arr: any) => {
        rewardsApi.getWorkoutRewardInfo(params, res => {
            const { data, code, message } = res;
            setStatus('showData');
            if (code === 200) {
                const arrNew = arr.length ? arr : totalNumList;
                arrNew[0].value = data.numberUnlockedUsers;
                arrNew[1].value = data.associatedWorkouts;
                arrNew[2].value = data.totalNumberUnlocks;
                setTotalNumList(arrNew);
                if (!data.expiryStatus && data.expiryDate) {
                    data.expiryDateShow = window.format.foramtTimeDMYHM(+data.expiryDate);
                } else {
                    data.expiryDateShow = 'Forever';
                }
                if (data.collectionRecordVOS && data.collectionRecordVOS.length) {
                    data.collectionRecordVOS.forEach((item: defaultInfo) => {
                        item.collectionTime = window.format.foramtTimeDMYHM(+item.collectionTime);
                    });
                }
                setState({
                    ...state,
                    ...data,
                });
            } else {
                window.format.alert('error', message);
            }
        });
    };
    const handleChange = (key: number, value: string) => {
        const arr = JSON.parse(JSON.stringify(totalNumList));
        arr[key].type = value;
        // setTotalNumList([...arr]);
        const params = {
            redeemRewardsId: state.workoutRewardsId,
            numberUnlockedUsers: arr[0].type,
            associatedQuantity: arr[1].type,
            totalNumberUnlocks: arr[2].type,
        };
        getInfo(params, arr);
    };
    const [totalNumList, setTotalNumList] = useState([
        { type: '2', label: 'Total number of users unlocked reward', time: '', value: 0 },
        { type: '2', label: 'The number of workout associated', time: '', value: 0 },
        { type: '2', label: 'Total number of rewards to unlock', time: '', value: 0 },
    ]);
    const [pageFrom, setPageFrom] = useState('');
    const { drop } = useAliveController();
    const back = () => {
        // navigate(-1);
        drop('RewardDetail');
        navigate(pageFrom ? pageFrom : '/rewards');
    };
    const linkToPage = (type: number, val?: defaultInfo) => {
        if (type === 1) {
            window.sessionStorage.setItem('workoutId', val?.workoutId);
            navigate('/workouts/detail?from=/rewards/detail');
            return;
        }
        navigate('/workouts/statistics-list');
    };
    const [showMore, setShowMore] = useState(false);
    const opeation = (key: number) => {
        if (key) {
            // del
            setModalVisibile(true);
            return;
        }
        const obj = JSON.parse(JSON.stringify(state));
        delete obj.numberUnlockedUsers;
        delete obj.associatedWorkouts;
        delete obj.totalNumberUnlocks;
        delete obj.workoutListVOS;
        delete obj.treasureVOS;
        delete obj.collectionRecordVOS;
        window.sessionStorage.setItem('workoutRewardInfo', JSON.stringify(obj));
        navigate('/rewards/workout-reward');
    };
    const confirmDel = () => {
        const params = {
            id: state.workoutRewardsId,
        };
        setDelLoading(true);
        rewardsApi.delWorkoutReward(params, res => {
            const { data, code, message } = res;
            setDelLoading(false);
            if (code === 200) {
                setModalVisibile(false);
                back();
            } else if (code === 1000) {
                setModalVisibile(false);
                setAlertModalVisibile(true);
            } else {
                window.format.alert('error', message);
            }
        });
    };
    const [modalVisibile, setModalVisibile] = useState(false);
    const [alertModalVisibile, setAlertModalVisibile] = useState(false);
    const [delLoading, setDelLoading] = useState(false);
    return (
        <>
            <div className="RewardDetail-detail_main">
                <div className="top_back r-left">
                    <div className="back_box r-left" onClick={back}>
                        <i className="back_icon"></i>
                        Back
                    </div>
                    <Button
                        className="r-defaultBlick_btn marLeft"
                        style={{ width: 146 }}
                        icon={<></>}
                        onClick={() => opeation(0)}
                    >
                        Edit
                    </Button>
                    <div className="space15"></div>
                    <Button
                        className="r-defaultBlick_btn"
                        style={{ width: 146 }}
                        icon={<></>}
                        onClick={() => opeation(1)}
                    >
                        Remove
                    </Button>
                </div>
                {status === 'loading' ? (
                    <div className="data_loading flex-center" style={{ height: 'auto' }}>
                        <Spin />
                    </div>
                ) : (
                    <section className="detail_box">
                        <ul className="r-left topNum_ul">
                            {totalNumList.map((v, i) => {
                                return (
                                    <li key={i} className={`wordout_card r-left ${i === 1 ? 'space_box' : ''}`}>
                                        <span className="card_left">
                                            <div className="card_label">{v.label}</div>
                                            <Select
                                                // value={v.type}
                                                defaultValue="2"
                                                style={{ width: 158 }}
                                                onChange={val => handleChange(i, val)}
                                                className="r-select r-select_mini"
                                                suffixIcon={<i className="sel_arrow_icon"></i>}
                                                options={[
                                                    { value: '0', label: 'Before Today' },
                                                    { value: '1', label: 'This Year' },
                                                    { value: '2', label: 'This Month' },
                                                    { value: '3', label: 'Today' },
                                                ]}
                                            />
                                        </span>
                                        <span className="card_val marLeft">{v.value}</span>
                                    </li>
                                );
                            })}
                        </ul>
                        <div className="workouts_action">
                            <div className="info_row">
                                <div className="info_tip">REWARDS NAME : </div>
                                <div className="reward_name r-left">
                                    {state.rewardsImage && state.rewardsImage !== null ? (
                                        <img src={state.rewardsImage} className="reward_img" alt="" />
                                    ) : (
                                        <span className="user_img_text">
                                            {window.format.getFirstLetter(state.rewardsName)}
                                        </span>
                                    )}
                                    {state.rewardsName}
                                </div>
                            </div>
                            <div className="info_row r-top">
                                <span className="info_tip">REWARDS ID : </span>
                                <span className="info_msg">{state.workoutRewardsId}</span>
                            </div>
                            <div className="info_row r-top">
                                <span className="info_tip">REDEMPTION CRITERIA :</span>
                                <span className="info_msg">
                                    {+state.redemptionCriteria === 0 ? 'Time based' : 'Quantity based'}
                                </span>
                            </div>
                            {state.redemptionCriteria ? (
                                <div className="info_row r-top">
                                    <span className="info_tip">TOTAL AMOUNT :</span>
                                    <span className="info_msg">{state.quantity ?? 0}</span>
                                </div>
                            ) : (
                                <></>
                            )}
                            <div className="info_row r-top">
                                <span className="info_tip">EXPIRY DATE :</span>
                                <span className="info_msg">{state.expiryDateShow}</span>
                            </div>
                        </div>
                        <div
                            className="workouts_action mar20"
                            style={{ display: state.workoutListVOS.length ? 'block' : 'none' }}
                        >
                            <div className="info_row r-top">
                                <span className="info_tip">ASSOCIATED WORKOUTS :</span>
                                <span className="info_msg">{state.workoutListVOS.length ?? 0}</span>
                            </div>
                            <div className="r-left workout_nameList" style={{ flexWrap: 'wrap' }}>
                                {state.workoutListVOS.map((v, i) => {
                                    return (
                                        <span key={i} className="workout_name" onClick={() => linkToPage(1, v)}>
                                            {v.workoutName}
                                        </span>
                                    );
                                })}
                            </div>
                        </div>
                        <div
                            className="workouts_action mar20"
                            style={{ display: state.treasureVOS.length ? 'block' : 'none' }}
                        >
                            <div className="info_row r-top">
                                <span className="info_tip">ASSOCIATED TREASURE :</span>
                                <span className="info_msg">{state.treasureVOS.length ?? 0}</span>
                            </div>
                            <div className="r-left info_row" style={{ flexWrap: 'wrap' }}>
                                {state.treasureVOS.map((v, i) => {
                                    return (
                                        <span key={i} className="info_msg" style={{ margin: '0 38px 10px 0' }}>
                                            {v.treasureName}
                                        </span>
                                    );
                                })}
                            </div>
                        </div>
                        <div
                            className="workout-location-statistics"
                            style={{ display: state.collectionRecordVOS.length ? 'block' : 'none' }}
                        >
                            <div className="r-left location_statisticsTip">
                                REDEMPTION
                                <div className="r-left marLeft">
                                    <span
                                        className="view_box"
                                        onClick={() => {
                                            setShowMore(!showMore);
                                        }}
                                    >
                                        view
                                    </span>
                                    <i className={`downIcon_btm ${showMore ? 'roteIcon' : ''}`}></i>
                                </div>
                            </div>
                            {showMore ? (
                                <ul className={`workout_list user_listBox ${showMore ? 'showList' : ''}`}>
                                    <li className="workout_item_header r-left">
                                        <span className="itemHeader_cell">USER’S NAME</span>
                                        <span className="itemHeader_cell">COMPLETED TIME</span>
                                    </li>
                                    {state.collectionRecordVOS.map((item: defaultInfo, i: number) => {
                                        return (
                                            <li className={`workout_item r-left`} key={i}>
                                                <span className="item_cell r-left">
                                                    <img src={item.avatarUrl} className="user_img" alt="" />
                                                    {item.fullName}
                                                </span>
                                                <span className="item_cell item_cellMini">{item.collectionTime}</span>
                                            </li>
                                        );
                                    })}
                                </ul>
                            ) : (
                                ''
                            )}
                        </div>
                    </section>
                )}
            </div>

            <Modal
                className="r-modal modal_no_padding"
                maskClosable={false}
                centered={true}
                open={modalVisibile}
                onCancel={() => setModalVisibile(false)}
                footer={''}
                title={''}
                key="rewardModal"
                closable={false}
            >
                <div className="modal_form">
                    <p className="del_tip">Are you sure to delete this reward?</p>
                    <div className="r-center" style={{ marginTop: 50 }}>
                        <Button
                            className="r-defaultBlick_btn"
                            style={{ flex: 1 }}
                            onClick={() => setModalVisibile(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="r-primary_btn"
                            type="primary"
                            style={{ flex: 1, marginLeft: 15 }}
                            loading={delLoading}
                            onClick={() => confirmDel()}
                        >
                            Confirm
                        </Button>
                    </div>
                </div>
            </Modal>

            <Modal
                className="r-modal modal_no_padding"
                maskClosable={false}
                centered={true}
                open={alertModalVisibile}
                onCancel={() => setAlertModalVisibile(false)}
                key="rewardModalCon"
                footer={''}
                title={''}
                closable={false}
            >
                <div className="modal_form">
                    <p className="del_tip">The reward is being used in the reward pool, please remove it first.</p>
                    <div className="r-center" style={{ marginTop: 50 }}>
                        <Button
                            className="r-primary_btn"
                            type="primary"
                            style={{ width: 194 }}
                            onClick={() => {
                                setAlertModalVisibile(false);
                                setModalVisibile(false);
                            }}
                        >
                            Okay
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};
export default RewardDetail;
