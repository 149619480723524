import { Input, Space, Button, message, Modal } from 'antd';
import comeBackImg from '@/assets/images/back.png';
import searchIcon from '@/assets/images/search.png';
import type { ColumnsType } from 'antd/es/table';
import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Pagination from '@/components/pagination';
import TableBox from '@/components/tableBox';
import dayjs from 'dayjs';
import userApi from '@/api/user';
import { useSelector } from 'react-redux';
import { useAliveController } from 'react-activation';
import debounce from 'lodash.debounce';
import { useAppDispatch } from '@/store/hooks';
import { setIsGetList } from '@/store/stateSlice';
interface DataType {
    groupName: string;
    memberCount: string;
    createTime: string;
    administrator: boolean;
    groupId: string;
}
interface getListParams {
    pageNum: number;
    pageSize: number;
    keyWord: string;
}
const GroupList = (props: any) => {
    const dispatch = useAppDispatch();
    const { currentUserId } = useSelector((state: any) => state.state);
    const { isGetList } = useSelector((state: any) => state.state);
    useEffect(() => {
        if (isGetList) {
            getList(listParams);
            dispatch(setIsGetList(false));
        }
    }, [isGetList]);
    const navigate = useNavigate();
    const [status, setStatus] = useState('showData');
    const [listParams, setListParams] = useState<getListParams>({
        pageNum: 1,
        pageSize: 10,
        keyWord: '',
    });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [totalRecord, setTotalRecord] = useState(0);
    const handlePageChange = prop => {
        setListParams(state => ({ ...state, pageNum: prop }));
    };
    const columns: ColumnsType<DataType> = [
        {
            title: 'GROUP NAME',
            dataIndex: 'groupName',
            key: 'groupName',
        },
        {
            title: 'NUMBER OF MEMBERS',
            dataIndex: 'memberCount',
            key: 'memberCount',
        },
        {
            title: 'CREATION DATE/JOIN DATE',
            dataIndex: 'createTime',
            key: 'createTime',
            render: time => dayjs(+time).format('DD/MM/YYYY'),
        },
        {
            title: 'ADMIN',
            dataIndex: 'administrator',
            key: 'administrator',
            render: administrator => (administrator ? 'YES' : 'NO'),
        },
        {
            title: '',
            dataIndex: 'type',
            key: 'type',
            render: (status, record) => (
                <Button
                    icon={<></>}
                    className="table_button"
                    onClick={e => {
                        e.stopPropagation();
                        setCurrentGroup(record);
                        setIsModalOpen(true);
                    }}
                >
                    Remove
                </Button>
            ),
        },
    ];
    useEffect(() => {
        getList(listParams);
    }, [listParams]);
    const debouncedSave = useCallback(
        debounce(nextValue => setListParams(nextValue), 1000),
        []
    );
    const inputChange = e => {
        debouncedSave({ ...listParams, keyword: e.target.value, pageNum: 1 });
    };
    const [groupList, setGroupList] = useState<DataType | null>(null);
    const getList = params => {
        setStatus('loading');
        userApi.getUserGroupList(currentUserId, params, res => {
            const { code, data } = res;
            if (code === 200) {
                if (data.records.length > 0) {
                    setGroupList(data.records);
                    setStatus('showData');
                } else {
                    setStatus('empty');
                }
                setTotalRecord(data.total);
            } else {
                setStatus('empty');
                message.error(res.message);
            }
        });
    };
    const [currentGroup, setCurrentGroup] = useState<DataType>({
        groupName: '',
        memberCount: '',
        createTime: '',
        administrator: false,
        groupId: '',
    });
    const onDeleteGroup = () => {
        userApi.deleteGroup(currentGroup.groupId, res => {
            if (res.code === 200) {
                message.success(res.message);
                getList(listParams);
                setIsModalOpen(false);
            } else {
                message.error(res.message);
            }
        });
    };
    const { drop } = useAliveController();
    return (
        <div className="page-main points table_page">
            <div className="flex-between page_head t72_b50">
                <div
                    className="come_back"
                    onClick={async () => {
                        await drop('/user/group');
                        navigate('/user/detail');
                    }}
                >
                    <img src={comeBackImg} alt="" />
                    Back
                </div>
            </div>
            <div className="search_box">
                <Space wrap>
                    <Input
                        allowClear
                        className="search_input"
                        size="large"
                        placeholder="Search"
                        style={{ width: 210 }}
                        maxLength={50}
                        prefix={<img src={searchIcon} alt="" />}
                        onChange={inputChange}
                    />
                </Space>
            </div>
            <TableBox
                status={status}
                columns={columns}
                className="is_clickable"
                onRow={record => {
                    navigate('/user/groupDetail?identity=' + record.groupId);
                }}
                data={groupList}
                rowKey="groupId"
            />
            {status === 'showData' ? (
                <Pagination total={totalRecord} pageChange={handlePageChange} pageNo={listParams.pageNum} />
            ) : (
                <></>
            )}
            <Modal
                className="modal-suspend"
                closable={false}
                maskClosable={false}
                centered={true}
                open={isModalOpen}
                onCancel={() => {
                    setIsModalOpen(false);
                }}
                footer={[]}
            >
                <div className="modal-tip">
                    After deleting this group, all data in this group will be deleted. This operation is irrevocable.
                    Are you sure you want to continue?
                </div>
                <Space size={12} style={{ marginTop: 43 }}>
                    <Button
                        onClick={() => {
                            setIsModalOpen(false);
                        }}
                        style={{ width: 194, height: 54 }}
                    >
                        Cancel
                    </Button>
                    <Button type="primary" htmlType="submit" onClick={onDeleteGroup} style={{ width: 194, height: 54 }}>
                        Confirm
                    </Button>
                </Space>
            </Modal>
        </div>
    );
};
export default GroupList;
