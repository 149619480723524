import comeBackImg from '@/assets/images/back.png';
import type { ColumnsType } from 'antd/es/table';
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Pagination from '@/components/pagination';
import TableBox from '@/components/tableBox';
import { useSelector } from 'react-redux';
import { message } from 'antd';
import userApi from '@/api/user';
import dayjs from 'dayjs';
interface DataType {
    rewardName: string;
    nameOrAmount: string;
    receiveTime: number;
    offersName: string;
    brandName: string;
    brandLocation: string;
    createTime: string;
    exchangeTypes: boolean;
}
interface getListParams {
    pageNum: number;
    pageSize: number;
}

const UserRewardsRedemption = (props: any) => {
    const { currentUserId } = useSelector((state: any) => state.state);
    const query = new URLSearchParams(useLocation().search);
    useEffect(() => {
        getList(listParams);
    }, []);
    const navigate = useNavigate();
    const [status, setStatus] = useState('showData');
    const [listParams, setListParams] = useState<getListParams>({
        pageNum: 1,
        pageSize: 10,
    });
    const [totalRecord, setTotalRecord] = useState(0);

    const handlePageChange = prop => {
        setListParams(state => ({ ...state, pageNum: prop }));
        getList({ ...listParams, pageNum: prop });
    };
    const brandRewards: ColumnsType<DataType> = [
        {
            title: 'OFFERS NAME',
            dataIndex: 'offersName',
            key: 'offersName',
        },
        {
            title: 'BRAND NAME',
            dataIndex: 'offersName',
            key: 'offersName',
        },
        {
            title: 'BRAND LOCATION',
            dataIndex: 'brandLocation',
            key: 'brandLocation',
        },
        {
            title: 'REDEMPTION DATE',
            dataIndex: 'createTime',
            key: 'createTime',
            render: time => dayjs(Number(time)).format('DD/MM/YYYY'),
        },
    ];
    const redeemRewards: ColumnsType<DataType> = [
        {
            title: 'REWARDS NAME',
            dataIndex: 'rewardName',
            render: (name, record) => (name ? name : '-'),
            key: 'rewardName',
        },
        {
            title: 'TYPE',
            dataIndex: 'exchangeTypes',
            key: 'exchangeTypes',
            render: (type, record) =>
                type === 1 ? `${record.nameOrAmount} FitLoot` : type === 0 ? record.nameOrAmount : 'Treasure',
            width: 500,
            // 0 锻炼奖励 1 兑换 2 宝藏
        },
        {
            title: 'REDEMPTION DATE',
            dataIndex: 'receiveTime',
            key: 'receiveTime',
            render: time => dayjs(Number(time)).format('DD/MM/YYYY'),
            width: 500,
        },
    ];
    const [rewardsList, setRewardsList] = useState<DataType[]>([]);
    const getList = params => {
        setStatus('loading');
        userApi.getUserRewardsList(
            query.get('type') === '1' ? '/admin/user/brand-offers/list/' : '/admin/user/redeem-rewards-entity/list/',
            currentUserId,
            params,
            res => {
                const { code, data } = res;
                if (code === 200) {
                    if (data.records.length > 0) {
                        setRewardsList(data.records);
                        setStatus('showData');
                    } else {
                        setStatus('empty');
                    }
                    setTotalRecord(data.total);
                } else {
                    setStatus('empty');
                    message.error(res.message);
                }
            }
        );
    };
    return (
        <div className="page-main fitLoot table_page">
            <div className="flex-between page_head t72_b50">
                <div
                    className="come_back"
                    onClick={() => {
                        navigate('/user/detail');
                    }}
                >
                    <img src={comeBackImg} alt="" />
                    Back
                </div>
            </div>
            <TableBox
                status={status}
                columns={query.get('type') === '1' ? brandRewards : redeemRewards}
                data={rewardsList}
                rowKey={query.get('type') === '1' ? 'createTime' : 'receiveTime'}
            />
            {status === 'showData' ? (
                <Pagination total={totalRecord} pageChange={handlePageChange} pageNo={listParams.pageNum} />
            ) : (
                <></>
            )}
        </div>
    );
};
export default UserRewardsRedemption;
