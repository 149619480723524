import { Input, Space, Select, DatePicker, message } from 'antd';
import comeBackImg from '@/assets/images/back.png';
import searchIcon from '@/assets/images/search.png';
import selectIcon from '@/assets/images/down.png';
import calendarIcon from '@/assets/images/calendar_icon.png';
import type { ColumnsType } from 'antd/es/table';
import { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Pagination from '@/components/pagination';
import TableBox from '@/components/tableBox';
import dayjs from 'dayjs';
import type { RangePickerProps } from 'antd/es/date-picker';
import homeApi from '@/api/dashboard';
import debounce from 'lodash.debounce';
import { useSelector } from 'react-redux';
interface DataType {
    ranking: string;
    fullName: string;
    avatarUrl: string;
    totalPoints: string;
}
interface getListParams {
    pageNum: number;
    pageSize: number;
    keyword: string;
    country: number | string;
    state: string | null;
    yearMonth: string;
}

const Points = (props: any) => {
    const query = new URLSearchParams(useLocation().search);
    const navigate = useNavigate();
    const [status, setStatus] = useState('showData');
    const [listParams, setListParams] = useState<getListParams>({
        pageNum: 1,
        pageSize: 10,
        keyword: '',
        country: 0,
        state: null,
        yearMonth: dayjs().format('YYYY-MM'),
    });
    const [totalRecord, setTotalRecord] = useState(0);
    const { countryList, areaData } = useSelector((state: any) => state.country);
    const [stateOptions, setStateOptions] = useState<{ value: string; label: string }[]>([]);
    const selectArea = country => {
        if (!country) return;
        const area = areaData.filter(i => {
            return i.country === country;
        });
        setStateOptions(area[0].areaList);
    };
    const disabledDate: RangePickerProps['disabledDate'] = current => {
        return current && current > dayjs().endOf('day');
    };
    const columns: ColumnsType<DataType> = [
        {
            title: 'RANKING',
            dataIndex: 'ranking',
            key: 'ranking',
        },
        {
            title: 'USER’S NAME',
            dataIndex: 'fullName',
            key: 'fullName',
            render: (name, record) => (
                <span className="user_info">
                    <img style={{ width: 25, height: 25 }} src={record.avatarUrl} alt="" />
                    {name}
                </span>
            ),
        },
        {
            title: query.get('type') === '0' ? 'POINTS ACCRUED' : 'FITLOOT ACCRUED',
            dataIndex: 'totalPoints',
            key: 'totalPoints',
            render: points => `${points} points`,
        },
    ];
    const debouncedSave = useCallback(
        debounce(nextValue => setListParams(nextValue), 1000),
        []
    );
    const inputChange = e => {
        debouncedSave({ ...listParams, keyword: e.target.value, pageNum: 1 });
    };
    useEffect(() => {
        getRanking(listParams);
    }, [listParams]);
    const [rankingList, setRankingList] = useState<DataType[]>([]);
    const getRanking = listParams => {
        setStatus('loading');
        const params = {
            pageNum: listParams.pageNum,
            pageSize: 10,
            keyword: listParams.keyword,
            type: query.get('type'),
            country: listParams.country ? listParams.country : null,
            state: listParams.country ? listParams.state : null,
            yearMonth: query.get('type') === '0' ? listParams.yearMonth : null,
        };
        homeApi.getPointsRangking(params, res => {
            if (res.code === 200) {
                if (res.data.records.length > 0) {
                    setRankingList(res.data.records);
                    setTotalRecord(res.data.total);
                    setStatus('showData');
                } else {
                    setStatus('empty');
                }
            } else {
                message.error(res.message);
                setStatus('empty');
            }
        });
    };
    const handlePageChange = prop => {
        setListParams(state => ({ ...state, pageNum: prop }));
    };
    return (
        <div className="page-main points table_page">
            <div className="flex-between page_head t72_b50">
                <div
                    className="come_back"
                    onClick={() => {
                        navigate('/dashboard');
                    }}
                >
                    <img src={comeBackImg} alt="" />
                    Back
                </div>
            </div>
            <div className="search_box">
                <Space wrap>
                    <Input
                        allowClear
                        className="search_input"
                        size="large"
                        placeholder="Search"
                        style={{ width: 210 }}
                        maxLength={50}
                        prefix={<img src={searchIcon} alt="" />}
                        onChange={inputChange}
                    />
                    {query.get('type') === '0' ? (
                        <DatePicker
                            allowClear={false}
                            style={{ width: 258 }}
                            placeholder="mm/yyyy"
                            suffixIcon={<img src={calendarIcon} alt="" />}
                            defaultValue={dayjs().endOf('day')}
                            format={'MMM YYYY'}
                            picker="month"
                            disabledDate={disabledDate}
                            onChange={month => {
                                setListParams(state => ({
                                    ...state,
                                    yearMonth: month ? dayjs(month).format('YYYY-MM') : '',
                                }));
                            }}
                        />
                    ) : (
                        ''
                    )}

                    <Select
                        suffixIcon={<img src={selectIcon} alt="" />}
                        defaultValue={0}
                        style={{ width: 138 }}
                        onChange={(value: number) => {
                            setListParams(state => ({
                                ...state,
                                country: value,
                                state: null,
                                pageNum: 1,
                            }));
                            selectArea(value);
                        }}
                        options={[{ value: 0, label: 'Global' }, ...countryList]}
                    />
                    <Select
                        suffixIcon={<img src={selectIcon} alt="" />}
                        value={listParams.state}
                        placeholder="State"
                        disabled={!listParams.country}
                        style={{ width: 138 }}
                        onChange={(value: string) => {
                            setListParams(state => ({
                                ...state,
                                state: value,
                                pageNum: 1,
                            }));
                        }}
                        options={listParams.country ? stateOptions : []}
                    />
                </Space>
            </div>
            <TableBox status={status} columns={columns} data={rankingList} rowKey="userId" />
            {status === 'showData' ? (
                <Pagination total={totalRecord} pageChange={handlePageChange} pageNo={listParams.pageNum} />
            ) : (
                <></>
            )}
        </div>
    );
};
export default Points;
