import { initializeApp } from 'firebase/app';
import { getDatabase, ref, onValue, off } from 'firebase/database';
import { useState } from 'react';
import { useAppDispatch } from '@/store/hooks';
import { setFeedBackCount, setlocationFeedBackCount } from '@/store/stateSlice';
import { useSelector } from 'react-redux';
const useFireBase = () => {
    const dispatch = useAppDispatch();
    const { feedBackCount, locationFeedBackCount } = useSelector((state: any) => state.state);
    const firebaseConfig = {
        apiKey: 'AIzaSyDqYthxuxU75Qoo06CFOKAwdou85Oj6US0',
        authDomain: 'boreal-augury-336302.firebaseapp.com',
        databaseURL: 'https://boreal-augury-336302-default-rtdb.firebaseio.com',
        projectId: 'boreal-augury-336302',
        storageBucket: 'boreal-augury-336302.appspot.com',
        messagingSenderId: '654889986277',
        appId: '1:654889986277:web:67b80674cd1f8b96b50c0c',
        measurementId: 'G-X6VLW4ZNLE',
    };
    const [state, setState] = useState({
        time: Date.parse(new Date() as any) / 1000,
        isLocationCountFirst: true,
        isCountFirst: true,
        facebackCountRef: '' as any,
    });
    const [facebackCountRef, setFacebackCountRef] = useState<null | any>(null);
    const [locationFacebackCountRef, setLocationFacebackCountRef] = useState<null | any>(null);
    const app = initializeApp(firebaseConfig);
    const database = getDatabase(app);
    const initDataBase = () => {
        // console.log(feedBackCount);
        // console.log(locationFeedBackCount);
        const locationFacebackCountRef = ref(database, `/feedback/locationRequest/count`);
        setLocationFacebackCountRef(locationFacebackCountRef);
        onValue(locationFacebackCountRef, snapshot => {
            const data = snapshot.val();
            // console.log('loaction', data);
            setState({ ...state, isLocationCountFirst: false });
            const num = data && data !== null ? data : 0;
            dispatch(setlocationFeedBackCount(num));
        });
        const facebackCountRef = ref(database, `/feedback/flaggedReport/count`);
        setFacebackCountRef(facebackCountRef);
        onValue(facebackCountRef, snapshot => {
            const data = snapshot.val();
            // console.log('flaggedReport', data);
            setState({ ...state, isCountFirst: false });
            const num = data && data !== null ? data : 0;
            dispatch(setFeedBackCount(num));
        });
    };

    const offDataBase = () => {
        off(locationFacebackCountRef);
        off(facebackCountRef);
        setLocationFacebackCountRef(null);
        setFacebackCountRef(null);
        dispatch(setlocationFeedBackCount(0));
        dispatch(setFeedBackCount(0));
        console.log('offline count dataBase');
    };
    return {
        initDataBase,
        offDataBase,
    };
};

export default useFireBase;
