import { Input, Space, Select, message } from 'antd';
import searchIcon from '@/assets/images/search.png';
import selectIcon from '@/assets/images/down.png';
import selectOptions from '@/utils/selectOptions';
import type { ColumnsType } from 'antd/es/table';
import { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Pagination from '@/components/pagination';
import TableBox from '@/components/tableBox';
import debounce from 'lodash.debounce';
import subscriptionApi from '@/api/subscription';
import dayjs from 'dayjs';
interface tableType {
    fullName: string;
    avatarUrl: string;
    paymentTime: string;
    subscriptionPrice: string;
    subscriptionPlan: string;
    dueDate: string;
    expiresTime: string;
}
interface getListParams {
    pageNum: number;
    pageSize: number;
    keyword: string;
    subscriptionPlan: number | string;
}
const Subscription = (props: any) => {
    const navigate = useNavigate();
    const [status, setStatus] = useState('showData');
    const [subscriptionType, setSubscriptionType] = useState(0);
    const [listParams, setListParams] = useState<getListParams>({
        pageNum: 1,
        pageSize: 10,
        keyword: '',
        subscriptionPlan: 0,
    });
    const [totalRecord, setTotalRecord] = useState(0);

    const handlePageChange = prop => {
        setListParams(state => ({ ...state, pageNum: prop }));
    };
    const columns: ColumnsType<tableType> = [
        {
            title: 'FULL NAME',
            dataIndex: 'fullName',
            key: 'fullName',
            render: (name, record) => (
                <span className="user_info">
                    <img style={{ width: 25, height: 25 }} src={record.avatarUrl} alt="" />
                    {name}
                </span>
            ),
        },
        {
            title: 'PAYMENT DATE',
            dataIndex: 'paymentTime',
            key: 'paymentTime',
            render: time => dayjs(+time).format('DD/MM/YYYY'),
        },
        {
            title: 'SUBSCRIPTION PLAN',
            dataIndex: 'subscriptionPlan',
            key: 'subscriptionPlan',
        },
        {
            title: 'AMOUNT',
            dataIndex: 'subscriptionPrice',
            key: 'subscriptionPrice',
            render: price => `$${price}`,
        },
        {
            title: 'DUE DATE',
            dataIndex: 'expiresTime',
            key: 'expiresTime',
            render: time => dayjs(+time).format('DD/MM/YYYY'),
        },
    ];
    const debouncedSave = useCallback(
        debounce(nextValue => setListParams(nextValue), 1000),
        []
    );
    const inputChange = e => {
        debouncedSave({ ...listParams, keyword: e.target.value, pageNum: 1 });
    };
    const [tableData, setTableData] = useState<tableType[]>();
    useEffect(() => {
        getList(listParams);
    }, [listParams]);
    const getList = params => {
        if (params.subscriptionPlan === 0) {
            delete params.subscriptionPlan;
        }
        if (!params.keyword) {
            delete params.keyword;
        }
        setStatus('loading');
        subscriptionApi.getSubscriptionList(params, res => {
            const { data, code } = res;
            if (code === 200) {
                if (data.records.length > 0) {
                    setTableData(data.records);
                    setStatus('showData');
                } else {
                    setStatus('empty');
                }
                setTotalRecord(data.total);
            } else {
                setStatus('empty');
                message.error(res.message);
            }
        });
    };
    return (
        <div className="page-main user table_page">
            <div className="search_box" style={{ marginTop: 50 }}>
                <Space wrap size={15}>
                    <Input
                        allowClear
                        className="search_input"
                        size="large"
                        placeholder="Search"
                        style={{ width: 210 }}
                        maxLength={50}
                        prefix={<img src={searchIcon} alt="" />}
                        onChange={inputChange}
                    />
                    <Select
                        value={subscriptionType}
                        suffixIcon={<img src={selectIcon} alt="" />}
                        style={{ width: 210 }}
                        onChange={(value: number) => {
                            setSubscriptionType(value);
                            setListParams(state => ({ ...state, subscriptionPlan: value, pageNum: 1 }));
                        }}
                        options={selectOptions.subscriptionPlan}
                    />
                </Space>
            </div>
            <TableBox status={status} columns={columns} data={tableData} rowKey="paymentTime" />
            {status === 'showData' ? (
                <Pagination total={totalRecord} pageChange={handlePageChange} pageNo={listParams.pageNum} />
            ) : (
                <></>
            )}
        </div>
    );
};
export default Subscription;
