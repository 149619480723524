import { Input, Space, message, Modal, Button, DatePicker, Badge } from 'antd';
import searchIcon from '@/assets/images/search.png';
import { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Pagination from '@/components/pagination';
import TableBox from '@/components/tableBox';
import type { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import calendarIcon from '@/assets/images/calendar_icon.png';
import socialsApi from '@/api/socials';
import debounce from 'lodash.debounce';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '@/store/hooks';
import { setIsGetList } from '@/store/stateSlice';
import comeBackImg from '@/assets/images/back.png';
interface userObjType {
    fullName: string;
    avatarUrl: string;
    key: number;
    createTime: string;
    postId: string;
    personallyVisible: boolean;
    countComment: string;
    countLike: string;
}
interface getListParams {
    pageNum: number;
    pageSize: number;
    keyword: string;
    startTime: string | number;
    endTime: string | number;
}

const Socials = (props: any) => {
    const currentUserId = props.userId; // If userId exists from userDetail page
    const { isGetList } = useSelector((state: any) => state.state);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (isGetList) {
            getList(listParams);
            dispatch(setIsGetList(false));
        }
    }, [isGetList]);
    const { RangePicker } = DatePicker;
    const navigate = useNavigate();
    const [status, setStatus] = useState('showData');
    const [listParams, setListParams] = useState<getListParams>({
        pageNum: 1,
        pageSize: 10,
        keyword: '',
        startTime: '',
        endTime: '',
    });
    useEffect(() => {
        getList(listParams);
    }, [listParams]);
    const [totalRecord, setTotalRecord] = useState(0);
    const disabledDate: RangePickerProps['disabledDate'] = current => {
        return current && current > dayjs().endOf('day');
    };
    const handlePageChange = prop => {
        setListParams(state => ({ ...state, pageNum: prop }));
    };
    const [currentUser, setCurrentUser] = useState<userObjType | null>(null);
    const columns = [
        !currentUserId && {
            title: 'POSTER NAME',
            dataIndex: 'name',
            key: 'name',
            render: (name, record) => (
                <span className="user_info">
                    <img style={{ width: 25, height: 25 }} src={record.avatarUrl} alt="" />
                    {record.fullName}
                </span>
            ),
        },
        {
            title: 'POST ID',
            dataIndex: 'postId',
            key: 'postId',
            render: postId =>
                currentUserId ? (
                    <span
                        className="view_more"
                        onClick={() => {
                            navigate(`/socials/post?identity=${postId}&from=/user/post`);
                        }}
                    >
                        {postId}
                    </span>
                ) : (
                    postId
                ),
        },

        {
            title: 'DATE AND TIME PUBLISHED',
            dataIndex: 'createTime',
            key: 'createTime',
            render: time => (time ? dayjs(Number(time)).format('DD/MM/YYYY HH:mm') : ''),
        },
        {
            title: 'WHO CAN VIEW',
            dataIndex: 'personallyVisible',
            key: 'personallyVisible',
            render: isOnlyMe => (isOnlyMe ? 'Only me' : 'Followers'),
        },
        {
            title: 'REACTIONS',
            dataIndex: 'countLike',
            key: 'countLike',
        },
        {
            title: 'COMMENTS',
            dataIndex: 'countComment',
            key: 'countComment',
        },
        {
            title: '',
            dataIndex: 'countComment',
            key: 'countComment',
            render: (Comments, record) => (
                <Button
                    icon={<></>}
                    className="table_button"
                    onClick={e => {
                        e.stopPropagation();
                        setIsModalOpen(true);
                        setCurrentUser(record);
                    }}
                >
                    Remove
                </Button>
            ),
        },
    ].filter(Boolean);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const debouncedSave = useCallback(
        debounce(nextValue => setListParams(nextValue), 1000),
        []
    );
    const inputChange = e => {
        debouncedSave({ ...listParams, keyword: e.target.value, pageNum: 1 });
    };
    const [unreadQuantity, setUnreadQuantity] = useState(0);
    const [tableData, setTableData] = useState<userObjType[]>();
    const getList = params => {
        if (currentUserId) {
            params.userId = currentUserId;
        }
        setStatus('loading');
        socialsApi.getPostList(params, res => {
            const { data, code } = res;
            if (code === 200) {
                if (data.records.length > 0) {
                    setUnreadQuantity(data.metaData.unreadQuantity);
                    setTableData(data.records);
                    setStatus('showData');
                } else {
                    setStatus('empty');
                }
                setTotalRecord(data.total);
            } else {
                setStatus('empty');
                message.error(res.message);
            }
        });
    };
    const dateOnChange = (dates, dateStrings) => {
        setListParams(state => ({
            ...state,
            startTime: dates ? dayjs(`${dates[0].format('YYYY-MM-DD')} 00:00:00`).valueOf() : '',
            endTime: dates ? dayjs(`${dates[1].format('YYYY-MM-DD')} 23:59:59`).valueOf() : '',
        }));
    };
    const removePost = () => {
        socialsApi.deletePost({ postId: currentUser?.postId }, res => {
            if (res.code === 200) {
                setIsModalOpen(false);
                getList(listParams);
                message.success(res.message);
            } else {
                message.error(res.message);
            }
        });
    };
    return (
        <div className="page-main socials table_page">
            {currentUserId ? (
                <div className="flex-between page_head" style={{ marginTop: 72 }}>
                    <div
                        className="come_back"
                        onClick={() => {
                            navigate('/user/detail');
                        }}
                    >
                        <img src={comeBackImg} alt="" />
                        Back
                    </div>
                </div>
            ) : (
                ''
            )}
            <div className="search_box flex-between" style={{ marginTop: currentUserId ? 40 : 50 }}>
                <div>
                    <Space wrap size={15}>
                        <Input
                            allowClear
                            onChange={inputChange}
                            className="search_input"
                            size="large"
                            placeholder="Search"
                            style={{ width: 210 }}
                            maxLength={50}
                            prefix={<img src={searchIcon} alt="" />}
                        />
                        <RangePicker
                            placeholder={['dd/mm/yyyy', 'dd/mm/yyyy']}
                            style={{ width: 258 }}
                            suffixIcon={<img src={calendarIcon} alt="" />}
                            format={'DD/MM/YYYY'}
                            disabledDate={disabledDate}
                            onChange={dateOnChange}
                        />
                    </Space>
                </div>
                {!currentUserId ? (
                    <div className="report_all">
                        {unreadQuantity > 0 ? <div className="unread_badge">{unreadQuantity}</div> : ''}
                        <Button
                            icon={<></>}
                            style={{ height: 48, color: '#0085FF', borderColor: '#0085FF' }}
                            onClick={() => {
                                navigate('/socials/reportReportApplication');
                            }}
                        >
                            Report Application
                        </Button>
                    </div>
                ) : (
                    ''
                )}
            </div>
            <TableBox
                status={status}
                columns={columns}
                data={tableData}
                className={currentUserId ? '' : 'is_clickable'}
                onRow={record => {
                    if (!currentUserId) {
                        setCurrentUser(record);
                        navigate(`/socials/post?identity=${record.postId}`);
                    }
                }}
                rowKey="postId"
            />
            {status === 'showData' ? (
                <Pagination total={totalRecord} pageChange={handlePageChange} pageNo={listParams.pageNum} />
            ) : (
                <></>
            )}
            <Modal
                className="modal-suspend"
                closable={false}
                maskClosable={false}
                centered={true}
                open={isModalOpen}
                onCancel={() => {
                    setIsModalOpen(false);
                }}
                footer={[]}
            >
                <div className="modal-tip">Are you sure you wish to remove this Post?</div>
                <Space size={12} style={{ marginTop: 43 }}>
                    <Button
                        onClick={() => {
                            setIsModalOpen(false);
                        }}
                        style={{ width: 194, height: 54 }}
                    >
                        Cancel
                    </Button>
                    <Button type="primary" htmlType="submit" onClick={removePost} style={{ width: 194, height: 54 }}>
                        Confirm
                    </Button>
                </Space>
            </Modal>
        </div>
    );
};
export default Socials;
