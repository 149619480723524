import { Modal, Space, Button, message } from 'antd';
import userApi from '@/api/user';
const UserStatusModal = (props: any) => {
    const { isRemove, isModalOpen, modalClose, currentUser } = props.params;
    const modifyStatus = () => {
        if (isRemove) {
            // remove
            userApi.deleteUser({ userId: currentUser.userId }, res => {
                const { code } = res;
                if (code === 200) {
                    message.success(res.message);
                    modalClose(1);
                }
            });
        } else {
            // Active or Inactive
            userApi.disableUser({ userId: currentUser.userId, isDisable: !currentUser.disabled }, res => {
                const { code } = res;
                if (code === 200) {
                    message.success(res.message);
                    modalClose(2);
                }
            });
        }
    };
    return (
        <Modal
            destroyOnClose={true}
            className="modal-suspend"
            closable={false}
            maskClosable={false}
            centered={true}
            open={isModalOpen}
            onCancel={() => {
                modalClose(0);
            }}
            footer={[]}
        >
            <div className="modal-tip">
                {isRemove
                    ? 'After deleting the account, all data of the user will be deleted. This operation is irrevocable. Are you sure you want to continue?'
                    : currentUser?.disabled
                    ? 'After freeing the account, the user can log in the APP normally. Are you sure you want to continue?'
                    : 'After disabling the account, the user will no longer be able to log in to the APP. Are you sure you want to continue?'}
            </div>
            <Space size={12} style={{ marginTop: 43 }}>
                <Button
                    onClick={() => {
                        modalClose(0);
                    }}
                    style={{ width: 194, height: 54 }}
                >
                    Cancel
                </Button>
                <Button type="primary" htmlType="submit" onClick={modifyStatus} style={{ width: 194, height: 54 }}>
                    Confirm
                </Button>
            </Space>
        </Modal>
    );
};
export default UserStatusModal;
